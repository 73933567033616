import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { AxiosError } from 'axios';
import { Text, Button, Popover, Group, Switch, Select } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useViewportSize } from '@mantine/hooks';
import CustomSelectItem, {
  ItemSchema,
} from '../../components/CustomSelectItem/CustomSelectItem';
import useAccountsService from '../../services/accounts';
import { handleCustomSelectCreate, capitalizeTransform } from '../../lib/util';
import useVirtualNubansService from '../../services/virtualNubans';
import { GetAccountsResponse } from '../../types/accountsTypes';
import useNotification from '../../hooks/useNotification';
import { GetVirtualNubansResponse } from '../../types/virtualNubansTypes';
import { ReactComponent as ChevronDownGray } from '../../assets/svg/chevron-down-gray.svg';
import usePermissions from '../../hooks/usePermissions';

interface IPaymentsFilter {
  accountId: string;
  setAccountId: (value: string) => void;
  virtualNubanId: string;
  setVirtualNubanId: (value: string) => void;
  dateFrom: string;
  setDateFrom: (value: string) => void;
  dateTo: string;
  setDateTo: (value: string) => void;
  onFilterItemChange: (
    value: string,
    itemState: string,
    setItemState: (value: string) => void
  ) => void;
}

const PaymentsFilter = ({
  accountId,
  setAccountId,
  virtualNubanId,
  setVirtualNubanId,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  onFilterItemChange,
}: IPaymentsFilter) => {
  const { width } = useViewportSize();
  const [expandFilter, setExpandFilter] = useState<boolean>(false);
  const [filterNumber, setFilterNumber] = useState<number>(0);
  const [accountChecked, setAccountChecked] = useState<boolean>(false);
  const [virtualNubansChecked, setVirtualNubansChecked] =
    useState<boolean>(false);
  const { getAccounts } = useAccountsService();
  const { getVirtualNubans } = useVirtualNubansService();
  const [accounts, setAccounts] = useState<ItemSchema[]>([]);
  const [virtualNubans, setVirtualNubans] = useState<ItemSchema[]>([]);
  const [dateFromControl, setDateFromControl] = useState<Date | null>(null);
  const [dateFromChecked, setDateFromChecked] = useState<boolean>(false);
  const [dateToControl, setDateToControl] = useState<Date | null>(null);
  const [dateToChecked, setDateToChecked] = useState<boolean>(false);
  const { handleError } = useNotification();
  const { accountRead, virtualAaccountRead } = usePermissions();

  useEffect(() => {
    if (accountRead) {
      handleGetAccounts();
    }
    if (virtualAaccountRead) {
      handleGetVirtualNubans();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (accountChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange('', accountId, setAccountId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [accountChecked]);

  useEffect(() => {
    if (virtualNubansChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange('', virtualNubanId, setVirtualNubanId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [virtualNubansChecked]);

  useEffect(() => {
    if (dateFromChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      setDateFromControl(null);
      onFilterItemChange('', dateFrom, setDateFrom);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [dateFromChecked]);

  useEffect(() => {
    if (dateToChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      setDateToControl(null);
      onFilterItemChange('', dateTo, setDateTo);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [dateToChecked]);

  const handleGetAccounts = () => {
    getAccounts({ page: 0, size: 50 })
      .then((res: GetAccountsResponse) => {
        const data: ItemSchema[] = res.data.map((account) => ({
          label: capitalizeTransform(account?.accountName ?? ''),
          description: `${account.accountNumber} (${account.id})`,
          value: account.id,
        }));

        setAccounts(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleGetVirtualNubans = () => {
    getVirtualNubans({ page: 0, size: 50 })
      .then((res: GetVirtualNubansResponse) => {
        const data: ItemSchema[] = res.data.map((virtualNuban) => ({
          extravalue: virtualNuban.accountNumber,
          label: capitalizeTransform(virtualNuban.accountName),
          description: `${capitalizeTransform(virtualNuban.bank.name)} - ${
            virtualNuban.accountNumber
          }`,
          value: virtualNuban.id,
        }));

        setVirtualNubans(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  return (
    <div className="filter-container">
      <Popover
        opened={expandFilter}
        onChange={setExpandFilter}
        position="bottom-end"
        width={320}
        shadow="lg"
      >
        <Popover.Target>
          <Button
            variant="default"
            size={width > 700 ? 'md' : 'sm'}
            onClick={() => {
              setExpandFilter(!expandFilter);
            }}
            compact
            className="compact-btn unstyled"
            rightIcon={<ChevronDownGray />}
          >
            Filters{' '}
            {filterNumber > 0 && (
              <span className="filter-number">{filterNumber}</span>
            )}
          </Button>
        </Popover.Target>

        <Popover.Dropdown>
          <div className="filter-menu">
            <Group align="center" position="apart" mt={10}>
              <Text fw={500} fz={14}>
                Account ID
              </Text>

              <Switch
                checked={accountChecked}
                onChange={(event) =>
                  setAccountChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {accountChecked && (
              <div className="filter-item-expanded">
                <Select
                  placeholder="Select Account/ Input ID"
                  value={accountId}
                  onChange={(value: string) => {
                    onFilterItemChange(value, accountId, setAccountId);
                    setExpandFilter(false);
                  }}
                  searchable
                  itemComponent={CustomSelectItem}
                  data={accounts}
                  maxDropdownHeight={400}
                  creatable
                  getCreateLabel={(query) => `Select ${query}`}
                  onCreate={(query) => {
                    return handleCustomSelectCreate(
                      query,
                      accounts,
                      () => onFilterItemChange(query, accountId, setAccountId),
                      setAccounts
                    );
                  }}
                />
              </div>
            )}

            <Group align="center" position="apart" mt={10}>
              <Text fw={500} fz={14}>
                Virtual Nuban ID
              </Text>

              <Switch
                checked={virtualNubansChecked}
                onChange={(event) =>
                  setVirtualNubansChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {virtualNubansChecked && (
              <div className="filter-item-expanded">
                <Select
                  placeholder="Select Virtual Nuban/ Input ID"
                  value={virtualNubanId}
                  onChange={(value: string) => {
                    onFilterItemChange(
                      value,
                      virtualNubanId,
                      setVirtualNubanId
                    );
                    setExpandFilter(false);
                  }}
                  searchable
                  itemComponent={CustomSelectItem}
                  data={virtualNubans}
                  maxDropdownHeight={400}
                  creatable
                  getCreateLabel={(query) => `Select ${query}`}
                  onCreate={(query) => {
                    return handleCustomSelectCreate(
                      query,
                      virtualNubans,
                      () =>
                        onFilterItemChange(
                          query,
                          virtualNubanId,
                          setVirtualNubanId
                        ),
                      setVirtualNubans
                    );
                  }}
                />
              </div>
            )}

            <Group align="center" position="apart" mt={10}>
              <Text fw={500} fz={14}>
                Date from
              </Text>

              <Switch
                checked={dateFromChecked}
                onChange={(event) =>
                  setDateFromChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {dateFromChecked && (
              <div className="filter-item-expanded">
                <DatePicker
                  placeholder="Pick date from"
                  value={dateFromControl}
                  onChange={(value: Date) => {
                    setDateFromControl(value);
                    if (value) {
                      onFilterItemChange(
                        moment(value).format('YYYY-MM-DD'),
                        dateFrom,
                        setDateFrom
                      );
                      setExpandFilter(false);
                    } else {
                      onFilterItemChange('', dateFrom, setDateFrom);
                    }
                  }}
                />
              </div>
            )}

            <Group align="center" position="apart" mt={10}>
              <Text fw={500} fz={14}>
                Date to
              </Text>

              <Switch
                checked={dateToChecked}
                onChange={(event) =>
                  setDateToChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {dateToChecked && (
              <div className="filter-item-expanded">
                <DatePicker
                  placeholder="Pick date to"
                  value={dateToControl}
                  onChange={(value: Date) => {
                    setDateToControl(value);
                    if (value) {
                      onFilterItemChange(
                        moment(value).format('YYYY-MM-DD'),
                        dateTo,
                        setDateTo
                      );
                    } else {
                      onFilterItemChange('', dateTo, setDateTo);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default PaymentsFilter;
