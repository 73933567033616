import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Tabs,
  Group,
  Button,
  Badge,
  Text,
  LoadingOverlay,
  Divider,
  ScrollArea,
  Table,
  Tooltip,
  Drawer,
  ActionIcon,
} from '@mantine/core';
import useSubAccountsService from '../../services/subAccounts';
import { AccountBalanceType } from '../../types/accountsTypes';
import {
  TransactionType,
  GetTransactionsResponse,
  TransactionDirections,
  TransactionTypes,
} from '../../types/transactionsTypes';
import { ResponseMetaPagination } from '../../lib/types';
import {
  capitalizeTransform,
  downloadFile,
  formatAmount,
  formatDate2,
  formatName,
  truncateString,
} from '../../lib/util';
import { initialMetaData } from '../../lib/store/reducers/DataReducer';
import useNotification from '../../hooks/useNotification';
import { ReactComponent as ChevronBack } from '../../assets/svg/chevron-back.svg';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { ReactComponent as Download } from '../../assets/svg/download-black.svg';
import './accounts.scss';
import TransactionDetails from '../Transactions/TransactionDetails';
import useStatementsService from '../../services/statements';
import {
  GetStatementsResponse,
  StatementStatusTypes,
  StatementType,
} from '../../types/statementsTypes';
import { scrollToElement } from '../../lib/util';
import TransactionsFilter from '../Transactions/TransactionsFilter';
import ExportData, {
  ExportResources,
} from '../../components/ExportData/ExportData';
import { useViewportSize } from '@mantine/hooks';
import { SubAccountType } from '../../types/subAccountsTypes';
import { CustomerAccountTypes } from '../../types/customersTypes';
import { AccountInfoItem, BalanceComponent } from './AccountOverview';
import GenerateCustomeStatement from '../../components/modals/Statements/GenerateCustomeStatement';
import usePermissions from '../../hooks/usePermissions';

const SubAccountOverview = () => {
  const [activeTab, setActiveTab] = useState<string | null>('transactions');
  const [accountName, setAccountName] = useState<string>('');

  return (
    <div className="account-overview">
      <Helmet>
        <title> {accountName || 'Sub Account Overview'} | Anchor</title>
        <meta name="description" content="View the details of a sub account" />
      </Helmet>

      <SubAccountDetails
        accountName={accountName}
        setAccountName={setAccountName}
      />

      <Group pt={6} id={containerId} grow>
        <Tabs mt={30} value={activeTab} onTabChange={setActiveTab} color="dark">
          <Tabs.List>
            <Tabs.Tab value="transactions">Transactions</Tabs.Tab>
            <Tabs.Tab value="statement">Statement</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="transactions" pt="lg">
            <Transactions />
          </Tabs.Panel>

          <Tabs.Panel value="statement" pt="lg">
            <Statements accountName={accountName} />
          </Tabs.Panel>
        </Tabs>
      </Group>
    </div>
  );
};

interface IAccountDetails {
  accountName: string;
  setAccountName: (name: string) => void;
}

const SubAccountDetails = ({
  accountName,
  setAccountName,
}: IAccountDetails) => {
  const { subAccountId } = useParams();
  const navigate = useNavigate();
  const [subAccount, setSubAccount] = useState<SubAccountType | null>(null);
  const [balance, setBalance] = useState<AccountBalanceType>({
    availableBalance: 0,
    ledgerBalance: 0,
    hold: 0,
    pending: 0,
  });
  const { getSubAccountDetails, getSubAccountBalance } =
    useSubAccountsService();
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { subaccountRead } = usePermissions();

  useEffect(() => {
    if (subAccountId && subaccountRead) {
      handleGetSubAccountDetails();
      getBalances();
    }
    //eslint-disable-next-line
  }, []);

  const handleGetSubAccountDetails = () => {
    if (subAccountId) {
      setLoading(true);

      getSubAccountDetails(subAccountId)
        .then((res: SubAccountType) => {
          setSubAccount(res);
          if (res?.customer.data.type === CustomerAccountTypes.business) {
            setAccountName(
              capitalizeTransform(res?.customer.data.detail.businessName)
            );
          } else {
            setAccountName(formatName(res?.customer?.data?.fullName));
          }
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getBalances = () => {
    if (subAccountId) {
      getSubAccountBalance(subAccountId)
        .then((res: AccountBalanceType) => {
          setBalance(res);
        })
        .catch((err: AxiosError) => {
          handleError(err);
        });
    }
  };

  return (
    <>
      <Group spacing={8} mb={10}>
        <ChevronBack className="click" onClick={() => navigate(-1)} />

        <Text fz={20} fw={500} color="#2E2E2E">
          {accountName || 'Sub Account Overview'}
        </Text>
      </Group>

      <div className="account-card mt relative">
        <LoadingOverlay visible={loading} />

        <Group className="info-items">
          <BalanceComponent
            desc="Account balance"
            value={`₦${formatAmount(balance.availableBalance)}`}
          />

          <BalanceComponent
            desc="Ledger balance"
            value={`₦${formatAmount(balance.ledgerBalance)}`}
          />

          <BalanceComponent
            desc="Pending"
            value={`₦${formatAmount(balance.pending)}`}
            extraClassname="add-opacity"
          />

          <BalanceComponent
            desc="Hold"
            value={`₦${formatAmount(balance.hold)}`}
            extraClassname="add-opacity"
          />
        </Group>

        <Divider my={20} />

        <Group className="info-items">
          <AccountInfoItem
            desc="Date Created"
            value={moment(subAccount?.createdAt).format('MMM Do, YYYY h:mm a')}
          />

          <AccountInfoItem desc="Sub Account ID" value={subAccount?.id ?? ''} />

          <AccountInfoItem
            desc="Account Type"
            value={subAccount?.accountType ?? ''}
          />
        </Group>
      </div>
    </>
  );
};

const Transactions = () => {
  const { subAccountId } = useParams();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [type, setType] = useState<string>('');
  const [direction, setDirection] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const { getSubAccountTransactions } = useSubAccountsService();
  const [transactions, setTransactions] = useState<{
    data: TransactionType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  }>({ data: [], meta: initialMetaData, dataFetched: false });
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>('');
  const [exportModal, setExportModal] = useState<boolean>(false);
  const { width } = useViewportSize();
  const { transactionRead } = usePermissions();

  useEffect(() => {
    if (transactionRead) getAccountTransactions();

    //eslint-disable-next-line
  }, [page, size, type, direction, dateFrom, dateTo]);

  const getAccountTransactions = () => {
    if (subAccountId) {
      setLoading(true);

      getSubAccountTransactions({
        size,
        page,
        subAccountId,
        type,
        direction,
        startDate: dateFrom,
        toDate: dateTo,
      })
        .then((res: GetTransactionsResponse) => {
          setTransactions({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          });

          if (transactions.dataFetched) {
            setTimeout(() => {
              scrollToElement(containerId, 'start');
            }, 100);
          }
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFilterItemChange = (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => {
    if (value !== itemState) setLoading(true);
    setItemState && setItemState(value);
  };

  return (
    <div className="relative table-container with-action-btns">
      <LoadingOverlay
        visible={loading}
        loader={<Text color="gray">Fetching transactions...</Text>}
      />

      <Drawer
        opened={detailsDrawer}
        onClose={() => setDetailsDrawer(false)}
        padding="xl"
        size={650}
        title={
          <Text fw={600} fz={23}>
            Transaction Details
          </Text>
        }
        position="right"
      >
        <TransactionDetails
          transactionId={transactionId}
          modalActive={detailsDrawer}
        />
      </Drawer>

      <ExportData
        modalOpen={exportModal}
        closeModal={() => {
          setExportModal(false);
        }}
        resource={ExportResources.subAccountTransactions}
        extraData={{ subAccountId: subAccountId ?? '' }}
      />

      <Group spacing={10} position="right" className="action-btns">
        <TransactionsFilter
          type={type}
          setType={setType}
          direction={direction}
          setDirection={setDirection}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          onFilterItemChange={onFilterItemChange}
        />

        <Button
          compact
          className="compact-btn"
          color="dark"
          size={width > 700 ? 'md' : 'sm'}
          onClick={() => {
            setExportModal(true);
          }}
          disabled={!subAccountId}
        >
          Export CSV
        </Button>
      </Group>

      {!loading && transactions.meta.total > 0 && (
        <>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table
              mt={10}
              verticalSpacing="md"
              sx={{ minWidth: transactions.data.length === 0 ? 500 : 900 }}
              highlightOnHover
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Summary</th>
                  <th>Direction</th>
                  <th>Amount</th>
                  <th>Balance After</th>
                </tr>
              </thead>
              <tbody>
                {transactions.data.map(
                  (transaction: TransactionType, index: number) => (
                    <tr
                      className="click"
                      key={index}
                      onClick={() => {
                        setTransactionId(transaction.id);
                        setDetailsDrawer(true);
                      }}
                      id={transaction.id}
                    >
                      <td className="gray">
                        {moment(transaction.createdAt).format('MMM D, YYYY')}
                      </td>

                      <td>
                        <div className="type-badge">
                          {transaction.type ===
                            TransactionTypes.NIPTransaction && 'NIP'}
                          {transaction.type ===
                            TransactionTypes.BookTransaction && 'Book'}
                          {transaction.type === TransactionTypes.NIPReversal &&
                            'Reversal'}
                          {transaction.type ===
                            TransactionTypes.FeeTransaction && 'Fee'}
                          {transaction.type === TransactionTypes.CUSTOM &&
                            'Custom'}
                          {transaction.type ===
                            TransactionTypes.BillsTransaction && 'Bill'}
                          {transaction.type ===
                            TransactionTypes.CommissionTransaction &&
                            'Commission'}
                        </div>
                      </td>

                      <td className="capitalize" style={{ maxWidth: 250 }}>
                        <Tooltip
                          multiline
                          width={350}
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label={transaction.summary}
                          position="bottom"
                          disabled={transaction.summary.length < 51}
                        >
                          <span>{truncateString(transaction.summary, 50)}</span>
                        </Tooltip>
                      </td>

                      <td>
                        <div
                          className={`direction-badge ${
                            transaction.direction ===
                            TransactionDirections.credit
                              ? 'inflow'
                              : 'outflow'
                          }`}
                        >
                          {transaction.direction}
                        </div>
                      </td>

                      <td>{`₦${formatAmount(transaction.amount)}`}</td>

                      <td className="gray">
                        {transaction.balanceAfter &&
                          `₦${formatAmount(transaction.balanceAfter)}`}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>

            {transactions.meta.total === 0 && (
              <Text pt={70} color="gray" align="center">
                No transactions to display
              </Text>
            )}
          </ScrollArea>

          <PaginationControls
            meta={transactions.meta}
            setPage={setPage}
            setLoader={setLoading}
            setSize={setSize}
          />
        </>
      )}

      {transactions.dataFetched && transactions.meta.total === 0 && (
        <Text pt={84} color="gray" align="center">
          No transactions to display
        </Text>
      )}
    </div>
  );
};

interface IStatements {
  accountName: string;
}

const Statements = ({ accountName }: IStatements) => {
  const { subAccountId } = useParams();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getStatements, downloadStatement } = useStatementsService();
  const [statements, setStatements] = useState<{
    data: StatementType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  }>({ data: [], meta: initialMetaData, dataFetched: false });
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [statementId, setStatementId] = useState<string>('');
  const [statementModal, setStatementModal] = useState<boolean>(false);
  const { width } = useViewportSize();
  const { statementRead } = usePermissions();

  useEffect(() => {
    if (subAccountId && statementRead) handleGetStatements();

    //eslint-disable-next-line
  }, [page, size]);

  const handleGetStatements = () => {
    if (subAccountId) {
      setLoading(true);

      getStatements({
        size,
        page,
        accountId: subAccountId,
        accountType: 'SubAccount',
      })
        .then((res: GetStatementsResponse) => {
          setStatements({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          });

          if (statements.dataFetched) {
            setTimeout(() => {
              scrollToElement(containerId, 'start');
            }, 100);
          }
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDownloadStatement = (statement: StatementType) => {
    downloadStatement(statement.id)
      .then((res: File) => {
        const fileName = `${accountName}-${statement.account.data.id}-${statement.fromDate}-${statement.toDate}`;

        const extension = 'xlsx';

        downloadFile(
          res,
          `${fileName}.${extension}`,
          'application/octet-stream'
        );

        setStatementId('');
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  return (
    <div className="relative table-container with-action-btns">
      <LoadingOverlay
        visible={loading}
        loader={<Text color="gray">Fetching statements...</Text>}
      />

      <GenerateCustomeStatement
        modalOpen={statementModal}
        closeModal={() => {
          setStatementModal(false);
        }}
        accountId={subAccountId ?? ''}
        accountType="SubAccount"
        callback={handleGetStatements}
      />

      <Group spacing={10} position="right" className="action-btns">
        <Button
          compact
          className="compact-btn"
          color="dark"
          size={width > 700 ? 'md' : 'sm'}
          onClick={() => {
            setStatementModal(true);
          }}
          disabled={!subAccountId}
        >
          Custom Statement
        </Button>
      </Group>

      {!loading && statements.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table verticalSpacing="md" sx={{ minWidth: 600 }} highlightOnHover>
              <thead>
                <tr>
                  <th>Period</th>
                  <th>Status</th>
                  <th>Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {statements.data.map((statement: StatementType) => (
                  <tr key={statement.id} id={statement.id}>
                    <td className="gray">
                      {statement.fromDate}{' '}
                      <span className="arrow-right-text">→</span>{' '}
                      {statement.toDate}
                    </td>

                    <td>
                      <Badge
                        color={
                          statement.status === StatementStatusTypes.completed
                            ? 'green'
                            : 'gray'
                        }
                      >
                        {statement.status}
                      </Badge>
                    </td>

                    <td>
                      {formatDate2(statement.createdAt, 'MMM D, YYYY h:mm a')}
                    </td>

                    <td className="table-last">
                      {statement.status === StatementStatusTypes.completed && (
                        <ActionIcon
                          onClick={() => {
                            setStatementId(statement.id);
                            handleDownloadStatement(statement);
                          }}
                          loading={statement.id === statementId}
                          size="sm"
                        >
                          <Download />
                        </ActionIcon>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>

          <PaginationControls
            meta={statements.meta}
            setPage={setPage}
            setLoader={setLoading}
            setSize={setSize}
          />
        </React.Fragment>
      )}

      {statements.dataFetched && statements.meta.total === 0 && (
        <Text pt={84} color="gray" align="center">
          No statement found
        </Text>
      )}
    </div>
  );
};

const containerId = 'data-container';

export default SubAccountOverview;
