import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetReportsResponse,
  ReportType,
  GetReportsParams,
  CreateReportData,
} from '../types/reportsTypes';

const useReportsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, mode);

  const getReports = (params: GetReportsParams) => {
    return new Promise<GetReportsResponse>((resolve, reject) => {
      authApi
        .get(`${APIS.REPORTS.REPORTS}?size=${params.size}&page=${params.page}`)
        .then((res: GetReportsResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getReport = (reportId: string) => {
    return new Promise<ReportType>((resolve, reject) => {
      authApi
        .get(APIS.REPORTS.REPORT(reportId), {
          params: {
            include: 'DepositAccount',
          },
        })
        .then((res: { data: ReportType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const downloadReport = (reportId: string) => {
    return new Promise<File>((resolve, reject) => {
      axiosApi
        .get(APIS.REPORTS.DOWNLOAD_REPORT(reportId), {
          responseType: 'blob',
        })
        .then((res: { data: File }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const createReport = (data: CreateReportData) => {
    return new Promise<ReportType>((resolve, reject) => {
      const { fromDate, toDate, reportType, documentType, accountId } = data;

      authApi
        .post(APIS.REPORTS.REPORTS, {
          fromDate,
          toDate,
          reportType,
          documentType,
          account: {
            id: accountId,
            type: 'DepositAccount',
          },
        })
        .then((res: { data: ReportType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getReports,
    getReport,
    downloadReport,
    createReport,
  };
};

export default useReportsService;
