import { createSlice } from '@reduxjs/toolkit';

export enum Mode {
  Live = 1,
  Test,
  Playground,
}

export interface SettingsState {
  mode: Mode;
}

const getSettingsStateFromLocalStorage = () => {
  const settingsString = localStorage.getItem('settings');
  if (settingsString) {
    const settings = JSON.parse(settingsString) as SettingsState;
    return settings;
  }
};

const initialState: SettingsState = {
  mode: Mode.Live,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: getSettingsStateFromLocalStorage() || initialState,
  reducers: {
    goToLiveMode: (state) => {
      state.mode = Mode.Live;
      localStorage.setItem('settings', JSON.stringify(state));
    },
    goToTestMode: (state) => {
      state.mode = Mode.Test;
      localStorage.setItem('settings', JSON.stringify(state));
    },
    clearSettings: (state) => {
      state.mode = initialState.mode;
      localStorage.removeItem('settings');
    },
  },
});

export const { goToLiveMode, goToTestMode, clearSettings } =
  settingsSlice.actions;

export default settingsSlice.reducer;
