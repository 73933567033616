import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { AxiosError } from 'axios';
import {
  FileInput,
  TextInput,
  Button,
  Modal,
  Group,
  CloseButton,
} from '@mantine/core';
import { useAppDispatch } from '../../../hooks/useRedux';
import { setShowLoader } from '../../../lib/store/reducers/UtilityReducer';
import useOrganizationService from '../../../services/organization';
import {
  DocumentType,
  DocumentFormatTypes,
  UploadDocumentData,
} from '../../../types/documentTypes';
import Success from '../../../assets/svg/success-pop.svg';
import { ReactComponent as Upload } from '../../../assets/svg/upload.svg';
import { ReactComponent as Download } from '../../../assets/svg/download.svg';
import useNotification from '../../../hooks/useNotification';

interface IBusinessDocuments {
  prevStep: () => void;
  isLive: boolean;
  documents: DocumentType[];
  getOrganizationDocuments: (hideLoader?: boolean) => void;
  setActiveView: Dispatch<SetStateAction<'overview' | 'edit' | ''>>;
}

const BusinessDocuments = ({
  prevStep,
  isLive,
  documents,
  getOrganizationDocuments,
  setActiveView,
}: IBusinessDocuments) => {
  const dispatch = useAppDispatch();
  const { uploadDocument } = useOrganizationService();
  const [showCompletedModal, setShowCompletedModal] = useState<boolean>(false);
  const { handleError } = useNotification();

  useEffect(() => {
    getOrganizationDocuments();
    // eslint-disable-next-line
  }, []);

  const handleUpload = (
    type: DocumentFormatTypes,
    documentId: string,
    data: File | string,
    callback: () => void
  ) => {
    const requestData: UploadDocumentData = {
      textData: type === DocumentFormatTypes.TEXT ? (data as string) : '',
      fileData: type === DocumentFormatTypes.FILE ? data : '',
    };

    if (type === DocumentFormatTypes.FILE) dispatch(setShowLoader(true));

    uploadDocument(documentId, requestData)
      .then(() => {
        getOrganizationDocuments(type === DocumentFormatTypes.TEXT);
        callback();
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        dispatch(setShowLoader(false));
      });
  };

  return (
    <>
      <div className="org-documents c-step">
        <Modal
          opened={showCompletedModal}
          onClose={() => {
            setShowCompletedModal(false);
            setActiveView('overview');
          }}
          size={400}
        >
          <Completed />
        </Modal>

        <div className="s-title">Business Documents</div>
        <div className="s-desc">Please upload all documents</div>

        {documents.map((document: DocumentType) => (
          <DocumentInput
            key={document.id}
            document={document}
            handleUpload={handleUpload}
            isLive={isLive}
          />
        ))}

        {documents.length === 0 && (
          <div className="d-empty">No document found!</div>
        )}
      </div>

      <Group grow mt={20}>
        <Button variant="default" onClick={prevStep}>
          Previous
        </Button>

        <Button
          color="dark"
          onClick={() => {
            setShowCompletedModal(true);
          }}
          disabled={
            isLive
              ? true
              : documents.find((document: DocumentType) => !document.submitted)
              ? true
              : false
          }
        >
          Submit
        </Button>
      </Group>
    </>
  );
};

interface IDocumentInput {
  document: DocumentType;
  handleUpload: (
    type: DocumentFormatTypes,
    documentId: string,
    data: File | string,
    callback: () => void
  ) => void;
  isLive: boolean;
}

const DocumentInput = ({ document, handleUpload, isLive }: IDocumentInput) => {
  const [edit, setEdit] = useState<boolean>(!document.submitted);
  const [textInput, setTextInput] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (document.submitted && document.format === DocumentFormatTypes.TEXT) {
      setTextInput(document.data);
    }
  }, [document]);

  const onSave = () => {
    if (document.format === DocumentFormatTypes.FILE) {
      file &&
        handleUpload(DocumentFormatTypes.FILE, document.id, file, () =>
          setEdit(false)
        );
    } else {
      if (textInput === document.data) {
        return;
      }

      textInput &&
        handleUpload(DocumentFormatTypes.TEXT, document.id, textInput, () =>
          setEdit(false)
        );
    }
  };

  return (
    <div className="document">
      <div className="d-name">{document.documentType.replace(/_/g, ' ')}</div>
      <div className="d-desc">{document.description}</div>

      {document.data && (
        <div className="d-data">
          {document.format === DocumentFormatTypes.FILE && (
            <Download className="download-icon" />
          )}
          {document.data}
        </div>
      )}

      {!edit ? (
        <Button
          variant="default"
          color="dark"
          fullWidth
          onClick={() => {
            setEdit(true);
          }}
          disabled={isLive}
        >
          Edit
        </Button>
      ) : (
        <Group spacing={10} className="edit-document-row">
          {document.format === DocumentFormatTypes.FILE && (
            <FileInput
              placeholder="Select file to upload"
              value={file}
              onChange={setFile}
              icon={<Upload />}
              disabled={document.verified || isLive}
              rightSection={
                document.submitted && (
                  <CloseButton onClick={() => setEdit(false)} />
                )
              }
            />
          )}

          {document.format === DocumentFormatTypes.TEXT && (
            <TextInput
              placeholder={document.documentType}
              value={textInput}
              onChange={(event) => setTextInput(event.currentTarget.value)}
              disabled={document.verified || isLive}
              autoComplete="off"
              rightSection={
                document.submitted && (
                  <CloseButton onClick={() => setEdit(false)} />
                )
              }
            />
          )}

          <Button
            variant="default"
            onClick={onSave}
            disabled={(!file && !textInput) || isLive}
          >
            Save
          </Button>
        </Group>
      )}
    </div>
  );
};

const Completed = () => {
  return (
    <div className="steps-completed">
      <img src={Success} alt="success-splash" height={225} width={225} />

      <div className="c-title">Complaince data submitted 🎉</div>

      <div className="c-desc">
        Your complaince data has been submitted successfully for review.
      </div>
    </div>
  );
};

export default BusinessDocuments;
