import { selectMode } from '../lib/store';
import { useAppSelector } from './useRedux';
import { Mode } from '../lib/store/reducers/SettingsReducer';

const usePermissions = () => {
  const mode = useAppSelector(selectMode);
  const permissions = useAppSelector((state) => state.user.permissions);

  const accountRead =
    permissions.includes('account.read') || mode === Mode.Test;
  const accountWrite =
    permissions.includes('account.write') || mode === Mode.Test;

  const transactionRead =
    permissions.includes('transaction.read') || mode === Mode.Test;
  const transactionWrite =
    permissions.includes('transaction.write') || mode === Mode.Test;

  const transferRead =
    permissions.includes('transfer.read') || mode === Mode.Test;
  const transferWrite =
    permissions.includes('transfer.write') || mode === Mode.Test;

  const counterpartyRead =
    permissions.includes('counterparty.read') || mode === Mode.Test;
  const counterpartyWrite =
    permissions.includes('counterparty.write') || mode === Mode.Test;

  const subaccountRead =
    permissions.includes('subaccount.read') || mode === Mode.Test;
  const subaccountWrite =
    permissions.includes('subaccount.write') || mode === Mode.Test;

  const reportRead = permissions.includes('report.read') || mode === Mode.Test;
  const reportWrite =
    permissions.includes('report.write') || mode === Mode.Test;

  const auditRead = permissions.includes('audit.read') || mode === Mode.Test;
  const auditWrite = permissions.includes('audit.write') || mode === Mode.Test;

  const customerRead =
    permissions.includes('customer.read') || mode === Mode.Test;
  const customerWrite =
    permissions.includes('customer.write') || mode === Mode.Test;

  const virtualAaccountRead =
    permissions.includes('virtual.account.read') || mode === Mode.Test;
  const virtualAaccountWrite =
    permissions.includes('virtual.account.write') || mode === Mode.Test;

  const userRead = permissions.includes('user.read') || mode === Mode.Test;
  const userWrite = permissions.includes('user.write') || mode === Mode.Test;
  const userInvite = permissions.includes('user.invite') || mode === Mode.Test;

  const billPaymentRead =
    permissions.includes('billpayment.read') || mode === Mode.Test;
  const billPaymentWrite =
    permissions.includes('billpayment.write') || mode === Mode.Test;

  const apikeyRead = permissions.includes('apikey.read') || mode === Mode.Test;
  const apikeyWrite =
    permissions.includes('apikey.write') || mode === Mode.Test;

  const feeRead = permissions.includes('fee.read') || mode === Mode.Test;
  const feeWrite = permissions.includes('fee.write') || mode === Mode.Test;

  const feeConfigRead =
    permissions.includes('fee.config.read') || mode === Mode.Test;
  const feeConfigWrite =
    permissions.includes('fee.config.write') || mode === Mode.Test;

  const contractRead =
    permissions.includes('contract.read') || mode === Mode.Test;
  const contractWrite =
    permissions.includes('contract.write') || mode === Mode.Test;

  const webhookRead =
    permissions.includes('webhook.read') || mode === Mode.Test;
  const webhookWrite =
    permissions.includes('webhook.write') || mode === Mode.Test;

  const documentRead =
    permissions.includes('document.read') || mode === Mode.Test;
  const documentWrite =
    permissions.includes('document.write') || mode === Mode.Test;

  const statementRead =
    permissions.includes('statement.read') || mode === Mode.Test;
  const statementWrite =
    permissions.includes('statement.write') || mode === Mode.Test;

  const roleRead = permissions.includes('role.read') || mode === Mode.Test;
  const roleWrite = permissions.includes('role.write') || mode === Mode.Test;

  const savingsRead =
    permissions.includes('savings.read') || mode === Mode.Test;
  const savingsWrite =
    permissions.includes('savings.write') || mode === Mode.Test;

  const organizationAdmin =
    permissions.includes('organization.admin') || mode === Mode.Test;

  const eventRead = permissions.includes('event.read') || mode === Mode.Test;

  return {
    accountRead,
    accountWrite,
    transactionRead,
    transactionWrite,
    transferRead,
    transferWrite,
    counterpartyRead,
    counterpartyWrite,
    subaccountRead,
    subaccountWrite,
    reportRead,
    reportWrite,
    auditRead,
    auditWrite,
    customerRead,
    customerWrite,
    virtualAaccountRead,
    virtualAaccountWrite,
    userRead,
    userWrite,
    userInvite,
    billPaymentRead,
    billPaymentWrite,
    apikeyRead,
    apikeyWrite,
    feeRead,
    feeWrite,
    feeConfigRead,
    feeConfigWrite,
    contractRead,
    contractWrite,
    webhookRead,
    webhookWrite,
    documentRead,
    documentWrite,
    statementRead,
    statementWrite,
    roleRead,
    roleWrite,
    savingsRead,
    savingsWrite,
    organizationAdmin,
    eventRead,
  };
};

export default usePermissions;
