import React, { useState } from 'react';
import moment from 'moment';
import {
  Table,
  ScrollArea,
  Text,
  Drawer,
  Button,
  Group,
  Tooltip,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { formatAmount } from '../../lib/util';
import {
  TransactionType,
  TransactionDirections,
  TransactionTypes,
} from '../../types/transactionsTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { ResponseMetaPagination } from '../../lib/types';
import EmptyData from '../../components/EmptyData/EmptyData';
import TransactionsFilter from './TransactionsFilter';
import { truncateString } from '../../lib/util';
import ExportData, {
  ExportResources,
} from '../../components/ExportData/ExportData';
import TransactionDetails from './TransactionDetails';

interface ITransactionsTable {
  transactions: {
    data: TransactionType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  setPage: (value: number) => void;
  type?: string;
  setType?: (value: string) => void;
  accountId?: string;
  setAccountId?: (value: string) => void;
  direction?: string;
  setDirection?: (value: string) => void;
  customerId?: string;
  setCustomerId?: (value: string) => void;
  dateFrom?: string;
  setDateFrom?: (value: string) => void;
  dateTo?: string;
  setDateTo?: (value: string) => void;
  onFilterItemChange?: (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => void;
  exportAccountId?: string;
  isSubAccount?: boolean;
  loading: boolean;
  setLoading: (value: boolean) => void;
  setSize: (value: number) => void;
}

const TransactionsTable = ({
  transactions,
  setPage,
  type,
  setType,
  accountId,
  setAccountId,
  direction,
  setDirection,
  customerId,
  setCustomerId,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  onFilterItemChange,
  exportAccountId,
  isSubAccount,
  loading,
  setLoading,
  setSize,
}: ITransactionsTable) => {
  const { width } = useViewportSize();
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>('');
  const [exportModal, setExportModal] = useState<boolean>(false);

  return (
    <div>
      <Drawer
        opened={detailsDrawer}
        onClose={() => setDetailsDrawer(false)}
        padding="xl"
        size={650}
        title={
          <Text fw={600} fz={23}>
            Transaction Details
          </Text>
        }
        position="right"
      >
        <TransactionDetails
          transactionId={transactionId}
          modalActive={detailsDrawer}
        />
      </Drawer>

      <ExportData
        modalOpen={exportModal}
        closeModal={() => {
          setExportModal(false);
        }}
        resource={
          isSubAccount
            ? ExportResources.subAccountTransactions
            : ExportResources.transactions
        }
        extraData={{ accountId: exportAccountId ?? '' }}
      />

      <Group mt={10} position="right" spacing={10}>
        <TransactionsFilter
          type={type}
          setType={setType}
          accountId={accountId}
          setAccountId={setAccountId}
          direction={direction}
          setDirection={setDirection}
          customerId={customerId}
          setCustomerId={setCustomerId}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          onFilterItemChange={onFilterItemChange}
        />

        <Button
          compact
          className="compact-btn"
          color="dark"
          size={width > 700 ? 'md' : 'sm'}
          onClick={() => {
            setExportModal(true);
          }}
        >
          Export
        </Button>
      </Group>

      <ScrollArea
        offsetScrollbars
        type="always"
        className="table-scroll-container"
        mt={10}
      >
        <Table
          mt={10}
          verticalSpacing="md"
          sx={{ minWidth: transactions.data.length === 0 ? 500 : 850 }}
          highlightOnHover
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Summary</th>
              <th>Direction</th>
              <th>Amount</th>
              <th>Balance After</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              transactions.data.map(
                (transaction: TransactionType, index: number) => (
                  <tr
                    className="click"
                    key={index}
                    onClick={() => {
                      setTransactionId(transaction.id);
                      setDetailsDrawer(true);
                    }}
                    id={transaction.id}
                  >
                    <td className="gray">
                      {moment(transaction.createdAt).format('MMM D, YYYY')}
                    </td>

                    <td>
                      <div className="type-badge">
                        {transaction.type === TransactionTypes.NIPTransaction &&
                          'NIP'}
                        {transaction.type ===
                          TransactionTypes.BookTransaction && 'Book'}
                        {transaction.type === TransactionTypes.NIPReversal &&
                          'Reversal'}
                        {transaction.type === TransactionTypes.FeeTransaction &&
                          'Fee'}
                        {transaction.type === TransactionTypes.CUSTOM &&
                          'Custom'}
                        {transaction.type ===
                          TransactionTypes.BillsTransaction && 'Bill'}
                        {transaction.type ===
                          TransactionTypes.CommissionTransaction &&
                          'Commission'}
                      </div>
                    </td>

                    <td className="capitalize">
                      <Tooltip
                        multiline
                        width={350}
                        withArrow
                        transition="fade"
                        transitionDuration={200}
                        label={transaction.summary}
                        position="bottom"
                        disabled={transaction.summary.length < 41}
                      >
                        <span>{truncateString(transaction.summary, 40)}</span>
                      </Tooltip>
                    </td>

                    <td>
                      <div
                        className={`direction-badge ${
                          transaction.direction === TransactionDirections.credit
                            ? 'inflow'
                            : 'outflow'
                        }`}
                      >
                        {transaction.direction}
                      </div>
                    </td>

                    <td>{`₦${formatAmount(transaction.amount)}`}</td>

                    <td className="gray">
                      {transaction.balanceAfter &&
                        `₦${formatAmount(transaction.balanceAfter)}`}
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </Table>

        {transactions.meta.total === 0 && (
          <EmptyData
            title="There are no transactions to display"
            desc="At the moment, there is no data to be displayed."
          />
        )}
      </ScrollArea>

      {!loading && (
        <PaginationControls
          meta={transactions.meta}
          setPage={setPage}
          setLoader={setLoading}
          setSize={setSize}
        />
      )}
    </div>
  );
};

export default TransactionsTable;
