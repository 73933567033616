import React from 'react';
import './action-step.scss';

type ActionStepProps = {
  number: number;
  title: string;
  desc: string;
  mt?: number;
};

const ActionStep = ({ number, title, desc, mt }: ActionStepProps) => {
  return (
    <div className="action-step" style={{ marginTop: mt ?? 0 }}>
      <div className="a-s-top">
        <div className="a-s-number">{number}</div>
        <div className="a-s-title">{title}</div>
      </div>

      <div className="a-s-desc">{desc}</div>
    </div>
  );
};

export default ActionStep;
