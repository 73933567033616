import { AccountType } from './accountsTypes';
import { ResponseMetaPagination } from '../lib/types';
import { CounterpartyType } from './counterpartiesTypes';

export type GetTransfersResponse = {
  data: TransferType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type TransferType = {
  id: string;
  type: string;
  counterParty?: {
    data: CounterpartyType;
  };
  account: {
    data: AccountType;
  };
  destinationAccount?: {
    data: AccountType;
  };
  subAccount?: {
    data: Data;
  };
  destinationSubAccount?: {
    data: Data;
  };
  customer: {
    data: Data;
  };
  reference: string;
  createdAt: Date;
  reason?: string;
  failureReason?: string;
  amount: number;
  currency: string;
  status: string;
  sessionId?: string;
  metadata?: Record<string, string>;
};

interface Data {
  id: string;
  type: string;
}

export enum TransferStatusTypes {
  completed = 'COMPLETED',
  failed = 'FAILED',
  pending = 'PENDING',
}

export enum TransferTypes {
  NIP_TRANSFER = 'NIP_TRANSFER',
  BOOK_TRANSFER = 'BOOK_TRANSFER',
}

export type CreateTransferData = {
  amount: string;
  currency: string;
  sourceAccountId: string;
  destinationAccountId: string;
  counterpartyId: string;
  reason: string;
  reference: string;
  idempotencykey: string;
  metadata: any;
};

export type GetTransfersParams = {
  page: number;
  size: number;
  type: string;
  accountId?: string;
  customerId?: string;
  counterPartyId?: string;
  destinationAccountId?: string;
  from?: string;
  to?: string;
  query?: string;
  onlySubAccount?: boolean;
  subAccountId?: string;
  destinationSubAccountId?: string;
};
