import React from 'react';
import './metric-card.scss';

interface IMetricCard {
  Icon: any;
  title: string;
  value: string;
}

const MetricCard = ({ Icon, title, value }: IMetricCard) => {
  return (
    <div className="metric-card">
      <div className="m-c-img">
        <Icon />
      </div>

      <div className="m-c-info">
        <div className="m-title">{title}</div>

        <div className="m-value">{value}</div>
      </div>
    </div>
  );
};

export default MetricCard;
