import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { AxiosError } from 'axios';
import { Text, Button, Popover, Group, Switch, Select } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useViewportSize } from '@mantine/hooks';
import {
  CustomerAccountTypes,
  GetCustomersResponse,
} from '../../types/customersTypes';
import CustomSelectItem, {
  ItemSchema,
} from '../../components/CustomSelectItem/CustomSelectItem';
import useAccountsService from '../../services/accounts';
import useCustomersService from '../../services/customers';
import { handleCustomSelectCreate, capitalizeTransform } from '../../lib/util';
import { GetAccountsResponse } from '../../types/accountsTypes';
import useNotification from '../../hooks/useNotification';
import { ReactComponent as ChevronDownGray } from '../../assets/svg/chevron-down-gray.svg';
import usePermissions from '../../hooks/usePermissions';

interface ITransactionsFilter {
  type?: string;
  setType?: (value: string) => void;
  accountId?: string;
  setAccountId?: (value: string) => void;
  direction?: string;
  setDirection?: (value: string) => void;
  customerId?: string;
  setCustomerId?: (value: string) => void;
  dateFrom?: string;
  setDateFrom?: (value: string) => void;
  dateTo?: string;
  setDateTo?: (value: string) => void;
  onFilterItemChange?: (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => void;
}

const TransactionsFilter = ({
  type,
  setType,
  accountId,
  setAccountId,
  direction,
  setDirection,
  customerId,
  setCustomerId,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  onFilterItemChange,
}: ITransactionsFilter) => {
  const { width } = useViewportSize();
  const [expandFilter, setExpandFilter] = useState<boolean>(false);
  const [filterNumber, setFilterNumber] = useState<number>(0);
  const [typeChecked, setTypeChecked] = useState<boolean>(false);
  const [directionChecked, setDirectionChecked] = useState<boolean>(false);
  const [accountChecked, setAccountChecked] = useState<boolean>(false);
  const [customerChecked, setCustomerChecked] = useState<boolean>(false);
  const [dateFromControl, setDateFromControl] = useState<Date | null>(null);
  const [dateFromChecked, setDateFromChecked] = useState<boolean>(false);
  const [dateToControl, setDateToControl] = useState<Date | null>(null);
  const [dateToChecked, setDateToChecked] = useState<boolean>(false);
  const { handleError } = useNotification();
  const { getAccounts } = useAccountsService();
  const { getCustomers } = useCustomersService();
  const [accounts, setAccounts] = useState<ItemSchema[]>([]);
  const [customers, setCustomers] = useState<ItemSchema[]>([]);
  const { customerRead, accountRead } = usePermissions();

  useEffect(() => {
    if (setAccountId && accountRead) {
      handleGetAccounts();
    }

    if (setCustomerId && customerRead) {
      handleGetCustomers();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange && onFilterItemChange('', type, setType);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [typeChecked]);

  useEffect(() => {
    if (directionChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange && onFilterItemChange('', direction, setDirection);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [directionChecked]);

  useEffect(() => {
    if (!setAccountId) {
      return;
    }

    if (accountChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange && onFilterItemChange('', accountId, setAccountId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [accountChecked]);

  useEffect(() => {
    if (!setCustomerId) {
      return;
    }

    if (customerChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange && onFilterItemChange('', customerId, setCustomerId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [customerChecked]);

  useEffect(() => {
    if (dateFromChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      setDateFromControl(null);
      onFilterItemChange && onFilterItemChange('', dateFrom, setDateFrom);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [dateFromChecked]);

  useEffect(() => {
    if (dateToChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      setDateToControl(null);
      onFilterItemChange && onFilterItemChange('', dateTo, setDateTo);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [dateToChecked]);

  const handleGetAccounts = () => {
    getAccounts({ page: 0, size: 50 })
      .then((res: GetAccountsResponse) => {
        const data: ItemSchema[] = res.data.map((account) => ({
          label: capitalizeTransform(account?.accountName ?? ''),
          description: `${account.accountNumber} (${account.id})`,
          value: account.id,
        }));

        setAccounts(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleGetCustomers = () => {
    getCustomers({ page: 0, size: 50 })
      .then((res: GetCustomersResponse) => {
        const data: ItemSchema[] = res.data.map((customer) => {
          return {
            label: capitalizeTransform(
              customer?.type === CustomerAccountTypes.individual
                ? `${customer?.fullName?.firstName} ${customer?.fullName?.lastName}`
                : customer?.detail?.businessName
            ),
            description: customer?.id,
            value: customer?.id,
          };
        });

        setCustomers(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  return (
    <div className="filter-container">
      <Popover
        opened={expandFilter}
        onChange={setExpandFilter}
        position="bottom-end"
        width={320}
        shadow="lg"
      >
        <Popover.Target>
          <Button
            variant="default"
            size={width > 700 ? 'md' : 'sm'}
            onClick={() => {
              setExpandFilter(!expandFilter);
            }}
            compact
            className="compact-btn unstyled"
            rightIcon={<ChevronDownGray />}
          >
            Filters{' '}
            {filterNumber > 0 && (
              <span className="filter-number">{filterNumber}</span>
            )}
          </Button>
        </Popover.Target>

        <Popover.Dropdown>
          <div className="filter-menu">
            {setAccountId && (
              <Fragment>
                <Group align="center" position="apart">
                  <Text fw={500} fz={14}>
                    Account ID
                  </Text>

                  <Switch
                    checked={accountChecked}
                    onChange={(event) =>
                      setAccountChecked(event.currentTarget.checked)
                    }
                  />
                </Group>
                {accountChecked && (
                  <div className="filter-item-expanded">
                    <Select
                      placeholder="Select Account/ Input ID"
                      value={accountId}
                      onChange={(value: string) => {
                        onFilterItemChange &&
                          onFilterItemChange(value, accountId, setAccountId);
                        setExpandFilter(false);
                      }}
                      searchable
                      itemComponent={CustomSelectItem}
                      data={accounts}
                      maxDropdownHeight={400}
                      creatable
                      getCreateLabel={(query) => `Select ${query}`}
                      onCreate={(query) => {
                        return handleCustomSelectCreate(
                          query,
                          accounts,
                          () =>
                            onFilterItemChange &&
                            onFilterItemChange(query, accountId, setAccountId),
                          setAccounts
                        );
                      }}
                    />
                  </div>
                )}
              </Fragment>
            )}

            {setCustomerId && (
              <Fragment>
                <Group align="center" position="apart" mt={10}>
                  <Text fw={500} fz={14}>
                    Customer ID
                  </Text>

                  <Switch
                    checked={customerChecked}
                    onChange={(event) =>
                      setCustomerChecked(event.currentTarget.checked)
                    }
                  />
                </Group>
                {customerChecked && (
                  <div className="filter-item-expanded">
                    <Select
                      placeholder="Select Customer/ Input ID"
                      value={customerId}
                      onChange={(value: string) => {
                        onFilterItemChange &&
                          onFilterItemChange(value, customerId, setCustomerId);
                        setExpandFilter(false);
                      }}
                      searchable
                      itemComponent={CustomSelectItem}
                      data={customers}
                      maxDropdownHeight={400}
                      creatable
                      getCreateLabel={(query) => `Select ${query}`}
                      onCreate={(query) => {
                        return handleCustomSelectCreate(
                          query,
                          customers,
                          () =>
                            onFilterItemChange &&
                            onFilterItemChange(
                              query,
                              customerId,
                              setCustomerId
                            ),
                          setCustomers
                        );
                      }}
                    />
                  </div>
                )}
              </Fragment>
            )}

            <Group align="center" position="apart" mt={10}>
              <Text fw={500} fz={14}>
                Direction
              </Text>

              <Switch
                checked={directionChecked}
                onChange={(event) =>
                  setDirectionChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {directionChecked && (
              <div className="filter-item-expanded">
                <Select
                  placeholder="Select direction"
                  value={direction}
                  onChange={(value: string) => {
                    onFilterItemChange &&
                      onFilterItemChange(value, direction, setDirection);
                    setExpandFilter(false);
                  }}
                  data={['Debit', 'Credit']}
                />
              </div>
            )}

            <Group align="center" position="apart" mt={10}>
              <Text fw={500} fz={14}>
                Category
              </Text>

              <Switch
                checked={typeChecked}
                onChange={(event) =>
                  setTypeChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {typeChecked && (
              <div className="filter-item-expanded">
                <Select
                  placeholder="Select category"
                  value={type}
                  onChange={(value: string) => {
                    onFilterItemChange &&
                      onFilterItemChange(value, type, setType);
                    setExpandFilter(false);
                  }}
                  data={[
                    { value: 'NIP_TRANSFER', label: 'NIP' },
                    { value: 'BOOK_TRANSFER', label: 'Book' },
                    { value: 'SETTLEMENT', label: 'Settlement' },
                    {
                      value: 'NIP_TRANSFER_REVERSAL',
                      label: 'NIP Reversal',
                    },
                    // { value: 'BILL_PAYMENT', label: 'Bill Payment' },
                  ]}
                />
              </div>
            )}

            <Group align="center" position="apart" mt={10}>
              <Text fw={500} fz={14}>
                Date from
              </Text>

              <Switch
                checked={dateFromChecked}
                onChange={(event) =>
                  setDateFromChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {dateFromChecked && (
              <div className="filter-item-expanded">
                <DatePicker
                  placeholder="Pick date from"
                  value={dateFromControl}
                  onChange={(value: Date) => {
                    setDateFromControl(value);
                    if (value) {
                      onFilterItemChange &&
                        onFilterItemChange(
                          moment(value).format('YYYY-MM-DD'),
                          dateFrom,
                          setDateFrom
                        );
                      setExpandFilter(false);
                    } else {
                      onFilterItemChange &&
                        onFilterItemChange('', dateFrom, setDateFrom);
                    }
                  }}
                />
              </div>
            )}

            <Group align="center" position="apart" mt={10}>
              <Text fw={500} fz={14}>
                Date to
              </Text>

              <Switch
                checked={dateToChecked}
                onChange={(event) =>
                  setDateToChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {dateToChecked && (
              <div className="filter-item-expanded">
                <DatePicker
                  placeholder="Pick date to"
                  value={dateToControl}
                  onChange={(value: Date) => {
                    setDateToControl(value);
                    if (value) {
                      onFilterItemChange &&
                        onFilterItemChange(
                          moment(value).format('YYYY-MM-DD'),
                          dateTo,
                          setDateTo
                        );
                      setExpandFilter(false);
                    } else {
                      onFilterItemChange &&
                        onFilterItemChange('', dateTo, setDateTo);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default TransactionsFilter;
