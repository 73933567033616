import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetSubAccountsTransactionsParams,
  GetSubAccountsResponse,
  CreateSubAccountData,
  SubAccountType,
} from '../types/subAccountsTypes';
import { AccountBalanceType } from '../types/accountsTypes';
import { GetTransactionsResponse } from '../types/transactionsTypes';

const useSubAccountsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);

  const getSubAccounts = (size: number, page: number) => {
    return new Promise<GetSubAccountsResponse>((resolve, reject) => {
      authApi
        .get(`${APIS.ACCOUNTS.SUB_ACCOUNTS}?size=${size}&page=${page}`, {
          params: {
            include: 'BusinessCustomer,IndividualCustomer',
          },
        })
        .then((res: GetSubAccountsResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getSubAccountTransactions = (
    params: GetSubAccountsTransactionsParams
  ) => {
    return new Promise<GetTransactionsResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.ACCOUNTS.GET_SUB_ACCOUNT_TRANSACTIONS}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: 'DepositAccount,NIP_TRANSFER,CounterParty,BOOK_TRANSFER',
              ...(params.subAccountId && {
                subAccountId: params.subAccountId,
              }),
              ...(params.parentAccount && {
                parentAccount: params.parentAccount,
              }),
              ...(params.startDate && {
                startDate: params.startDate,
              }),
              ...(params.toDate && {
                toDate: params.toDate,
              }),
              ...(params.type && {
                type: params.type,
              }),
              ...(params.direction && {
                direction: params.direction,
              }),
            },
          }
        )
        .then((res: GetTransactionsResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getSubAccountDetails = (subAccountId: string) => {
    return new Promise<SubAccountType>((resolve, reject) => {
      authApi
        .get(`${APIS.ACCOUNTS.GET_SUB_ACCOUNT_DETAILS(subAccountId)}`, {
          params: {
            include: 'BusinessCustomer,IndividualCustomer,VirtualNuban',
          },
        })
        .then((res: { data: SubAccountType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const createSubAccount = (data: CreateSubAccountData) => {
    return new Promise<SubAccountType>((resolve, reject) => {
      const body = {
        createVirtualNuban: data.createVirtualNuban,
        metadata: data.metadata,
        customer: {
          data: {
            id: data.customerId,
            type: data.customerId.includes('anc_bus_cst')
              ? 'BusinessCustomer'
              : 'IndividualCustomer',
          },
        },
        parentAccount: {
          data: {
            id: data.accountId,
            type: 'DepositAccount',
          },
        },
      };

      authApi
        .request({
          method: 'POST',
          url: APIS.ACCOUNTS.SUB_ACCOUNTS,
          type: 'SubAccount',
          body,
        })
        .then((res: { data: SubAccountType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getSubAccountBalance = (subAccountId: string) => {
    return new Promise<AccountBalanceType>((resolve, reject) => {
      authApi
        .get(`${APIS.ACCOUNTS.GET_SUB_ACCOUNT_BALANCE(subAccountId)}`)
        .then((res: { data: AccountBalanceType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getSubAccountTransactions,
    getSubAccounts,
    createSubAccount,
    getSubAccountDetails,
    getSubAccountBalance,
  };
};

export default useSubAccountsService;
