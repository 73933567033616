import React, { ReactElement } from 'react';
import { Skeleton } from '@mantine/core';
import './detail-item.scss';

interface IDetailItem {
  title: string;
  extraClasses?: string;
  children?: any;
}

const DetailItem = ({ title, extraClasses, children }: IDetailItem) => {
  return (
    <div className={`info-group ${extraClasses ?? ''}`}>
      <div className="i-g-title">{title}</div>
      {children && <div className="i-g-desc">{children}</div>}
    </div>
  );
};

interface IDetailItem2 {
  title: string;
  extraClasses?: string;
  desc: string | number | ReactElement | undefined;
}

export const DetailItem2 = ({ title, extraClasses, desc }: IDetailItem2) => {
  return (
    <div className={`detail-item ${extraClasses ?? ''}`}>
      <div className="i-text">{title}</div>

      {desc ? (
        <div className="i-desc">{desc}</div>
      ) : (
        <Skeleton height={8} width={200} />
      )}
    </div>
  );
};

export default DetailItem;
