import React, { useState, useEffect, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextInput,
  Select,
  Group,
  Text,
  LoadingOverlay,
  ActionIcon,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { TransferTypes } from '../../types/transfersTypes';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import useAccountsService from '../../services/accounts';
import useCounterpartyService from '../../services/counterparties';
import useTransfersService from '../../services/transfers';
import useNotification from '../../hooks/useNotification';
import CustomSelectItem, {
  ItemSchema,
} from '../../components/CustomSelectItem/CustomSelectItem';
import { ReactComponent as Plus } from '../../assets/svg/plus.svg';
import { CreateCounterpartyModal } from '../Counterparty/CreateCounterparty';
import ConfirmTransfer, {
  TransferSummaryType,
} from '../../components/modals/Transfers/ConfirmTransfer';
import {
  CounterpartyType,
  GetCounterPartiesResponse,
} from '../../types/counterpartiesTypes';
import {
  handleCustomSelectCreate,
  capitalizeTransform,
  generateRandomString,
} from '../../lib/util';
import { AccountTypes, GetAccountsResponse } from '../../types/accountsTypes';
import { showNotification } from '@mantine/notifications';
import { MetadataSchema, metadataSchema } from '../Accounts/CreateBankAccount';
import { ReactComponent as Trash } from '../../assets/svg/trash.svg';
import { selectMode } from '../../lib/store';
import { useAppSelector } from '../../hooks/useRedux';

const CreateTransfer = () => {
  const mode = useAppSelector(selectMode);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  const { getAccounts } = useAccountsService();
  const { getCounterparties } = useCounterpartyService();
  const { createTransfer } = useTransfersService();
  const [transferType, setTransferType] = useState<string | null>(
    TransferTypes.BOOK_TRANSFER
  );
  const [accounts, setAccounts] = useState<ItemSchema[]>([]);
  const [counterparties, setCounterparties] = useState<ItemSchema[]>([]);
  const [createCounterpartyModal, setCreateCounterpartyModal] =
    useState<boolean>(false);
  const [confirmTransferModal, setConfirmTransferModal] =
    useState<boolean>(false);
  const [transferSummary, setTransferSummary] = useState<
    Record<string, unknown>
  >({});
  const { handleError } = useNotification();

  useEffect(() => {
    // const type = searchParams.get('type');
    // if (type === 'nip' || !type) {
    //   setTransferType(TransferTypes.NIP_TRANSFER);
    // } else {
    //   setTransferType(TransferTypes.BOOK_TRANSFER);
    // }

    form.setFieldValue('metadata', []);
    //eslint-disable-next-line
  }, []);

  const form = useForm({
    initialValues: {
      amount: '',
      sourceAccountId: '',
      destinationAccountId: '',
      counterpartyId: '',
      reason: '',
      reference: '',
      idempotencykey: generateRandomString(10),
      metadata: [{ key: '', value: '' }],
    },

    validate: {
      sourceAccountId: (value) =>
        value === '' ? 'Select source account' : null,
    },
  });

  useEffect(() => {
    handleGetAccounts();
    handleGetCounterparties();
    //eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    const sourceAccount = accounts.find(
      (account) => form.values.sourceAccountId === account.value
    );

    if (sourceAccount) {
      setTransferSummary({
        ...transferSummary,
        sourceAccount,
      });
    }

    //eslint-disable-next-line
  }, [form.values.sourceAccountId]);

  useEffect(() => {
    const destinationAccount = accounts.find(
      (account) => form.values.destinationAccountId === account.value
    );

    if (destinationAccount) {
      setTransferSummary({
        ...transferSummary,
        destinationAccount,
      });
    }
    //eslint-disable-next-line
  }, [form.values.destinationAccountId]);

  useEffect(() => {
    const counterparty = counterparties.find(
      (counterparty) => form.values.counterpartyId === counterparty.value
    );

    if (counterparty) {
      setTransferSummary({
        ...transferSummary,
        counterparty,
      });
    }

    //eslint-disable-next-line
  }, [form.values.counterpartyId]);

  const handleGetAccounts = () => {
    getAccounts({ page: 0, size: 100 })
      .then((res: GetAccountsResponse) => {
        const rootAccounts: ItemSchema[] = res.data
          .filter(
            (account) =>
              account.type === AccountTypes.FBO ||
              account.type === AccountTypes.MASTER ||
              account.type === AccountTypes.REVENUE ||
              account.type === AccountTypes.SETTLEMENT
          )
          .map((account) => ({
            label: `${capitalizeTransform(account?.accountName ?? '')} - ${
              account.type
            }`,
            description: `${account.accountNumber} (${account.id})`,
            value: account.id,
            group: 'Root Accounts',
            extravalue: account.accountNumber,
          }));

        const depositAccounts: ItemSchema[] = res.data
          .filter(
            (account) =>
              account.type !== AccountTypes.FBO &&
              account.type !== AccountTypes.MASTER &&
              account.type !== AccountTypes.REVENUE &&
              account.type !== AccountTypes.SETTLEMENT
          )
          .map((account) => ({
            label: `${capitalizeTransform(account?.accountName ?? '')} - ${
              account.type
            }`,
            description: `${account.accountNumber} (${account.id})`,
            value: account.id,
            group: 'Deposit Accounts',
            extravalue: account.accountNumber,
          }));

        setAccounts(rootAccounts.concat(depositAccounts));
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleGetCounterparties = () => {
    getCounterparties({ page: 0, size: 100 })
      .then((res: GetCounterPartiesResponse) => {
        const data: ItemSchema[] = res.data.map((item: CounterpartyType) => ({
          extravalue: item.accountNumber,
          label: capitalizeTransform(
            `${item?.accountName ?? 'N/A'} - ${item?.bank?.name ?? ''}`
          ),
          description: `${item?.accountNumber ?? ''} (${item.id})`,
          value: item.id,
        }));

        setCounterparties(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const onPressSubmit = (values: typeof form.values) => {
    setTransferSummary({
      ...transferSummary,
      amount: values.amount + '00',
      reason: values.reason,
      reference: values.reference,
      idempotencykey: values.idempotencykey,
    });
    setConfirmTransferModal(true);
  };

  const handleSubmit = () => {
    setConfirmTransferModal(false);

    const metadata: Record<string, string> = {};
    const values = form.values;

    if (values.metadata.length > 0) {
      for (let i = 0; i < values.metadata.length; i++) {
        metadata[`${values.metadata[i].key}`] = values.metadata[i].value;
      }
    }

    const data = {
      ...values,
      amount: values.amount + '00',
      currency: 'NGN',
      metadata,
    };

    setLoading(true);

    if (transferType) {
      createTransfer(transferType, data)
        .then(() => {
          showNotification({
            title: 'Success',
            message: 'Transaction created successfully.',
            color: 'orange',
          });

          navigate(-1);
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const afterCounterpartyCreateCallback = (data: CounterpartyType) => {
    const item: ItemSchema = {
      extravalue: data.accountNumber,
      label: `${data.accountName.toLowerCase()} (${data.bank.name.toLowerCase()})`,
      description: `${data.accountNumber} - ${data.id}`,
      value: data.id,
    };
    setCounterparties((current) => [...current, item]);
    form.setFieldValue('counterpartyId', data.id);
    setCreateCounterpartyModal(false);
  };

  return (
    <div className="create-page">
      <Helmet>
        <title>Create Transfer | Anchor</title>
        <meta name="description" content="Initiate a new transfer" />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <CreateCounterpartyModal
        modalOpen={createCounterpartyModal}
        closeModal={() => {
          setCreateCounterpartyModal(false);
        }}
        callback={afterCounterpartyCreateCallback}
      />

      <ConfirmTransfer
        isNipTransfer={transferType === TransferTypes.NIP_TRANSFER}
        modalOpen={confirmTransferModal}
        closeModal={() => {
          setConfirmTransferModal(false);
        }}
        transferSummary={transferSummary as TransferSummaryType}
        handleSubmit={handleSubmit}
      />

      <div className="c-p-header">
        <div className="h-title">Create Transfer</div>

        <div className="h-routes">
          <span
            className="click"
            onClick={() => {
              navigate('/transfers');
            }}
          >
            Transfers
          </span>{' '}
          <ArrowRight /> <span className="route-present no-select">Create</span>
        </div>
      </div>

      <div className="c-form-group">
        <div className="c-g-title">Type</div>

        <div className="c-g-content">
          <div className="form-row">
            <Select
              required
              className="form-item"
              label="Transfer type"
              placeholder="Select transfer type"
              value={transferType}
              onChange={setTransferType}
              data={[
                { value: 'NIP_TRANSFER', label: 'NIP' },
                { value: 'BOOK_TRANSFER', label: 'Book' },
              ]}
              transition="pop-top-left"
              transitionDuration={80}
              transitionTimingFunction="ease"
              disabled
            />
          </div>
        </div>
      </div>

      <form onSubmit={form.onSubmit((values) => onPressSubmit(values))}>
        <div className="c-form-group">
          <div className="c-g-title">Information</div>

          <div className="c-g-content">
            <div className="form-row">
              <TextInput
                required
                className="form-item"
                placeholder="Amount"
                label="Amount"
                type="number"
                icon={'₦'}
                value={form.values.amount}
                onKeyDown={(e) =>
                  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (
                    e.target.value === '' ||
                    /^[0-9\b]+$/.test(e.target.value)
                  ) {
                    form.setFieldValue('amount', e.target.value);
                  }
                }}
                autoComplete="off"
              />
            </div>

            <div className="c-g-c-inner">
              <div className="form-row">
                <Select
                  required
                  className="form-item"
                  label="Source account ID"
                  placeholder="Select Account/ Input ID"
                  searchable
                  itemComponent={CustomSelectItem}
                  data={accounts}
                  maxDropdownHeight={400}
                  creatable
                  getCreateLabel={(query) => `Select ${query}`}
                  onCreate={(query) => {
                    return handleCustomSelectCreate(
                      query,
                      accounts,
                      () => form.setFieldValue('sourceAccountId', query),
                      setAccounts
                    );
                  }}
                  {...form.getInputProps('sourceAccountId')}
                  transition="pop-top-left"
                  transitionDuration={80}
                  transitionTimingFunction="ease"
                />

                {transferType === TransferTypes.NIP_TRANSFER && (
                  <Group spacing="xs" className="form-item" align="flex-end">
                    <Select
                      required={transferType === TransferTypes.NIP_TRANSFER}
                      label="Counterparty ID"
                      placeholder="Select Counterparty/ Input ID"
                      className="form-item-with-add"
                      searchable
                      itemComponent={CustomSelectItem}
                      data={counterparties}
                      maxDropdownHeight={400}
                      creatable
                      getCreateLabel={(query) => `Select ${query}`}
                      onCreate={(query) => {
                        return handleCustomSelectCreate(
                          query,
                          counterparties,
                          () => form.setFieldValue('counterpartyId', query),
                          setCounterparties
                        );
                      }}
                      {...form.getInputProps('counterpartyId')}
                      transition="pop-top-left"
                      transitionDuration={80}
                      transitionTimingFunction="ease"
                    />

                    <Button
                      className="czo"
                      leftIcon={<Plus style={{ opacity: 0.55 }} />}
                      variant="default"
                      onClick={() => {
                        setCreateCounterpartyModal(true);
                      }}
                    >
                      <Text opacity={0.55}>New</Text>
                    </Button>
                  </Group>
                )}

                {transferType === TransferTypes.BOOK_TRANSFER && (
                  <Select
                    required={transferType === TransferTypes.BOOK_TRANSFER}
                    className="form-item"
                    label="Destination account ID"
                    placeholder="Select Account/ Input ID"
                    searchable
                    itemComponent={CustomSelectItem}
                    data={accounts}
                    maxDropdownHeight={400}
                    creatable
                    getCreateLabel={(query) => `Select ${query}`}
                    onCreate={(query) => {
                      return handleCustomSelectCreate(
                        query,
                        accounts,
                        () => form.setFieldValue('destinationAccountId', query),
                        setAccounts
                      );
                    }}
                    {...form.getInputProps('destinationAccountId')}
                    transition="pop-top-left"
                    transitionDuration={80}
                    transitionTimingFunction="ease"
                  />
                )}
              </div>
            </div>

            <div className="form-row">
              <TextInput
                mt={16}
                className="form-item"
                placeholder="Reason"
                label="Reason"
                {...form.getInputProps('reason')}
                autoComplete="off"
              />

              <TextInput
                mt={16}
                className="form-item"
                placeholder="Reference"
                label="Reference"
                {...form.getInputProps('reference')}
                required
                autoComplete="off"
              />
            </div>

            <div className="form-row">
              <TextInput
                className="form-item"
                placeholder="Idempotency key"
                label="Idempotency key"
                disabled
                {...form.getInputProps('idempotencykey')}
                autoComplete="off"
              />
            </div>
          </div>
        </div>

        <div className="metadata">
          <div className="m-header">
            <div className="m-title">
              Metadata <span>(Optional)</span>
            </div>

            <Button
              variant="subtle"
              compact
              onClick={() => form.insertListItem('metadata', metadataSchema)}
            >
              Add Metadata
            </Button>
          </div>

          {form.values.metadata.map((item: MetadataSchema, index: number) => (
            <div key={index} className="metadata-row">
              <div className="m-r-fields">
                <TextInput
                  label="key"
                  placeholder="Key"
                  className="form-item"
                  required
                  {...form.getInputProps(`metadata.${index}.key`)}
                />

                <TextInput
                  label="Value"
                  placeholder="Value"
                  className="form-item-with-add"
                  required
                  {...form.getInputProps(`metadata.${index}.value`)}
                />
              </div>

              <ActionIcon
                onClick={() => form.removeListItem('metadata', index)}
              >
                <Trash />
              </ActionIcon>
            </div>
          ))}
        </div>

        <Group position="apart" mt={30}>
          <Button
            variant="default"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>

          <Button color="dark" type="submit">
            Create Transfer
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default CreateTransfer;
