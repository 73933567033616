import React, { Fragment, useState, useEffect } from 'react';
import { useClickOutside } from '@mantine/hooks';
import { Burger, Avatar, Menu, ScrollArea, NavLink } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useRedux';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { ReactComponent as Home } from '../../assets/svg/navigation/home.svg';
import { ReactComponent as HomeActive } from '../../assets/svg/navigation/home-active.svg';
import { ReactComponent as Accounts } from '../../assets/svg/navigation/accounts.svg';
import { ReactComponent as AccountsActive } from '../../assets/svg/navigation/accounts-active.svg';
import { ReactComponent as Customers } from '../../assets/svg/navigation/customers.svg';
import { ReactComponent as CustomersActive } from '../../assets/svg/navigation/customers-active.svg';
import { ReactComponent as Settings } from '../../assets/svg/navigation/settings.svg';
import { ReactComponent as SettingsActive } from '../../assets/svg/navigation/settings-active.svg';
import { ReactComponent as Organisation } from '../../assets/svg/navigation/organization.svg';
import { ReactComponent as OrganisationActive } from '../../assets/svg/navigation/organization-active.svg';
import { ReactComponent as Transactions } from '../../assets/svg/navigation/transactions.svg';
import { ReactComponent as TransactionsActive } from '../../assets/svg/navigation/transactions-active.svg';
import { ReactComponent as Transfers } from '../../assets/svg/navigation/transfers.svg';
import { ReactComponent as TransfersActive } from '../../assets/svg/navigation/transfers-active.svg';
import { ReactComponent as Verify } from '../../assets/svg/navigation/verify.svg';
import { ReactComponent as VerifyActive } from '../../assets/svg/navigation/verify-active.svg';
import { ReactComponent as Compliance } from '../../assets/svg/navigation/compliance.svg';
import { ReactComponent as ComplianceActive } from '../../assets/svg/navigation/compliance-active.svg';
import { ReactComponent as Events } from '../../assets/svg/navigation/events.svg';
import { ReactComponent as EventsActive } from '../../assets/svg/navigation/events-active.svg';
import { ReactComponent as ArrowUp } from '../../assets/svg/arrow-up-light.svg';
import { ReactComponent as Logout } from '../../assets/svg/logout.svg';
import { ReactComponent as ArrowBack } from '../../assets/svg/arrow-back.svg';
import useNotification from '../../hooks/useNotification';
import EnvSwitch from '../EnvSwitch/EnvSwitch';
import usePermissions from '../../hooks/usePermissions';

type CategoryType = {
  categoryName: string;
  visible: boolean;
  routes: {
    groupId?: string;
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    iconActive: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    name: string;
    route?: string;
    visible: boolean;
    children?: {
      name: string;
      route: string;
      visible: boolean;
    }[];
  }[];
};
interface SidebarProps {
  toggleSidebar: () => void;
  showSidebar: boolean;
}
const Sidebar = ({ toggleSidebar, showSidebar }: SidebarProps) => {
  const title = showSidebar ? 'Close navigation' : 'Open navigation';
  const [orgName, setOrgName] = useState<string>('');
  const [activeCategory, setActiveCategory] = useState<
    'core' | 'settings' | ''
  >('');
  const { firstName, lastName } = useAppSelector(
    (state) => state.user.userDetails
  );
  const organizations = useAppSelector((state) => state.user.organizations);
  const { logoutUser } = useNotification();
  const location = useLocation();
  const {
    accountRead,
    transactionRead,
    transferRead,
    counterpartyRead,
    subaccountRead,
    reportRead,
    auditRead,
    customerRead,
    virtualAaccountRead,
    userRead,
    apikeyRead,
    webhookRead,
    roleRead,
    organizationAdmin,
    eventRead,
  } = usePermissions();

  const sidebarRef = useClickOutside(() => {
    if (showSidebar) {
      toggleSidebar();
    }
  });

  useEffect(() => {
    if (organizations.length > 0) {
      setOrgName(organizations[0]?.orgName);
    }
  }, [organizations]);

  useEffect(() => {
    const settingsRoutes = [
      '/settings/general',
      '/settings/profile',
      '/settings/users',
      '/settings/roles',
      '/compliance/kyc',
      '/api-keys',
      '/webhooks',
      '/events',
      '/audit-logs',
    ];

    if (new RegExp(settingsRoutes.join('|')).test(location.pathname)) {
      setActiveCategory('settings');
    } else {
      setActiveCategory('core');
    }

    //eslint-disable-next-line
  }, []);

  const coreCategories = [
    {
      categoryName: 'Home',
      visible: accountRead || transactionRead || virtualAaccountRead,
      routes: [
        {
          icon: Verify,
          iconActive: VerifyActive,
          name: 'Get Started',
          route: '/get-started',
          visible: false,
        },
        {
          icon: Home,
          iconActive: HomeActive,
          name: 'Dashboard',
          route: '/dashboard',
          visible: false,
        },
        {
          icon: Accounts,
          iconActive: AccountsActive,
          name: 'Root Accounts',
          route: '/root-accounts',
          visible: accountRead,
        },
        {
          icon: Transactions,
          iconActive: TransactionsActive,
          name: 'Transactions',
          route: '/organization-transactions',
          visible: transactionRead,
        },
        {
          icon: Home,
          iconActive: HomeActive,
          name: 'Cases',
          route: '/cases',
          visible: false,
        },
        {
          icon: Home,
          iconActive: HomeActive,
          name: 'Dispute',
          route: '/dispute',
          visible: false,
        },
        {
          groupId: 'virtual-accounts',
          icon: Home,
          iconActive: HomeActive,
          name: 'Virtual Accounts',
          visible: transactionRead || virtualAaccountRead,
          children: [
            {
              name: 'Payments',
              route: '/payments',
              visible: transactionRead,
            },
            {
              name: 'Settlements',
              route: '/settlements',
              visible: transactionRead,
            },
            {
              name: 'Account Numbers',
              route: '/account-numbers',
              visible: virtualAaccountRead,
            },
          ],
        },
      ],
    },
    {
      categoryName: 'Onboarding',
      visible: customerRead,
      routes: [
        {
          groupId: 'customers',
          icon: Customers,
          iconActive: CustomersActive,
          name: 'Customers',
          visible: customerRead,
          children: [
            {
              name: 'Individual',
              route: '/customers/individual',
              visible: customerRead,
            },
            {
              name: 'Business',
              route: '/customers/business',
              visible: customerRead,
            },
          ],
        },
      ],
    },
    {
      categoryName: 'Banking',
      visible:
        accountRead ||
        transactionRead ||
        transferRead ||
        counterpartyRead ||
        true,
      routes: [
        {
          icon: Accounts,
          iconActive: AccountsActive,
          name: 'Accounts',
          route: '/bank-accounts',
          visible: accountRead,
        },
        {
          icon: Transactions,
          iconActive: TransactionsActive,
          name: 'Transactions',
          route: '/transactions',
          visible: true,
        },
        {
          groupId: 'transfers',
          icon: Transfers,
          iconActive: TransfersActive,
          name: 'Transfers',
          visible: transferRead || counterpartyRead,
          children: [
            {
              name: 'NIP Transfers',
              route: '/nip-transfers',
              visible: transferRead,
            },
            {
              name: 'Book Transfers',
              route: '/book-transfers',
              visible: transferRead,
            },
            {
              name: 'Counterparty',
              route: '/counterparty',
              visible: counterpartyRead,
            },
          ],
        },
        {
          icon: Transactions,
          iconActive: TransactionsActive,
          name: 'Cards',
          route: '/cards',
          visible: false,
        },
        {
          icon: Transactions,
          iconActive: TransactionsActive,
          name: 'Bill Payments',
          route: '/bill-payments',
          visible: false,
        },
      ],
    },
    {
      categoryName: 'Digital Wallet',
      visible: subaccountRead || transactionRead || transferRead,
      routes: [
        {
          icon: Accounts,
          iconActive: AccountsActive,
          name: 'Sub Accounts',
          route: '/sub-accounts',
          visible: subaccountRead,
        },
        {
          icon: Transactions,
          iconActive: TransactionsActive,
          name: 'Transactions',
          route: '/sub-account-transactions',
          visible: transactionRead,
        },
        {
          groupId: 'sub-account-transfers',
          icon: Transfers,
          iconActive: TransfersActive,
          name: 'Transfers',
          visible: transferRead,
          children: [
            {
              name: 'NIP Transfers',
              route: '/sub-account-nip-transfers',
              visible: transferRead,
            },
            {
              name: 'Book Transfers',
              route: '/sub-account-book-transfers',
              visible: transferRead,
            },
          ],
        },
      ],
    },
    {
      categoryName: 'Monitoring',
      visible: reportRead,
      routes: [
        {
          icon: Organisation,
          iconActive: OrganisationActive,
          name: 'Reports',
          route: '/reports',
          visible: reportRead,
        },
      ],
    },
  ];

  const settingsCategories = [
    {
      categoryName: 'Organization',
      visible: true,
      routes: [
        {
          icon: Accounts,
          iconActive: AccountsActive,
          name: 'General',
          route: '/settings/general',
          visible: true,
        },
        {
          icon: Settings,
          iconActive: SettingsActive,
          name: 'Profile',
          route: '/settings/profile',
          visible: true,
        },
        {
          icon: Customers,
          iconActive: CustomersActive,
          name: 'Users',
          route: '/settings/users',
          visible: userRead,
        },
        {
          icon: Verify,
          iconActive: VerifyActive,
          name: 'Roles',
          route: '/settings/roles',
          visible: roleRead,
        },
        {
          icon: Organisation,
          iconActive: OrganisationActive,
          name: 'Audit Logs',
          route: '/audit-logs',
          visible: auditRead,
        },
      ],
    },
    {
      categoryName: 'Compliance',
      visible: organizationAdmin,
      routes: [
        {
          icon: Compliance,
          iconActive: ComplianceActive,
          name: 'KYC',
          route: '/compliance/kyc',
          visible: organizationAdmin,
        },
      ],
    },
    {
      categoryName: 'Developers',
      visible: apikeyRead || webhookRead || eventRead,
      routes: [
        {
          icon: Compliance,
          iconActive: ComplianceActive,
          name: 'API Keys',
          route: '/api-keys',
          visible: apikeyRead,
        },
        {
          icon: Organisation,
          iconActive: OrganisationActive,
          name: 'Webhooks',
          route: '/webhooks',
          visible: webhookRead,
        },
        {
          icon: Events,
          iconActive: EventsActive,
          name: 'Events',
          route: '/events',
          visible: eventRead,
        },
      ],
    },
  ];

  return (
    <div
      className={`sidebar-container no-select  ${
        showSidebar ? '' : 'is-hidden'
      }`}
      ref={sidebarRef}
    >
      <Logo className="app-logo" />

      <Burger
        opened={showSidebar}
        onClick={toggleSidebar}
        title={title}
        className="sidebar-ham"
      />

      <ScrollArea type="auto">
        <div className={`sidebar-inner  ${showSidebar ? '' : 'is-hidden'}`}>
          {activeCategory === 'settings' && (
            <NavLink
              label="Back"
              icon={<ArrowBack />}
              onClick={() => {
                setActiveCategory('core');
              }}
              color="gray"
              mb={20}
            />
          )}

          <RoutesGroup
            categories={
              activeCategory === 'core' ? coreCategories : settingsCategories
            }
            showSidebar={showSidebar}
            toggleSidebar={toggleSidebar}
            activeCategory={activeCategory}
          />

          {activeCategory === 'core' && (
            <NavLink
              label="Settings"
              icon={<Settings />}
              onClick={() => {
                setActiveCategory('settings');
              }}
              color="gray"
            />
          )}
        </div>
      </ScrollArea>

      <EnvSwitch />

      <Menu shadow="md" width="target" position="top">
        <Menu.Target>
          <div className="user-info click">
            <div className="u-i-wrapper">
              <Avatar src={null} alt="username" color="gray">
                {firstName && lastName
                  ? `${firstName[0]}${lastName[0]}`
                  : orgName && orgName[0].toUpperCase()}
              </Avatar>

              <div className="u-main">
                <div className="org-name">
                  {orgName && orgName.toLowerCase()}
                </div>

                <div className="user-name">{`${
                  firstName ? firstName : 'User'
                } ${lastName ? lastName : ''}`}</div>
              </div>
            </div>

            <div>
              <ArrowUp />
            </div>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<Logout />}
            onClick={() => {
              logoutUser();
            }}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

interface IRoutesGroup {
  categories: CategoryType[];
  toggleSidebar: () => void;
  showSidebar: boolean;
  activeCategory: 'core' | 'settings' | '';
}

const RoutesGroup = ({
  categories,
  toggleSidebar,
  showSidebar,
  activeCategory,
}: IRoutesGroup) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeGroup, setActiveGroup] = useState<string>('');
  const [prevCategory, updatePrevCategory] = useState<string | null>(null);

  useEffect(() => {
    updatePrevCategory(activeCategory);

    if (activeCategory === '' || prevCategory === '') {
      return;
    }

    setActiveGroup('');

    switch (activeCategory) {
      case 'core':
        navigate('/transactions');
        break;

      case 'settings':
        navigate('/settings/general');
        break;
    }
    //eslint-disable-next-line
  }, [activeCategory]);

  const isActive = (route: string) => location.pathname.includes(route);

  return (
    <Fragment>
      {categories.map(
        (category, index) =>
          category.visible && (
            <div
              className={category.categoryName ? 'nav-group' : ''}
              key={index}
            >
              <div className="s-title">{category.categoryName}</div>

              {category.routes.map((item, index) => (
                <Fragment key={index}>
                  {item.route && item.visible && (
                    <NavLink
                      key={index}
                      active={isActive(item.route)}
                      label={item.name}
                      icon={
                        isActive(item.route) ? (
                          <item.iconActive />
                        ) : (
                          <item.icon />
                        )
                      }
                      onClick={() => {
                        item.route && navigate(item.route);
                        setActiveGroup('');
                        if (showSidebar) {
                          toggleSidebar();
                        }
                      }}
                      color="gray"
                    />
                  )}

                  {item.children && item.visible && (
                    <NavLink
                      label={item.name}
                      icon={
                        item.children.find((item) => isActive(item.route)) ? (
                          <item.iconActive />
                        ) : (
                          <item.icon />
                        )
                      }
                      childrenOffset={32}
                      opened={activeGroup === item.groupId}
                      onClick={() => {
                        item.groupId && setActiveGroup(item.groupId);
                        if (activeGroup !== item.groupId) {
                          item.children && navigate(item.children[0].route);
                        }
                      }}
                    >
                      {item.children.map(
                        (item, index) =>
                          item.visible && (
                            <NavLink
                              key={index}
                              active={location.pathname.includes(item.route)}
                              label={item.name}
                              onClick={() => {
                                navigate(item.route);
                                if (showSidebar) {
                                  toggleSidebar();
                                }
                              }}
                              color="gray"
                            />
                          )
                      )}
                    </NavLink>
                  )}
                </Fragment>
              ))}
            </div>
          )
      )}
    </Fragment>
  );
};

export default Sidebar;
