import React, { Fragment } from 'react';
import { Text, Group, Switch, Select } from '@mantine/core';
import CustomSelectItem, {
  ItemSchema,
} from '../CustomSelectItem/CustomSelectItem';
import { handleCustomSelectCreate } from '../../lib/util';

interface IFilterSelectItem {
  label: string;
  checkedState: boolean;
  setCheckedState: (value: boolean) => void;
  closeFilterMenu: () => void;
  filterValue?: string;
  setFilterValue: (value: string) => void;
  filterData: ItemSchema[];
  setFilterData: (value: ItemSchema[]) => void;
  onFilterItemChange: (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => void;
}

const FilterSelectItem = ({
  label,
  checkedState,
  setCheckedState,
  closeFilterMenu,
  filterValue,
  filterData,
  onFilterItemChange,
  setFilterValue,
  setFilterData,
}: IFilterSelectItem) => {
  return (
    <Fragment>
      <Group align="center" position="apart" mt={10}>
        <Text fw={500} fz={14}>
          {label}
        </Text>

        <Switch
          checked={checkedState}
          onChange={(event) => setCheckedState(event.currentTarget.checked)}
        />
      </Group>
      {checkedState && (
        <div className="filter-item-expanded">
          <Select
            placeholder={`Select ${label} / Input Id`}
            value={filterValue}
            onChange={(value: string) => {
              onFilterItemChange(value, filterValue, setFilterValue);
              closeFilterMenu();
            }}
            searchable
            itemComponent={CustomSelectItem}
            data={filterData}
            maxDropdownHeight={400}
            creatable
            getCreateLabel={(query) => `Select ${query}`}
            onCreate={(query) => {
              return handleCustomSelectCreate(
                query,
                filterData,
                () => onFilterItemChange(query, filterValue, setFilterValue),
                setFilterData
              );
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default FilterSelectItem;
