export enum ResourceTypes {
  ApiKey = 'ApiKey',
  BookTransaction = 'BookTransaction',
  BookTransfer = 'BookTransfer',
  BulkTransfer = 'BulkTransfer',
  BusinessCustomer = 'BusinessCustomer',
  BusinessOfficer = 'BusinessOfficer',
  CounterParty = 'CounterParty',
  DepositAccount = 'DepositAccount',
  Document = 'Document',
  IndividualCustomer = 'IndividualCustomer',
  NIPTransaction = 'NIPTransaction',
  NipTransfer = 'NIPTransfer',
  Payment = 'Payment',
  PaymentSettlement = 'PaymentSettlement',
  Report = 'Report',
  Role = 'Role',
  Statement = 'Statement',
  SubAccount = 'SubAccount',
  User = 'User',
  VirtualNuban = 'VirtualNuban',
  Webhook = 'Webhook',
}
