import { ResponseMetaPagination } from '../lib/types';

export type GetWebhooksResponse = {
  data: WebhookType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type WebhookType = {
  id: string;
  type: string;
  createdAt: Date;
  deliveryMode: string;
  secretToken: string;
  enabledEvents: string[];
  label: string;
  url: string;
  status: string;
  supportIncluded: boolean;
};

export enum WebhookStatusTypes {
  enabled = 'Enabled',
}

export type CreateWebhookData = {
  deliveryMode: string;
  label: string;
  token: string;
  url: string;
  enabledEvents?: string[];
  supportIncluded: boolean;
};

export type SendSampleEventResponse = {
  request: Record<string, unknown> | string;
  response: Record<string, unknown> | string;
  responseCode: number;
};
