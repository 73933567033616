import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import { DocumentType, UploadDocumentData } from '../types/documentTypes';

const useDocumentsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, mode);

  const getCustomerDocuments = (customerId: string) => {
    return new Promise<DocumentType[]>((resolve, reject) => {
      authApi
        .get(APIS.DOCUMENTS.CUSTOMER_DOCUMENTS(customerId))
        .then((res: { data: DocumentType[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const uploadDocument = (
    customerId: string,
    documentId: string,
    data: UploadDocumentData
  ) => {
    return new Promise<DocumentType>((resolve, reject) => {
      const formData = new FormData();
      formData.append('textData', data.textData);
      formData.append('fileData', data.fileData);

      axiosApi
        .post(APIS.DOCUMENTS.UPLOAD_DOCUMENT(customerId, documentId), formData)
        .then((res: { data: DocumentType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const downloadDocument = (customerId: string, documentId: string) => {
    return new Promise<any>((resolve, reject) => {
      authApi
        .get(APIS.DOCUMENTS.DOWNLOAD_DOCUMENT(customerId, documentId))
        .then((res: { data: any }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getCustomerDocuments,
    uploadDocument,
    downloadDocument,
  };
};

export default useDocumentsService;
