import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import { AxiosError } from 'axios';
import {
  Button,
  Badge,
  Group,
  Table,
  ScrollArea,
  Menu,
  Text,
  Tooltip,
  LoadingOverlay,
  Tabs,
} from '@mantine/core';
import useUserManagementService from '../../../services/userManagement';
import useRoleManagementService from '../../../services/roleManagement';
import {
  UserType,
  InvitationStatusTypes,
  GetUsersResponse,
} from '../../../types/userManagementTypes';
import { ReactComponent as MenuCircle } from '../../../assets/svg/menu-circle.svg';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import EmptyData from '../../../components/EmptyData/EmptyData';
import PaginationControls from '../../../components/PaginationControls/PaginationControls';
import Confirmation from '../../../components/Confirmation/Confirmation';
import { selectMode } from '../../../lib/store';
import {
  initialMetaData,
  setUsers,
} from '../../../lib/store/reducers/DataReducer';
import './users.scss';
import { RoleType, GetRolesResponse } from '../../../types/roleManagementTypes';
import AddUsers from './AddUsers';
import { ResponseMetaPagination } from '../../../lib/types';
import UserDetails from './UserDetails';
import useNotification from '../../../hooks/useNotification';
import usePermissions from '../../../hooks/usePermissions';

type UsersState = {
  data: UserType[];
  meta: ResponseMetaPagination;
  dataFetched: boolean;
};

const Users = () => {
  const [activeTab, setActiveTab] = useState<string | null>('active');
  const [searchParams] = useSearchParams();
  const addUser = searchParams.get('addUser');

  useEffect(() => {
    if (addUser) {
      setActiveTab('pending');
    }
  }, [addUser]);

  return (
    <div>
      <Helmet>
        <title>Users | Anchor</title>
        <meta
          name="description"
          content="View and manage the members of your organization"
        />
      </Helmet>

      <div className="p-title extra-mb">Users</div>

      <Tabs value={activeTab} onTabChange={setActiveTab} color="dark">
        <Tabs.List>
          <Tabs.Tab value="active">Active Invites</Tabs.Tab>
          <Tabs.Tab value="pending">Pending Invites</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="active" pt="lg">
          <ActiveInvites />
        </Tabs.Panel>

        <Tabs.Panel value="pending" pt="lg">
          <PendingInvites />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

const ActiveInvites = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mode = useAppSelector(selectMode);
  const [userId, setUserId] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getUsers } = useUserManagementService();
  const { getRoles } = useRoleManagementService();
  const users = useAppSelector((state) => state.data.users);
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addUserDrawer, setAddUserDrawer] = useState<boolean>(false);
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const { handleError } = useNotification();
  const { userInvite } = usePermissions();

  useEffect(() => {
    handleGetUsers();
    handleGetRoles();
    //eslint-disable-next-line
  }, [page, size, mode]);

  const handleGetUsers = () => {
    if (!users.dataFetched) setLoading(true);

    getUsers({ size, page, invitationStatus: InvitationStatusTypes.accepted })
      .then((res: GetUsersResponse) => {
        dispatch(
          setUsers({
            data: res.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetRoles = () => {
    getRoles(40, 0)
      .then((res: GetRolesResponse) => {
        setRoles(res.data);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  return (
    <div className="relative table-container with-action-btns">
      <LoadingOverlay visible={loading} />

      <AddUsers
        drawerOpen={addUserDrawer}
        closeDrawer={() => setAddUserDrawer(false)}
        callback={() => {
          navigate('/settings/users?addUser=true');
        }}
      />

      <UserDetails
        drawerOpen={detailsDrawer}
        closeDrawer={() => setDetailsDrawer(false)}
        userId={userId}
        userActionCallback={() => handleGetUsers()}
        roles={roles}
      />

      {users.data.length > 0 && (
        <React.Fragment>
          <Group position="right" align="center" className="action-btns">
            <Button
              compact
              className="compact-btn main"
              color="dark"
              onClick={() => {
                setAddUserDrawer(true);
              }}
              disabled={!userInvite}
            >
              Add User
            </Button>
          </Group>

          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table mt={20} verticalSpacing="md" sx={{ minWidth: 800 }}>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Date Invited</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  users.data.map((user: UserType, index: number) => (
                    <tr
                      key={index}
                      id={user.id}
                      className="click"
                      onClick={() => {
                        setUserId(user.id);
                        setDetailsDrawer(true);
                      }}
                    >
                      <td>
                        {user.firstName && `${user.firstName} ${user.lastName}`}
                      </td>

                      <td>{user.email}</td>

                      <td>
                        <Tooltip
                          multiline
                          width={220}
                          withArrow
                          disabled={user.roles.data.length < 2}
                          label={
                            <ul>
                              {user.roles.data.length > 0 &&
                                user.roles.data.map((role: RoleType) => (
                                  <li key={role.id}>
                                    {role.roleName && role.roleName}
                                  </li>
                                ))}
                            </ul>
                          }
                        >
                          <div className="type-badge">
                            {user.roles.data.length > 0 &&
                              user.roles.data[0]?.roleName}{' '}
                            {user.roles.data.length > 1 &&
                              `+ ${user.roles.data.length - 1}`}
                          </div>
                        </Tooltip>
                      </td>

                      <td>
                        {user.invitationStatus ===
                          InvitationStatusTypes.accepted && (
                          <Badge color="green">active</Badge>
                        )}

                        {user.invitationStatus ===
                          InvitationStatusTypes.pending && (
                          <Badge color="pink">pending</Badge>
                        )}
                      </td>

                      <td>{moment(user.createdAt).format('MMM Do, YYYY')}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={users.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {users.data.length === 0 && (
        <EmptyData
          title="You have not invited any team member"
          desc="At the moment, there is no data to be displayed."
          buttonText="Add User"
          buttonAction={() => {
            setAddUserDrawer(true);
          }}
        />
      )}
    </div>
  );
};

const PendingInvites = () => {
  const mode = useAppSelector(selectMode);
  const [activeUser, setActiveUser] = useState<UserType | null>(null);
  const [deleteInviteModal, setDeleteInviteModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getUsers, resendInvite, deleteInvite } = useUserManagementService();
  const [users, setUsers] = useState<UsersState>({
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const addUser = searchParams.get('addUser');
  const { handleError } = useNotification();
  const [addUserDrawer, setAddUserDrawer] = useState<boolean>(false);
  const { userInvite } = usePermissions();

  useEffect(() => {
    if (addUser) {
      handleGetUsers();
      navigate('/settings/users', { replace: true });
    }
    //eslint-disable-next-line
  }, [addUser]);

  useEffect(() => {
    handleGetUsers();
    //eslint-disable-next-line
  }, [page, size, mode]);

  const handleGetUsers = () => {
    setLoading(true);

    getUsers({ size, page, invitationStatus: InvitationStatusTypes.pending })
      .then((res: GetUsersResponse) => {
        setUsers({
          data: res.data,
          meta: res?.meta?.pagination,
          dataFetched: true,
        });
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteInvite = () => {
    if (activeUser) {
      setLoading(true);

      deleteInvite(activeUser.email)
        .then(() => {
          showNotification({
            title: 'Success',
            message: 'Invititation deleted successfully.',
            color: 'orange',
          });

          setActiveUser(null);
          handleGetUsers();
        })
        .catch((error: AxiosError) => {
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleResendInvite = (email: string) => {
    setLoading(true);

    resendInvite(email)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Invititation resent successfully.',
          color: 'orange',
        });

        setActiveUser(null);
        handleGetUsers();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="relative table-container with-action-btns">
      <LoadingOverlay visible={loading} />

      <AddUsers
        drawerOpen={addUserDrawer}
        closeDrawer={() => setAddUserDrawer(false)}
        callback={() => {
          navigate('/settings/users?addUser=true');
        }}
      />

      <Confirmation
        isOpened={deleteInviteModal}
        title="Are you sure you want to delete this invitation?"
        closeModal={() => {
          setDeleteInviteModal(false);
        }}
        hasInput={false}
        confirmText="Delete"
        submit={() => {
          setDeleteInviteModal(false);
          handleDeleteInvite();
        }}
      />

      {users.data.length > 0 && (
        <React.Fragment>
          <Group position="right" align="center" className="action-btns">
            <Button
              compact
              className="compact-btn main"
              color="dark"
              onClick={() => {
                setAddUserDrawer(true);
              }}
              disabled={!userInvite}
            >
              Add User
            </Button>
          </Group>

          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table mt={20} verticalSpacing="md" sx={{ minWidth: 800 }}>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Date Invited</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  users.data.map((user: UserType, index: number) => (
                    <tr key={index}>
                      <td>{user.email}</td>

                      <td>
                        <div className="type-badge">
                          {user.roles.data.length > 0 &&
                            user.roles.data[0]?.roleName}
                        </div>
                      </td>

                      <td>{moment(user.createdAt).format('MMM Do, YYYY')}</td>

                      <td className="table-last">
                        <Menu
                          withArrow
                          transition="rotate-right"
                          transitionDuration={150}
                          withinPortal
                        >
                          <Menu.Target>
                            <MenuCircle className="click" />
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Item
                              onClick={() => {
                                handleResendInvite(user.email);
                              }}
                            >
                              <Text fw={500}>Resend Invite</Text>
                            </Menu.Item>
                            <Menu.Item
                              color="red"
                              onClick={() => {
                                setActiveUser(user);
                                setDeleteInviteModal(true);
                              }}
                            >
                              <Text fw={500}>Delete Invite</Text>
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={users.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {users.data.length === 0 && (
        <EmptyData
          title="You do not have any pending invites"
          desc="At the moment, there is no data to be displayed."
          buttonText="Add User"
          buttonAction={() => {
            setAddUserDrawer(true);
          }}
        />
      )}
    </div>
  );
};

export default Users;
