import { ResponseMetaPagination } from '../lib/types';
import { AccountType } from './accountsTypes';

export type GetReportsResponse = {
  data: ReportType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type ReportType = {
  id: string;
  type: string;
  account: {
    data: AccountType;
  };
  reportType: string;
  fromDate: string;
  createdAt: string;
  completedAt?: string;
  documentType?: string;
  timezone: string;
  toDate: string;
  rowCount: number;
  updatedAt: string;
  status: string;
};

export type GetReportsParams = {
  page: number;
  size: number;
};

export type CreateReportData = {
  fromDate: string;
  toDate: string;
  reportType: string;
  documentType: string;
  accountId: string;
};

export enum ReportTypes {
  balance_summary = 'balance_summary',
  transaction_history = 'transaction_history',
}

export enum ReportStatusTypes {
  completed = 'completed',
  initiated = 'initiated',
}

export enum ReportDocumentTypes {
  EXCEL = 'excel',
  CSV = 'csv',
}
