import { ResponseMetaPagination } from '../lib/types';
import { Data } from './businessTypes';

export type GetStatementsResponse = {
  data: StatementType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type StatementType = {
  id: string;
  type: string;
  account: {
    data: Data;
  };
  fromDate: string;
  createdAt: string;
  documentType: string;
  timezone: string;
  toDate: string;
  rowCount: number;
  updatedAt: string;
  status: string;
};

export type GetStatementsParams = {
  page: number;
  size: number;
  accountId?: string;
  accountType?: string;
};

export type CreateStatementData = {
  fromDate: string;
  toDate: string;
  accountId: string;
  accountType: 'DepositAccount' | 'SubAccount';
};

export enum StatementStatusTypes {
  completed = 'completed',
  initiated = 'initiated',
  failed = 'failed',
}
