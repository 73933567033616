import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  Button,
  Group,
  LoadingOverlay,
  ScrollArea,
  Tabs,
  Menu,
  Drawer,
  Badge,
} from '@mantine/core';
import useNotification from '../../../hooks/useNotification';
import { formatDate2 } from '../../../lib/util';
import { ReactComponent as ArrowDownRight } from '../../../assets/svg/arrow-down-right.svg';
import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron-down.svg';
import { DetailItem2 } from '../../../components/DetailItem/DetailItem';
import AuditLogsNested from '../../../components/AuditLogs/AuditLogsNested';
import Confirmation from '../../../components/Confirmation/Confirmation';
import { showNotification } from '@mantine/notifications';
import { ApiKeyStateTypes, ApiKeyType } from '../../../types/apiKeysTypes';
import useApiKeysService from '../../../services/apiKeys';

enum ConfirmationTextTypes {
  activate = 'activate',
  revoke = 'revoke',
  delete = 'delete',
}

interface ApiKeyDetailsProps {
  modalOpen: boolean;
  closeModal: () => void;
  callback: () => void;
  activeApiKey: ApiKeyType | null;
}

const ApiKeyDetails = ({
  modalOpen,
  closeModal,
  callback,
  activeApiKey,
}: ApiKeyDetailsProps) => {
  const [activeTab, setActiveTab] = useState<string | null>('audit');
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [confirmationText, setConfirmationText] = useState<
    ConfirmationTextTypes | ''
  >('');
  const [apiKey, setApiKey] = useState<ApiKeyType | null>(null);
  const { getApiKey, activateApiKey, revokeApiKey, deleteApiKey } =
    useApiKeysService();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleError } = useNotification();

  useEffect(() => {
    if (activeApiKey) {
      setApiKey(activeApiKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApiKey]);

  const handleGetApikey = () => {
    if (!activeApiKey) return;

    getApiKey(activeApiKey.id)
      .then((res: ApiKeyType) => {
        setApiKey(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleConfirm = () => {
    if (!apiKey) return;

    let action;
    let message = '';

    switch (confirmationText) {
      case ConfirmationTextTypes.activate:
        action = activateApiKey;
        message = 'Api key activated.';
        break;

      case ConfirmationTextTypes.revoke:
        action = revokeApiKey;
        message = 'Api key revoked.';
        break;

      case ConfirmationTextTypes.delete:
        action = deleteApiKey;
        message = 'Api key deleted.';
        break;
    }

    setLoading(true);

    if (action) {
      action(apiKey.id)
        .then(() => {
          showNotification({
            title: 'Success',
            message,
            color: 'orange',
          });

          if (confirmationText === ConfirmationTextTypes.delete) {
            closeModal();
          } else {
            handleGetApikey();
          }
          callback();
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Drawer
      opened={modalOpen}
      onClose={closeModal}
      title={
        <Group align="center" spacing={40}>
          <div className="modal-title">
            {apiKey?.label ?? 'Api Key Details'}
          </div>

          <Menu shadow="md" width="target">
            <Menu.Target>
              <Button
                leftIcon={<ArrowDownRight />}
                rightIcon={<ChevronDown />}
                color="dark"
              >
                Actions
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              {apiKey?.state !== ApiKeyStateTypes.expired && (
                <Menu.Item
                  onClick={() => {
                    setConfirmationText(
                      apiKey?.state === ApiKeyStateTypes.inactive
                        ? ConfirmationTextTypes.activate
                        : ConfirmationTextTypes.revoke
                    );
                    setConfirmationModal(true);
                  }}
                  className="menu-item active text-center"
                >
                  {apiKey?.state === ApiKeyStateTypes.inactive
                    ? 'Activate'
                    : 'Revoke'}
                </Menu.Item>
              )}

              <Menu.Item
                color="#DE7965"
                fw={500}
                fz={13}
                className="text-center"
                onClick={() => {
                  setConfirmationText(ConfirmationTextTypes.delete);
                  setConfirmationModal(true);
                }}
              >
                Delete ApiKey
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      }
      padding="xl"
      size="100vh"
      position="bottom"
      overlayOpacity={0.5}
      className="details-drawer-bottom gray-bg"
    >
      <LoadingOverlay visible={loading} />

      <Confirmation
        isOpened={confirmationModal}
        title={`Are you sure you want to ${confirmationText} this API key?`}
        closeModal={() => {
          setConfirmationModal(false);
          setConfirmationText('');
        }}
        hasInput={confirmationText === ConfirmationTextTypes.delete}
        confirmText={confirmationText.toUpperCase()}
        submit={() => {
          setConfirmationModal(false);
          handleConfirm();
        }}
      />

      {apiKey && (
        <ScrollArea type="auto" className="fullscreen-modal-section-scroll">
          <div className="permission-item-details">
            <ApiKeyInfo apiKey={apiKey} />

            <div className="p-tabs-container fullscreen-modal-section-scroll allow-grow child no-pr relative">
              <Tabs value={activeTab} onTabChange={setActiveTab} color="dark">
                <Tabs.List>
                  <Tabs.Tab value="audit">Audit Logs</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="audit" pt="xs">
                  <AuditLogsNested
                    extraParams={{ resourceId: apiKey.id }}
                    extraClasses="no-border"
                  />
                </Tabs.Panel>
              </Tabs>
            </div>
          </div>
        </ScrollArea>
      )}
    </Drawer>
  );
};

interface Props {
  apiKey: ApiKeyType;
}

const ApiKeyInfo = ({ apiKey }: Props) => {
  return (
    <div className="p-i-info">
      <div className="p-i-main">
        <div>
          <DetailItem2 title="ID" desc={apiKey.id} extraClasses="stack" />

          <DetailItem2
            title="Environment"
            desc={apiKey.environment}
            extraClasses="stack"
          />

          <DetailItem2 title="Label" desc={apiKey.label} extraClasses="stack" />

          <DetailItem2
            title="Prefix"
            desc={apiKey.prefix}
            extraClasses="stack"
          />
        </div>

        <div>
          <DetailItem2
            title="Date Created"
            desc={formatDate2(apiKey.createdAt, 'MMM Do, YYYY h:mm a')}
            extraClasses="stack"
          />

          <DetailItem2
            title="State"
            desc={
              <Badge
                color={
                  apiKey.state === ApiKeyStateTypes.active ? 'green' : 'red'
                }
              >
                {apiKey.state}
              </Badge>
            }
            extraClasses="stack"
          />

          <DetailItem2
            title="Expiry Date"
            desc={
              apiKey?.expiryTime
                ? formatDate2(apiKey?.expiryTime, 'MMM Do, YYYY h:mm a')
                : 'Never expires'
            }
            extraClasses="stack"
          />
        </div>
      </div>
    </div>
  );
};

export default ApiKeyDetails;
