import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput, Group, Button } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { ReactComponent as Search } from '../../assets/svg/search.svg';
import { ReactComponent as Close } from '../../assets/svg/close.svg';
import './global-search.scss';

const GlobalSearch = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [collapseSearchTrigger, setCollapseSearchTriggerTrigger] =
    useState<number>(0);
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => setOpened(false));
  const navigate = useNavigate();
  const [value, setValue] = useState<string>('');
  const [selectedResource, setSelectedResource] = useState<string>('');

  useEffect(() => {
    if (collapseSearchTrigger !== 0) {
      setOpened(false);
    }
  }, [collapseSearchTrigger]);

  enum Resources {
    transactions = 'transactions',
    payments = 'payments',
    nipTransfers = 'NIP transfers',
    bookTransfers = 'book transfers',
    accounts = 'accounts',
    businessCustomers = 'business customers',
    individualCustomers = 'individual customers',
    counterparty = 'counterparty',
    virtualNubans = 'virtual nubans',
  }

  const resources = [
    Resources.businessCustomers,
    Resources.individualCustomers,
    Resources.counterparty,
    Resources.nipTransfers,
    Resources.bookTransfers,
    Resources.accounts,
    Resources.payments,
    Resources.virtualNubans,
  ];

  const handleSearch = () => {
    setCollapseSearchTriggerTrigger(Math.random());

    switch (selectedResource) {
      case Resources.transactions:
        navigate(`/transactions?search=${value}`);
        break;

      case Resources.payments:
        navigate(`/payments?search=${value}`);
        break;

      case Resources.nipTransfers:
        navigate(`/nip-transfers?search=${value}`);
        break;

      case Resources.bookTransfers:
        navigate(`/book-transfers?search=${value}`);
        break;

      case Resources.accounts:
        navigate(`/bank-accounts?search=${value}`);
        break;

      case Resources.businessCustomers:
        navigate(`/customers/business/?search=${value}`);
        break;

      case Resources.individualCustomers:
        navigate(`/customers/individual/?search=${value}`);
        break;

      case Resources.counterparty:
        navigate(`/counterparty?search=${value}`);
        break;

      case Resources.virtualNubans:
        navigate(`/account-numbers?search=${value}`);
        break;
    }
  };

  return (
    <div className="global-search">
      <Group
        className={`input-wrapper ${opened ? 'focused' : ''}`}
        spacing={10}
        ref={ref}
        onClick={() => setOpened(true)}
      >
        {selectedResource ? (
          <div className="type-badge">
            <span>{selectedResource}</span>
            <Close
              className="click"
              onClick={() => {
                setSelectedResource('');
                setValue('');
              }}
            />
          </div>
        ) : (
          <Search />
        )}

        <TextInput
          value={value}
          onChange={(event) => {
            if (selectedResource) {
              setValue(event.currentTarget.value);
            }
          }}
          ref={inputRef}
          variant="unstyled"
          placeholder={
            selectedResource
              ? `Search ${selectedResource}`
              : 'Search on Anchor - Select resource to continue'
          }
          autoComplete="off"
          onKeyUp={(e) => {
            if (e.code === 'Enter') {
              if (value.trim().length !== 0) {
                handleSearch();
              }
            }
          }}
        />

        <div className={`g-s-dropdown ${opened ? 'active' : ''}`}>
          <div className="dropdown-inner">
            {selectedResource ? (
              <Button
                color="dark"
                disabled={value.trim().length === 0}
                onClick={handleSearch}
              >
                Search in {selectedResource}
              </Button>
            ) : (
              <>
                <div className="d-title">Search within:</div>
                <div className="resources">
                  {resources.map((resource: string) => (
                    <div
                      key={resource}
                      className="type-badge click"
                      onClick={() => {
                        setSelectedResource(resource);
                        inputRef.current?.focus();
                      }}
                    >
                      {resource}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </Group>
    </div>
  );
};

export default GlobalSearch;
