import { APIS } from '../lib/api/apilog';
import { createApi, ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { AxiosError } from 'axios';
import {
  JoinTeamType,
  InviteUserType,
  GetUsersResponse,
  UserType,
  ManageUserRoleData,
  GetUsersParams,
} from '../types/userManagementTypes';
import { Mode } from '../lib/store/reducers/SettingsReducer';

const useUserManagementService = () => {
  const api = createApi();
  const authApi = useApi(ApiAuthMode.BearerToken, Mode.Live);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, Mode.Live);

  const joinTeam = (inviteCode: string, body: JoinTeamType) => {
    return new Promise((resolve, reject) => {
      api
        .request({
          method: 'POST',
          url: APIS.USER_MANAGEMENT.JOIN_TEAM(inviteCode),
          type: 'User',
          body,
        })
        .then((res: { data: UserType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getUsers = (params: GetUsersParams) => {
    return new Promise<GetUsersResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.USER_MANAGEMENT.USER_MANAGEMENT}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: 'Role',
              ...(params?.invitationStatus && {
                invitationStatus: params?.invitationStatus,
              }),
              ...(params?.roleId && {
                roleId: params?.roleId,
              }),
            },
          }
        )
        .then((res: GetUsersResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getUser = (userId: string) => {
    return new Promise<UserType>((resolve, reject) => {
      authApi
        .get(APIS.USER_MANAGEMENT.USER(userId), {
          params: {
            include: 'Role',
          },
        })
        .then((res: { data: UserType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const inviteUsers = (body: InviteUserType[]) => {
    return new Promise((resolve, reject) => {
      authApi
        .request({
          method: 'POST',
          url: APIS.USER_MANAGEMENT.USER_MANAGEMENT,
          type: 'InviteUsers',
          body,
        })
        .then((res: { data: { message: string } }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const checkIfUserExists = (email: string) => {
    return new Promise<{ userExists: boolean }>((resolve, reject) => {
      authApi
        .get(`${APIS.USER_MANAGEMENT.CHECK_IF_USER_EXISTS(email)}`)
        .then((res: { data: { userExists: boolean } }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const resendInvite = (email: string) => {
    return new Promise((resolve, reject) => {
      axiosApi
        .put(APIS.USER_MANAGEMENT.USER(email))
        .then((res: { data: { message: string } }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const deleteInvite = (email: string) => {
    return new Promise((resolve, reject) => {
      authApi
        .delete(APIS.USER_MANAGEMENT.USER_MANAGEMENT, email)
        .then((res: { data: { message: string } }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const deleteUser = (userId: string) => {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(APIS.USER_MANAGEMENT.DELETE_USER(userId))
        .then((res: Record<string, never>) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const assignRoleToUser = (data: ManageUserRoleData) => {
    return new Promise<InviteUserType>((resolve, reject) => {
      authApi
        .post(APIS.USER_MANAGEMENT.ASSIGN_ROLE_TO_USER, {
          user: {
            id: data.userId,
          },
          role: {
            id: data.roleId,
          },
        })
        .then((res: { data: InviteUserType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const revokeUserRole = (data: ManageUserRoleData) => {
    return new Promise<InviteUserType>((resolve, reject) => {
      authApi
        .post(APIS.USER_MANAGEMENT.REVOKE_USER_ROLE, {
          user: {
            id: data.userId,
          },
          role: {
            id: data.roleId,
          },
        })
        .then((res: { data: InviteUserType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    joinTeam,
    getUsers,
    getUser,
    inviteUsers,
    checkIfUserExists,
    resendInvite,
    deleteInvite,
    deleteUser,
    assignRoleToUser,
    revokeUserRole,
  };
};

export default useUserManagementService;
