import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseMetaPagination } from '../../types';
import { UserType } from '../../../types/userManagementTypes';
import { CustomerType } from '../../../types/customersTypes';
import { TransferType } from '../../../types/transfersTypes';
import { CounterpartyType } from '../../../types/counterpartiesTypes';
import { PaymentType } from '../../../types/paymentsTypes';
import { AccountType } from '../../../types/accountsTypes';
import { SubAccountType } from '../../../types/subAccountsTypes';
import { TransactionType } from '../../../types/transactionsTypes';
import { WebhookType } from '../../../types/webhooksTypes';
import { EventType } from '../../../types/eventsTypes';
import { VirtualNubanType } from '../../../types/virtualNubansTypes';
import { ApiKeyType } from '../../../types/apiKeysTypes';
import { SettlementType } from '../../../types/settlementsTypes';
import { AuditType } from '../../../types/auditsTypes';

export const initialMetaData = {
  number: 0,
  size: 0,
  total: 0,
  totalPages: 0,
};

export interface DataState {
  users: {
    data: UserType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  customers: {
    data: CustomerType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  nipTransfers: {
    data: TransferType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  bookTransfers: {
    data: TransferType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  counterparties: {
    data: CounterpartyType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  payments: {
    data: PaymentType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  accounts: {
    data: AccountType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  subAccounts: {
    data: SubAccountType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  webhooks: {
    data: WebhookType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  transactions: {
    data: TransactionType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  transactionsOrg: {
    data: TransactionType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  events: {
    data: EventType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  virtualNubans: {
    data: VirtualNubanType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  apiKeys: {
    data: ApiKeyType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  subAccountTransactions: {
    data: TransactionType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  settlements: {
    data: SettlementType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  audits: {
    data: AuditType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
}

export const initialState: DataState = {
  users: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  customers: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  nipTransfers: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  bookTransfers: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  counterparties: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  payments: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  accounts: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  subAccounts: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  webhooks: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  transactions: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  transactionsOrg: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  events: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  virtualNubans: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  apiKeys: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  subAccountTransactions: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  settlements: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
  audits: {
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  },
};

export const dataSlice = createSlice({
  name: 'data',
  initialState: initialState,
  reducers: {
    setUsers: (
      state,
      action: PayloadAction<{
        data: UserType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.users = action.payload;
    },
    setCustomers: (
      state,
      action: PayloadAction<{
        data: CustomerType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.customers = action.payload;
    },
    setNipTransfers: (
      state,
      action: PayloadAction<{
        data: TransferType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.nipTransfers = action.payload;
    },
    setBookTransfers: (
      state,
      action: PayloadAction<{
        data: TransferType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.bookTransfers = action.payload;
    },
    setCounterparties: (
      state,
      action: PayloadAction<{
        data: CounterpartyType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.counterparties = action.payload;
    },
    setPayments: (
      state,
      action: PayloadAction<{
        data: PaymentType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.payments = action.payload;
    },
    setAccounts: (
      state,
      action: PayloadAction<{
        data: AccountType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.accounts = action.payload;
    },
    setSubAccounts: (
      state,
      action: PayloadAction<{
        data: SubAccountType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.subAccounts = action.payload;
    },
    setWebhooks: (
      state,
      action: PayloadAction<{
        data: WebhookType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.webhooks = action.payload;
    },
    setTransactions: (
      state,
      action: PayloadAction<{
        data: TransactionType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.transactions = action.payload;
    },
    setTransactionsOrg: (
      state,
      action: PayloadAction<{
        data: TransactionType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.transactionsOrg = action.payload;
    },
    setEvents: (
      state,
      action: PayloadAction<{
        data: EventType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.events = action.payload;
    },
    setVirtualNubans: (
      state,
      action: PayloadAction<{
        data: VirtualNubanType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.virtualNubans = action.payload;
    },
    setApiKeys: (
      state,
      action: PayloadAction<{
        data: ApiKeyType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.apiKeys = action.payload;
    },
    setSubAccountTransactions: (
      state,
      action: PayloadAction<{
        data: TransactionType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.subAccountTransactions = action.payload;
    },
    setSettlements: (
      state,
      action: PayloadAction<{
        data: SettlementType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.settlements = action.payload;
    },
    setAudits: (
      state,
      action: PayloadAction<{
        data: AuditType[];
        meta: ResponseMetaPagination;
        dataFetched: boolean;
      }>
    ) => {
      state.audits = action.payload;
    },
  },
});

export const {
  setUsers,
  setCustomers,
  setNipTransfers,
  setBookTransfers,
  setCounterparties,
  setPayments,
  setAccounts,
  setSubAccounts,
  setWebhooks,
  setTransactions,
  setTransactionsOrg,
  setEvents,
  setVirtualNubans,
  setApiKeys,
  setSubAccountTransactions,
  setSettlements,
  setAudits,
} = dataSlice.actions;

export default dataSlice.reducer;
