import React, { useState } from 'react';
import moment from 'moment';
import {
  ScrollArea,
  Text,
  Group,
  CloseButton,
  Stack,
  Space,
  Badge,
  Button,
} from '@mantine/core';
import { formatAmount, capitalizeTransform } from '../../lib/util';
import { PaymentType, SettlementStatusTypes } from '../../types/paymentsTypes';
import { SettlementType } from '../../types/settlementsTypes';
import { DetailItem2 } from '../DetailItem/DetailItem';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import { getCurrencySymbol } from '../../lib/util';

interface IPaymentDetails {
  payment: PaymentType;
  otherResource?: boolean;
  close?: () => void;
}

const PaymentDetails = ({ payment, otherResource, close }: IPaymentDetails) => {
  const [activeExtra, setActiveExtra] = useState<
    'settlement' | 'settlementAccount' | 'virtualNuban' | ''
  >('');

  const getStatus = () => {
    if (!payment || payment.settlements.data.length === 0) {
      return '';
    }

    let hasCompleted = false;
    let hasPending = false;
    let hasFailed = false;

    for (let i = 0; i < payment.settlements.data.length; i++) {
      const settlementStatus = payment.settlements.data[i].status;

      if (settlementStatus === SettlementStatusTypes.COMPLETED) {
        hasCompleted = true;
        break;
      } else if (settlementStatus === SettlementStatusTypes.PENDING) {
        hasPending = true;
        break;
      }
      if (settlementStatus === SettlementStatusTypes.FAILED) {
        hasFailed = true;
      }
    }

    if (hasCompleted) {
      return SettlementStatusTypes.COMPLETED;
    } else if (hasPending) {
      return SettlementStatusTypes.PENDING;
    } else if (hasFailed) {
      return SettlementStatusTypes.FAILED;
    } else {
      return '';
    }
  };

  return (
    <ScrollArea
      style={{ height: otherResource ? 'unset' : '88vh' }}
      type="scroll"
    >
      <div
        className={`card-details details-view ${otherResource ? 'other' : ''}`}
      >
        <Group position={getStatus() ? 'apart' : 'right'} align="center">
          {getStatus() && (
            <Badge
              mb={5}
              color={
                getStatus() === SettlementStatusTypes.COMPLETED
                  ? 'green'
                  : 'red'
              }
            >
              {getStatus()}
            </Badge>
          )}

          {otherResource && (
            <CloseButton aria-label="Close modal" onClick={close} />
          )}
        </Group>

        <Group position="apart" align="center">
          <div className="t-amount">
            {`${getCurrencySymbol(payment.currency)}${formatAmount(
              payment?.amount ?? 0
            )}`}
          </div>
        </Group>

        <div className="group-title">Counterparty & Customer</div>

        <div className="source-destination">
          <Stack spacing={4}>
            <div>
              {capitalizeTransform(`${payment?.counterParty?.accountName}`)}
            </div>
            <div className="s-d-sec">
              {payment?.counterParty?.accountNumber}
            </div>
            <div className="s-d-sec">
              {capitalizeTransform(`${payment?.counterParty?.bank.name}`)}
            </div>
          </Stack>

          <ArrowRight />

          <Stack spacing={4}>
            <div>
              {capitalizeTransform(payment?.virtualNuban?.data?.accountName)}
            </div>
            <div className="s-d-sec">
              {payment?.virtualNuban?.data?.accountNumber}
            </div>
            <div className="s-d-sec">
              {capitalizeTransform(payment?.virtualNuban?.data?.bank.name)}
            </div>
          </Stack>
        </div>

        <Space h={40} />

        <DetailItem2 title="Payment ID" desc={payment.id} />

        <DetailItem2
          title="Payment Date"
          desc={moment(payment?.createdAt).format('MMM Do, YYYY h:mm a')}
        />

        {payment.narration && (
          <DetailItem2 title="Narration" desc={payment.narration} />
        )}

        {payment.paymentReference && (
          <DetailItem2 title="Reference" desc={payment.paymentReference} />
        )}

        {payment.virtualNuban && (
          <div className={`d-extra-info ${otherResource ? 'nest' : ''}`}>
            {activeExtra === 'virtualNuban' ? (
              <div>
                <Group position="apart" mb={20}>
                  <div className="e-i-title">Virtual Nuban</div>

                  <CloseButton
                    aria-label="Close"
                    onClick={() => setActiveExtra('')}
                  />
                </Group>

                <DetailItem2
                  title="Account ID"
                  desc={payment?.virtualNuban?.data?.id}
                />

                <DetailItem2
                  title="Account Name"
                  desc={capitalizeTransform(
                    payment?.virtualNuban?.data?.accountName
                  )}
                />

                <DetailItem2
                  title="Account Number"
                  desc={payment?.virtualNuban?.data?.accountNumber}
                />

                <DetailItem2
                  title="Bank Name"
                  desc={capitalizeTransform(
                    payment?.virtualNuban?.data?.bank?.name
                  )}
                />
              </div>
            ) : (
              <Group position="apart">
                <Stack spacing={8}>
                  <Text fw={500}>Virtual Nuban</Text>
                  <div className="resource-card-desc">
                    {capitalizeTransform(
                      payment?.virtualNuban?.data?.accountName
                    )}
                  </div>
                </Stack>

                <Stack spacing={8} align="flex-end">
                  <Text fz={16} fw={600}>
                    {payment?.virtualNuban?.data?.accountNumber}
                  </Text>

                  <Button
                    variant="light"
                    rightIcon={<ArrowRight className="arr-right" />}
                    compact
                    onClick={() => {
                      setActiveExtra('virtualNuban');
                    }}
                  >
                    See details
                  </Button>
                </Stack>
              </Group>
            )}
          </div>
        )}

        {payment.settlements &&
          getStatus() === SettlementStatusTypes.COMPLETED && (
            <div className={`d-extra-info ${otherResource ? 'nest' : ''}`}>
              {activeExtra === 'settlement' ? (
                payment.settlements.data.map(
                  (settlement: SettlementType, index: number) => (
                    <div key={index}>
                      <Group position="apart" mb={20}>
                        <div className="e-i-title">Settlement Details</div>

                        <CloseButton
                          aria-label="Close"
                          onClick={() => setActiveExtra('')}
                        />
                      </Group>

                      <DetailItem2 title="Settlement ID" desc={settlement.id} />

                      <DetailItem2
                        title="Amount"
                        desc={`${getCurrencySymbol(
                          payment.currency
                        )}${formatAmount(settlement.amount ?? 0)}`}
                      />

                      <DetailItem2
                        title="Settled Amount"
                        desc={`${getCurrencySymbol(
                          payment.currency
                        )}${formatAmount(settlement.settledAmount ?? 0)}`}
                      />

                      <DetailItem2
                        title="Status"
                        desc={
                          <Badge
                            color={
                              settlement.status ===
                              SettlementStatusTypes.COMPLETED
                                ? 'green'
                                : 'red'
                            }
                          >
                            {settlement.status}
                          </Badge>
                        }
                      />
                    </div>
                  )
                )
              ) : (
                <Group position="apart">
                  <Stack spacing={8}>
                    <Text fw={500}>Settlement</Text>
                    <div className="resource-card-desc">
                      {`${getCurrencySymbol(payment.currency)}${formatAmount(
                        payment?.settlements?.data[0].amount ?? 0
                      )}`}
                    </div>
                  </Stack>

                  <Stack spacing={8} align="flex-end">
                    <Text fz={16} fw={600}>
                      <Badge
                        color={
                          payment?.settlements?.data[0].status ===
                          SettlementStatusTypes.COMPLETED
                            ? 'green'
                            : 'red'
                        }
                      >
                        {payment?.settlements?.data[0].status}
                      </Badge>
                    </Text>

                    <Button
                      variant="light"
                      rightIcon={<ArrowRight className="arr-right" />}
                      compact
                      onClick={() => {
                        setActiveExtra('settlement');
                      }}
                    >
                      See details
                    </Button>
                  </Stack>
                </Group>
              )}
            </div>
          )}

        {!otherResource && payment.settlementAccount && (
          <div className={`d-extra-info ${otherResource ? 'nest' : ''}`}>
            {activeExtra === 'settlementAccount' ? (
              <div>
                <Group position="apart" mb={20}>
                  <div className="e-i-title">Settlement Account</div>

                  <CloseButton
                    aria-label="Close"
                    onClick={() => setActiveExtra('')}
                  />
                </Group>

                <DetailItem2
                  title="Account ID"
                  desc={payment?.settlementAccount?.data?.id}
                />

                <DetailItem2
                  title="Account Name"
                  desc={capitalizeTransform(
                    payment?.settlementAccount?.data?.accountName
                  )}
                />

                <DetailItem2
                  title="Account Number"
                  desc={payment?.settlementAccount?.data?.accountNumber}
                />

                <DetailItem2
                  title="Bank Name"
                  desc={capitalizeTransform(
                    payment?.settlementAccount?.data?.bank?.name
                  )}
                />
              </div>
            ) : (
              <Group position="apart">
                <Stack spacing={8}>
                  <Text fw={500}>Settlement Account</Text>
                  <div className="resource-card-desc">
                    {capitalizeTransform(
                      payment?.settlementAccount?.data?.accountName
                    )}
                  </div>
                </Stack>

                <Stack spacing={8} align="flex-end">
                  <Text fz={16} fw={600}>
                    {payment?.settlementAccount?.data?.accountNumber}
                  </Text>

                  <Button
                    variant="light"
                    rightIcon={<ArrowRight className="arr-right" />}
                    compact
                    onClick={() => {
                      setActiveExtra('settlementAccount');
                    }}
                  >
                    See details
                  </Button>
                </Stack>
              </Group>
            )}
          </div>
        )}
      </div>
    </ScrollArea>
  );
};

export default PaymentDetails;
