import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetTransactionsParams,
  GetTransactionsResponse,
  TransactionType,
} from '../types/transactionsTypes';

const useTransactionsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);

  const getTransactions = (params: GetTransactionsParams) => {
    return new Promise<GetTransactionsResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.TRANSACTIONS.TRANSACTIONS}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: '',
              ...(params?.accountId && {
                accountId: params?.accountId,
              }),
              ...(params?.type && {
                type: params?.type,
              }),
              ...(params?.direction && {
                direction: params?.direction,
              }),
              ...(params?.customerId && {
                customerId: params?.customerId,
              }),
              ...(params?.from && {
                from: params?.from,
              }),
              ...(params?.to && {
                to: params?.to,
              }),
              ...(params?.query && {
                query: params?.query,
              }),
            },
          }
        )
        .then((res: GetTransactionsResponse) => {
          const { data } = res;

          data.forEach((transaction) => {
            if (transaction.referenceTransaction) {
              const referenceTransaction =
                transaction.referenceTransaction.data;

              if (referenceTransaction.id === transaction.id) {
                delete transaction.referenceTransaction;
              } else if (referenceTransaction.referenceTransaction) {
                const referenceTransaction2 =
                  referenceTransaction.referenceTransaction.data;

                if (referenceTransaction.id === referenceTransaction2.id) {
                  delete referenceTransaction.referenceTransaction;
                }
              }
            }
          });

          resolve({ ...res, data });
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getTransactionDetails = (transactionId: string) => {
    return new Promise<TransactionType>((resolve, reject) => {
      authApi
        .get(`${APIS.TRANSACTIONS.GET_TRANSACTION_DETAILS(transactionId)}`, {
          params: {
            include:
              'DepositAccount,NIP_TRANSFER,CounterParty,BOOK_TRANSFER,Payment',
          },
        })
        .then((res: { data: TransactionType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getTransactions,
    getTransactionDetails,
  };
};

export default useTransactionsService;
