import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetEventsResponse,
  EventType,
  EventTypesType,
} from '../types/eventsTypes';

const useEventsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, mode);

  const getEvents = (size: number, page: number) => {
    return new Promise<GetEventsResponse>((resolve, reject) => {
      authApi
        .get(`${APIS.EVENTS.EVENTS}?size=${size}&page=${page}`)
        .then((res: GetEventsResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getEventDetails = (eventId: string) => {
    return new Promise<EventType>((resolve, reject) => {
      axiosApi
        .get(`${APIS.EVENTS.EVENT(eventId)}`, {
          params: {
            include:
              'BusinessCustomer,IndividualCustomer,Document,NIP_TRANSFER,BOOK_TRANSFER,CounterParty,DepositAccount,Payment,VirtualNuban,SubAccount',
          },
        })
        .then((res: { data: EventType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getEventTypes = () => {
    return new Promise<EventTypesType[]>((resolve, reject) => {
      authApi
        .get(`${APIS.EVENTS.EVENT_TYPES}`)
        .then((res: { data: EventTypesType[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getEvents,
    getEventDetails,
    getEventTypes,
  };
};

export default useEventsService;
