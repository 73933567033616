import React, { ReactElement } from 'react';
import { Modal, Group, Text, Button } from '@mantine/core';
import { ReactComponent as Send } from '../../../assets/svg/send-orange.svg';
import { formatAmount } from '../../../lib/util';
import { useViewportSize } from '@mantine/hooks';
import { ItemSchema } from '../../CustomSelectItem/CustomSelectItem';

export type TransferSummaryType = {
  sourceAccount: ItemSchema;
  counterparty?: ItemSchema;
  destinationAccount?: ItemSchema;
  amount: string;
  reason: string;
  reference: string;
  idempotencykey: string;
};

interface IConfirmTransfer {
  isNipTransfer: boolean;
  modalOpen: boolean;
  closeModal: () => void;
  transferSummary: TransferSummaryType;
  handleSubmit: () => void;
}

const ConfirmTransfer = ({
  isNipTransfer,
  modalOpen,
  closeModal,
  transferSummary,
  handleSubmit,
}: IConfirmTransfer) => {
  const { width } = useViewportSize();

  return (
    <Modal
      opened={modalOpen}
      onClose={closeModal}
      size={540}
      fullScreen={width < 600}
    >
      {modalOpen && (
        <div className="confirm-transfer">
          <div className="c-t-summary">
            <Group position="apart">
              <Group spacing="xs">
                <Send className="send-icon" />
                <Text fw={500}>Confirm transfer</Text>
              </Group>

              <Text fz={18} fw={500}>
                NGN {formatAmount(parseInt(transferSummary.amount))}
              </Text>
            </Group>

            {transferSummary.reason && (
              <div className="s-row w-full">
                <SummaryItem title="Reason" desc={transferSummary.reason} />
              </div>
            )}

            {transferSummary.reference && (
              <div className="s-row w-full">
                <SummaryItem
                  title="Reference"
                  desc={transferSummary.reference}
                />
              </div>
            )}

            {transferSummary.idempotencykey && (
              <div className="s-row w-full">
                <SummaryItem
                  title="Idempotency key"
                  desc={transferSummary.idempotencykey}
                />
              </div>
            )}

            <div className="s-row">
              <SummaryItem
                title="Source ID"
                desc={transferSummary.sourceAccount.value}
              />
              <SummaryItem
                title={isNipTransfer ? 'Counterparty ID' : 'Destination ID'}
                desc={
                  isNipTransfer
                    ? transferSummary?.counterparty?.value
                    : transferSummary?.destinationAccount?.value
                }
              />
            </div>

            <div className="s-row">
              <SummaryItem
                title="Source account name"
                desc={
                  <span className="capitalize">
                    {transferSummary.sourceAccount.label}
                  </span>
                }
              />
              <SummaryItem
                title={isNipTransfer ? 'Counterparty name' : 'Destination name'}
                desc={
                  <span className="capitalize">
                    {isNipTransfer
                      ? transferSummary?.counterparty?.label
                      : transferSummary?.destinationAccount?.label}
                  </span>
                }
              />
            </div>

            <div className="s-row">
              <SummaryItem
                title="Source account no."
                desc={transferSummary.sourceAccount.extravalue}
              />
              <SummaryItem
                title={
                  isNipTransfer
                    ? 'Counterparty account no.'
                    : 'Destination account no.'
                }
                desc={
                  isNipTransfer
                    ? transferSummary?.counterparty?.extravalue
                    : transferSummary?.destinationAccount?.extravalue
                }
              />
            </div>
          </div>

          <div className="c-t-action">
            <Button color="dark" mt={20} onClick={handleSubmit}>
              Confirm transfer
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

interface ISummaryItem {
  title: string;
  desc?: string | ReactElement;
}

const SummaryItem = ({ title, desc }: ISummaryItem) => {
  return (
    <div className="s-item">
      <div className="s-i-title">{title}</div>
      <div className="s-i-desc">{desc}</div>
    </div>
  );
};

export default ConfirmTransfer;
