export const APIS = {
  AUTH: {
    SIGNIN: 'auth/login',
    SIGNUP: 'auth/signup',
    VERIFY_EMAIL: 'auth/verify-email',
    CHANGE_PASSWORD: 'auth/change-password',
    FORGOT_PASSWORD: 'auth/reset-password',
    SET_NEW_PASSWORD: 'auth/set-new-password',
    GET_PERMISSIONS: 'auth/permissions',
    INITIATE_MFA_SETUP: 'auth/initiate-mfa-setup',
    COMPLETE_MFA_SETUP: 'auth/setup-mfa',
    SEND_OTP: (email: string) => `auth/send-otp/${email}`,
    CHECK_MFA_ENABLED: 'auth/check-mfa',
  },
  USER_MANAGEMENT: {
    USER_MANAGEMENT: 'user-management',
    JOIN_TEAM: (code: string) => `user-management/${code}/create`,
    CHECK_IF_USER_EXISTS: (email: string) =>
      `user-management/user-exists/${email}`,
    USER: (userId: string) => `user-management/${userId}`,
    ASSIGN_ROLE_TO_USER: 'user-management/assign-role',
    REVOKE_USER_ROLE: 'user-management/revoke-role',
    DELETE_USER: (userId: string) => `user-management/delete-user/${userId}`,
  },
  CUSTOMERS: {
    CUSTOMERS: 'customers',
    SEARCH_CUSTOMERS: (
      customerType: string,
      searchValue: string,
      include: string
    ) =>
      `customers/search?customerType=${customerType}&searchValue=${searchValue}&include=${include}`,
    UPDATE_CUSTOMER: (customerId: string) => `customers/update/${customerId}`,
    CUSTOMER: (customerId: string) => `customers/${customerId}`,
    VERIFY_INDIVIDUAL_CUSTOMER: (customerId: string) =>
      `customers/${customerId}/verification/individual`,
    VERIFY_BUSINESS_CUSTOMER: (customerId: string) =>
      `customers/${customerId}/verification/business`,
  },
  BUSINESSES: {
    BUSINESSES: 'businesses',
    BUSINESS: (businessId: string) => `businesses/${businessId}`,
    UPDATE_BUSINESS_CUSTOMER: (customerId: string) =>
      `businesses/${customerId}`,
    BUSINESS_OFFICERS: (businessId: string) =>
      `businesses/${businessId}/officers`,
    BUSINESS_OFFICER: (businessId: string, officerId: string) =>
      `businesses/${businessId}/officers/${officerId}`,
  },
  DOCUMENTS: {
    CUSTOMER_DOCUMENTS: (customerId: string) => `documents/${customerId}`,
    UPLOAD_DOCUMENT: (customerId: string, documentId: string) =>
      `documents/upload-document/${customerId}/${documentId}`,
    DOWNLOAD_DOCUMENT: (customerId: string, documentId: string) =>
      `documents/download-document/${customerId}/${documentId}`,
  },
  TRANSFERS: {
    TRANSFERS: 'transfers',
    GET_TRANSFER_DETAILS: (transferId: string) => `transfers/${transferId}`,
    VERIFY_TRANSFER: (transferId: string) => `transfers/verify/${transferId}`,
  },
  COUNTERPARTIES: {
    COUNTERPARTIES: 'counterparties',
    COUNTERPARTY: (counterpartyid: string) =>
      `counterparties/${counterpartyid}`,
    BANKS: 'banks',
    VERIFY_ACCOUNT: (bankId: string, accountNumber: string) =>
      `payments/verify-account/${bankId}/${accountNumber}`,
  },
  PAYMENTS: {
    PAYMENTS: 'payments',
    GET_PAYMENT_DETAILS: (paymentId: string) => `payments/${paymentId}`,
  },
  ACCOUNTS: {
    ACCOUNTS: 'accounts',
    GET_ACCOUNT_DETAILS: (accountId: string) => `accounts/${accountId}`,
    GET_ACCOUNT_BALANCE: (accountId: string) => `accounts/balance/${accountId}`,
    FREEZE_ACCOUNT: (accountId: string) => `accounts/${accountId}/freeze`,
    UNFREEZE_ACCOUNT: 'accounts/unfreeze',

    SUB_ACCOUNTS: 'sub-accounts',
    GET_SUB_ACCOUNT_DETAILS: (subAccountId: string) =>
      `sub-accounts/${subAccountId}`,
    GET_SUB_ACCOUNT_BALANCE: (subAccountId: string) =>
      `sub-accounts/balance/${subAccountId}`,
    GET_SUB_ACCOUNT_TRANSACTIONS: 'sub-accounts/transactions',
    ROOT_ACCOUNTS: 'accounts/root-accounts',
    GET_ACCOUNT_PRODUCTS: 'accounts/products',
  },
  WEBHOOKS: {
    WEBHOOKS: 'webhooks',
    SEND_SAMPLE_EVENT: (webhookId: string) => `webhooks/verify/${webhookId}`,
    WEBHOOK: (webhookId: string) => `webhooks/${webhookId}`,
  },
  TRANSACTIONS: {
    TRANSACTIONS: 'transactions',
    GET_TRANSACTION_DETAILS: (transactionId: string) =>
      `transactions/${transactionId}`,
  },
  VIRTUAL_NUBANS: {
    VIRTUAL_NUBANS: 'virtual-nubans',
    VIRTUAL_NUBAN: (virtualAccountId: string) =>
      `virtual-nubans/${virtualAccountId}`,
    FETCH_BY_SETTLEMENT_ACCOUNT: (accountId: string) =>
      `virtual-nubans/by-settlement-account/${accountId}`,
  },
  EVENTS: {
    EVENTS: 'events',
    EVENT: (eventId: string) => `events/${eventId}`,
    EVENT_TYPES: 'events/event-types',
  },
  ORGANIZATION: {
    ACTIVATE_ORGANIZATION: 'organizations/activate',
    GET_ORGANIZATION_COMPLIANCE: 'organizations/compliance',
    GET_ORGANIZATION_DETAILS: 'organizations/details',
    GET_DOCUMENTS: 'organizations/documents',
    GET_DOCUMENT: (documentId: string) =>
      `organizations/documents/${documentId}`,
    DOWNLOAD_DOCUMENT: (documentId: string) =>
      `organizations/download-document/${documentId}`,
    UPLOAD_DOCUMENT: (documentId: string) =>
      `organizations/upload-document/${documentId}`,
    BUSINESS_OFFICERS: 'organizations/business-officers',
    BUSINESS_OFFICER: (officerId: string) =>
      `organizations/business-officers/${officerId}`,
    OFFICERS_REQUIREMENT: 'organizations/officers-requirement',
  },
  API_MANAGEMENT: {
    API_KEYS: 'api-key',
    API_KEY: (apikey: string) => `api-key/${apikey}`,
    ACTIVATE_API_KEY: (apikey: string) => `api-key/activate/${apikey}`,
    REVOKE_API_KEY: (apikey: string) => `api-key/revoke/${apikey}`,
    DELETE_API_KEY: (apikey: string) => `api-key/delete/${apikey}`,
  },
  SETTLEMENTS: {
    SETTLEMENTS: 'settlements',
    SETTLEMENT: (settlementId: string) => `settlements/${settlementId}`,
  },
  SANDBOX: {
    TRANSACTION_NOTIFICATION: 'transaction-notification',
  },
  ROLE_MANAGEMENT: {
    ROLES: 'role',
    ROLE: (roleId: string) => `role/${roleId}`,
  },
  REPORTS: {
    REPORTS: 'reports',
    REPORT: (reportId: string) => `reports/${reportId}`,
    DOWNLOAD_REPORT: (reportId: string) => `reports/download/${reportId}`,
  },
  STATEMENTS: {
    STATEMENTS: 'statements',
    STATEMENT: (statementId: string) => `statements/${statementId}`,
    DOWNLOAD_STATEMENT: (statementId: string) =>
      `statements/download/${statementId}`,
  },
  AUDITS: {
    AUDITS: 'audits',
    AUDIT: (auditId: string) => `audits/${auditId}`,
    AUDIT_RESOURCE_TYPES: 'audits/resource-types',
    AUDIT_EVENT_TYPES: 'audits/event-types',
  },
};
