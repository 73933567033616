import React, { forwardRef } from 'react';
import { Text } from '@mantine/core';
import './custom-select-item.scss';

export type ItemSchema = {
  label: string;
  description: string;
  value: string;
  extravalue?: string;
};
interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  description: string;
}

// eslint-disable-next-line react/display-name
const CustomSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text size={13} className="c-text">
        {label}
      </Text>

      <Text fz={11} opacity={0.5} className="c-text">
        {description}
      </Text>
    </div>
  )
);

export default CustomSelectItem;
