import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  CreateWebhookData,
  WebhookType,
  SendSampleEventResponse,
  GetWebhooksResponse,
} from '../types/webhooksTypes';

const useWebhooksService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, mode);

  const getWebhooks = (size: number, page: number) => {
    return new Promise<GetWebhooksResponse>((resolve, reject) => {
      authApi
        .get(`${APIS.WEBHOOKS.WEBHOOKS}?size=${size}&page=${page}`)
        .then((res: GetWebhooksResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getWebhook = (webhookId: string) => {
    return new Promise<WebhookType>((resolve, reject) => {
      authApi
        .get(APIS.WEBHOOKS.WEBHOOK(webhookId))
        .then((res: { data: WebhookType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const createWebhook = (body: CreateWebhookData) => {
    return new Promise<WebhookType>((resolve, reject) => {
      authApi
        .request({
          method: 'POST',
          url: APIS.WEBHOOKS.WEBHOOKS,
          type: 'Webhook',
          body,
        })
        .then((res: { data: WebhookType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const deleteWebhook = (webhookId: string) => {
    return new Promise((resolve, reject) => {
      authApi
        .delete(APIS.WEBHOOKS.WEBHOOKS, webhookId)
        .then((res: { headers: unknown }) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const updateWebhook = (body: CreateWebhookData, webhookId: string) => {
    return new Promise<WebhookType>((resolve, reject) => {
      axiosApi
        .patch(APIS.WEBHOOKS.WEBHOOK(webhookId), {
          data: {
            type: 'Webhook',
            attributes: body,
          },
        })
        .then((res: { data: { data: WebhookType } }) => {
          resolve(res.data.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const sendSampleEvent = (
    webhookId: string,
    body: {
      eventType: string;
    }
  ) => {
    return new Promise<SendSampleEventResponse>((resolve, reject) => {
      authApi
        .request({
          method: 'POST',
          url: APIS.WEBHOOKS.SEND_SAMPLE_EVENT(webhookId),
          type: 'Webhook',
          body,
        })
        .then((res: { data: SendSampleEventResponse }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getWebhooks,
    getWebhook,
    createWebhook,
    deleteWebhook,
    updateWebhook,
    sendSampleEvent,
  };
};

export default useWebhooksService;
