import React from 'react';
import { Helmet } from 'react-helmet';

const Dashboard = () => {
  return (
    <div>
      <Helmet>
        <title>Dashboard | Anchor</title>
        <meta name="description" content="View the summary of your data" />
      </Helmet>
      Dashboard
    </div>
  );
};

export default Dashboard;
