import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useRedux';
import { ReactComponent as Build } from '../../assets/svg/onboard-build.svg';
import { ReactComponent as BuildActive } from '../../assets/svg/onboard-build-active.svg';
import { ReactComponent as Docs } from '../../assets/svg/onboard-docs.svg';
import { ReactComponent as DocsActive } from '../../assets/svg/onboard-docs-active.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import './onboarding.scss';

const Onboarding = () => {
  const [active, setActive] = useState<string>('build');
  const firstName = useAppSelector((state) => state.user.userDetails.firstName);
  const navigate = useNavigate();

  return (
    <div className="onboarding">
      <div className="welcome-user">Welcome to Anchor, {firstName}!</div>
      <div className="welcome-desc">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </div>

      <div className="onboarding-main">
        <div
          className={`o-item click ${active === 'build' ? 'active' : ''}`}
          onClick={() => {
            setActive('build');
          }}
        >
          <div className="icon">
            {active === 'build' ? <BuildActive /> : <Build />}
          </div>

          <div className="text-content">
            <div className="t-c-title">Start building on anchor</div>
            {active === 'build' && (
              <Fragment>
                <div className="t-c-desc">Lorem ipsum dolor sit amet</div>
                <div
                  className="onboard-action click"
                  onClick={() => {
                    navigate('/organization');
                  }}
                >
                  Create your first webhook
                </div>
              </Fragment>
            )}
          </div>

          <ArrowRight
            className={`arrow ${active === 'build' ? 'rotate' : ''}`}
          />
        </div>

        <div
          className={`o-item click ${active === 'docs' ? 'active' : ''}`}
          onClick={() => {
            setActive('docs');
          }}
        >
          <div className="icon">
            {active === 'docs' ? <DocsActive /> : <Docs />}
          </div>

          <div className="text-content">
            <div className="t-c-title">Go to API Docs</div>
            {active === 'docs' && (
              <Fragment>
                <div className="t-c-desc">Lorem ipsum dolor sit amet</div>
                <a
                  href="https://docs.getanchor.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="onboard-action click">
                    Read about our API Docs
                  </div>
                </a>
              </Fragment>
            )}
          </div>

          <ArrowRight
            className={`arrow ${active === 'docs' ? 'rotate' : ''}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
