import React from 'react';
import moment from 'moment';
import { Badge, Table, ScrollArea, Group, Text } from '@mantine/core';
import {
  VirtualNubanType,
  VirtualNubanStatus,
} from '../../types/virtualNubansTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { ResponseMetaPagination } from '../../lib/types';

interface IVirtualNubansTable {
  virtualNubans: {
    data: VirtualNubanType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  };
  setPage: (value: number) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  setSize: (value: number) => void;
}

const VirtualNubansTable = ({
  virtualNubans,
  setPage,
  loading,
  setLoading,
  setSize,
}: IVirtualNubansTable) => {
  return (
    <div>
      <ScrollArea
        offsetScrollbars
        type="always"
        className="table-scroll-container"
      >
        <Table verticalSpacing="md" sx={{ minWidth: 700 }} highlightOnHover>
          <thead>
            <tr>
              <th>Account Name</th>
              <th>Account</th>
              <th>Type</th>
              <th>Status</th>
              <th>Date Created</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              virtualNubans.data.map(
                (virtualNuban: VirtualNubanType, index: number) => (
                  <tr key={index}>
                    <td className="capitalize" id={virtualNuban.id}>
                      {virtualNuban.accountName.toLowerCase()}
                    </td>

                    <td>
                      <Group spacing="xs">
                        <Text>{virtualNuban.accountNumber}</Text>
                        <Text color="gray" className="capitalize">
                          {virtualNuban.bank.name.toLowerCase()}
                        </Text>
                      </Group>
                    </td>

                    <td>
                      <div className="type-badge">
                        {virtualNuban.permanent ? 'Reserved' : 'Dynamic'}
                      </div>
                    </td>

                    <td>
                      <Badge
                        color={
                          virtualNuban.status === VirtualNubanStatus.active
                            ? 'green'
                            : 'red'
                        }
                        className="capitalize"
                      >
                        {virtualNuban.status}
                      </Badge>
                    </td>

                    <td className="gray">
                      {moment(virtualNuban.createdAt).format('MMM D, YYYY')}
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      </ScrollArea>

      {!loading && (
        <PaginationControls
          meta={virtualNubans.meta}
          setPage={setPage}
          setLoader={setLoading}
          setSize={setSize}
        />
      )}
    </div>
  );
};

export default VirtualNubansTable;
