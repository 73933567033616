import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetCustomersParams,
  GetCustomersResponse,
} from '../types/customersTypes';

const useCustomersService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);

  const getCustomers = (params: GetCustomersParams) => {
    return new Promise<GetCustomersResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.CUSTOMERS.CUSTOMERS}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: '',
              ...(params?.query && {
                query: params?.query,
              }),
              ...(params?.customerType && {
                customerType: params?.customerType,
              }),
            },
          }
        )
        .then((res: GetCustomersResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getCustomers,
  };
};

export default useCustomersService;
