import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Modal, LoadingOverlay, Group, Button } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { Prism } from '@mantine/prism';
import { EventType } from '../../../types/eventsTypes';
import useEventsService from '../../../services/events';
import useNotification from '../../../hooks/useNotification';
import './event-details.scss';
interface IEventDetails {
  modalOpen: boolean;
  closeModal: () => void;
  eventId: string;
}

const EventDetails = ({ modalOpen, closeModal, eventId }: IEventDetails) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { getEventDetails } = useEventsService();
  const [event, setEvent] = useState<EventType | null>(null);
  const { width } = useViewportSize();
  const { handleError } = useNotification();

  useEffect(() => {
    if (modalOpen) {
      handleGetEventDetails();
    }

    return () => {
      setEvent(null);
    };
    //eslint-disable-next-line
  }, [modalOpen]);

  const handleGetEventDetails = () => {
    setLoading(true);

    getEventDetails(eventId)
      .then((res: EventType) => {
        setEvent(res);
        setLoading(false);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        handleError(error);
      });
  };

  return (
    <Modal
      opened={modalOpen}
      onClose={closeModal}
      size={800}
      title={<span className="modal-title">Event Details</span>}
      fullScreen={width < 700}
    >
      <div className="event-details relative">
        <LoadingOverlay visible={loading} />

        <Prism language="json" withLineNumbers className="e-block">
          {event ? JSON.stringify(event, null, 2) : ''}
        </Prism>

        <Group className="modal-footer" position="right" mt={20}>
          <Button
            compact
            className="compact-btn"
            color="dark"
            onClick={closeModal}
          >
            Close
          </Button>
        </Group>
      </div>
    </Modal>
  );
};

export default EventDetails;
