import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { AxiosError } from 'axios';
import { Table, ScrollArea, LoadingOverlay } from '@mantine/core';
import useEventsService from '../../services/events';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import { EventType, GetEventsResponse } from '../../types/eventsTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import EventDetails from '../../components/modals/Events/EventDetails';
import { setEvents } from '../../lib/store/reducers/DataReducer';
import useNotification from '../../hooks/useNotification';

const Events = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getEvents } = useEventsService();
  const events = useAppSelector((state) => state.data.events);
  const mode = useAppSelector(selectMode);
  const [eventDetailsModal, setEventDetailsModal] = useState<boolean>(false);
  const [eventId, setEventId] = useState<string>('');
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    handleGetEvents();
    //eslint-disable-next-line
  }, [page, size, mode]);

  const handleGetEvents = () => {
    if (!events.dataFetched) setLoading(true);

    getEvents(size, page)
      .then((res: GetEventsResponse) => {
        dispatch(
          setEvents({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Events | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of events within your organization"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <EventDetails
        modalOpen={eventDetailsModal}
        closeModal={() => {
          setEventDetailsModal(false);
        }}
        eventId={eventId}
      />

      <div className="p-title extra-mb">Events</div>

      {events.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table
              mt={5}
              verticalSpacing="md"
              sx={{ minWidth: 600 }}
              highlightOnHover
            >
              <thead>
                <tr>
                  <th>Type</th>
                  <th>ID</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  events.data.map((event: EventType, index: number) => (
                    <tr
                      key={index}
                      className="click"
                      onClick={() => {
                        setEventId(event.id);
                        setEventDetailsModal(true);
                      }}
                      id={event.id}
                    >
                      <td>{event.type}</td>

                      <td>{event.id}</td>

                      <td className="gray">
                        {moment(event.createdAt).format(
                          'MMM D, YYYY h:mm:ss a'
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={events.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {events.meta.total === 0 && (
        <EmptyData
          title="There are no events to display"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

export default Events;
