import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  CreateTransferData,
  TransferTypes,
  GetTransfersParams,
  GetTransfersResponse,
  TransferType,
} from '../types/transfersTypes';

const useTransfersService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);

  const getTransfers = (params: GetTransfersParams) => {
    return new Promise<GetTransfersResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.TRANSFERS.TRANSFERS}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: 'DepositAccount,CounterParty',
              ...(params?.accountId && {
                accountId: params?.accountId,
              }),
              ...(params.type && {
                type: params.type,
              }),
              ...(params?.customerId && {
                customerId: params?.customerId,
              }),
              ...(params?.counterPartyId && {
                counterPartyId: params?.counterPartyId,
              }),
              ...(params?.destinationAccountId && {
                destinationAccountId: params?.destinationAccountId,
              }),
              ...(params?.from && {
                from: params?.from,
              }),
              ...(params?.to && {
                to: params?.to,
              }),
              ...(params?.query && {
                query: params?.query,
              }),
              ...(params?.onlySubAccount && {
                onlySubAccount: params?.onlySubAccount,
              }),
              ...(params?.subAccountId && {
                subAccountId: params?.subAccountId,
              }),
              ...(params?.destinationSubAccountId && {
                destinationSubAccountId: params?.destinationSubAccountId,
              }),
            },
          }
        )
        .then((res: GetTransfersResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getTransferDetails = (transferId: string) => {
    return new Promise<TransferType>((resolve, reject) => {
      authApi
        .get(`${APIS.TRANSFERS.GET_TRANSFER_DETAILS(transferId)}`, {
          params: {
            include: 'DepositAccount,CounterParty',
          },
        })
        .then((res: { data: TransferType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const createTransfer = (transferType: string, data: CreateTransferData) => {
    return new Promise<TransferType>((resolve, reject) => {
      const {
        amount,
        currency,
        reason,
        reference,
        metadata,
        sourceAccountId,
        counterpartyId,
        destinationAccountId,
        idempotencykey,
      } = data;

      const body = {
        amount,
        currency,
        reason,
        reference,
        metadata,
        account: {
          data: {
            id: sourceAccountId,
            type: 'DepositAccount',
          },
        },
        ...(transferType === TransferTypes.NIP_TRANSFER && {
          counterParty: {
            data: {
              id: counterpartyId,
              type: 'CounterParty',
            },
          },
        }),
        ...(transferType === TransferTypes.BOOK_TRANSFER && {
          destinationAccount: {
            data: {
              id: destinationAccountId,
              type: 'DepositAccount',
            },
          },
        }),
      };

      authApi
        .request({
          method: 'POST',
          url: APIS.TRANSFERS.TRANSFERS,
          type:
            transferType === TransferTypes.NIP_TRANSFER
              ? 'NIPTransfer'
              : 'BookTransfer',
          body,
          headers: {
            'x-anchor-idempotent-key': idempotencykey,
          },
        })
        .then((res: { data: TransferType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const verifyTransfer = (transferId: string) => {
    return new Promise<TransferType>((resolve, reject) => {
      authApi
        .get(`${APIS.TRANSFERS.VERIFY_TRANSFER(transferId)}`, {
          params: {
            include: 'DepositAccount,CounterParty',
          },
        })
        .then((res: { data: TransferType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getTransfers,
    getTransferDetails,
    createTransfer,
    verifyTransfer,
  };
};

export default useTransfersService;
