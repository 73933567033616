import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { LoadingOverlay, ScrollArea, Text, Table } from '@mantine/core';
import useNotification from '../../hooks/useNotification';
import { formatDate2 } from '../../lib/util';
import AuditDetails from '../../pages/AuditLogs/AuditDetails';
import {
  AuditType,
  AuditsState,
  GetAuditsParams,
  GetAuditsResponse,
} from '../../types/auditsTypes';
import { initialMetaData } from '../../lib/store/reducers/DataReducer';
import useAuditsService from '../../services/audits';
import PaginationControls from '../../components/PaginationControls/PaginationControls';

interface AuditTrailProps {
  extraParams: GetAuditsParams;
  extraClasses?: string;
}

const AuditLogsNested = ({ extraParams, extraClasses }: AuditTrailProps) => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getAudits } = useAuditsService();
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [audits, setAudits] = useState<AuditsState>({
    data: [],
    meta: initialMetaData,
    dataFetched: false,
  });
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [auditId, setAuditId] = useState<string>('');

  useEffect(() => {
    if (extraParams) {
      handleGetAudits();
    } else {
      setAudits({
        data: [],
        meta: initialMetaData,
        dataFetched: false,
      });
    }
    //eslint-disable-next-line
  }, [page, size]);

  const handleGetAudits = () => {
    setLoading(true);

    getAudits({
      page,
      size,
      ...extraParams,
    })
      .then((res: GetAuditsResponse) => {
        setAudits({
          data: res?.data,
          meta: res?.meta?.pagination,
          dataFetched: true,
        });
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="relative">
      <LoadingOverlay visible={loading} />

      <AuditDetails
        auditId={auditId}
        drawerOpen={detailsDrawer}
        closeDrawer={() => setDetailsDrawer(false)}
      />

      <div className={`table-container ${extraClasses ?? ''}`}>
        {audits.dataFetched && (
          <React.Fragment>
            <ScrollArea
              offsetScrollbars
              type="always"
              className="table-scroll-container nested"
            >
              <Table
                mt={5}
                verticalSpacing="md"
                sx={{
                  minWidth: audits.data.length > 0 ? 450 : 'auto',
                }}
                highlightOnHover
              >
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Source</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    audits.data.map((audit: AuditType, index: number) => (
                      <tr
                        key={index}
                        id={audit.id}
                        className="click"
                        onClick={() => {
                          setAuditId(audit.id);
                          setDetailsDrawer(true);
                        }}
                      >
                        <td>{audit.eventType}</td>
                        <td>{audit.user}</td>
                        <td>
                          {formatDate2(audit.createdAt, 'DD-MMM-YY hh:mm:ss A')}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              {audits.meta.total === 0 && (
                <Text pt={70} color="gray" align="center">
                  No audits to display
                </Text>
              )}
            </ScrollArea>

            {!loading && (
              <PaginationControls
                meta={audits.meta}
                setPage={setPage}
                setSize={setSize}
                setLoader={setLoading}
              />
            )}
          </React.Fragment>
        )}

        {audits.meta.total === 0 && !audits.dataFetched && (
          <Text pt={70} color="gray" align="center">
            No audits to display
          </Text>
        )}
      </div>
    </div>
  );
};

export default AuditLogsNested;
