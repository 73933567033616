import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { LoadingOverlay } from '@mantine/core';
import useTransfersService from '../../services/transfers';
import { TransferType } from '../../types/transfersTypes';
import TransferDetails from '../../components/Transfers/TransferDetails';
import useNotification from '../../hooks/useNotification';

interface IDetailsWrapper {
  transferId: string;
  drawerActive: boolean;
}

const DetailsWrapper = ({ transferId, drawerActive }: IDetailsWrapper) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transfer, setTransfer] = useState<TransferType | null>(null);
  const { getTransferDetails, verifyTransfer } = useTransfersService();
  const { handleError } = useNotification();

  useEffect(() => {
    if (drawerActive) {
      handleGetTransferDetails();
    }
    //eslint-disable-next-line
  }, []);

  const handleGetTransferDetails = () => {
    setLoading(true);

    getTransferDetails(transferId)
      .then((res: TransferType) => {
        setTransfer(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVerifyTransfer = () => {
    setLoading(true);

    verifyTransfer(transferId)
      .then((res: TransferType) => {
        setTransfer(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingOverlay visible={loading} />

      {transfer && (
        <TransferDetails
          transfer={transfer}
          handleVerify={handleVerifyTransfer}
        />
      )}
    </>
  );
};

export default DetailsWrapper;
