import { ResponseMetaPagination } from '../lib/types';

export type GetCustomersResponse = {
  data: CustomerType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};
export type CustomerType = IndividualCustomer & BusinessCustomer;

export type IndividualCustomer = {
  id: string;
  type: string;
  createdAt: Date;
  phoneNumber: string;
  address: Address;
  soleProprietor: boolean;
  fullName: FullName;
  identificationLevel2: IdentificationLevel2;
  email: string;
  verification: Verification;
};

export type BusinessCustomer = {
  id: string;
  type: string;
  createdAt: Date;
  isRoot: boolean;
  contact: Contact;
  detail: Detail;
  verification: Verification;
  officers: Officer[];
};

interface IdentificationLevel2 {
  dateOfBirth: string;
  gender: string;
  bvn: string;
  selfieImage?: any;
}

export enum CustomerAccountTypes {
  business = 'BusinessCustomer',
  individual = 'IndividualCustomer',
}

export enum VerificationStatusTypes {
  pending = 'pending',
  unverified = 'unverified',
  approved = 'approved',
  pendingManualReview = 'pending.manual.review',
}
export interface FullName {
  firstName: string;
  lastName: string;
  middleName: string;
  maidenName?: string | null;
}

export interface Address {
  addressLine_1: string;
  addressLine_2: string;
  country: string;
  city: string;
  postalCode: string;
  state: string;
}

export interface Verification {
  level?: string;
  status: VerificationStatusTypes;
  details?: {
    status: string;
    type: string;
    validatedItems?: any[];
  }[];
}

export interface Officer {
  role: string;
  fullName: FullName;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  nationality: string;
  address: Address;
  bvn: string;
  percentOwned: number;
}

export interface Detail {
  businessName: string;
  businessBvn: string;
  industry: string;
  registrationType: string;
  dateOfRegistration: string;
  description: string;
  country: string;
  website?: string;
}

export interface Contact {
  email: {
    general: string;
    support: string;
    dispute: string;
  };
  phoneNumber: string;
  address: AddressContact;
}

export interface AddressContact {
  main: Address;
  registered: Address;
}

export type GetCustomersParams = {
  page: number;
  size: number;
  query?: string;
  customerType?: CustomerAccountTypes;
};
