import {
  configureStore,
  combineReducers,
  AnyAction,
  Reducer,
} from '@reduxjs/toolkit';
import SettingsReducer from './reducers/SettingsReducer';
import UserReducer, {
  initialState as initialUserState,
} from './reducers/UserReducer';
import UtilityReducer from './reducers/UtilityReducer';
import DataReducer, {
  initialState as initialDataState,
} from './reducers/DataReducer';
import logger from 'redux-logger';
import { LOGOUT, CLEAR_DATA } from './actionTypes/actionTypes';

let middleware: any = [];
if (process.env.NODE_ENV === 'development') {
  middleware = [logger];
}

const combinedReducer = combineReducers({
  user: UserReducer,
  settings: SettingsReducer,
  utility: UtilityReducer,
  data: DataReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === LOGOUT) {
    state.user = initialUserState;
    state.data = initialDataState;
  } else if (action.type === CLEAR_DATA) {
    state.data = initialDataState;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// export const selectUser = (state: RootState) => state.user.loggedIn;
// const mode = useAppSelector((state) => state.settings.mode);
export const selectAuthKeyProd = (state: RootState) => {
  const authKeys = state.user.authKeys;
  const found = authKeys.filter(
    (authKey) => authKey && authKey.env === 'PROD'
  )[0];
  if (found) {
    return found.id;
  }
};

export const selectAuthKeyTest = (state: RootState) => {
  const authKeys = state.user.authKeys;
  const found = authKeys.filter(
    (authKey) => authKey && authKey.env === 'TEST'
  )[0];
  if (found) {
    return found.id;
  }
};

export const selectAuthKeyPlayground = (state: RootState) => {
  const authKeys = state.user.authKeys;
  const found = authKeys.filter(
    (authKey) => authKey && authKey.env === 'PROD_PLAYGROUND'
  )[0];
  if (found) {
    return found.id;
  }
};

export const selectMode = (state: RootState) => state.settings.mode;

export const selectAccessToken = (state: RootState) => state.user.accessToken;
export const selectOrganizationId = (state: RootState) =>
  state.user.organizations[0].id;

export default store;
