import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import moment from 'moment';
import {
  Badge,
  Table,
  ScrollArea,
  Button,
  Group,
  LoadingOverlay,
} from '@mantine/core';
import EmptyData from '../../../components/EmptyData/EmptyData';
import useWebhooksService from '../../../services/webhooks';
import useNotification from '../../../hooks/useNotification';
import { useAppDispatch } from '../../../hooks/useRedux';
import { useAppSelector } from '../../../hooks/useRedux';
import PaginationControls from '../../../components/PaginationControls/PaginationControls';
import {
  WebhookType,
  WebhookStatusTypes,
  GetWebhooksResponse,
} from '../../../types/webhooksTypes';
import { selectMode } from '../../../lib/store';
import { setWebhooks } from '../../../lib/store/reducers/DataReducer';
import usePermissions from '../../../hooks/usePermissions';
import CreateWebhook from './CreateWebhook';
import WebhookDetails from './WebhookDetails';
import './webhooks.scss';

const Webhooks = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getWebhooks } = useWebhooksService();
  const webhooks = useAppSelector((state) => state.data.webhooks);
  const mode = useAppSelector(selectMode);
  const [activeWebhook, setActiveWebhook] = useState<WebhookType | null>(null);
  const { handleError } = useNotification();
  const dispatch = useAppDispatch();
  const { webhookWrite } = usePermissions();
  const [createWebhookModal, setCreateWebhookModal] = useState<boolean>(false);
  const [webhookDetailsModal, setWebhookDetailsModal] =
    useState<boolean>(false);

  useEffect(() => {
    handleGetWebhooks();
    //eslint-disable-next-line
  }, [page, size, mode]);

  const handleGetWebhooks = () => {
    if (!webhooks.dataFetched) setLoading(true);

    getWebhooks(size, page)
      .then((res: GetWebhooksResponse) => {
        dispatch(
          setWebhooks({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const viewWebhook = (webhook: WebhookType) => {
    setActiveWebhook(webhook);
    setWebhookDetailsModal(true);
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Webhooks | Anchor</title>
        <meta name="description" content="View and manage your webhooks" />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <CreateWebhook
        modalOpen={createWebhookModal}
        closeModal={() => {
          setCreateWebhookModal(false);
          setActiveWebhook(null);
        }}
        callback={() => handleGetWebhooks()}
        webhook={activeWebhook}
      />

      <WebhookDetails
        modalOpen={webhookDetailsModal}
        closeModal={() => {
          setWebhookDetailsModal(false);
          setActiveWebhook(null);
        }}
        callback={() => handleGetWebhooks()}
        activeWebhook={activeWebhook}
      />

      <Group position="apart" align="center" mb={25}>
        <div className="p-title">Webhooks</div>

        {webhooks.meta.total > 0 && (
          <Button
            color="dark"
            onClick={() => setCreateWebhookModal(true)}
            compact
            className="compact-btn"
            disabled={!webhookWrite}
          >
            Add Webhook
          </Button>
        )}
      </Group>

      {webhooks.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table verticalSpacing="md" sx={{ minWidth: 900 }}>
              <thead>
                <tr>
                  <th>URL</th>
                  <th>Name</th>
                  <th>Delivery Type</th>
                  <th>Status</th>
                  <th>Date Created</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  webhooks.data.map((webhook: WebhookType, index: number) => (
                    <tr
                      key={index}
                      className="click"
                      onClick={() => viewWebhook(webhook)}
                    >
                      <td className="link-new-tab" id={webhook.id}>
                        {webhook.url}
                      </td>

                      <td>{webhook.label}</td>

                      <td>
                        <div className="type-badge">{webhook.deliveryMode}</div>
                      </td>

                      <td>
                        <Badge
                          color={
                            webhook.status === WebhookStatusTypes.enabled
                              ? 'green'
                              : 'red'
                          }
                        >
                          {webhook.status}
                        </Badge>
                      </td>

                      <td className="gray">
                        {moment(webhook.createdAt).format(
                          'MMM Do, YYYY h:mm a'
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={webhooks.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {webhooks.meta.total === 0 && (
        <EmptyData
          title="Welcome to webhooks"
          desc="At the moment, there is no data to be displayed."
          buttonText="Create webhook now"
          buttonAction={() => {
            setCreateWebhookModal(true);
          }}
        />
      )}
    </div>
  );
};

export default Webhooks;
