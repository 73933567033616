import { ResponseMetaPagination } from '../lib/types';

export type GetAccountsResponse = {
  data: AccountType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type AccountType = {
  id: string;
  type: string;
  subAccounts: {
    data: any[];
  };
  virtualNubans?: {
    data: Data[];
  };
  customer: {
    data: Data;
  };
  createdAt: Date;
  bank: {
    id: string;
    name: string;
    cbnCode: string;
    nipCode: string;
  };
  accountName?: string;
  frozen: boolean;
  currency: string;
  accountNumber: string;
  status: string;
};

export interface Data {
  id: string;
  type: string;
}

export enum AccountStatusTypes {
  active = 'ACTIVE',
}

export type AccountBalanceType = {
  availableBalance: number;
  ledgerBalance: number;
  hold: number;
  pending: number;
};

export type GetAccountsParams = {
  page: number;
  size: number;
  accountType?: string;
  customerId?: string;
  query?: string;
};

export type CreateBankAccountData = {
  productName: string;
  metadata?: Record<string, string>;
  customerId: string;
};

export enum AccountTypes {
  SAVINGS = 'SAVINGS',
  CURRENT = 'CURRENT',
  MASTER = 'MASTER',
  REVENUE = 'REVENUE',
  SETTLEMENT = 'SETTLEMENT',
  FBO = 'FBO',
}
