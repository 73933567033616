import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import {
  Badge,
  Table,
  ScrollArea,
  Text,
  Drawer,
  Group,
  Button,
  CloseButton,
  Tooltip,
  LoadingOverlay,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import useTransfersService from '../../services/transfers';
import {
  TransferType,
  TransferStatusTypes,
  TransferTypes,
  GetTransfersResponse,
} from '../../types/transfersTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import TransfersFilter from './TransfersFilter';
import { truncateString, formatAmount } from '../../lib/util';
import ExportData from '../../components/ExportData/ExportData';
import TransferDetails from './DetailsWrapper';
import { setNipTransfers } from '../../lib/store/reducers/DataReducer';
import useNotification from '../../hooks/useNotification';
import './transfers.scss';

const NipTransfers = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const idParam = searchParams.get('id');
  const { width } = useViewportSize();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [query] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const [counterPartyId, setCounterPartyId] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const { getTransfers } = useTransfersService();
  const transfers = useAppSelector((state) => state.data.nipTransfers);
  const mode = useAppSelector(selectMode);
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [transferId, setTransferId] = useState<string>('');
  const [exportModal, setExportModal] = useState<boolean>(false);
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (search) {
      handleGetTransfers(search);
    } else {
      handleGetTransfers();
    }
    //eslint-disable-next-line
  }, [
    page,
    size,
    search,
    query,
    mode,
    accountId,
    customerId,
    counterPartyId,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    if (idParam) {
      setTransferId(idParam);
      setDetailsDrawer(true);
      navigate('/nip-transfers', { replace: true });
    }
    //eslint-disable-next-line
  }, [idParam]);

  const handleGetTransfers = (searchQuery?: string) => {
    if (!transfers.dataFetched || searchQuery) setLoading(true);

    getTransfers({
      size,
      page,
      query: searchQuery ? searchQuery : query,
      type: TransferTypes.NIP_TRANSFER,
      accountId,
      customerId,
      counterPartyId,
      from: dateFrom,
      to: dateTo,
    })
      .then((res: GetTransfersResponse) => {
        dispatch(
          setNipTransfers({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterItemChange = (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => {
    if (value !== itemState) setLoading(true);
    setItemState && setItemState(value);
  };

  const clearSearch = () => {
    setLoading(true);
    navigate('/nip-transfers', { replace: true });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>NIP Transfers | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of NIP Transfers"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <Drawer
        opened={detailsDrawer}
        onClose={() => setDetailsDrawer(false)}
        title={
          <Text fw={600} fz={23}>
            Transfer Details
          </Text>
        }
        padding="xl"
        size={650}
        position="right"
      >
        <TransferDetails transferId={transferId} drawerActive={detailsDrawer} />
      </Drawer>

      <ExportData
        modalOpen={exportModal}
        closeModal={() => {
          setExportModal(false);
        }}
        resource={TransferTypes.NIP_TRANSFER}
      />

      <Group mb={20} position="apart" align="center">
        <div className={`p-title ${search ? 'search-result' : ''}`}>
          {search ? `Showing results for '${search}'` : 'NIP Transfers'}
        </div>

        {search ? (
          <CloseButton
            title="Clear search"
            size="xl"
            iconSize={20}
            onClick={clearSearch}
          />
        ) : (
          <Button
            color="dark"
            style={{ display: width < 600 ? 'none' : '' }}
            disabled
            onClick={() => {
              navigate(`/transfers/create?type=nip`);
            }}
          >
            Create Transfer
          </Button>
        )}
      </Group>

      {transfers.dataFetched && (
        <React.Fragment>
          <Group mt={10} position="right" spacing={10}>
            <TransfersFilter
              accountId={accountId}
              setAccountId={setAccountId}
              customerId={customerId}
              setCustomerId={setCustomerId}
              counterPartyId={counterPartyId}
              setCounterPartyId={setCounterPartyId}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
              onFilterItemChange={onFilterItemChange}
            />

            <Button
              compact
              className="compact-btn"
              color="dark"
              size={width > 700 ? 'md' : 'sm'}
              onClick={() => {
                setExportModal(true);
              }}
            >
              Export
            </Button>
          </Group>

          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
            mt={10}
          >
            <Table verticalSpacing="md" sx={{ minWidth: 950 }} highlightOnHover>
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>
                    From <span className="arrow-right-text">→</span> To
                  </th>
                  <th>Reason</th>
                  <th></th>
                  <th>Status</th>
                  <th></th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  transfers.data.map(
                    (transfer: TransferType, index: number) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setTransferId(transfer.id);
                          setDetailsDrawer(true);
                        }}
                        className="click"
                        id={transfer.id}
                      >
                        <td className="gray">
                          {' '}
                          {`₦${formatAmount(transfer.amount)}`}
                        </td>
                        <td className="capitalize">
                          {transfer?.account?.data?.accountName?.toLowerCase()}{' '}
                          <span className="arrow-right-text">→</span>{' '}
                          {transfer?.counterParty?.data?.accountName?.toLowerCase()}
                        </td>
                        <td>
                          <Tooltip
                            multiline
                            width={330}
                            withArrow
                            transition="fade"
                            transitionDuration={200}
                            label={transfer?.reason}
                            position="bottom"
                            disabled={
                              transfer?.reason
                                ? transfer.reason.length < 31
                                : true
                            }
                          >
                            <span>
                              {transfer?.reason &&
                                truncateString(transfer.reason as string, 30)}
                            </span>
                          </Tooltip>
                        </td>
                        <td></td>
                        <td>
                          <Badge
                            color={
                              transfer.status === TransferStatusTypes.completed
                                ? 'green'
                                : 'red'
                            }
                          >
                            {transfer.status}
                          </Badge>
                        </td>
                        <td></td>
                        <td className="gray">
                          {moment(transfer.createdAt).format('MMM D, YYYY')}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </Table>

            {transfers.meta.total === 0 && (
              <EmptyData
                title="There are no transfers to display"
                desc="At the moment, there is no data to be displayed."
              />
            )}
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={transfers.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {transfers.meta.total === 0 && !transfers.dataFetched && (
        <EmptyData
          title="You have not created any transfer"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

export default NipTransfers;
