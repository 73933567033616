export type DocumentType = {
  id: string;
  type: string;
  customer: {
    data: Data;
  };
  createdAt: Date;
  expires: boolean;
  submitted: boolean;
  data: string;
  documentType: DocumentTypes;
  format: string;
  verified: boolean;
  description: string;
};

export enum DocumentFormatTypes {
  TEXT = 'TEXT',
  FILE = 'FILE',
}

export type UploadDocumentData = {
  textData: string;
  fileData: File | string;
};

interface Data {
  id: string;
  type: string;
}

export enum DocumentTypes {
  TIN = 'TIN',
  CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
  RC_NUMBER = 'RC_NUMBER',
  MEMORANDUM_OF_ASSOCIATION = 'MEMORANDUM_OF_ASSOCIATION',
  CAC_STATUS_REPORT = 'CAC_STATUS_REPORT',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
}
