import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UtilityState {
  showLoader: boolean;
  sessionRoute: string;
}

const initialState: UtilityState = {
  showLoader: false,
  sessionRoute: '',
};

export const utilitySlice = createSlice({
  name: 'utility',
  initialState: initialState,
  reducers: {
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    setSessionRoute: (state, action: PayloadAction<string>) => {
      state.sessionRoute = action.payload;
    },
  },
});

export const { setShowLoader, setSessionRoute } = utilitySlice.actions;

export default utilitySlice.reducer;
