import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { AxiosError } from 'axios';
import {
  Badge,
  ScrollArea,
  Text,
  Group,
  CloseButton,
  Stack,
  Space,
  Button,
} from '@mantine/core';
import { formatAmount, capitalizeTransform, formatName } from '../../lib/util';
import {
  TransferType,
  TransferStatusTypes,
  TransferTypes,
} from '../../types/transfersTypes';
import { DetailItem2 } from '../DetailItem/DetailItem';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import './transfer-details.scss';
import { getCurrencySymbol } from '../../lib/util';
import useSubAccountsService from '../../services/subAccounts';
import { SubAccountType } from '../../types/subAccountsTypes';
import useNotification from '../../hooks/useNotification';
import { CustomerAccountTypes } from '../../types/customersTypes';

interface ITransferDetails {
  transfer: TransferType;
  otherResource?: boolean;
  close?: () => void;
  handleVerify?: () => void;
}

const TransferDetails = ({
  transfer,
  otherResource,
  close,
  handleVerify,
}: ITransferDetails) => {
  const [activeExtra, setActiveExtra] = useState<
    | 'sourceAccount'
    | 'destinationAccount'
    | 'counterparty'
    | 'subAccount'
    | 'destinationSubAccount'
    | ''
  >('');
  const { getSubAccountDetails } = useSubAccountsService();
  const [subAccount, setSubAccount] = useState<SubAccountType | null>(null);
  const [destinationSubAccount, setDestinationSubAccount] =
    useState<SubAccountType | null>(null);
  const { handleError } = useNotification();

  useEffect(() => {
    if (transfer?.destinationSubAccount) {
      handleGetSubAccountDetails(
        transfer.destinationSubAccount.data.id,
        'destinationSubAccount'
      );
    }

    if (transfer.subAccount) {
      handleGetSubAccountDetails(transfer.subAccount.data.id, 'subAccount');
    }

    //eslint-disable-next-line
  }, []);

  const handleGetSubAccountDetails = (
    subAccountId: string,
    type: 'subAccount' | 'destinationSubAccount'
  ) => {
    getSubAccountDetails(subAccountId)
      .then((res: SubAccountType) => {
        if (type === 'subAccount') {
          setSubAccount(res);
        } else {
          setDestinationSubAccount(res);
        }
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  return (
    <ScrollArea
      style={{ height: otherResource ? 'unset' : '88vh' }}
      type="scroll"
    >
      <div
        className={`card-details details-view ${otherResource ? 'other' : ''} ${
          transfer.status === TransferStatusTypes.pending
            ? 'pending-transfer'
            : ''
        }`}
      >
        <Group position="apart" align="center">
          <Badge
            mb={5}
            color={
              transfer?.status === TransferStatusTypes.completed
                ? 'green'
                : 'red'
            }
          >
            {transfer?.status}
          </Badge>

          {otherResource && (
            <CloseButton aria-label="Close modal" onClick={close} />
          )}
        </Group>
        <Group position="apart" align="center">
          <div className="t-amount">
            {`${getCurrencySymbol(transfer.currency)}${formatAmount(
              transfer?.amount ?? 0
            )}`}
          </div>

          <div className="type-badge">
            {transfer?.type === TransferTypes.BOOK_TRANSFER && 'Book'}
            {transfer?.type === TransferTypes.NIP_TRANSFER && 'NIP'}
          </div>
        </Group>
        <div className="group-title">Source & Destination</div>
        <div className="source-destination">
          <Stack spacing={4}>
            <div>
              {capitalizeTransform(transfer?.account?.data?.accountName ?? '')}
            </div>
            <div className="s-d-sec">
              {transfer?.account?.data?.accountNumber}
            </div>
            <div className="s-d-sec">
              {capitalizeTransform(transfer?.account?.data?.bank?.name)}
            </div>
          </Stack>

          <ArrowRight />

          <Stack spacing={4}>
            {transfer?.type === TransferTypes.NIP_TRANSFER && (
              <>
                <div>
                  {capitalizeTransform(
                    `${transfer?.counterParty?.data?.accountName ?? ''}`
                  )}
                </div>
                <div className="s-d-sec">
                  {transfer?.counterParty?.data?.accountNumber}
                </div>
                <div className="s-d-sec">
                  {capitalizeTransform(
                    `${transfer?.counterParty?.data?.bank?.name ?? ''}`
                  )}
                </div>
              </>
            )}

            {transfer?.type === TransferTypes.BOOK_TRANSFER && (
              <>
                <div>
                  {capitalizeTransform(
                    `${transfer?.destinationAccount?.data?.accountName}`
                  )}
                </div>

                <div className="s-d-sec">
                  {transfer?.destinationAccount?.data?.accountNumber}
                </div>
                <div className="s-d-sec">
                  {capitalizeTransform(
                    `${transfer?.destinationAccount?.data?.bank.name}`
                  )}
                </div>
              </>
            )}
          </Stack>
        </div>

        <Space h={40} />

        <DetailItem2 title="Transfer ID" desc={transfer.id} />

        <DetailItem2
          title="Transfer Date"
          desc={moment(transfer?.createdAt).format('MMM Do, YYYY h:mm a')}
        />

        {transfer?.failureReason && (
          <DetailItem2 title="Failure Reason" desc={transfer.failureReason} />
        )}

        <DetailItem2 title="Reference" desc={transfer.reference} />

        {transfer?.reason && (
          <DetailItem2 title="Reason" desc={transfer.reason} />
        )}

        {transfer?.sessionId && (
          <DetailItem2 title="Session ID" desc={transfer.sessionId} />
        )}
        {transfer.metadata && (
          <>
            <div className="group-title">Metadata</div>
            {Object.keys(transfer?.metadata).map(
              (item: string, index: number) => (
                <DetailItem2
                  key={index}
                  title={item}
                  desc={transfer.metadata && transfer.metadata[item]}
                />
              )
            )}
          </>
        )}
        {!otherResource && transfer.account && (
          <div className={`d-extra-info ${otherResource ? 'nest' : ''}`}>
            {activeExtra === 'sourceAccount' ? (
              <div>
                <Group position="apart" mb={20}>
                  <div className="e-i-title">Source Account</div>

                  <CloseButton
                    aria-label="Close"
                    onClick={() => setActiveExtra('')}
                  />
                </Group>

                <DetailItem2
                  title="Account ID"
                  desc={transfer.account.data.id}
                />

                <DetailItem2
                  title="Account Name"
                  desc={capitalizeTransform(
                    transfer.account.data?.accountName ?? ''
                  )}
                />

                <DetailItem2
                  title="Account Number"
                  desc={transfer.account.data.accountNumber}
                />

                <DetailItem2
                  title="Bank Name"
                  desc={capitalizeTransform(transfer.account.data.bank.name)}
                />
              </div>
            ) : (
              <Group position="apart">
                <Stack spacing={8}>
                  <Text fw={500}>Source Account</Text>
                  <div className="resource-card-desc">
                    {capitalizeTransform(
                      transfer.account.data?.accountName ?? ''
                    )}
                  </div>
                </Stack>

                <Stack spacing={8} align="flex-end">
                  <Text fz={16} fw={600}>
                    {transfer.account.data.accountNumber}
                  </Text>

                  <Button
                    variant="light"
                    rightIcon={<ArrowRight className="arr-right" />}
                    compact
                    onClick={() => {
                      setActiveExtra('sourceAccount');
                    }}
                  >
                    See details
                  </Button>
                </Stack>
              </Group>
            )}
          </div>
        )}
        {transfer.type === TransferTypes.BOOK_TRANSFER &&
          transfer.destinationAccount && (
            <div className={`d-extra-info ${otherResource ? 'nest' : ''}`}>
              {activeExtra === 'destinationAccount' ? (
                <div>
                  <Group position="apart" mb={20}>
                    <div className="e-i-title">Destination Account</div>

                    <CloseButton
                      aria-label="Close"
                      onClick={() => setActiveExtra('')}
                    />
                  </Group>

                  <DetailItem2
                    title="Account ID"
                    desc={transfer.destinationAccount.data.id}
                  />

                  <DetailItem2
                    title="Account Name"
                    desc={capitalizeTransform(
                      transfer.destinationAccount.data?.accountName ?? ''
                    )}
                  />

                  <DetailItem2
                    title="Account Number"
                    desc={transfer.destinationAccount.data.accountNumber}
                  />

                  <DetailItem2
                    title="Bank Name"
                    desc={capitalizeTransform(
                      transfer.destinationAccount.data.bank.name
                    )}
                  />
                </div>
              ) : (
                <Group position="apart">
                  <Stack spacing={8}>
                    <Text fw={500}>Destination Account</Text>
                    <div className="resource-card-desc">
                      {capitalizeTransform(
                        transfer.destinationAccount.data?.accountName ?? ''
                      )}
                    </div>
                  </Stack>

                  <Stack spacing={8} align="flex-end">
                    <Text fz={16} fw={600}>
                      {transfer.destinationAccount.data.accountNumber}
                    </Text>

                    <Button
                      variant="light"
                      rightIcon={<ArrowRight className="arr-right" />}
                      compact
                      onClick={() => {
                        setActiveExtra('destinationAccount');
                      }}
                    >
                      See details
                    </Button>
                  </Stack>
                </Group>
              )}
            </div>
          )}

        {subAccount && (
          <div className={`d-extra-info ${otherResource ? 'nest' : ''}`}>
            {activeExtra === 'subAccount' ? (
              <div>
                <Group position="apart" mb={20}>
                  <div className="e-i-title">Sub Account</div>

                  <CloseButton
                    aria-label="Close"
                    onClick={() => setActiveExtra('')}
                  />
                </Group>

                <DetailItem2 title="Sub Account ID" desc={subAccount.id} />

                <DetailItem2
                  title="Sub Account Name"
                  desc={capitalizeTransform(
                    subAccount.customer.data.type ===
                      CustomerAccountTypes.business
                      ? subAccount?.customer.data.detail.businessName
                      : formatName(subAccount?.customer?.data?.fullName)
                  )}
                />

                <DetailItem2
                  title="Account Type"
                  desc={subAccount.accountType}
                />
              </div>
            ) : (
              <Group position="apart">
                <Stack spacing={8}>
                  <Text fw={500}>Sub Account</Text>
                  <div className="resource-card-desc">
                    {capitalizeTransform(
                      subAccount.customer.data.type ===
                        CustomerAccountTypes.business
                        ? subAccount?.customer.data.detail.businessName
                        : formatName(subAccount?.customer?.data?.fullName)
                    )}
                  </div>
                </Stack>

                <Stack spacing={8} align="flex-end">
                  <Button
                    variant="light"
                    rightIcon={<ArrowRight className="arr-right" />}
                    compact
                    onClick={() => {
                      setActiveExtra('subAccount');
                    }}
                  >
                    See details
                  </Button>
                </Stack>
              </Group>
            )}
          </div>
        )}

        {destinationSubAccount && (
          <div className={`d-extra-info ${otherResource ? 'nest' : ''}`}>
            {activeExtra === 'destinationSubAccount' ? (
              <div>
                <Group position="apart" mb={20}>
                  <div className="e-i-title">Destination Sub Account</div>

                  <CloseButton
                    aria-label="Close"
                    onClick={() => setActiveExtra('')}
                  />
                </Group>

                <DetailItem2
                  title="Sub Account ID"
                  desc={destinationSubAccount.id}
                />

                <DetailItem2
                  title="Sub Account Name"
                  desc={capitalizeTransform(
                    destinationSubAccount.customer.data.type ===
                      CustomerAccountTypes.business
                      ? destinationSubAccount?.customer.data.detail.businessName
                      : formatName(
                          destinationSubAccount?.customer?.data?.fullName
                        )
                  )}
                />

                <DetailItem2
                  title="Account Type"
                  desc={destinationSubAccount.accountType}
                />
              </div>
            ) : (
              <Group position="apart">
                <Stack spacing={8}>
                  <Text fw={500}>Destination Sub Account</Text>
                  <div className="resource-card-desc">
                    {capitalizeTransform(
                      destinationSubAccount.customer.data.type ===
                        CustomerAccountTypes.business
                        ? destinationSubAccount?.customer.data.detail
                            .businessName
                        : formatName(
                            destinationSubAccount?.customer?.data?.fullName
                          )
                    )}
                  </div>
                </Stack>

                <Stack spacing={8} align="flex-end">
                  <Button
                    variant="light"
                    rightIcon={<ArrowRight className="arr-right" />}
                    compact
                    onClick={() => {
                      setActiveExtra('destinationSubAccount');
                    }}
                  >
                    See details
                  </Button>
                </Stack>
              </Group>
            )}
          </div>
        )}

        {transfer.type === TransferTypes.NIP_TRANSFER && transfer.counterParty && (
          <div className={`d-extra-info ${otherResource ? 'nest' : ''}`}>
            {activeExtra === 'counterparty' ? (
              <div>
                <Group position="apart" mb={20}>
                  <div className="e-i-title">Counterparty</div>

                  <CloseButton
                    aria-label="Close"
                    onClick={() => setActiveExtra('')}
                  />
                </Group>

                <DetailItem2
                  title="Account ID"
                  desc={transfer.counterParty.data.id}
                />

                <DetailItem2
                  title="Account Name"
                  desc={capitalizeTransform(
                    transfer.counterParty?.data?.accountName
                  )}
                />

                <DetailItem2
                  title="Account Number"
                  desc={transfer.counterParty?.data?.accountNumber}
                />

                <DetailItem2
                  title="Bank Name"
                  desc={capitalizeTransform(
                    transfer.counterParty.data?.bank?.name
                  )}
                />
              </div>
            ) : (
              <Group position="apart">
                <Stack spacing={8}>
                  <Text fw={500}>Counterparty</Text>
                  <div className="resource-card-desc">
                    {capitalizeTransform(
                      transfer?.counterParty?.data?.accountName
                    )}
                  </div>
                </Stack>

                <Stack spacing={8} align="flex-end">
                  <Text fz={16} fw={600}>
                    {transfer?.counterParty?.data?.accountNumber}
                  </Text>

                  <Button
                    variant="light"
                    rightIcon={<ArrowRight className="arr-right" />}
                    compact
                    onClick={() => {
                      setActiveExtra('counterparty');
                    }}
                  >
                    See details
                  </Button>
                </Stack>
              </Group>
            )}
          </div>
        )}

        {transfer.status === TransferStatusTypes.pending && (
          <div className="floating-btn">
            <Button radius="xl" color="dark" onClick={handleVerify}>
              Verify Transfer
            </Button>
          </div>
        )}
      </div>
    </ScrollArea>
  );
};

export default TransferDetails;
