import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetAuditsResponse,
  GetAuditsParams,
  AuditType,
  AuditEventType,
} from '../types/auditsTypes';

const useAuditsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, mode);

  const getAudits = (params: GetAuditsParams) => {
    return new Promise<GetAuditsResponse>((resolve, reject) => {
      authApi
        .get(`${APIS.AUDITS.AUDITS}?size=${params.size}&page=${params.page}`, {
          params: {
            include: undefined,
            ...(params?.eventName && {
              eventName: params?.eventName,
            }),
            ...(params?.resourceId && {
              resourceId: params?.resourceId,
            }),
            ...(params?.fromDate && {
              fromDate: params?.fromDate,
            }),
            ...(params?.toDate && {
              toDate: params?.toDate,
            }),
            ...(params?.username && {
              username: params?.username,
            }),
            ...(params?.resourceType && {
              resourceType: params?.resourceType,
            }),
            ...(params?.ipAddress && {
              ipAddress: params?.ipAddress,
            }),
            ...(params?.actor && {
              actor: params?.actor,
            }),
          },
        })
        .then((res: GetAuditsResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getAudit = (auditId: string, unlinked?: boolean) => {
    return new Promise<AuditType>((resolve, reject) => {
      const instance = unlinked ? axiosApi : authApi;

      instance
        .get(APIS.AUDITS.AUDIT(auditId))
        .then((res: { data: AuditType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getAuditResourceTypes = () => {
    return new Promise<string[]>((resolve, reject) => {
      authApi
        .get(APIS.AUDITS.AUDIT_RESOURCE_TYPES)
        .then((res: { data: string[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getAuditEventTypes = () => {
    return new Promise<AuditEventType[]>((resolve, reject) => {
      authApi
        .get(APIS.AUDITS.AUDIT_EVENT_TYPES)
        .then((res: { data: AuditEventType[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getAudits,
    getAudit,
    getAuditResourceTypes,
    getAuditEventTypes,
  };
};

export default useAuditsService;
