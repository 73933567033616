import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
  Table,
  ScrollArea,
  Button,
  Group,
  Text,
  CloseButton,
  LoadingOverlay,
} from '@mantine/core';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import useCounterpartyService from '../../services/counterparties';
import {
  CounterpartyType,
  GetCounterPartiesResponse,
} from '../../types/counterpartiesTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import useNotification from '../../hooks/useNotification';
import { setCounterparties } from '../../lib/store/reducers/DataReducer';
import usePermissions from '../../hooks/usePermissions';

const Counterparty = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [query] = useState<string>('');
  const { getCounterparties } = useCounterpartyService();
  const counterparties = useAppSelector((state) => state.data.counterparties);
  const mode = useAppSelector(selectMode);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { counterpartyWrite } = usePermissions();

  useEffect(() => {
    if (search) {
      setLoading(true);
      handleGetCounterparties(search);
    } else {
      handleGetCounterparties();
    }
    //eslint-disable-next-line
  }, [page, size, mode, search, query]);

  const handleGetCounterparties = (searchQuery?: string) => {
    if (!counterparties.dataFetched) setLoading(true);

    getCounterparties({
      size,
      page,
      query: searchQuery ? searchQuery : query,
    })
      .then((res: GetCounterPartiesResponse) => {
        dispatch(
          setCounterparties({
            data: res.data,
            meta: res.meta.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearSearch = () => {
    setLoading(true);
    navigate('/counterparty');
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Counterparty | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of counterparties"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <Group mb={25} position="apart" align="center">
        <div className={`p-title ${search ? 'search-result' : ''}`}>
          {search ? `Showing results for '${search}'` : 'Counterparty'}
        </div>

        {search ? (
          <CloseButton
            title="Clear search"
            size="xl"
            iconSize={20}
            onClick={clearSearch}
          />
        ) : (
          counterparties.meta.total > 0 && (
            <Button
              color="dark"
              onClick={() => {
                navigate('/counterparty/add');
              }}
              disabled={!counterpartyWrite}
            >
              Add <span className="btn-lg-only">Counterparty</span>
            </Button>
          )
        )}
      </Group>

      {counterparties.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table verticalSpacing="md" sx={{ minWidth: 800 }}>
              <thead>
                <tr>
                  <th>Account Name</th>
                  <th>ID</th>
                  <th>Account</th>
                  <th>Date Added</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  counterparties.data.map(
                    (counterparty: CounterpartyType, index: number) => (
                      <tr key={index}>
                        <td className="capitalize" id={counterparty.id}>
                          {counterparty?.accountName
                            ? counterparty.accountName.toLowerCase()
                            : 'N/A'}
                        </td>
                        <td>
                          <Text color="#626262">{counterparty.id}</Text>
                        </td>
                        <td>
                          <Group spacing="xs">
                            <Text>{counterparty?.accountNumber}</Text>
                            <Text color="gray" className="capitalize">
                              {counterparty?.bank?.name.toLowerCase()}
                            </Text>
                          </Group>
                        </td>
                        <td className="gray">
                          {moment(counterparty.createdAt).format('MMM D, YYYY')}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={counterparties.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {counterparties.meta.total === 0 && (
        <EmptyData
          title="No counterparty yet"
          desc="At the moment, there is no data to be displayed."
          buttonAction={() => {
            navigate('/counterparty/add');
          }}
          buttonText={<Group>Create counterparty</Group>}
        />
      )}
    </div>
  );
};

export default Counterparty;
