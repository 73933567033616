import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import { TextInput, Select, Button, ActionIcon, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { ReactComponent as ArrowBack } from '../../assets/svg/arrow-back.svg';
import useCustomersService from '../../services/customers';
import useAccountsService from '../../services/accounts';
import CustomSelectItem, {
  ItemSchema,
} from '../../components/CustomSelectItem/CustomSelectItem';
import { handleCustomSelectCreate, capitalizeTransform } from '../../lib/util';
import { ReactComponent as Trash } from '../../assets/svg/trash.svg';
import { setShowLoader } from '../../lib/store/reducers/UtilityReducer';
import {
  CustomerAccountTypes,
  GetCustomersResponse,
} from '../../types/customersTypes';
import { OrganizationType } from '../../types/organizationTypes';
import { selectMode } from '../../lib/store';
import { Mode } from '../../lib/store/reducers/SettingsReducer';
import useNotification from '../../hooks/useNotification';
import usePermissions from '../../hooks/usePermissions';

export interface MetadataSchema {
  key: string;
  value: string;
}

export const metadataSchema: MetadataSchema = {
  key: '',
  value: '',
};

const CreateBankAccount = () => {
  const mode = useAppSelector(selectMode);
  const organizations = useAppSelector((state) => state.user.organizations);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getCustomers } = useCustomersService();
  const { createBankAccount, getAccountProducts } = useAccountsService();
  const [rootCustomer, setRootCustomer] = useState<ItemSchema[]>([]);
  const [customers, setCustomers] = useState<ItemSchema[]>([]);
  const { handleError } = useNotification();
  const { customerRead } = usePermissions();
  const [accountProducts, setAccountProducts] = useState<string[]>([]);

  useEffect(() => {
    if (organizations.length > 0) {
      const org: OrganizationType = organizations[0];

      const data: ItemSchema = {
        label: org?.complianceStatus?.basicDetail?.businessName ?? '',
        description: org?.customer?.data?.id,
        value: org?.customer?.data?.id,
      };

      setRootCustomer([data]);
    }
    handleGetAccountProducts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (customerRead) handleGetCustomers();
    //eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    if (mode === Mode.Live) {
      if (rootCustomer.length > 0) {
        form.setFieldValue('customerId', `${rootCustomer[0]?.value}`);
      }
    } else {
      form.setFieldValue('customerId', '');
    }
    //eslint-disable-next-line
  }, [mode, rootCustomer]);

  const handleGetCustomers = () => {
    getCustomers({ page: 0, size: 50 })
      .then((res: GetCustomersResponse) => {
        const data: ItemSchema[] = res.data.map((customer) => {
          return {
            label: capitalizeTransform(
              customer?.type === CustomerAccountTypes.individual
                ? `${customer?.fullName?.firstName} ${customer?.fullName?.lastName}`
                : customer?.detail?.businessName
            ),
            description: customer?.id,
            value: customer?.id,
          };
        });

        setCustomers(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleGetAccountProducts = () => {
    getAccountProducts()
      .then((res: string[]) => {
        setAccountProducts(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  interface FormValues {
    productName: string;
    customerId: string;
    metadata: MetadataSchema[];
  }

  const form = useForm({
    initialValues: {
      productName: '',
      customerId: '',
      metadata: [],
    },

    validate: {
      productName: (value) => (value === '' ? 'Select account type' : null),
      customerId: (value) => (value === '' ? 'Select customer' : null),
    },
  });

  const handleSubmit = (values: FormValues) => {
    const metadata: Record<string, string> = {};

    if (values.metadata.length > 0) {
      for (let i = 0; i < values.metadata.length; i++) {
        metadata[`${values.metadata[i].key}`] = values.metadata[i].value;
      }
    }

    dispatch(setShowLoader(true));
    createBankAccount({
      productName: values.productName,
      customerId: values.customerId,
      metadata,
    })
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Account creation request processing.',
          color: 'orange',
        });
        navigate(-1);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        dispatch(setShowLoader(false));
      });
  };

  return (
    <div className="add-new">
      <Helmet>
        <title>Create Bank Account | Anchor</title>
        <meta name="description" content="Create a new bank account" />
      </Helmet>

      <div
        className="back-btn click"
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBack /> <span>Back</span>
      </div>

      <div className="add-new-main">
        <div className="i-m-title">Create Bank Account</div>

        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Tooltip
            position="bottom-end"
            withArrow
            multiline
            width={300}
            disabled={mode !== Mode.Live}
            transition="pop-top-left"
            transitionDuration={200}
            label="Creating bank account functionality when in Live Mode
                  on the dashboard is presently limited to the root customer of your
                  organization."
          >
            <div>
              <Select
                required
                mt={24}
                label="Customer"
                placeholder="Select customer/ input ID"
                searchable
                itemComponent={CustomSelectItem}
                data={mode === Mode.Live ? rootCustomer : customers}
                maxDropdownHeight={400}
                creatable
                disabled={mode === Mode.Live}
                getCreateLabel={(query) => `Select ${query}`}
                onCreate={(query) => {
                  return handleCustomSelectCreate(
                    query,
                    customers,
                    () => form.setFieldValue('customerId', query),
                    setCustomers
                  );
                }}
                {...form.getInputProps('customerId')}
              />
            </div>
          </Tooltip>

          <Select
            required
            label="Account Type"
            placeholder="Select account type"
            mt="sm"
            data={accountProducts}
            {...form.getInputProps('productName')}
          />

          <div className="metadata">
            <div className="m-header">
              <div className="m-title">
                Metadata <span>(Optional)</span>
              </div>

              <Button
                variant="subtle"
                compact
                onClick={() => form.insertListItem('metadata', metadataSchema)}
              >
                Add Metadata
              </Button>
            </div>

            {form.values.metadata.map((item: MetadataSchema, index: number) => (
              <div key={index} className="metadata-row">
                <div className="m-r-fields">
                  <TextInput
                    label="key"
                    placeholder="Key"
                    className="form-item"
                    required
                    {...form.getInputProps(`metadata.${index}.key`)}
                  />

                  <TextInput
                    label="Value"
                    placeholder="Value"
                    className="form-item-with-add"
                    required
                    {...form.getInputProps(`metadata.${index}.value`)}
                  />
                </div>

                <ActionIcon
                  onClick={() => form.removeListItem('metadata', index)}
                >
                  <Trash />
                </ActionIcon>
              </div>
            ))}
          </div>

          <Button type="submit" color="dark" fullWidth mt={20}>
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CreateBankAccount;
