import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Table,
  ScrollArea,
  Text,
  Drawer,
  LoadingOverlay,
  CloseButton,
  Group,
  Tooltip,
} from '@mantine/core';
import usePaymentsService from '../../services/payments';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import {
  capitalizeTransform,
  formatAmount,
  truncateString,
} from '../../lib/util';
import { PaymentType, GetPaymentsResponse } from '../../types/paymentsTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import PaymentsFilter from './PaymentsFilter';
import PaymentDetails from '../../components/Payments/PaymentDetails';
import { setPayments } from '../../lib/store/reducers/DataReducer';
import useNotification from '../../hooks/useNotification';

const Payments = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [query] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const [virtualNubanId, setVirtualNubanId] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const { getPayments } = usePaymentsService();
  const payments = useAppSelector((state) => state.data.payments);
  const mode = useAppSelector(selectMode);
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<string>('');
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const idParam = searchParams.get('id');
  const navigate = useNavigate();
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (search) {
      handleGetPayments(search);
    } else {
      handleGetPayments();
    }
    //eslint-disable-next-line
  }, [
    page,
    size,
    mode,
    accountId,
    virtualNubanId,
    search,
    query,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    if (idParam) {
      setPaymentId(idParam);
      setDetailsDrawer(true);
      navigate('/payments', { replace: true });
    }
    //eslint-disable-next-line
  }, [idParam]);

  const handleGetPayments = (searchQuery?: string) => {
    if (!payments.dataFetched || searchQuery) setLoading(true);

    getPayments({
      size,
      page,
      accountId,
      virtualNubanId,
      query: searchQuery ? searchQuery : query,
      from: dateFrom,
      to: dateTo,
    })
      .then((res: GetPaymentsResponse) => {
        dispatch(
          setPayments({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterItemChange = (
    value: string,
    itemState: string,
    setItemState: (value: string) => void
  ) => {
    if (value !== itemState) setLoading(true);
    setItemState(value);
  };

  const clearSearch = () => {
    setLoading(true);
    navigate('/payments', { replace: true });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Payments | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of payments and settlements"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <Drawer
        opened={detailsDrawer}
        onClose={() => setDetailsDrawer(false)}
        title={
          <Text fw={600} fz={23}>
            Payment Details
          </Text>
        }
        padding="xl"
        size={650}
        position="right"
      >
        <DetailsWrapper paymentId={paymentId} modalActive={detailsDrawer} />
      </Drawer>

      <Group position="apart" align="center" mb={search ? 10 : 0}>
        <div className={`p-title extra-mb ${search ? 'search-result' : ''}`}>
          {search ? `Showing results for '${search}'` : 'Payments'}
        </div>

        {search && (
          <CloseButton
            title="Clear search"
            size="xl"
            iconSize={20}
            onClick={clearSearch}
          />
        )}
      </Group>

      {payments.dataFetched && (
        <React.Fragment>
          <PaymentsFilter
            accountId={accountId}
            setAccountId={setAccountId}
            virtualNubanId={virtualNubanId}
            setVirtualNubanId={setVirtualNubanId}
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            onFilterItemChange={onFilterItemChange}
          />

          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
            mt={10}
          >
            <Table
              mt={10}
              verticalSpacing="md"
              sx={{ minWidth: 1000 }}
              highlightOnHover
            >
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Customer</th>
                  <th>Counterparty</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  payments.data.map((payment: PaymentType, index: number) => (
                    <tr
                      className="click"
                      key={index}
                      onClick={() => {
                        setPaymentId(payment.id);
                        setDetailsDrawer(true);
                      }}
                      id={payment.id}
                    >
                      <td className="gray">{`₦${formatAmount(
                        payment.amount
                      )}`}</td>

                      <td className="capitalize">
                        <Tooltip
                          multiline
                          width={350}
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label={capitalizeTransform(payment.narration)}
                          position="bottom"
                          disabled={payment.narration.length < 41}
                        >
                          <span>
                            {truncateString(
                              payment.narration.toLowerCase(),
                              40
                            )}
                          </span>
                        </Tooltip>
                      </td>

                      <td>{payment?.virtualNuban?.data?.accountName}</td>

                      <td>{payment?.counterParty?.accountName}</td>

                      <td className="gray">
                        {moment(payment.createdAt).format('MMM D, YYYY')}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            {payments.meta.total === 0 && (
              <EmptyData
                title="There are no payments to display"
                desc="At the moment, there is no data to be displayed."
              />
            )}
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={payments.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {payments.meta.total === 0 && !payments.dataFetched && (
        <EmptyData
          title="There are no payments to display"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

interface IPaymentDetails {
  paymentId: string;
  modalActive: boolean;
}

export const DetailsWrapper = ({ paymentId, modalActive }: IPaymentDetails) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { getPaymentDetails } = usePaymentsService();
  const [payment, setPayment] = useState<PaymentType | null>(null);
  const { handleError } = useNotification();

  useEffect(() => {
    if (modalActive) {
      handleGetPaymentDetails();
    }
    //eslint-disable-next-line
  }, []);

  const handleGetPaymentDetails = () => {
    setLoading(true);

    getPaymentDetails(paymentId)
      .then((res: PaymentType) => {
        setPayment(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingOverlay visible={loading} />

      {payment && <PaymentDetails payment={payment} />}
    </>
  );
};

export default Payments;
