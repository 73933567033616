import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { TextInput, Select, Button, ActionIcon } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { ReactComponent as ArrowBack } from '../../assets/svg/arrow-back.svg';
import useAccountsService from '../../services/accounts';
import useVirtualNubansService from '../../services/virtualNubans';
import CustomSelectItem, {
  ItemSchema,
} from '../../components/CustomSelectItem/CustomSelectItem';
import { handleCustomSelectCreate, capitalizeTransform } from '../../lib/util';
import { ReactComponent as Trash } from '../../assets/svg/trash.svg';
import { setShowLoader } from '../../lib/store/reducers/UtilityReducer';
import { GetAccountsResponse } from '../../types/accountsTypes';
import useNotification from '../../hooks/useNotification';
import { selectMode } from '../../lib/store';
import usePermissions from '../../hooks/usePermissions';

const CreateVirtualNuban = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getAccounts } = useAccountsService();
  const { createVirtualNuban } = useVirtualNubansService();
  const [accounts, setAccounts] = useState<ItemSchema[]>([]);
  const { handleError } = useNotification();
  const mode = useAppSelector(selectMode);
  const { virtualAaccountWrite } = usePermissions();

  useEffect(() => {
    handleGetAccounts();
    //eslint-disable-next-line
  }, [mode]);

  const handleGetAccounts = () => {
    getAccounts({ page: 0, size: 50 })
      .then((res: GetAccountsResponse) => {
        const data: ItemSchema[] = res.data.map((account) => ({
          label: capitalizeTransform(account?.accountName ?? ''),
          description: `${account.accountNumber} (${account.id})`,
          value: account.id,
        }));

        setAccounts(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  interface FormValues {
    name: string;
    bvn: string;
    accountId: string;
    metadata: IMetadataSchema[];
  }

  const form = useForm({
    initialValues: {
      name: '',
      bvn: '',
      accountId: '',
      metadata: [],
    },

    validate: {
      accountId: (value) => (value === '' ? 'Select account' : null),
    },
  });

  interface IMetadataSchema {
    key: string;
    value: string;
  }

  const metadataSchema: IMetadataSchema = {
    key: '',
    value: '',
  };

  const handleSubmit = (values: FormValues) => {
    const metadata: Record<string, string> = {};

    if (values.metadata.length > 0) {
      for (let i = 0; i < values.metadata.length; i++) {
        metadata[`${values.metadata[i].key}`] = values.metadata[i].value;
      }
    }

    dispatch(setShowLoader(true));

    createVirtualNuban({
      virtualAccountDetail: {
        name: values.name,
        bvn: values.bvn,
      },
      settlementAccountId: values.accountId,
      metadata,
    })
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Account number created.',
          color: 'orange',
        });
        navigate(-1);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        dispatch(setShowLoader(false));
      });
  };

  return (
    <div className="add-new">
      <Helmet>
        <title>Create Account Number | Anchor</title>
        <meta name="description" content="Create a new account number" />
      </Helmet>

      <div
        className="back-btn click"
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBack /> <span>Back</span>
      </div>

      <div className="add-new-main">
        <div className="i-m-title">Create Account Number</div>

        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput
            required
            mt="sm"
            label="Account Name"
            placeholder="Account Name"
            {...form.getInputProps('name')}
          />

          <TextInput
            required
            mt="sm"
            label="BVN"
            placeholder="BVN"
            type="number"
            value={form.values.bvn}
            onKeyDown={(e) =>
              ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length > 11) {
                return;
              }
              if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
                form.setFieldValue('bvn', e.target.value);
              }
            }}
          />

          <Select
            required
            mt="sm"
            className="form-item"
            label="Settlement Account"
            placeholder="Select account/ input ID"
            searchable
            itemComponent={CustomSelectItem}
            data={accounts}
            maxDropdownHeight={400}
            creatable
            getCreateLabel={(query) => `Select ${query}`}
            onCreate={(query) => {
              return handleCustomSelectCreate(
                query,
                accounts,
                () => form.setFieldValue('accountId', query),
                setAccounts
              );
            }}
            {...form.getInputProps('accountId')}
          />

          <div className="metadata">
            <div className="m-header">
              <div className="m-title">
                Metadata <span>(Optional)</span>
              </div>

              <Button
                variant="subtle"
                compact
                onClick={() => form.insertListItem('metadata', metadataSchema)}
              >
                Add Metadata
              </Button>
            </div>

            {form.values.metadata.map(
              (item: IMetadataSchema, index: number) => (
                <div key={index} className="metadata-row">
                  <div className="m-r-fields">
                    <TextInput
                      label="key"
                      placeholder="Key"
                      className="form-item"
                      required
                      {...form.getInputProps(`metadata.${index}.key`)}
                    />

                    <TextInput
                      label="Value"
                      placeholder="Value"
                      className="form-item-with-add"
                      required
                      {...form.getInputProps(`metadata.${index}.value`)}
                    />
                  </div>

                  <ActionIcon
                    onClick={() => form.removeListItem('metadata', index)}
                  >
                    <Trash />
                  </ActionIcon>
                </div>
              )
            )}
          </div>

          <Button
            type="submit"
            color="dark"
            fullWidth
            mt={20}
            disabled={!virtualAaccountWrite}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CreateVirtualNuban;
