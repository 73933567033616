import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { NotificationsProvider } from '@mantine/notifications';
import { MantineProvider, LoadingOverlay } from '@mantine/core';
import { useAppSelector } from './hooks/useRedux';
import './App.scss';

import Signin from './pages/Auth/Signin';
import Signup from './pages/Auth/Signup';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import EmailSent from './pages/Auth/EmailSent';
import JoinTeam from './pages/Auth/JoinTeam';
import LoggedinContainer from './components/LoggedIn';
import { handlePosthogCustomEvent } from './lib/utility/posthog';
import useNotification from './hooks/useNotification';

const App = () => {
  const showLoader = useAppSelector((state) => state.utility.showLoader);
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  const location = useLocation();
  const { checkTokenExpiry, logoutUser } = useNotification();

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      handlePosthogCustomEvent(event, location.pathname);
    };

    document.addEventListener('click', handleDocumentClick, false);

    return () => {
      document.removeEventListener('click', handleDocumentClick, false);
    };
  }, [location]);

  useEffect(() => {
    if (loggedIn && checkTokenExpiry()) {
      logoutUser(true);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <MantineProvider
        theme={{
          fontFamily: 'Satoshi',
          colors: {
            'anchor-orange': [
              '#FFF4E6',
              '#FFE8CC',
              '#FFD8A8',
              '#FFC078',
              '#FFA94D',
              '#FF922B',
              '#F4811E',
              '#F4811E',
              '#F4811E',
              '#F4811E',
            ],
          },
          primaryColor: 'anchor-orange',
          defaultRadius: 6,
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <LoadingOverlay visible={showLoader} />
        <NotificationsProvider
          position="top-right"
          zIndex={2077}
          autoClose={4000}
          limit={4}
        >
          <Routes>
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route path="/join-team" element={<JoinTeam />} />
            <Route
              path="/*"
              element={
                loggedIn ? (
                  <LoggedinContainer />
                ) : (
                  <Navigate replace to="/signin" />
                )
              }
            />
          </Routes>
        </NotificationsProvider>
      </MantineProvider>
    </div>
  );
};

export default App;
