import { APIS } from '../lib/api/apilog';
import { createApi, ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { AxiosError } from 'axios';
import { Mode } from '../lib/store/reducers/SettingsReducer';
import {
  SignupType,
  SigninType,
  SigninResponse,
  InitiateMfaSetupResponse,
  SignupResponse,
  MessageResponse,
} from '../types/authTypes';
import { PermissionType } from '../types/apiKeysTypes';

const useAuthService = () => {
  const api = createApi();
  const authApi = useApi(ApiAuthMode.BearerToken, Mode.Live);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, Mode.Live);
  const axiosApiNoAuth = useAxiosApi(ApiAuthMode.NoAuth, Mode.Live);

  const signup = (body: SignupType) => {
    return new Promise<SignupResponse>((resolve, reject) => {
      api
        .request({
          method: 'POST',
          url: APIS.AUTH.SIGNUP,
          type: 'Signup',
          body,
        })
        .then((res: { data: SignupResponse }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const signin = (body: SigninType) => {
    return new Promise<SigninResponse>((resolve, reject) => {
      api
        .request({
          method: 'POST',
          url: APIS.AUTH.SIGNIN,
          type: 'Login',
          body,
          params: {
            include: 'Organization',
          },
        })
        .then((res: { data: SigninResponse }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const forgotPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      api
        .request({
          method: 'POST',
          url: APIS.AUTH.FORGOT_PASSWORD,
          type: 'ForgotPassword',
          body: { email },
        })
        .then((res: { data: MessageResponse }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const resetPassword = (body: { password: string; reference: string }) => {
    return new Promise((resolve, reject) => {
      api
        .request({
          method: 'POST',
          url: APIS.AUTH.SET_NEW_PASSWORD,
          type: 'SetNewPassword',
          body,
        })
        .then((res: { data: MessageResponse }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const changePassword = (body: {
    oldPassword: string;
    newPassword: string;
  }) => {
    return new Promise((resolve, reject) => {
      authApi
        .request({
          method: 'POST',
          url: APIS.AUTH.CHANGE_PASSWORD,
          type: 'ChangePassword',
          body,
        })
        .then((res: { data: MessageResponse }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getPermissions = () => {
    return new Promise<PermissionType[]>((resolve, reject) => {
      authApi
        .get(APIS.AUTH.GET_PERMISSIONS)
        .then((res: { data: PermissionType[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const checkMfaEnabled = (body: SigninType) => {
    return new Promise<{ mfaEnabled: boolean }>((resolve, reject) => {
      api
        .post(APIS.AUTH.CHECK_MFA_ENABLED, body)
        .then((res: { data: { mfaEnabled: boolean } }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const sendOtp = (email: string) => {
    return new Promise<Record<string, never>>((resolve, reject) => {
      axiosApi
        .post(APIS.AUTH.SEND_OTP(email))
        .then((res: { data: Record<string, never> }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const initiateMfaSetup = (token: string) => {
    return new Promise<InitiateMfaSetupResponse>((resolve, reject) => {
      axiosApiNoAuth
        .post(APIS.AUTH.INITIATE_MFA_SETUP, undefined, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: { data: InitiateMfaSetupResponse }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const completeMfaSetup = (otp: string, token: string) => {
    return new Promise<Record<string, never>>((resolve, reject) => {
      api
        .post(
          APIS.AUTH.COMPLETE_MFA_SETUP,
          { otp },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: Record<string, never>) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    signup,
    signin,
    forgotPassword,
    resetPassword,
    changePassword,
    getPermissions,
    checkMfaEnabled,
    sendOtp,
    initiateMfaSetup,
    completeMfaSetup,
  };
};

export default useAuthService;
