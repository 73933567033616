import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  Table,
  ScrollArea,
  Group,
  Button,
  Badge,
  Drawer,
  Text,
  LoadingOverlay,
} from '@mantine/core';
import { useAppSelector } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import {
  ReportType,
  GetReportsResponse,
  ReportTypes,
  ReportStatusTypes,
} from '../../types/reportsTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import ReportDetails from './ReportDetails';
import useNotification from '../../hooks/useNotification';
import useReportsService from '../../services/reports';
import { ResponseMetaPagination } from '../../lib/types';
import { initialMetaData } from '../../lib/store/reducers/DataReducer';
import { formatDate2 } from '../../lib/util';
import usePermissions from '../../hooks/usePermissions';
import './reports.scss';

const Reports = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getReports } = useReportsService();
  const [reports, setReports] = useState<{
    data: ReportType[];
    meta: ResponseMetaPagination;
  }>({ data: [], meta: initialMetaData });
  const mode = useAppSelector(selectMode);
  const [reportDetailsModal, setReportDetailsModal] = useState<boolean>(false);
  const [reportId, setReportId] = useState<string>('');
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { reportWrite } = usePermissions();

  useEffect(() => {
    handleGetReports();
    //eslint-disable-next-line
  }, [page, size, mode]);

  useEffect(() => {
    setReports({ data: [], meta: initialMetaData });
  }, [mode]);

  const handleGetReports = () => {
    setLoading(true);

    getReports({ size, page })
      .then((res: GetReportsResponse) => {
        setReports({
          data: res?.data,
          meta: res?.meta?.pagination,
        });
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Reports | Anchor</title>
        <meta
          name="description"
          content="View the details of reports generated"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <Drawer
        opened={reportDetailsModal}
        onClose={() => {
          setReportDetailsModal(false);
          setReportId('');
        }}
        title={
          <Text fw={600} fz={23}>
            Report Details
          </Text>
        }
        padding="xl"
        size={650}
        position="right"
      >
        <ReportDetails modalOpen={reportDetailsModal} reportId={reportId} />
      </Drawer>

      <Group position="apart" align="center" mb={25}>
        <div className="p-title">Reports</div>

        <Button
          color="dark"
          onClick={() => {
            navigate('/reports/schedule');
          }}
          disabled={!reportWrite}
        >
          Schedule
        </Button>
      </Group>

      {reports.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table
              mt={5}
              verticalSpacing="md"
              sx={{ minWidth: 670 }}
              highlightOnHover
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {reports.data.map((report: ReportType, index: number) => (
                  <tr
                    key={index}
                    className="click"
                    onClick={() => {
                      setReportId(report.id);
                      setReportDetailsModal(true);
                    }}
                    id={report.id}
                  >
                    <td>
                      {report.fromDate}{' '}
                      <span className="arrow-right-text">→</span>{' '}
                      {report.toDate}
                    </td>

                    <td>
                      <div className="type-badge">
                        {report.reportType ===
                          ReportTypes.transaction_history &&
                          'Transaction history'}
                        {report.reportType === ReportTypes.balance_summary &&
                          'Balance summary'}
                      </div>
                    </td>

                    <td>
                      <Badge
                        color={
                          report.status === ReportStatusTypes.completed
                            ? 'green'
                            : 'gray'
                        }
                      >
                        {report.status}
                      </Badge>
                    </td>

                    <td className="gray">
                      {formatDate2(report.createdAt, 'MMM D, YYYY h:mm a')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={reports.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {reports.meta.total === 0 && (
        <EmptyData
          title="There are no reports to display"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

export default Reports;
