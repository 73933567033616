import React from 'react';
import { Button } from '@mantine/core';
import Empty from '../../assets/png/empty-state.png';
import './empty-data.scss';

interface IEmptyData {
  title: string;
  desc: string;
  buttonText?: any;
  buttonAction?: (() => void) | null;
}

const EmptyData = ({ title, desc, buttonText, buttonAction }: IEmptyData) => {
  return (
    <div className="empty-content">
      <img height={185} width={221} src={Empty} alt="no content" />
      <div className="e-c-title">{title}</div>
      <div className="e-c-desc">{desc}</div>
      {buttonAction && (
        <Button color="dark" onClick={buttonAction} mt={32}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default EmptyData;
