import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  ScrollArea,
  Group,
  Button,
  LoadingOverlay,
} from '@mantine/core';
import useSubAccountsService from '../../services/subAccounts';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import {
  SubAccountType,
  GetSubAccountsResponse,
} from '../../types/subAccountsTypes';
import { CustomerAccountTypes } from '../../types/customersTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import { capitalizeTransform } from '../../lib/util';
import { setSubAccounts } from '../../lib/store/reducers/DataReducer';
import useNotification from '../../hooks/useNotification';
import usePermissions from '../../hooks/usePermissions';

const SubAccounts = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getSubAccounts } = useSubAccountsService();
  const subAccounts = useAppSelector((state) => state.data.subAccounts);
  const mode = useAppSelector(selectMode);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { subaccountRead } = usePermissions();

  useEffect(() => {
    if (subaccountRead) handleGetSubAccounts();
    //eslint-disable-next-line
  }, [page, size, mode]);

  const handleGetSubAccounts = () => {
    if (!subAccounts.dataFetched) setLoading(true);

    getSubAccounts(size, page)
      .then((res: GetSubAccountsResponse) => {
        dispatch(
          setSubAccounts({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Sub Accounts | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of bank accounts and sub ledgers"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <Group position="apart" align="center" mb={20}>
        <div className="p-title">Sub Accounts</div>

        <Button
          color="dark"
          onClick={() => {
            navigate(`/sub-accounts/create`);
          }}
        >
          Add Sub Account
        </Button>
      </Group>

      {subAccounts.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table
              mt={12}
              verticalSpacing="md"
              sx={{ minWidth: 600 }}
              highlightOnHover
            >
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Account Type</th>
                  <th>Date Created</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  subAccounts.data.map(
                    (account: SubAccountType, index: number) => (
                      <tr
                        key={index}
                        onClick={() => {
                          navigate(`/sub-account/${account.id}`);
                        }}
                        className="click"
                        id={account.id}
                      >
                        <td className="capitalize">
                          {account.customer.data.type ===
                            CustomerAccountTypes.business &&
                            account.customer.data.detail.businessName.toLowerCase()}

                          {account.customer.data.type ===
                            CustomerAccountTypes.individual &&
                            `${account.customer.data?.fullName?.firstName} ${account.customer.data?.fullName?.lastName}`.toLowerCase()}
                        </td>
                        <td>
                          <div className="type-badge">
                            {capitalizeTransform(account.accountType)}
                          </div>
                        </td>
                        <td className="gray">
                          {moment(account.createdAt).format('MMM Do, YYYY')}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={subAccounts.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {subAccounts.meta.total === 0 && (
        <EmptyData
          title="You have not created any sub account"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

export default SubAccounts;
