import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useForm } from '@mantine/form';
import { TextInput, Button } from '@mantine/core';
import AuthHeader, { HeaderTypes } from '../../components/Auth/AuthHeader';
import useAuthService from '../../services/auth';
import { useAppDispatch } from '../../hooks/useRedux';
import { setShowLoader } from '../../lib/store/reducers/UtilityReducer';
import useNotification from '../../hooks/useNotification';

const ForgotPassword = () => {
  const { forgotPassword } = useAuthService();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleError } = useNotification();

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const handleSubmit = (values: { email: string }) => {
    dispatch(setShowLoader(true));

    forgotPassword(values.email)
      .then(() => {
        navigate('/email-sent/?reset=true');
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        dispatch(setShowLoader(false));
      });
  };

  return (
    <div className="auth-page">
      <Helmet>
        <title>Forgot Password | Anchor</title>
        <meta
          name="description"
          content="Input your email below to initiate password reset"
        />
      </Helmet>

      <AuthHeader type={HeaderTypes.noAccount} />

      <div className="auth-main">
        <div className="a-m-title">Forgot Password?</div>
        <div className="a-m-desc">
          Input your email below to initiate password reset
        </div>

        <form
          className="a-form"
          onSubmit={form.onSubmit((values) => handleSubmit(values))}
        >
          <TextInput
            withAsterisk
            label="Email"
            placeholder="your@email.com"
            {...form.getInputProps('email')}
          />

          <Button color="dark" mt={20} fullWidth type="submit">
            Submit
          </Button>

          <div className="sec-action">
            Need help? <span className="click">Contact us</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
