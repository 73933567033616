import { ResponseMetaPagination } from '../lib/types';

export type CreateRoleData = {
  name: string;
  permissions: string[];
  description: string;
};

export type GetRolesResponse = {
  data: RoleType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type RolesState = {
  data: RoleType[];
  meta: ResponseMetaPagination;
};

export type RoleType = {
  createdAt: Date;
  createdBy: string;
  roleName: string;
  description: string;
  permissions: string[];
  systemRole: boolean;
  id: string;
};

export enum SystemRoles {
  PrimaryOwner = 'PrimaryOwner',
  Administrator = 'Administrator',
  Developer = 'Developer',
}
