import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  Table,
  ScrollArea,
  LoadingOverlay,
  Group,
  Button,
  Menu,
  Checkbox,
} from '@mantine/core';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import { setAudits } from '../../lib/store/reducers/DataReducer';
import useNotification from '../../hooks/useNotification';
import useAuditsService from '../../services/audits';
import {
  AuditType,
  ColumnItem,
  GetAuditsParams,
  GetAuditsResponse,
} from '../../types/auditsTypes';
import { formatDate2, truncateString } from '../../lib/util';
import { ReactComponent as GridIcon } from '../../assets/svg/grid.svg';
import AuditDetails from './AuditDetails';
import AuditLogsFilter from './AuditLogsFilter';

export const initialFilterParams: GetAuditsParams = {
  actor: '',
  eventName: '',
  resourceId: '',
  username: '',
  resourceType: '',
  fromDate: '',
  toDate: '',
  ipAddress: '',
};

const AuditLogs = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const page = queryParams.get('page');
  const [size, setSize] = useState<number>(20);
  const { getAudits } = useAuditsService();
  const audits = useAppSelector((state) => state.data.audits);
  const mode = useAppSelector(selectMode);
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'activity',
    'event_type',
    'source',
    'ip_address',
    // 'location',
    'timestamp',
  ]);
  const [auditId, setAuditId] = useState<string>('');
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [filterParams, setFilterParams] =
    useState<GetAuditsParams>(initialFilterParams);

  useEffect(() => {
    handleGetAudits();
    //eslint-disable-next-line
  }, [filterParams, page, size, mode]);

  const handleGetAudits = () => {
    if (!audits.dataFetched) setLoading(true);

    getAudits({
      ...filterParams,

      page: page ? Number(page) - 1 : 0,
      size,
    })
      .then((res: GetAuditsResponse) => {
        dispatch(
          setAudits({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const data: ColumnItem[] = [
    {
      name: 'Activity',
      id: 'activity',
      cell: (audit: AuditType) => <Fragment>{audit.eventName}</Fragment>,
      extraClass: 'gray',
    },
    {
      name: 'Event type',
      id: 'event_type',
      cell: (audit: AuditType) => <Fragment>{audit.eventType}</Fragment>,
    },
    {
      name: 'Source',
      id: 'source',
      cell: (audit: AuditType) => <Fragment>{audit.user}</Fragment>,
    },
    {
      name: 'IP',
      id: 'ip_address',
      cell: (audit: AuditType) => <Fragment>{audit.ipAddress}</Fragment>,
    },
    // {
    //   name: 'Location',
    //   id: 'location',
    //   cell: (audit: AuditType) => <Fragment>{audit.location}</Fragment>,
    // },
    {
      name: 'User Agent',
      id: 'user_agent',
      cell: (audit: AuditType) => (
        <Fragment>{truncateString(audit.userAgent, 35)}</Fragment>
      ),
    },
    {
      name: 'Timestamp',
      id: 'timestamp',
      cell: (audit: AuditType) => (
        <Fragment>
          {formatDate2(audit.createdAt, 'DD-MMM-YY hh:mm:ss A')}
        </Fragment>
      ),
    },
    {
      name: 'Actor',
      id: 'actor',
      cell: (audit: AuditType) => (
        <span className="capitalize">{audit.actor}</span>
      ),
    },
    {
      name: 'Resource Type',
      id: 'resource_type',
      cell: (audit: AuditType) => (
        <Fragment>{audit.resource?.data?.type}</Fragment>
      ),
    },
    {
      name: 'Resource ID',
      id: 'resource_id',
      cell: (audit: AuditType) => (
        <Fragment>{audit.resource?.data?.id}</Fragment>
      ),
    },
  ];

  const handleFilterChange = (params: GetAuditsParams) => {
    if (JSON.stringify(filterParams) !== JSON.stringify(params)) {
      setLoading(true);
      navigate(`${location.pathname}?page=1`);
      setFilterParams(params);
    }
  };

  return (
    <>
      <AuditDetails
        auditId={auditId}
        drawerOpen={detailsDrawer}
        closeDrawer={() => setDetailsDrawer(false)}
      />

      <div className="table-container">
        <Helmet>
          <title>Audit Logs | Anchor</title>
          <meta
            name="description"
            content="View the log of all activities in your organization"
          />
        </Helmet>

        <LoadingOverlay visible={loading} />

        <div className="p-title extra-mb">Audit Logs</div>

        <Group position="right" mb={20}>
          <AuditLogsFilter
            filterParams={filterParams}
            handleFilterChange={handleFilterChange}
          />

          <ColumnsSelector
            data={data}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Group>

        {audits.dataFetched && (
          <React.Fragment>
            <ScrollArea
              offsetScrollbars
              type="always"
              className="table-scroll-container"
            >
              <Table
                mt={5}
                verticalSpacing="md"
                sx={{
                  minWidth:
                    audits.data.length === 0
                      ? 500
                      : selectedColumns.length * 170,
                }}
                highlightOnHover
              >
                <thead>
                  <tr>
                    {data
                      .filter((item: ColumnItem) =>
                        selectedColumns.includes(item.id)
                      )
                      .map((item: ColumnItem, index: number) => (
                        <th key={index}>{item.name}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    audits.data.map((audit: AuditType, index: number) => (
                      <tr
                        key={index}
                        id={audit.id}
                        className="click"
                        onClick={() => {
                          setAuditId(audit.id);
                          setDetailsDrawer(true);
                        }}
                      >
                        {data
                          .filter((item: ColumnItem) =>
                            selectedColumns.includes(item.id)
                          )
                          .map((item: ColumnItem, index: number) => (
                            <td
                              key={index}
                              className={item.extraClass ? item.extraClass : ''}
                            >
                              {item.cell(audit)}
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </Table>

              {audits.meta.total === 0 && (
                <EmptyData
                  title="There are no events to display"
                  desc="At the moment, there is no data to be displayed."
                />
              )}
            </ScrollArea>

            {!loading && (
              <PaginationControls
                meta={audits.meta}
                setPage={(pageValue) => {
                  navigate(`${location.pathname}?page=${pageValue + 1}`);
                }}
                setSize={setSize}
                setLoader={setLoading}
              />
            )}
          </React.Fragment>
        )}

        {audits.meta.total === 0 && !audits.dataFetched && (
          <EmptyData
            title="There are no events to display"
            desc="At the moment, there is no data to be displayed."
          />
        )}
      </div>
    </>
  );
};

interface ColumnsSelectorProps {
  data: ColumnItem[];
  selectedColumns: string[];
  setSelectedColumns: React.Dispatch<React.SetStateAction<string[]>>;
}

const ColumnsSelector = ({
  data,
  selectedColumns,
  setSelectedColumns,
}: ColumnsSelectorProps) => {
  const [opened, setOpened] = useState(false);

  const handleSelect = (id: string) => {
    if (selectedColumns.includes(id)) {
      setSelectedColumns(selectedColumns.filter((item) => item !== id));
    } else {
      setSelectedColumns([...selectedColumns, id]);
    }
  };

  return (
    <div className="columns-selector">
      <Menu
        shadow="md"
        width={160}
        position="bottom-end"
        opened={opened}
        onChange={setOpened}
      >
        <Menu.Target>
          <Button
            variant="default"
            leftIcon={<GridIcon />}
            className="btn-light t2"
          >
            Columns
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {data.map((item: ColumnItem, index: number) => (
            <div
              key={index}
              className={`menu-item ${
                selectedColumns.includes(item.id) ? 'active' : ''
              }`}
            >
              <Checkbox
                checked={selectedColumns.includes(item.id)}
                onChange={() => handleSelect(item.id)}
                label={item.name}
                size="xs"
              />
            </div>
          ))}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default AuditLogs;
