import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import {
  Button,
  Group,
  Popover,
  Text,
  CloseButton,
  Divider,
  Menu,
  ScrollArea,
  Input,
  Stack,
  ActionIcon,
} from '@mantine/core';
import { AxiosError } from 'axios';
import moment from 'moment';
import { ReactComponent as FilterIcon } from '../../assets/svg/filter.svg';
import useUserManagementService from '../../services/userManagement';
import {
  GetUsersResponse,
  InvitationStatusTypes,
  UserType,
} from '../../types/userManagementTypes';
import useNotification from '../../hooks/useNotification';
import { ReactComponent as SearchIcon } from '../../assets/svg/magnifying-glass.svg';
import { ReactComponent as ChevronDownGray } from '../../assets/svg/chevron-down-gray.svg';
import { ReactComponent as CircleRemove } from '../../assets/svg/x-mark-circle.svg';
import { AuditEventType, GetAuditsParams } from '../../types/auditsTypes';
import { initialFilterParams } from './AuditLogs';
import { ResourceTypes } from '../../types/resourceTypes';
import useAuditsService from '../../services/audits';
import { removeDuplicatesFromArray } from '../../lib/util';
import usePermissions from '../../hooks/usePermissions';

interface AuditLogsFilterProps {
  filterParams: GetAuditsParams;
  handleFilterChange: (params: GetAuditsParams) => void;
}

type FilterOption = {
  label: string;
  value: string;
  setValue: (value: string) => void;
  creatable: boolean;
  allowSearch: boolean;
  type: 'select' | 'input';
  children: {
    label: string;
    value: string;
  }[];
};

const AuditLogsFilter = ({
  filterParams,
  handleFilterChange,
}: AuditLogsFilterProps) => {
  const [filterNumber, setFilterNumber] = useState<number>(0);
  const [opened, setOpened] = useState(false);
  const [users, setUsers] = useState<UserType[]>([]);
  const { getUsers } = useUserManagementService();
  const { handleError } = useNotification();
  const [eventTypes, setEventTypes] = useState<AuditEventType[]>([]);
  const [resourceTypes, setResourceTypes] = useState<string[]>([]);
  const { getAuditEventTypes, getAuditResourceTypes } = useAuditsService();
  const [dateFilterOption, setDateFilterOption] = useState<
    '1' | '7' | '30' | ''
  >('');
  const { userRead, eventRead } = usePermissions();

  useEffect(() => {
    if (userRead) handleGetUsers();
    if (eventRead) handleGetEventTypes();
    handleGetResourceTypes();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const actorFilter = filterParams.actor ? 1 : 0;
    const eventNameFilter = filterParams.eventName ? 1 : 0;
    const userFilter = filterParams.username ? 1 : 0;
    const resourceTypeFilter = filterParams.resourceType ? 1 : 0;
    const resourceIdFilter = filterParams.resourceId ? 1 : 0;
    const dateFilter = dateFilterOption ? 1 : 0;

    const filterNumber =
      actorFilter +
      eventNameFilter +
      userFilter +
      resourceTypeFilter +
      resourceIdFilter +
      dateFilter;
    setFilterNumber(filterNumber);
  }, [
    filterParams.resourceId,
    filterParams.username,
    filterParams.actor,
    filterParams.eventName,
    filterParams.resourceType,
    dateFilterOption,
  ]);

  useEffect(() => {
    handleDaysChange();
    //eslint-disable-next-line
  }, [dateFilterOption]);

  const handleGetUsers = () => {
    getUsers({ page: 0, size: 50 })
      .then((res: GetUsersResponse) => {
        setUsers(res.data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleGetEventTypes = () => {
    getAuditEventTypes()
      .then((res: AuditEventType[]) => {
        setEventTypes(res);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const handleGetResourceTypes = () => {
    getAuditResourceTypes()
      .then((res: string[]) => {
        setResourceTypes(removeDuplicatesFromArray(res));
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const filterOptions: FilterOption[] = [
    {
      label: 'Actor',
      value: filterParams.actor as string,
      setValue: (value: string) =>
        handleFilterChange({ ...filterParams, actor: value }),
      creatable: false,
      allowSearch: false,
      type: 'select',
      children: [
        {
          label: 'System',
          value: 'system',
        },
        {
          label: 'API',
          value: 'API',
        },
        {
          label: 'Dashboard',
          value: 'dashboard',
        },
      ],
    },
    {
      label: 'Event Name',
      value: filterParams.eventName as string,
      setValue: (value: string) =>
        handleFilterChange({ ...filterParams, eventName: value }),
      creatable: false,
      allowSearch: true,
      type: 'select',
      children: eventTypes.map((eventType) => ({
        label: eventType.eventName,
        value: eventType.eventName,
      })),
    },
    {
      label: 'Date',
      value: dateFilterOption,
      setValue: (value: string) =>
        setDateFilterOption(value as '1' | '7' | '30' | ''),
      creatable: false,
      allowSearch: false,
      type: 'select',
      children: [
        {
          label: 'Last 24 hours',
          value: '1',
        },
        {
          label: 'Last 7 days',
          value: '7',
        },
        {
          label: 'Last 30 days',
          value: '30',
        },
      ],
    },
    {
      label: 'Resource type',
      value: filterParams.resourceType as string,
      setValue: (value: string) =>
        handleFilterChange({
          ...filterParams,
          resourceType: value as ResourceTypes,
        }),
      creatable: false,
      allowSearch: true,
      type: 'select',
      children: resourceTypes.map((resourceType) => ({
        label: resourceType,
        value: resourceType,
      })),
    },
    {
      label: 'Resource ID',
      value: filterParams.resourceId as string,
      setValue: (value: string) =>
        handleFilterChange({ ...filterParams, resourceId: value }),
      creatable: false,
      allowSearch: true,
      type: 'input',
      children: [],
    },
    {
      label: 'User',
      value: filterParams.username as string,
      setValue: (value: string) =>
        handleFilterChange({ ...filterParams, username: value }),
      creatable: false,
      allowSearch: true,
      type: 'select',
      children: users
        .filter(
          (user) => user.invitationStatus === InvitationStatusTypes.accepted
        )
        .map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: `${user.firstName} ${user.lastName}`,
        })),
    },
  ];

  const resetFilters = () => {
    handleFilterChange(initialFilterParams);
    setOpened(false);
  };

  const getLabel = (
    value: string,
    children: { label: string; value: string }[]
  ) => {
    const child = children.find((child) => child.value === value);

    if (child) {
      return child.label.length > 20
        ? `${child.label.slice(0, 20)}...`
        : child.label;
    } else {
      return '';
    }
  };

  const handleDaysChange = () => {
    if (dateFilterOption === '') {
      return handleFilterChange({
        ...filterParams,
        fromDate: '',
        toDate: '',
      });
    }

    const toDate = new Date();
    const fromDate = new Date(
      new Date().setDate(new Date().getDate() - Number(dateFilterOption))
    );

    handleFilterChange({
      ...filterParams,
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
      toDate: moment(toDate).format('YYYY-MM-DD'),
    });
  };

  return (
    <div>
      <Popover
        opened={opened}
        onChange={setOpened}
        width={400}
        position="bottom-end"
      >
        <Popover.Target>
          <Button
            variant="default"
            leftIcon={<FilterIcon />}
            className={`btn-light ${opened || filterNumber > 0 ? 'sd' : ''}`}
            onClick={() => setOpened((o) => !o)}
          >
            Filters{' '}
            {filterNumber > 0 && (
              <>
                <div className="divider" />
                <span className="btn-tag">+{filterNumber}</span>
              </>
            )}
          </Button>
        </Popover.Target>

        <Popover.Dropdown>
          <div className="filter-dropdown">
            <Group position="apart" mb={24}>
              <Text fw={500}>Filters</Text>

              <CloseButton onClick={() => setOpened(false)} />
            </Group>

            <Menu shadow="md" width={137} offset={24}>
              <Menu.Target>
                <Button
                  variant="default"
                  rightIcon={<Text size="lg">+</Text>}
                  className="btn-light"
                >
                  Add filter group
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                {filterOptions.map((option) => (
                  <FilterMenuItem key={option.label} option={option} />
                ))}
              </Menu.Dropdown>
            </Menu>

            <Divider mt={14} mb={24} />

            <Stack spacing={10}>
              {filterOptions
                .filter((option) => option.value.length > 0)
                .map((option) => (
                  <Group key={option.label} position="apart">
                    <Text size="sm" color="#889096" fw={500}>
                      {option.label}
                    </Text>

                    <Group align="center" spacing={8}>
                      <Menu shadow="md" width={200} position="bottom-end">
                        <Menu.Target>
                          <Button
                            variant="default"
                            className="btn-light no-pr"
                            rightIcon={<ChevronDownGray />}
                          >
                            {option.type === 'select'
                              ? getLabel(option.value, option.children)
                              : option.value}
                          </Button>
                        </Menu.Target>

                        <Menu.Dropdown>
                          <MenuDropdownChild option={option} />
                        </Menu.Dropdown>
                      </Menu>

                      <ActionIcon onClick={() => option.setValue('')}>
                        <CircleRemove />
                      </ActionIcon>
                    </Group>
                  </Group>
                ))}
            </Stack>

            <div className="f-footer">
              <Button size="xs" variant="default" onClick={resetFilters}>
                Reset
              </Button>
            </div>
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

interface FilterMenuItemProps {
  option: FilterOption;
}

const FilterMenuItem = ({ option }: FilterMenuItemProps) => {
  const { label, value } = option;
  const [opened, setOpened] = useState(false);

  return (
    <Menu
      shadow="md"
      width={200}
      position="right"
      opened={opened}
      onChange={setOpened}
      offset={20}
    >
      <Menu.Target>
        <div className={`menu-item click ${opened || value ? 'active' : ''}`}>
          {label}
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        <MenuDropdownChild option={option} />
      </Menu.Dropdown>
    </Menu>
  );
};

interface MenuDropdownChildProps {
  option: FilterOption;
}

const MenuDropdownChild = ({ option }: MenuDropdownChildProps) => {
  const { children, value, setValue, allowSearch, type } = option;
  const [input, setInput] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  }, []);

  return (
    <ScrollArea type="auto">
      <div className="filter-children-container">
        {allowSearch && (
          <Input
            icon={<SearchIcon />}
            placeholder={type === 'select' ? 'Search...' : 'Input value...'}
            variant="unstyled"
            className="menu-item active"
            value={input}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setInput(e.target.value)
            }
            size="xs"
            ref={inputRef}
          />
        )}

        {type === 'input' && (
          <Group mt={10} position="right">
            <Button
              compact
              color="cyan"
              onClick={() => {
                setValue(input);
              }}
              size="xs"
            >
              Apply
            </Button>
          </Group>
        )}

        {type === 'select' &&
          children
            .filter((child) =>
              child.label.toLowerCase().includes(input.toLowerCase())
            )
            .map((child) => (
              <Menu.Item
                key={child.label}
                className={`menu-item click ${
                  value === child.value ? 'active' : ''
                }`}
                onClick={() => {
                  setValue(child.value);
                }}
              >
                {child.label}
              </Menu.Item>
            ))}
      </div>
    </ScrollArea>
  );
};

export default AuditLogsFilter;
