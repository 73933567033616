import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetSettlementsResponse,
  GetSettlementsParams,
} from '../types/settlementsTypes';

const useSettlementsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);

  const getSettlements = (params: GetSettlementsParams) => {
    return new Promise<GetSettlementsResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.SETTLEMENTS.SETTLEMENTS}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: '',
              ...(params?.organizationId && {
                organizationId: params?.organizationId,
              }),
              ...(params?.paymentId && {
                paymentId: params?.paymentId,
              }),
              ...(params?.status && {
                status: params?.status,
              }),
            },
          }
        )
        .then((res: GetSettlementsResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getSettlements,
  };
};

export default useSettlementsService;
