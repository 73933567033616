import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  CreateBusinessCustomerData,
  BusinessCustomerType,
  OfficerType,
  AddOfficerData,
} from '../types/businessTypes';

const useBusinessService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, mode);

  const createBusinessCustomer = (body: CreateBusinessCustomerData) => {
    return new Promise<BusinessCustomerType>((resolve, reject) => {
      authApi
        .post(APIS.BUSINESSES.BUSINESSES, body)
        .then((res: { data: BusinessCustomerType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const updateBusinessCustomer = (
    businessId: string,
    data: CreateBusinessCustomerData
  ) => {
    return new Promise<BusinessCustomerType>((resolve, reject) => {
      const body = {
        data: {
          type: 'BusinessCustomerV2',
          attributes: data,
        },
      };

      axiosApi
        .patch(APIS.BUSINESSES.BUSINESS(businessId), body)
        .then((res: { data: BusinessCustomerType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getBusinessCustomer = (businessId: string) => {
    return new Promise<BusinessCustomerType>((resolve, reject) => {
      authApi
        .get(APIS.BUSINESSES.BUSINESS(businessId))
        .then((res: { data: BusinessCustomerType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getBusinessOfficers = (businessId: string) => {
    return new Promise<OfficerType[]>((resolve, reject) => {
      authApi
        .get(APIS.BUSINESSES.BUSINESS_OFFICERS(businessId))
        .then((res: { data: OfficerType[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const addBusinessOfficer = (businessId: string, data: AddOfficerData) => {
    return new Promise<OfficerType>((resolve, reject) => {
      authApi
        .post(APIS.BUSINESSES.BUSINESS_OFFICERS(businessId), data)
        .then((res: { data: OfficerType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const updateBusinessOfficer = (
    businessId: string,
    officerId: string,
    officer: AddOfficerData
  ) => {
    const body = {
      data: {
        type: 'BusinessOfficer',
        attributes: officer,
      },
    };

    return new Promise<OfficerType>((resolve, reject) => {
      axiosApi
        .patch(APIS.BUSINESSES.BUSINESS_OFFICER(businessId, officerId), body)
        .then((res: { data: OfficerType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const deleteBusinessOfficer = (businessId: string, officerId: string) => {
    return new Promise<OfficerType>((resolve, reject) => {
      axiosApi
        .delete(APIS.BUSINESSES.BUSINESS_OFFICER(businessId, officerId))
        .then((res: { data: OfficerType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    createBusinessCustomer,
    getBusinessCustomer,
    getBusinessOfficers,
    addBusinessOfficer,
    updateBusinessOfficer,
    deleteBusinessOfficer,
    updateBusinessCustomer,
  };
};

export default useBusinessService;
