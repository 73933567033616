import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import {
  Modal,
  Text,
  Button,
  Select,
  TextInput,
  LoadingOverlay,
  Tabs,
  Group,
  ScrollArea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Prism } from '@mantine/prism';
import useVirtualNubansService from '../../../services/virtualNubans';
import useSandboxService from '../../../services/sandbox';
import CustomSelectItem, {
  ItemSchema,
} from '../../CustomSelectItem/CustomSelectItem';
import {
  handleCustomSelectCreate,
  capitalizeTransform,
} from '../../../lib/util';
import { PaymentType } from '../../../types/paymentsTypes';
import { apiNameSpace, sandBoxBaseUrl } from '../../../lib/constants';
import { APIS } from '../../../lib/api/apilog';
import useNotification from '../../../hooks/useNotification';
import { GetVirtualNubansResponse } from '../../../types/virtualNubansTypes';

interface ISimulateTransfer {
  modalOpen: boolean;
  closeModal: () => void;
}

interface IRequestBody {
  URL: string;
  method: string;
  headers: {
    'X-anchor-key': string;
    'Content-Type': string;
    Accept: string;
  };
  payload: {
    data: {
      type: string;
      attributes: {
        transactionAmount: number;
        sourceAccount: {
          accountName: string;
          accountNumber: string;
        };
      };
      relationships: {
        destinationAccount: {
          data: {
            id: string;
            type: string;
          };
        };
      };
    };
  };
}

interface SuccessResponse {
  data: PaymentType;
}
interface ErrorResponse {
  errors: {
    title: string;
    status: string;
    detail: string;
  }[];
}
type IResponseBody = SuccessResponse | ErrorResponse;

const SimulateTransfer = ({ modalOpen, closeModal }: ISimulateTransfer) => {
  const [apiKey, setApiKey] = useState<string>('');
  const { getVirtualNubans } = useVirtualNubansService();
  const { simulateTransfer } = useSandboxService(apiKey);
  const [loading, setLoading] = useState<boolean>(false);
  const [virtualNubans, setVirtualNubans] = useState<ItemSchema[]>([]);
  const [activeTab, setActiveTab] = useState<'request' | 'response' | null>(
    'request'
  );
  const [requestBody, setRequestBody] = useState<IRequestBody | null>(null);
  const [responseBody, setResponseBody] = useState<IResponseBody | null>(null);
  const { handleError } = useNotification();

  const form = useForm({
    initialValues: {
      transactionAmount: '',
      sourceAccount: {
        accountName: '',
        accountNumber: '',
      },
      virtualNubanId: '',
    },

    validate: {
      virtualNubanId: (value) => (!value ? 'Select Virtual Nuban' : null),
    },
  });

  useEffect(() => {
    handleGetVirtualNubans();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateRequestBody();
    //eslint-disable-next-line
  }, [form.values, apiKey]);

  const updateRequestBody = () => {
    const values = form.values;

    const body: IRequestBody = {
      URL: `${sandBoxBaseUrl}${apiNameSpace}/${APIS.SANDBOX.TRANSACTION_NOTIFICATION}`,
      method: 'POST',
      headers: {
        'X-anchor-key': apiKey,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      payload: {
        data: {
          type: 'Notification',
          attributes: {
            transactionAmount: parseInt(`${values.transactionAmount}00`),
            sourceAccount: {
              accountName: values.sourceAccount.accountName,
              accountNumber: values.sourceAccount.accountNumber,
            },
          },
          relationships: {
            destinationAccount: {
              data: {
                id: values.virtualNubanId,
                type: 'VirtualNuban',
              },
            },
          },
        },
      },
    };

    setRequestBody(body);
  };

  const handleGetVirtualNubans = () => {
    setLoading(true);

    getVirtualNubans({ page: 0, size: 50 })
      .then((res: GetVirtualNubansResponse) => {
        const data: ItemSchema[] = res.data.map((virtualNuban) => ({
          extravalue: virtualNuban.accountNumber,
          label: capitalizeTransform(virtualNuban.accountName),
          description: `${capitalizeTransform(virtualNuban.bank.name)} - ${
            virtualNuban.accountNumber
          }`,
          value: virtualNuban.id,
        }));

        setVirtualNubans(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (values: typeof form.values) => {
    setLoading(true);

    simulateTransfer({
      ...values,
      transactionAmount: parseInt(`${values.transactionAmount}00`),
    })
      .then((res: { data: PaymentType }) => {
        setLoading(false);
        setResponseBody(res);
        form.reset();
      })
      .catch((err: IResponseBody) => {
        setLoading(false);
        setResponseBody(err);
      })
      .finally(() => {
        setActiveTab('response');
      });
  };

  return (
    <Modal
      opened={modalOpen}
      onClose={closeModal}
      title={
        <Text fz={18} fw={600}>
          Simulate Transfer
        </Text>
      }
      size={650}
      overflow="inside"
    >
      <div>
        <LoadingOverlay visible={loading} />

        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <TextInput
            label="Sandbox API key"
            placeholder="Enter API key"
            required
            value={apiKey}
            onChange={(event) => setApiKey(event.currentTarget.value)}
            autoComplete="off"
          />

          <Group grow mt="sm">
            <TextInput
              label="Source Account Name"
              placeholder="Source account name"
              required
              {...form.getInputProps('sourceAccount.accountName')}
              autoComplete="off"
            />

            <TextInput
              required
              label="Source Account Number"
              placeholder="Source account number"
              type="number"
              value={form.values.sourceAccount.accountNumber}
              onKeyDown={(e) =>
                ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > 10) {
                  return;
                }
                if (
                  e.target.value === '' ||
                  /^[0-9\b]+$/.test(e.target.value)
                ) {
                  form.setFieldValue(
                    'sourceAccount.accountNumber',
                    e.target.value
                  );
                }
              }}
              autoComplete="off"
            />
          </Group>

          <Group grow mt="sm" align="flex-start">
            <TextInput
              required
              placeholder="Amount"
              label="Amount"
              type="number"
              icon={'₦'}
              value={form.values.transactionAmount}
              onKeyDown={(e) =>
                ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
              }
              onChange={(e: any) => {
                if (
                  e.target.value === '' ||
                  /^[0-9\b]+$/.test(e.target.value)
                ) {
                  form.setFieldValue('transactionAmount', e.target.value);
                }
              }}
              autoComplete="off"
            />

            <Select
              label="Virtual Nuban"
              placeholder="Source virtual nuban/ input id"
              searchable
              required
              itemComponent={CustomSelectItem}
              data={virtualNubans}
              maxDropdownHeight={400}
              creatable
              getCreateLabel={(query) => `Select ${query}`}
              onCreate={(query) => {
                return handleCustomSelectCreate(
                  query,
                  virtualNubans,
                  () => form.setFieldValue('virtualNubanId', query),
                  setVirtualNubans
                );
              }}
              {...form.getInputProps('virtualNubanId')}
            />
          </Group>

          <RequestDetails
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            requestBody={requestBody}
            responseBody={responseBody}
          />

          <Button color="dark" mt={20} type="submit" disabled={!apiKey}>
            Send Request
          </Button>
        </form>
      </div>
    </Modal>
  );
};

interface IRequestDetails {
  activeTab: 'request' | 'response' | null;
  setActiveTab: (value: 'request' | 'response' | null) => void;
  requestBody: IRequestBody | null;
  responseBody: IResponseBody | null;
}

const RequestDetails = ({
  activeTab,
  setActiveTab,
  requestBody,
  responseBody,
}: IRequestDetails) => {
  return (
    <Tabs mt={30} value={activeTab} onTabChange={setActiveTab}>
      <Tabs.List>
        <Tabs.Tab value="request">Request</Tabs.Tab>
        <Tabs.Tab value="response">Response</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="request">
        <ScrollArea style={{ height: '35vh' }} type="always">
          <Prism language="json" withLineNumbers trim>
            {requestBody ? JSON.stringify(requestBody, null, 2) : ''}
          </Prism>
        </ScrollArea>
      </Tabs.Panel>

      <Tabs.Panel value="response">
        <ScrollArea style={{ height: '35vh' }} type="always">
          <Prism language="json" withLineNumbers trim>
            {responseBody ? JSON.stringify(responseBody, null, 2) : ''}
          </Prism>
        </ScrollArea>
      </Tabs.Panel>
    </Tabs>
  );
};

export default SimulateTransfer;
