import React, { useState, useEffect, Fragment } from 'react';
import { AxiosError } from 'axios';
import {
  Button,
  Group,
  TextInput,
  Select,
  Textarea,
  Checkbox,
  CloseButton,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePicker } from '@mantine/dates';
import { useAppDispatch } from '../../../hooks/useRedux';
import useOrganizationService from '../../../services/organization';
import { OrganizationCompliance } from '../../../types/organizationTypes';
import { setShowLoader } from '../../../lib/store/reducers/UtilityReducer';
import {
  formatDate2,
  statesNG,
  capitalizeTransform,
  industries,
} from '../../../lib/util';
import useNotification from '../../../hooks/useNotification';

interface IBusinessDetails {
  nextStep: () => void;
  setActiveView: React.Dispatch<React.SetStateAction<'overview' | 'edit' | ''>>;
  orgCompliance: OrganizationCompliance | null;
  getComplianceData: () => void;
}

const BusinessDetails = ({
  nextStep,
  setActiveView,
  orgCompliance,
  getComplianceData,
}: IBusinessDetails) => {
  const dispatch = useAppDispatch();
  const [sameAddressChecked, setSameAddressChecked] = useState<boolean>(false);
  const { activateOrganization } = useOrganizationService();
  const { handleError } = useNotification();

  useEffect(() => {
    if (
      orgCompliance &&
      orgCompliance.complianceStatus.basicDetailSubmitted &&
      orgCompliance.complianceStatus.basicDetail
    ) {
      const {
        address,
        complianceStatus: { basicDetail, organizationContactDetails },
      } = orgCompliance;

      form.setValues({
        ...basicDetail,
        ...organizationContactDetails,
        dateOfRegistration: new Date(basicDetail.dateOfRegistration),
        address,
      });

      if (
        address &&
        JSON.stringify(address.main) === JSON.stringify(address.registered)
      ) {
        setSameAddressChecked(true);
      }
    }
    //eslint-disable-next-line
  }, []);

  const form = useForm({
    initialValues: {
      businessName: '',
      industry: '',
      registrationType: '',
      dateOfRegistration: new Date(),
      country: '',
      description: '',
      website: '',
      phoneNumber: '',
      email: {
        general: '',
        support: '',
        dispute: '',
      },
      address: {
        registered: {
          addressLine_1: '',
          addressLine_2: '',
          postalCode: '',
          city: '',
          state: '',
          country: '',
        },
        main: {
          addressLine_1: '',
          addressLine_2: '',
          postalCode: '',
          city: '',
          state: '',
          country: '',
        },
      },
    },

    validate: {
      industry: (value) => (value === '' ? 'Select industry' : null),
      registrationType: (value) =>
        value === '' ? 'Select registration type' : null,
      country: (value) => (value === '' ? 'Select country' : null),
      dateOfRegistration: (value) =>
        !value ? 'Select date of registration' : null,
      address: {
        registered: {
          country: (value) => (value === '' ? 'Select country' : null),
        },
      },
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    const data = values;

    if (sameAddressChecked) {
      data.address.main = values.address.registered;
    }

    dispatch(setShowLoader(true));
    activateOrganization({
      ...data,
      dateOfRegistration: formatDate2(data.dateOfRegistration, 'YYYY-MM-DD'),
    })
      .then(() => {
        getComplianceData();
        nextStep();
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        dispatch(setShowLoader(false));
      });
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <div className="c-step">
        <Group position="apart">
          <div className="s-title">Business Details</div>

          {orgCompliance?.complianceStatus.basicDetailSubmitted && (
            <CloseButton
              onClick={() => {
                setActiveView('overview');
              }}
            />
          )}
        </Group>

        <div className="s-desc">
          Kindly provide the information below about your organization
        </div>

        <TextInput
          required
          mt={24}
          label="Business Name"
          placeholder="Business Name"
          {...form.getInputProps('businessName')}
        />

        <Select
          required
          label="Industry"
          placeholder="Select Industry"
          mt="sm"
          data={industries.map((industry) => ({
            value: industry,
            label: industry
              .replace(/([A-Z])/g, ' $1')
              .replace(/-/g, ' -')
              .replace(/_/g, ' ')
              .trim(),
          }))}
          searchable
          {...form.getInputProps('industry')}
        />

        <Select
          required
          label="Registration Type"
          placeholder="Select registration type"
          mt="sm"
          data={[
            {
              value: 'Private_Incorporated',
              label: 'Private Incorporated',
            },
            {
              value: 'Incorporated_Trustees',
              label: 'Incorporated Trustees',
            },
            { value: 'Business_Name', label: 'Business Name' },
            { value: 'Free_Zone', label: 'Free Zone' },
            { value: 'Gov', label: 'Gov' },
            {
              value: 'Private_Incorporated_Gov',
              label: 'Private Incorporated Gov',
            },
            { value: 'Cooperative_Society', label: 'Cooperative Society' },
            { value: 'Public_Incorporated', label: 'Public Incorporated' },
          ]}
          {...form.getInputProps('registrationType')}
        />

        <DatePicker
          required
          label="Date Of Registration"
          placeholder="Select date of registration"
          withAsterisk
          mt="sm"
          inputFormat="DD-MM-YYYY"
          allowFreeInput
          initialLevel="year"
          {...form.getInputProps('dateOfRegistration')}
        />

        <Select
          required
          label="Country"
          placeholder="Select Country"
          mt="sm"
          data={[{ value: 'NG', label: 'Nigeria' }]}
          {...form.getInputProps('country')}
        />

        <Textarea
          label="Business Description"
          placeholder="Business Description"
          autosize
          required
          minRows={2}
          maxRows={4}
          mt="sm"
          {...form.getInputProps('description')}
        />

        <TextInput
          mt={40}
          required
          label="Phone Number"
          placeholder="Phone number"
          {...form.getInputProps('phoneNumber')}
        />

        <TextInput
          mt="sm"
          label="Website (optional)"
          placeholder="Website"
          {...form.getInputProps('website')}
        />

        <TextInput
          mt="sm"
          label="Business Email (optional)"
          placeholder="Business email"
          {...form.getInputProps('email.general')}
        />

        <TextInput
          mt="sm"
          label="Support Email (optional)"
          placeholder="Support email"
          {...form.getInputProps('email.support')}
        />

        <TextInput
          mt="sm"
          label="Dispute Email (optional)"
          placeholder="Dispute email"
          {...form.getInputProps('email.dispute')}
        />

        <TextInput
          required
          mt={40}
          label="Registered Address"
          placeholder="Address line 1"
          {...form.getInputProps('address.registered.addressLine_1')}
        />

        <TextInput
          required
          mt="sm"
          label="Address Line 2"
          placeholder="Address line 2"
          {...form.getInputProps('address.registered.addressLine_2')}
        />

        <TextInput
          required
          mt="sm"
          label="Postal Code"
          placeholder="Postal code"
          {...form.getInputProps('address.registered.postalCode')}
        />

        <TextInput
          required
          mt="sm"
          label="City"
          placeholder="City"
          {...form.getInputProps('address.registered.city')}
        />

        <Select
          required
          label="State"
          placeholder="Select state"
          data={statesNG.sort().map((state: string) => ({
            label: capitalizeTransform(state),
            value: capitalizeTransform(state),
          }))}
          searchable
          mt="sm"
          {...form.getInputProps('address.registered.state')}
        />

        <Select
          required
          label="Country"
          placeholder="Select Country"
          mt="sm"
          data={[{ value: 'NG', label: 'Nigeria' }]}
          {...form.getInputProps('address.registered.country')}
        />

        <Group mt="sm" className="o-a-r" align="center">
          Office Address <span>*</span>
          (
          <Checkbox
            label="Same as Registered Address"
            checked={sameAddressChecked}
            onChange={(event) =>
              setSameAddressChecked(event.currentTarget.checked)
            }
          />
          )
        </Group>

        {!sameAddressChecked && (
          <Fragment>
            <TextInput
              required={sameAddressChecked ? false : true}
              mt={2}
              placeholder="Address line 1"
              {...form.getInputProps('address.main.addressLine_1')}
            />

            <TextInput
              required={sameAddressChecked ? false : true}
              mt="sm"
              label="Address Line 2"
              placeholder="Address line 2"
              {...form.getInputProps('address.main.addressLine_2')}
            />

            <TextInput
              required={sameAddressChecked ? false : true}
              mt="sm"
              label="Postal Code"
              placeholder="Postal code"
              {...form.getInputProps('address.main.postalCode')}
            />

            <div className="items-row">
              <TextInput
                required={sameAddressChecked ? false : true}
                mt="sm"
                label="City"
                placeholder="City"
                {...form.getInputProps('address.main.city')}
              />

              <Select
                required
                label="State"
                placeholder="Select state"
                data={statesNG.sort().map((state: string) => ({
                  label: capitalizeTransform(state),
                  value: capitalizeTransform(state),
                }))}
                searchable
                mt="sm"
                {...form.getInputProps('address.main.state')}
              />

              <Select
                required={sameAddressChecked ? false : true}
                label="Country"
                placeholder="Select Country"
                mt="sm"
                data={[{ value: 'NG', label: 'Nigeria' }]}
                {...form.getInputProps('address.main.country')}
              />
            </div>
          </Fragment>
        )}
      </div>

      <Button color="dark" fullWidth mt={20} type="submit">
        Save
      </Button>
    </form>
  );
};

export default BusinessDetails;
