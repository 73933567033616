import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetPaymentsParams,
  GetPaymentsResponse,
  PaymentType,
} from '../types/paymentsTypes';

const usePaymentsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);

  const getPayments = (params: GetPaymentsParams) => {
    return new Promise<GetPaymentsResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.PAYMENTS.PAYMENTS}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: 'VirtualNuban',
              ...(params?.accountId && {
                accountId: params?.accountId,
              }),
              ...(params?.virtualNubanId && {
                virtualNubanId: params?.virtualNubanId,
              }),
              ...(params?.query && {
                query: params?.query,
              }),
              ...(params?.from && {
                from: params?.from,
              }),
              ...(params?.to && {
                to: params?.to,
              }),
            },
          }
        )
        .then((res: GetPaymentsResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getPaymentDetails = (paymentId: string) => {
    return new Promise<PaymentType>((resolve, reject) => {
      authApi
        .get(`${APIS.PAYMENTS.GET_PAYMENT_DETAILS(paymentId)}`, {
          params: {
            include: 'VirtualNuban,DepositAccount,PaymentSettlement',
          },
        })
        .then((res: { data: PaymentType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getPayments,
    getPaymentDetails,
  };
};

export default usePaymentsService;
