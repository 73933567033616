import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { Select, Button, LoadingOverlay } from '@mantine/core';
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import useReportsService from '../../services/reports';
import useNotification from '../../hooks/useNotification';
import { ReactComponent as ArrowBack } from '../../assets/svg/arrow-back.svg';
import CustomSelectItem, {
  ItemSchema,
} from '../../components/CustomSelectItem/CustomSelectItem';
import useAccountsService from '../../services/accounts';
import { capitalizeTransform, handleCustomSelectCreate } from '../../lib/util';
import { GetAccountsResponse } from '../../types/accountsTypes';
import { selectMode } from '../../lib/store';
import { useAppSelector } from '../../hooks/useRedux';
import usePermissions from '../../hooks/usePermissions';

const ScheduleReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { createReport } = useReportsService();
  const { handleError } = useNotification();
  const [dateRange, setDateRange] = useState<DateRangePickerValue>([
    null,
    null,
  ]);
  const [accounts, setAccounts] = useState<ItemSchema[]>([]);
  const { getAccounts } = useAccountsService();
  const mode = useAppSelector(selectMode);
  const { reportWrite } = usePermissions();

  useEffect(() => {
    handleGetAccounts();
    //eslint-disable-next-line
  }, [mode]);

  const form = useForm({
    initialValues: {
      reportType: '',
      documentType: '',
      accountId: '',
    },

    validate: {
      reportType: (value) => (value === '' ? 'Select report type' : null),
      documentType: (value) => (value === '' ? 'Select document type' : null),
      accountId: (value) => (value === '' ? 'Select account' : null),
    },
  });

  const handleGetAccounts = () => {
    getAccounts({ page: 0, size: 100 })
      .then((res: GetAccountsResponse) => {
        const data: ItemSchema[] = res.data.map((account) => ({
          label: capitalizeTransform(account?.accountName ?? ''),
          description: `${account.accountNumber} (${account.id})`,
          value: account.id,
        }));

        setAccounts(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleSubmit = (values: typeof form.values) => {
    if (dateRange[0] && dateRange[1]) {
      setLoading(true);

      createReport({
        ...values,
        fromDate: moment(dateRange[0]).format('YYYY-MM-DD'),
        toDate: moment(dateRange[1]).format('YYYY-MM-DD'),
      })
        .then(() => {
          showNotification({
            title: 'Report scheduled',
            message: 'Report has been scheduled successfully',
            color: 'orange',
          });
          navigate(-1);
        })
        .catch((error: AxiosError) => {
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="add-new">
      <Helmet>
        <title>Schedule Report | Anchor</title>
        <meta name="description" content="Schedule a new report" />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <div
        className="back-btn click"
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBack /> <span>Back</span>
      </div>

      <div className="add-new-main">
        <div className="i-m-title">Schedule Report</div>

        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Select
            required
            label="Report Type"
            placeholder="Select Report Type"
            mt={24}
            data={[
              { label: 'Transaction history', value: 'transaction_history' },
              { label: 'Balance summary', value: 'balance_summary' },
            ]}
            {...form.getInputProps('reportType')}
            autoComplete="off"
          />

          <Select
            required
            label="Format"
            placeholder="Select document format"
            mt="md"
            data={[
              { label: 'CSV', value: 'csv' },
              { label: 'Excel', value: 'excel' },
            ]}
            {...form.getInputProps('documentType')}
            autoComplete="off"
          />

          <Select
            required
            mt="md"
            className="form-item"
            label="Select Account"
            placeholder="Select account/ Input ID"
            searchable
            itemComponent={CustomSelectItem}
            data={accounts}
            maxDropdownHeight={400}
            creatable
            getCreateLabel={(query) => `Select ${query}`}
            onCreate={(query) => {
              return handleCustomSelectCreate(
                query,
                accounts,
                () => form.setFieldValue('accountId', query),
                setAccounts
              );
            }}
            {...form.getInputProps('accountId')}
          />

          <DateRangePicker
            required
            mt="md"
            label="Start Date - End Date"
            placeholder="Pick dates range"
            value={dateRange}
            onChange={setDateRange}
          />

          <Button
            color="dark"
            fullWidth
            mt="lg"
            type="submit"
            disabled={!dateRange[0] || !dateRange[1] || !reportWrite}
          >
            Schedule Report
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ScheduleReport;
