import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useAppDispatch } from '../../hooks/useRedux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  NumberInput,
  Button,
  Checkbox,
  Anchor,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import AuthHeader, { HeaderTypes } from '../../components/Auth/AuthHeader';
import { passwordSchema } from '../../lib/utility/validator';
import useUserManagementService from '../../services/userManagement';
import { setShowLoader } from '../../lib/store/reducers/UtilityReducer';
import useNotification from '../../hooks/useNotification';
import './auth.scss';

const JoinTeam = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { joinTeam } = useUserManagementService();
  const [inviteCodeValue, setInviteCodeValue] = useState('');
  const { handleError } = useNotification();

  useEffect(() => {
    const refValue = searchParams.get('invitationCode');
    if (refValue) {
      setInviteCodeValue(refValue);
    }
    //eslint-disable-next-line
  }, []);

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      password: '',
      termsOfService: false,
    },

    validate: {
      password: (value) =>
        passwordSchema.validate(value)
          ? null
          : 'Must contain 8 characters, 1 upper case, 1 symbol and 1 number.',
      termsOfService: (value) => (!value ? 'Accept terms to continue' : null),
    },
  });

  const handleSubmit = (values: any) => {
    if (!inviteCodeValue) {
      return showNotification({
        color: 'red',
        title: 'Invite code missing',
        message: 'To join, continue from link in received email',
      });
    }

    delete values['termsOfService'];

    dispatch(setShowLoader(true));

    joinTeam(inviteCodeValue, values)
      .then(() => {
        showNotification({
          title: 'Signup successful',
          message: 'Sign in to continue.',
          color: 'orange',
        });

        navigate('/signin');
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        dispatch(setShowLoader(false));
      });
  };

  return (
    <div className="auth-page">
      <Helmet>
        <title>Join Organization | Anchor</title>
        <meta
          name="description"
          content="Fill the details below to join your organization"
        />
      </Helmet>

      <AuthHeader type={HeaderTypes.hasAccount} />

      <div className="auth-main lrgr">
        <div className="a-m-title">
          Welcome to Anchor, <br />
        </div>
        <div className="a-m-desc">
          Fill the details below to join your organization.
        </div>

        <form
          className="a-form la6g"
          onSubmit={form.onSubmit((values) => handleSubmit(values))}
        >
          <div className="form-row">
            <TextInput
              label="First name"
              className="form-item"
              required
              placeholder="First name"
              {...form.getInputProps('firstName')}
            />

            <TextInput
              label="Last name"
              className="form-item"
              required
              placeholder="Last name"
              {...form.getInputProps('lastName')}
            />
          </div>

          <div className="form-row">
            <NumberInput
              label="Phone number"
              className="form-item"
              required
              placeholder="Phone number"
              min={234}
              defaultValue={234}
              hideControls
              {...form.getInputProps('phoneNumber')}
            />

            <PasswordInput
              required
              className="form-item"
              label="Password"
              placeholder="Create a password"
              {...form.getInputProps('password')}
            />
          </div>

          <Checkbox
            mt="md"
            label={
              <div
                className={`no-select ${
                  form?.errors?.termsOfService ? 'error-text' : ''
                }`}
              >
                I have read and agreed to the{' '}
                <Anchor
                  size="sm"
                  href="https://getanchor.co/privacy"
                  target="_blank"
                >
                  Privacy Policy
                </Anchor>{' '}
                and{' '}
                <Anchor
                  size="sm"
                  href="https://getanchor.co/terms"
                  target="_blank"
                >
                  Terms & Conditions
                </Anchor>
                .
              </div>
            }
            {...form.getInputProps('termsOfService', { type: 'checkbox' })}
          />

          <Button color="dark" mt={20} fullWidth type="submit">
            Join organization
          </Button>
        </form>
      </div>
    </div>
  );
};

export default JoinTeam;
