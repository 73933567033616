import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import useSubAccountsService from '../../services/subAccounts';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import { selectMode } from '../../lib/store';
import TransactionsTable from './TransactionsTable';
import { setSubAccountTransactions } from '../../lib/store/reducers/DataReducer';
import { GetTransactionsResponse } from '../../types/transactionsTypes';
import useNotification from '../../hooks/useNotification';
import { LoadingOverlay } from '@mantine/core';

const SubAccountTransactions = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [type, setType] = useState<string>('');
  const [direction, setDirection] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const { getSubAccountTransactions } = useSubAccountsService();
  const transactions = useAppSelector(
    (state) => state.data.subAccountTransactions
  );
  const mode = useAppSelector(selectMode);
  const dispatch = useAppDispatch();
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    handleGetSubAccountTransactions();
    //eslint-disable-next-line
  }, [page, size, mode, type, direction, dateFrom, dateTo]);

  const handleGetSubAccountTransactions = () => {
    if (!transactions.dataFetched) {
      setLoading(true);
    }

    getSubAccountTransactions({
      size,
      page,
      type,
      direction,
      startDate: dateFrom,
      toDate: dateTo,
    })
      .then((res: GetTransactionsResponse) => {
        dispatch(
          setSubAccountTransactions({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterItemChange = (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => {
    if (value !== itemState) setLoading(true);
    setItemState && setItemState(value);
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Transactions | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of transactions"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <div className="p-title extra-mb">Transactions</div>

      {transactions.dataFetched && (
        <TransactionsTable
          transactions={transactions}
          setPage={setPage}
          type={type}
          setType={setType}
          direction={direction}
          setDirection={setDirection}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          onFilterItemChange={onFilterItemChange}
          isSubAccount
          loading={loading}
          setLoading={setLoading}
          setSize={setSize}
        />
      )}

      {transactions.meta.total === 0 && !transactions.dataFetched && (
        <EmptyData
          title="There are no transactions to display"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

export default SubAccountTransactions;
