import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useForm } from '@mantine/form';
import { PasswordInput, Button } from '@mantine/core';
import AuthHeader, { HeaderTypes } from '../../components/Auth/AuthHeader';
import { passwordSchema } from '../../lib/utility/validator';
import useAuthService from '../../services/auth';
import { useAppDispatch } from '../../hooks/useRedux';
import { setShowLoader } from '../../lib/store/reducers/UtilityReducer';
import useNotification from '../../hooks/useNotification';
import './auth.scss';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [reference, setReference] = useState<string>('');
  const { resetPassword } = useAuthService();
  const dispatch = useAppDispatch();
  const { handleError } = useNotification();

  useEffect(() => {
    const refValue = searchParams.get('ref');
    if (refValue) {
      setReference(refValue);
    }
    //eslint-disable-next-line
  }, []);

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validate: {
      password: (value) =>
        passwordSchema.validate(value)
          ? null
          : 'Must contain 8 characters, 1 upper case, 1 symbol and 1 number.',
      confirmPassword: (value, values) =>
        value !== values.password ? 'Passwords do not match' : null,
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    dispatch(setShowLoader(true));

    resetPassword({ password: values.password, reference })
      .then(() => {
        showNotification({
          title: 'Password reset',
          message: 'Sign in to continue.',
          color: 'orange',
        });

        navigate('/signin');
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        dispatch(setShowLoader(false));
      });
  };

  return (
    <div className="auth-page">
      <Helmet>
        <title>Reset Password | Anchor</title>
        <meta
          name="description"
          content="Enter a new password to reset your password"
        />
      </Helmet>

      <AuthHeader type={HeaderTypes.noAccount} />

      <div className="auth-main">
        <div className="a-m-title">Change your password</div>
        <div className="a-m-desc">
          Complete the form below to change your password
        </div>

        <form
          className="a-form"
          onSubmit={form.onSubmit((values) => handleSubmit(values))}
        >
          <PasswordInput
            required
            mt="sm"
            label="New password"
            placeholder="Password"
            {...form.getInputProps('password')}
          />

          <PasswordInput
            required
            mt="sm"
            label="Confirm password"
            placeholder="Confirm password"
            {...form.getInputProps('confirmPassword')}
          />

          <Button color="dark" mt={20} fullWidth type="submit">
            Confirm
          </Button>

          <div className="sec-action">
            Need help? <span className="click">Contact us</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
