import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { makeServer } from './lib/server';
import { PostHogProvider } from 'posthog-js/react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './lib/store';

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_USE_MOCK_SERVER
) {
  makeServer({ environment: 'development' });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_DEPLOY_ENV === 'prod' ? 0.5 : 0.1,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={posthogOptions}
        >
          <App />
        </PostHogProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
