import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { Badge, Table, ScrollArea, LoadingOverlay } from '@mantine/core';
import { useAppSelector } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import { AccountType, AccountStatusTypes } from '../../types/accountsTypes';
import { selectMode } from '../../lib/store';
import useAccountsService from '../../services/accounts';
import useNotification from '../../hooks/useNotification';
import usePermissions from '../../hooks/usePermissions';

const RootAccounts = () => {
  const [rootAccounts, setRootAccounts] = useState<AccountType[]>([]);
  const mode = useAppSelector(selectMode);
  const navigate = useNavigate();
  const { getRootAccounts } = useAccountsService();
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { accountRead } = usePermissions();

  useEffect(() => {
    if (accountRead) getAccounts();

    //eslint-disable-next-line
  }, [mode]);

  const getAccounts = () => {
    setLoading(true);

    getRootAccounts()
      .then((res: AccountType[]) => {
        setRootAccounts(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Root Accounts | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of your root accounts"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <div className="p-title extra-mb">Root Accounts</div>

      {rootAccounts.length > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table verticalSpacing="md" sx={{ minWidth: 800 }} highlightOnHover>
              <thead>
                <tr>
                  <th>Customer name</th>
                  <th>Account number</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {rootAccounts.map((account: AccountType, index: number) => (
                  <tr
                    key={index}
                    className="click"
                    onClick={() => {
                      navigate(`/account/${account.id}`);
                    }}
                    id={account.id}
                  >
                    <td className="capitalize">
                      {account?.accountName &&
                        account?.accountName.toLowerCase()}
                    </td>
                    <td className="gray">{account?.accountNumber ?? ''}</td>
                    <td>
                      <div className="type-badge">{account.type}</div>
                    </td>
                    <td>
                      <Badge
                        color={
                          account.status === AccountStatusTypes.active
                            ? 'green'
                            : 'red'
                        }
                      >
                        {account.status}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>
        </React.Fragment>
      )}

      {rootAccounts.length === 0 && (
        <EmptyData
          title="No root account found"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

export default RootAccounts;
