import { AccountType } from './accountsTypes';
import { TransferType } from './transfersTypes';
import { PaymentType } from './paymentsTypes';
import { ResponseMetaPagination } from '../lib/types';

export type GetTransactionsResponse = {
  data: TransactionType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type TransactionType = {
  id: string;
  type: string;
  transfer?: {
    data: TransferType;
  };
  account: {
    data: AccountType;
  };
  payment?: {
    data: PaymentType;
  };
  summary: string;
  transactionType: string;
  createdAt: Date;
  amount: number;
  balanceBefore: number;
  currency: string;
  balanceAfter: number;
  transactionDate: Date;
  direction: string;
  referenceTransaction?: {
    data: TransactionType;
  };
};

export enum TransactionCategories {
  NIP_TRANSFER = 'NIP_TRANSFER',
  CUSTOM = 'CUSTOM',
  NIP_TRANSFER_REVERSAL = 'NIP_TRANSFER_REVERSAL',
  SETTLEMENT = 'SETTLEMENT',
  BOOK_TRANSFER = 'BOOK_TRANSFER',
  ORGANIZATION_FEE = 'ORGANIZATION_FEE',
  BILL_PAYMENT = 'BILL_PAYMENT',
}

export enum TransactionTypes {
  BookTransaction = 'BookTransaction',
  NIPTransaction = 'NIPTransaction',
  NIPReversal = 'NIPReversal',
  FeeTransaction = 'FeeTransaction',
  CUSTOM = 'CUSTOM',
  BillsTransaction = 'BillsTransaction',
  CommissionTransaction = 'CommissionTransaction',
}

export enum TransactionDirections {
  debit = 'Debit',
  credit = 'Credit',
}

export type GetTransactionsParams = {
  page: number;
  size: number;
  accountId?: string;
  type?: string;
  direction?: string;
  customerId?: string;
  from?: string;
  to?: string;
  query?: string;
};
