import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  CreateCounterpartyData,
  GetCounterpartiesParams,
  GetCounterPartiesResponse,
  CounterpartyType,
  BankType,
  VerifyAccountResponse,
} from '../types/counterpartiesTypes';
import { Mode } from '../lib/store/reducers/SettingsReducer';

const useCounterpartyService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);
  const authApiLive = useApi(ApiAuthMode.BearerToken, Mode.Live);

  const getCounterparties = (params: GetCounterpartiesParams) => {
    return new Promise<GetCounterPartiesResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.COUNTERPARTIES.COUNTERPARTIES}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: '',
              ...(params?.query && {
                query: params?.query,
              }),
            },
          }
        )
        .then((res: GetCounterPartiesResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getCounterparty = (counterpartyId: string) => {
    return new Promise<{ data: CounterpartyType }>((resolve, reject) => {
      authApi
        .get(APIS.COUNTERPARTIES.COUNTERPARTY(counterpartyId))
        .then((res: { data: CounterpartyType }) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getBankList = () => {
    return new Promise<BankType[]>((resolve, reject) => {
      authApi
        .get(`${APIS.COUNTERPARTIES.BANKS}`)
        .then((res: { data: BankType[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const verifyAccount = (bankId: string, accountNumber: string) => {
    return new Promise<VerifyAccountResponse>((resolve, reject) => {
      authApiLive
        .get(`${APIS.COUNTERPARTIES.VERIFY_ACCOUNT(bankId, accountNumber)}`)
        .then((res: { data: VerifyAccountResponse }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const createCounterparty = (data: CreateCounterpartyData) => {
    return new Promise<CounterpartyType>((resolve, reject) => {
      const { accountName, accountNumber, bankId } = data;

      const body = {
        accountName,
        accountNumber,
        bank: {
          data: {
            id: bankId,
            type: 'Bank',
          },
        },
      };

      authApi
        .request({
          method: 'POST',
          url: APIS.COUNTERPARTIES.COUNTERPARTIES,
          type: 'CounterParty',
          body,
        })
        .then((res: { data: CounterpartyType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getCounterparties,
    getCounterparty,
    getBankList,
    verifyAccount,
    createCounterparty,
  };
};

export default useCounterpartyService;
