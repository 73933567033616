import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useAxiosApi } from '../lib/api';
import { AxiosError } from 'axios';
import { SimulateTransferData } from '../types/sandboxTypes';
import { Mode } from '../lib/store/reducers/SettingsReducer';
import { PaymentType } from '../types/paymentsTypes';

const useSandboxService = (apiKey: string) => {
  const axiosApi = useAxiosApi(ApiAuthMode.ApiKey, Mode.Test, apiKey);

  const simulateTransfer = (data: SimulateTransferData) => {
    return new Promise<{ data: PaymentType }>((resolve, reject) => {
      const requestData = {
        data: {
          type: 'Notification',
          attributes: {
            transactionAmount: data.transactionAmount,
            sourceAccount: data.sourceAccount,
          },
          relationships: {
            destinationAccount: {
              data: {
                id: data.virtualNubanId,
                type: 'VirtualNuban',
              },
            },
          },
        },
      };

      axiosApi
        .post(APIS.SANDBOX.TRANSACTION_NOTIFICATION, requestData)
        .then((res: { data: { data: PaymentType } }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError.response?.data);
        });
    });
  };

  return {
    simulateTransfer,
  };
};

export default useSandboxService;
