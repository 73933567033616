import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Group, Stepper } from '@mantine/core';
import BusinessDetails from './BusinessDetails';
import BusinessOfficers from './BusinessOfficers';
import BusinessDocuments from './BusinessDocuments';
import './kyc.scss';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import useOrganizationService from '../../../services/organization';
import useNotification from '../../../hooks/useNotification';
import { OrganizationCompliance } from '../../../types/organizationTypes';
import { AxiosError } from 'axios';
import { setShowLoader } from '../../../lib/store/reducers/UtilityReducer';
import KycOverview from './KycOverview';
import { DocumentType } from '../../../types/documentTypes';
import { OfficerType } from '../../../types/businessTypes';
import { ReactComponent as ChevronBack } from '../../../assets/svg/chevron-back.svg';
import { scrollToPageTop } from '../../../lib/util';

const Compliance = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLive, setIsLive] = useState<boolean>(false);
  const [activeView, setActiveView] = useState<'overview' | 'edit' | ''>('');
  const organizations = useAppSelector((state) => state.user.organizations);
  const dispatch = useAppDispatch();
  const [orgCompliance, setOrgCompliance] =
    useState<OrganizationCompliance | null>(null);
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [officers, setOfficers] = useState<OfficerType[]>([]);
  const {
    getOrganizationCompliance,
    getDocuments,
    getOrgBusinessOfficers,
    downloadDocument,
  } = useOrganizationService();
  const { handleError } = useNotification();

  useEffect(() => {
    if (organizations.length > 0) {
      setIsLive(organizations[0]?.isLive);
    }
  }, [organizations]);

  useEffect(() => {
    getComplianceData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    scrollToPageTop();
  }, [activeView, activeStep]);

  const getComplianceData = () => {
    dispatch(setShowLoader(true));

    getOrganizationCompliance()
      .then((res: OrganizationCompliance) => {
        setOrgCompliance(res);

        if (!orgCompliance) {
          if (res.complianceStatus.basicDetailSubmitted) {
            getOfficers();
          }

          if (
            res.complianceStatus.primaryContactSubmitted &&
            res.complianceStatus.officersSubmitted
          ) {
            getOrganizationDocuments();
          }

          if (res.complianceStatus.basicDetailSubmitted) {
            setActiveView('overview');
          } else {
            setActiveView('edit');
          }
        }
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        dispatch(setShowLoader(false));
      });
  };

  const getOrganizationDocuments = () => {
    getDocuments()
      .then((res: DocumentType[]) => {
        setDocuments(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const getOfficers = () => {
    getOrgBusinessOfficers()
      .then((res: OfficerType[]) => {
        setOfficers(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleDocumentDownload = (documentId: string) => {
    downloadDocument(documentId)
      .then((res: any) => {
        console.log(res);
      })
      .catch((axiosError: AxiosError) => {
        handleError(axiosError);
      });
  };

  const nextStep = () => {
    setActiveStep((current) => (current < 2 ? current + 1 : current));
  };

  const prevStep = () =>
    setActiveStep((current) => (current > 0 ? current - 1 : current));

  return (
    <div>
      <Helmet>
        <title>KYC | Anchor</title>
        <meta
          name="description"
          content="Fill the forms to complete your orgniaztion KYC"
        />
      </Helmet>

      {activeView === 'overview' && orgCompliance && (
        <KycOverview
          orgCompliance={orgCompliance}
          documents={documents}
          officers={officers}
          isLive={isLive}
          setActiveView={setActiveView}
          setActiveStep={setActiveStep}
          handleDocumentDownload={handleDocumentDownload}
        />
      )}

      {activeView === 'edit' && (
        <div className="business-kyc-comp">
          <Group spacing={8} className="page-title">
            <ChevronBack
              className="click"
              onClick={() => {
                setActiveView('overview');
              }}
            />

            <div>KYC Overview</div>
          </Group>

          <Stepper
            size="xs"
            mt={20}
            active={activeStep}
            orientation="vertical"
            color="dark"
          >
            <Stepper.Step label="Business Details">
              <BusinessDetails
                nextStep={nextStep}
                setActiveView={setActiveView}
                orgCompliance={orgCompliance}
                getComplianceData={getComplianceData}
              />
            </Stepper.Step>

            <Stepper.Step label="Business Officers">
              <BusinessOfficers
                nextStep={nextStep}
                prevStep={prevStep}
                isLive={isLive}
                officers={officers}
                getOfficers={getOfficers}
                orgCompliance={orgCompliance}
                getComplianceData={getComplianceData}
              />
            </Stepper.Step>

            <Stepper.Step label="Documents">
              <BusinessDocuments
                prevStep={prevStep}
                isLive={isLive}
                documents={documents}
                getOrganizationDocuments={getOrganizationDocuments}
                setActiveView={setActiveView}
              />
            </Stepper.Step>
          </Stepper>
        </div>
      )}
    </div>
  );
};

export default Compliance;
