import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  TextInput,
  Select,
  Button,
  Group,
  LoadingOverlay,
  Switch,
  ScrollArea,
  Divider,
  Checkbox,
  Tooltip,
  Drawer,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import useWebhooksService from '../../../services/webhooks';
import useNotification from '../../../hooks/useNotification';
import useEventsService from '../../../services/events';
import { EventTypesType } from '../../../types/eventsTypes';
import { showNotification } from '@mantine/notifications';
import ActionStep from '../../../components/ActionStep/ActionStep';
import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron-down-gray.svg';
import { convertToTitleCase, validateUrl } from '../../../lib/util';
import { CreateWebhookData, WebhookType } from '../../../types/webhooksTypes';
import { ReactComponent as Info } from '../../../assets/svg/info-circle.svg';

const enum EventSelectionTypes {
  all = 'all',
  custom = 'custom',
}

export const unsupportedEvents = ['ach', 'wire'];

interface CreateWebhookProps {
  modalOpen: boolean;
  closeModal: () => void;
  callback: () => void;
  webhook: WebhookType | null;
}

const CreateWebhook = ({
  modalOpen,
  closeModal,
  callback,
  webhook,
}: CreateWebhookProps) => {
  const [eventTypes, setEventTypes] = useState<EventTypesType[]>([]);
  const { getEventTypes } = useEventsService();
  const { handleError } = useNotification();

  useEffect(() => {
    handleGetEventTypes();
    //eslint-disable-next-line
  }, []);

  const handleGetEventTypes = () => {
    getEventTypes()
      .then((res: EventTypesType[]) => {
        setEventTypes(res);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  return (
    <Drawer
      opened={modalOpen}
      onClose={closeModal}
      title={
        <span className="modal-title">
          {webhook ? 'Edit Webhook' : 'Create Webhook'}
        </span>
      }
      padding="xl"
      size="100vh"
      position="bottom"
      overlayOpacity={0.5}
      className="details-drawer-bottom gray-bg "
    >
      <WebhookForm
        closeModal={closeModal}
        callback={callback}
        eventTypes={eventTypes}
        webhook={webhook}
      />
    </Drawer>
  );
};

interface WebhookFormProps {
  closeModal: () => void;
  callback: () => void;
  webhook: WebhookType | null;
  eventTypes: EventTypesType[];
}

const WebhookForm = ({
  closeModal,
  callback,
  webhook,
  eventTypes,
}: WebhookFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [groupedEvents, setGroupedEvents] = useState<
    Record<string, EventTypesType[]>
  >({});
  const [eventsSelectionType, setEventsSelectionType] =
    useState<EventSelectionTypes | null>(null);
  const { createWebhook, updateWebhook } = useWebhooksService();
  const { handleError } = useNotification();

  useEffect(() => {
    if (webhook) {
      form.setFieldValue('deliveryMode', webhook.deliveryMode);
      form.setFieldValue('label', webhook.label);
      form.setFieldValue('token', webhook.secretToken);
      form.setFieldValue('url', webhook.url);
      form.setFieldValue('supportIncluded', webhook.supportIncluded);
      form.setFieldValue('enabledEvents', webhook.enabledEvents);
    } else {
      form.setFieldValue('enabledEvents', []);
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (eventTypes.length > 0) {
      handleEventsGrouping();
    }

    if (webhook && eventTypes.length > 0) {
      if (webhook.enabledEvents.length === eventTypes.length) {
        setEventsSelectionType(EventSelectionTypes.all);
      } else {
        setEventsSelectionType(EventSelectionTypes.custom);
      }
    }
    //eslint-disable-next-line
  }, [eventTypes]);

  const form = useForm({
    initialValues: {
      deliveryMode: '',
      label: '',
      token: '',
      url: '',
      enabledEvents: [''],
      supportIncluded: false,
    },

    validate: {
      url: (value) => (!validateUrl(value) ? 'Invalid Url' : null),
      deliveryMode: (value) => (value === '' ? 'Select delivery mode' : null),
      enabledEvents: (value) =>
        eventsSelectionType === EventSelectionTypes.all
          ? null
          : value.length === 0
          ? 'Select events'
          : null,
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    const data: CreateWebhookData = values;

    if (eventsSelectionType === EventSelectionTypes.all) {
      delete data.enabledEvents;
    }

    const action = webhook ? updateWebhook : createWebhook;

    setLoading(true);

    action(data, `${webhook?.id}`)
      .then(() => {
        showNotification({
          title: 'Success',
          message: `Webhook ${webhook ? 'updated' : 'created'} successfully.`,
          color: 'orange',
        });

        form.reset();
        closeModal();
        callback();
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEventsGrouping = () => {
    const grouped = eventTypes.reduce((acc, event) => {
      const key = event.type.split('.')[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(event);
      return acc;
    }, {} as Record<string, EventTypesType[]>);

    setGroupedEvents(grouped);
  };

  return (
    <div className="permission-item-create">
      <form
        className="add-drawer"
        onSubmit={form.onSubmit((values) => handleSubmit(values))}
      >
        <LoadingOverlay visible={loading} />

        <div className="form-fields">
          <ActionStep
            number={1}
            title={webhook ? 'Update Information' : 'Webhook Information'}
            desc="Please provide some details about this webhook"
          />

          <TextInput
            required
            mt={24}
            label="URL"
            placeholder="https://wm4jlbka.m.ngrok.io"
            {...form.getInputProps('url')}
            autoComplete="off"
          />

          <TextInput
            required
            mt="sm"
            label="Label"
            placeholder="notifications"
            {...form.getInputProps('label')}
            autoComplete="off"
          />

          <Select
            required
            label="Delivery Mode"
            placeholder="Select Delivery Mode"
            mt="sm"
            data={['AtLeastOnce']}
            rightSection={<ChevronDown />}
            {...form.getInputProps('deliveryMode')}
            autoComplete="off"
          />

          <TextInput
            required
            mt="sm"
            placeholder="13211"
            label="Secret Token"
            {...form.getInputProps('token')}
            autoComplete="off"
          />

          <Group spacing={10} mt={24}>
            <Tooltip
              multiline
              width={400}
              withArrow
              label="Include related resources and relationships when vierelated resources and relationships when vierelated resources and relationships when viewing events generated by this webhook."
              position="bottom-start"
            >
              <Info className="tooltip-icon" />
            </Tooltip>

            <Switch
              labelPosition="left"
              checked={form.values.supportIncluded}
              label="Support Included"
              size="sm"
              onChange={(event) =>
                form.setFieldValue(
                  'supportIncluded',
                  event.currentTarget.checked
                )
              }
            />
          </Group>
        </div>

        <div className="events">
          <ActionStep
            number={2}
            title={webhook ? 'Update Events' : 'Add Events'}
            desc="What kind of events do you want included"
          />

          <ScrollArea type="auto" className="event-selector">
            <Checkbox
              label="Add all events"
              color="cyan"
              checked={eventsSelectionType === EventSelectionTypes.all}
              onChange={(event) => {
                if (event.currentTarget.checked) {
                  form.setFieldValue(
                    'enabledEvents',
                    eventTypes.map((event) => event.type)
                  );

                  setEventsSelectionType(EventSelectionTypes.all);
                } else {
                  form.setFieldValue('enabledEvents', []);

                  setEventsSelectionType(EventSelectionTypes.custom);
                }
              }}
            />

            <Divider my="sm" />

            {Object.keys(groupedEvents)
              .filter((group: string) => !unsupportedEvents.includes(group))
              .map((group: string) => (
                <Group className="event-group" key={group} align="flex-start">
                  <div className="e-group-name">
                    {convertToTitleCase(group)}
                  </div>

                  <div className="group-items capitalize">
                    <Checkbox
                      label={`Everything related to ${convertToTitleCase(
                        group
                      )}`}
                      color="cyan"
                      checked={
                        eventsSelectionType === EventSelectionTypes.all ||
                        groupedEvents[group].every(
                          (eventType: EventTypesType) =>
                            form.values.enabledEvents.includes(eventType.type)
                        )
                      }
                      onChange={(event) => {
                        if (event.currentTarget.checked) {
                          form.setFieldValue('enabledEvents', [
                            ...form.values.enabledEvents,
                            ...groupedEvents[group].map(
                              (eventType: EventTypesType) => eventType.type
                            ),
                          ]);
                        } else {
                          setEventsSelectionType(EventSelectionTypes.custom);

                          form.setFieldValue(
                            'enabledEvents',
                            form.values.enabledEvents.filter(
                              (event) =>
                                !groupedEvents[group]
                                  .map(
                                    (eventType: EventTypesType) =>
                                      eventType.type
                                  )
                                  .includes(event)
                            )
                          );
                        }
                      }}
                    />

                    {groupedEvents[group].map((eventType: EventTypesType) => (
                      <Checkbox
                        key={eventType.type}
                        label={convertToTitleCase(
                          eventType.type.replace(group + '.', '')
                        )}
                        color="cyan"
                        checked={
                          eventsSelectionType === EventSelectionTypes.all ||
                          form.values.enabledEvents.includes(eventType.type)
                        }
                        onChange={(event) => {
                          if (event.currentTarget.checked) {
                            form.setFieldValue('enabledEvents', [
                              ...form.values.enabledEvents,
                              eventType.type,
                            ]);
                          } else {
                            setEventsSelectionType(EventSelectionTypes.custom);

                            form.setFieldValue(
                              'enabledEvents',
                              form.values.enabledEvents.filter(
                                (event) => event !== eventType.type
                              )
                            );
                          }
                        }}
                      />
                    ))}
                  </div>
                </Group>
              ))}
          </ScrollArea>
        </div>

        <Group className="add-drawer-footer" position="apart">
          <Button
            color="dark"
            variant="default"
            compact
            className="compact-btn"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="dark"
            compact
            className="compact-btn"
            disabled={
              eventsSelectionType === EventSelectionTypes.custom &&
              form.values.enabledEvents.length === 0
            }
          >
            {webhook ? 'Save' : 'Create Webhook'}
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default CreateWebhook;
