import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { AxiosError } from 'axios';
import {
  OrganizationCompliance,
  OfficersRequirement,
  OrganizationType,
} from '../types/organizationTypes';
import { DocumentType, UploadDocumentData } from '../types/documentTypes';
import {
  AddOfficerData,
  OfficerType,
  CreateBusinessCustomerData,
} from '../types/businessTypes';
import { Mode } from '../lib/store/reducers/SettingsReducer';

const useOrganizationService = () => {
  const authApi = useApi(ApiAuthMode.BearerToken, Mode.Live);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, Mode.Live);

  const activateOrganization = (body: CreateBusinessCustomerData) => {
    return new Promise<OrganizationType>((resolve, reject) => {
      authApi
        .post(APIS.ORGANIZATION.ACTIVATE_ORGANIZATION, body)
        .then((res: { data: OrganizationType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getOrganizationCompliance = () => {
    return new Promise<OrganizationCompliance>((resolve, reject) => {
      authApi
        .get(APIS.ORGANIZATION.GET_ORGANIZATION_COMPLIANCE)
        .then((res: { data: OrganizationCompliance }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getOrganizationDetails = () => {
    return new Promise<OrganizationType>((resolve, reject) => {
      authApi
        .get(APIS.ORGANIZATION.GET_ORGANIZATION_DETAILS)
        .then((res: { data: OrganizationType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getDocuments = () => {
    return new Promise<DocumentType[]>((resolve, reject) => {
      authApi
        .get(APIS.ORGANIZATION.GET_DOCUMENTS)
        .then((res: { data: DocumentType[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getDocument = (documentId: string) => {
    return new Promise<DocumentType>((resolve, reject) => {
      authApi
        .get(APIS.ORGANIZATION.GET_DOCUMENT(documentId))
        .then((res: { data: DocumentType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const uploadDocument = (documentId: string, data: UploadDocumentData) => {
    return new Promise<DocumentType>((resolve, reject) => {
      const formData = new FormData();
      formData.append('textData', data.textData);
      formData.append('fileData', data.fileData);

      axiosApi
        .post(APIS.ORGANIZATION.UPLOAD_DOCUMENT(documentId), formData)
        .then((res: { data: DocumentType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const downloadDocument = (documentId: string) => {
    return new Promise((resolve, reject) => {
      authApi
        .get(APIS.ORGANIZATION.DOWNLOAD_DOCUMENT(documentId))
        .then((res: { data: unknown }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getOrgBusinessOfficers = () => {
    return new Promise<OfficerType[]>((resolve, reject) => {
      authApi
        .get(APIS.ORGANIZATION.BUSINESS_OFFICERS)
        .then((res: { data: OfficerType[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const addOrgBusinessOfficer = (officer: AddOfficerData) => {
    const body = {
      data: {
        type: 'BusinessOfficer',
        attributes: officer,
      },
    };

    return new Promise<OfficerType>((resolve, reject) => {
      axiosApi
        .put(APIS.ORGANIZATION.BUSINESS_OFFICERS, body)
        .then((res: { data: OfficerType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const updateOrgBusinessOfficer = (
    officerId: string,
    officer: AddOfficerData
  ) => {
    const body = {
      data: {
        type: 'BusinessOfficer',
        attributes: officer,
      },
    };

    return new Promise<OfficerType>((resolve, reject) => {
      axiosApi
        .patch(APIS.ORGANIZATION.BUSINESS_OFFICER(officerId), body)
        .then((res: { data: OfficerType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const deleteOrgBusinessOfficer = (officerId: string) => {
    return new Promise<OfficerType>((resolve, reject) => {
      axiosApi
        .delete(APIS.ORGANIZATION.BUSINESS_OFFICER(officerId))
        .then((res: { data: OfficerType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getOfficersRequirement = () => {
    return new Promise<OfficersRequirement>((resolve, reject) => {
      authApi
        .get(APIS.ORGANIZATION.OFFICERS_REQUIREMENT)
        .then((res: { data: OfficersRequirement }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    activateOrganization,
    getOrganizationCompliance,
    getOrganizationDetails,
    getDocuments,
    getDocument,
    addOrgBusinessOfficer,
    getOrgBusinessOfficers,
    updateOrgBusinessOfficer,
    deleteOrgBusinessOfficer,
    uploadDocument,
    downloadDocument,
    getOfficersRequirement,
  };
};

export default useOrganizationService;
