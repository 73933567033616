import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  PasswordInput,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import useAuthService from '../../../services/auth';
import useNotification from '../../../hooks/useNotification';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { passwordSchema } from '../../../lib/utility/validator';

interface ChangePasswordProps {
  modalOpen: boolean;
  closeModal: () => void;
}

export const ChangePassword = ({
  modalOpen,
  closeModal,
}: ChangePasswordProps) => {
  const { width } = useViewportSize();
  const [loading, setLoading] = useState<boolean>(false);
  const { changePassword } = useAuthService();
  const { handleError } = useNotification();

  useEffect(() => {
    if (!modalOpen) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [modalOpen]);

  const form = useForm({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },

    validate: {
      newPassword: (value) =>
        passwordSchema.validate(value)
          ? null
          : 'Must contain 8 chars, 1 upper case, 1 symbol & 1 number.',
      confirmPassword: (value, values) =>
        value !== values.newPassword ? 'Passwords do not match' : null,
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    setLoading(true);

    const { oldPassword, newPassword } = values;

    changePassword({ oldPassword, newPassword })
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Password changed successfully',
          color: 'orange',
        });
        form.reset();
        closeModal();
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      opened={modalOpen}
      onClose={closeModal}
      size={500}
      title={<span className="modal-title">Change Password</span>}
      overflow="inside"
      fullScreen={width < 568}
      padding={width < 568 ? 'lg' : 50}
      withCloseButton={false}
      radius={8}
    >
      <form
        onSubmit={form.onSubmit((values) => handleSubmit(values))}
        className="password-input-wrapper"
      >
        <LoadingOverlay visible={loading} />

        <div>
          <PasswordInput
            required
            variant="unstyled"
            label="Old password"
            placeholder="Old Password"
            {...form.getInputProps('oldPassword')}
          />
        </div>

        <div style={{ marginTop: 30 }}>
          <PasswordInput
            required
            variant="unstyled"
            label="New password"
            placeholder="New Password"
            {...form.getInputProps('newPassword')}
            autoComplete="off"
          />

          <PasswordInput
            required
            variant="unstyled"
            label="Confirm Password"
            placeholder="Confirm password"
            {...form.getInputProps('confirmPassword')}
            autoComplete="off"
          />
        </div>

        <Group position="right" mt={40}>
          <Button
            variant="default"
            compact
            className="compact-btn"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button color="dark" compact className="compact-btn" type="submit">
            Change password
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default ChangePassword;
