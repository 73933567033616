export const drawerWidth = 240;

// const defaultBaseUrl =
//   process.env.NODE_ENV !== 'development'
//     ? 'https://api.s.getanchor.co'
//     : 'https://api.getanchor.co';

const defaultBaseUrl = 'https://api.getanchor.co';

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || defaultBaseUrl;
export const sandBoxBaseUrl = 'https://api.sandbox.getanchor.co';
export const apiNameSpace = '/api/v1';

export const detailModalStyle = {
  position: 'absolute',
  top: '0',
  right: '0',
  height: '100vh',
  overflowY: 'scroll',
  width: 520,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};
