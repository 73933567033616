import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import './auth-c.scss';

export enum HeaderTypes {
  hasAccount = 0,
  noAccount = 1,
  loggedIn = 2,
}

interface IAuthHeader {
  type?: HeaderTypes;
}

const AuthHeader = ({ type }: IAuthHeader) => {
  return (
    <div className="auth-header">
      <div className="border-top" />

      <div>
        <Logo />
      </div>

      <div className="a-h-right">
        {type === HeaderTypes.noAccount && (
          <>
            <span>Don’t have an account?</span>{' '}
            <Link to="/signup">Create an account</Link>
          </>
        )}

        {type === HeaderTypes.hasAccount && (
          <>
            <span>Already have an account?</span>
            <Link className="click" to="/signin">
              Login
            </Link>
          </>
        )}

        {type === HeaderTypes.loggedIn && (
          <span className="click logout">Logout</span>
        )}
      </div>
    </div>
  );
};

export default AuthHeader;
