import React, { useEffect } from 'react';
import { Modal, Text, Button, Select, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

interface IFreezeAccount {
  modalOpen: boolean;
  closeModal: () => void;
  submit: (values: { freezeReason: string; freezeDescription: string }) => void;
}

const FreezeAccount = ({ modalOpen, closeModal, submit }: IFreezeAccount) => {
  const form = useForm({
    initialValues: {
      freezeReason: '',
      freezeDescription: '',
    },

    validate: {
      freezeReason: (value) => (!value ? 'Please select reason' : null),
    },
  });

  useEffect(() => {
    if (!modalOpen) {
      form.reset();
    }
    //eslint-disable-next-line
  }, [modalOpen]);

  return (
    <Modal
      opened={modalOpen}
      onClose={closeModal}
      title={<Text fw={600}>Freeze Account</Text>}
    >
      <div>
        <form
          className="a-form"
          onSubmit={form.onSubmit((values) => {
            closeModal();
            submit(values);
          })}
        >
          <Select
            label="Freeze Reason"
            placeholder="Freeze Reason"
            data={[
              { value: 'FRAUD', label: 'Fraud' },
              { value: 'BY_CUSTOMER', label: 'By Customer' },
              { value: 'OTHER', label: 'Other' },
              { value: 'NONE', label: 'None' },
            ]}
            {...form.getInputProps('freezeReason')}
          />

          <Textarea
            label="Freeze Description"
            placeholder="Freeze Description"
            autosize
            required
            minRows={2}
            maxRows={4}
            mt={20}
            {...form.getInputProps('freezeDescription')}
          />

          <Button color="dark" mt={20} fullWidth type="submit">
            Freeze account
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default FreezeAccount;
