import { ResponseMetaPagination } from '../lib/types';

export type GetApiKeysResponse = {
  data: ApiKeyType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type CreateApiKeyData = {
  environment: string;
  label: string;
  authorities: string[];
  expiryDate?: Date;
};

export type PermissionType = {
  id: string;
  type: string;
  scope: string;
  environmentSpecific: boolean;
};

export type ApiKeyType = {
  id: string;
  type: string;
  resource: {
    data: {
      id: string;
      type: string;
    };
  };
  createdAt: Date;
  environment: string;
  prefix: string;
  label: string;
  state: string;
  token: string;
  expiryTime?: string;
};

export enum ApiKeyStateTypes {
  active = 'ACTIVE',
  inactive = 'IN_ACTIVE',
  expired = 'EXPIRED',
}
