import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  Select,
  Button,
  Group,
  LoadingOverlay,
  ScrollArea,
  Tabs,
  Text,
  Menu,
  Drawer,
} from '@mantine/core';
import useWebhooksService from '../../../services/webhooks';
import useNotification from '../../../hooks/useNotification';
import { ReactComponent as ChevronDownGray } from '../../../assets/svg/chevron-down-gray.svg';
import {
  capitalizeTransform,
  convertToTitleCase,
  formatDate2,
  getPermissionColor,
} from '../../../lib/util';
import {
  SendSampleEventResponse,
  WebhookType,
} from '../../../types/webhooksTypes';
import { Prism } from '@mantine/prism';
import { ReactComponent as ArrowDownRight } from '../../../assets/svg/arrow-down-right.svg';
import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron-down.svg';
import { DetailItem2 } from '../../../components/DetailItem/DetailItem';
import AuditLogsNested from '../../../components/AuditLogs/AuditLogsNested';
import Confirmation from '../../../components/Confirmation/Confirmation';
import { showNotification } from '@mantine/notifications';
import CreateWebhook from './CreateWebhook';

export const unsupportedEvents = ['ach', 'wire'];

interface WebhookDetailsProps {
  modalOpen: boolean;
  closeModal: () => void;
  callback: () => void;
  activeWebhook: WebhookType | null;
}

const WebhookDetails = ({
  modalOpen,
  closeModal,
  callback,
  activeWebhook,
}: WebhookDetailsProps) => {
  const [activeTab, setActiveTab] = useState<string | null>('test');
  const [deleteWebhookModal, setDeleteWebhookModal] = useState<boolean>(false);
  const { deleteWebhook, getWebhook } = useWebhooksService();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleError } = useNotification();
  const [createWebhookModal, setCreateWebhookModal] = useState<boolean>(false);
  const [webhook, setWebhook] = useState<WebhookType | null>(null);

  useEffect(() => {
    if (!modalOpen) setActiveTab('test');
  }, [modalOpen]);

  useEffect(() => {
    if (activeWebhook) {
      setWebhook(activeWebhook);
    }
  }, [activeWebhook]);

  const handleGetWebhook = () => {
    if (!activeWebhook) return;

    getWebhook(activeWebhook.id)
      .then((res: WebhookType) => {
        setWebhook(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleDeleteWebhook = () => {
    if (!webhook) return;

    setLoading(true);

    deleteWebhook(webhook.id)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Webhook deleted successfully.',
          color: 'orange',
        });
        callback();
        closeModal();
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEditWebhook = () => {
    handleGetWebhook();
    callback();
  };

  return (
    <Drawer
      opened={modalOpen}
      onClose={closeModal}
      title={
        <Group align="center" spacing={40}>
          <div className="modal-title">
            {webhook?.label ?? 'Webhook Details'}
          </div>

          <Menu shadow="md" width="target">
            <Menu.Target>
              <Button
                leftIcon={<ArrowDownRight />}
                rightIcon={<ChevronDown />}
                color="dark"
              >
                Actions
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                className="menu-item active text-center"
                onClick={() => setCreateWebhookModal(true)}
              >
                Edit Webhook
              </Menu.Item>

              <Menu.Item
                color="#DE7965"
                fw={500}
                fz={13}
                className="text-center"
                onClick={() => setDeleteWebhookModal(true)}
              >
                Delete Webhook
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      }
      padding="xl"
      size="100vh"
      position="bottom"
      overlayOpacity={0.5}
      className="details-drawer-bottom gray-bg"
    >
      <LoadingOverlay visible={loading} />

      <Confirmation
        isOpened={deleteWebhookModal}
        title="Are you sure you want to delete this webhook?"
        closeModal={() => {
          setDeleteWebhookModal(false);
        }}
        hasInput={false}
        confirmText="Delete"
        submit={() => {
          setDeleteWebhookModal(false);
          handleDeleteWebhook();
        }}
      />

      <CreateWebhook
        modalOpen={createWebhookModal}
        closeModal={() => {
          setCreateWebhookModal(false);
        }}
        callback={onEditWebhook}
        webhook={webhook}
      />

      {webhook && (
        <ScrollArea type="auto" className="fullscreen-modal-section-scroll">
          <div className="permission-item-details">
            <WebhookInfo webhook={webhook} />

            <div className="p-tabs-container fullscreen-modal-section-scroll allow-grow child no-pr relative">
              <Tabs value={activeTab} onTabChange={setActiveTab} color="dark">
                <Tabs.List>
                  <Tabs.Tab value="test">Test Webhook</Tabs.Tab>
                  <Tabs.Tab value="audit">Audit Logs</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="test" pt="lg">
                  <TestWebhook webhook={webhook} />
                </Tabs.Panel>

                <Tabs.Panel value="audit" pt="xs">
                  <AuditLogsNested
                    extraParams={{ resourceId: webhook.id }}
                    extraClasses="no-border"
                  />
                </Tabs.Panel>
              </Tabs>
            </div>
          </div>
        </ScrollArea>
      )}
    </Drawer>
  );
};

interface Props {
  webhook: WebhookType;
}

const WebhookInfo = ({ webhook }: Props) => {
  return (
    <div className="p-i-info">
      <div className="p-i-main">
        <div>
          <DetailItem2 title="URL" desc={webhook.url} extraClasses="stack" />

          <DetailItem2
            title="Label"
            desc={webhook.label}
            extraClasses="stack"
          />

          <DetailItem2
            title="Delivery mode"
            desc={webhook.deliveryMode}
            extraClasses="stack"
          />

          <DetailItem2
            title="Secret token"
            desc={webhook.secretToken}
            extraClasses="stack"
          />

          <DetailItem2
            title="Support Included"
            desc={capitalizeTransform(`${webhook.supportIncluded}`)}
            extraClasses="stack"
          />
        </div>

        <div>
          <DetailItem2
            title="Status"
            desc={webhook.status}
            extraClasses="stack"
          />

          <DetailItem2
            title="Date created"
            desc={formatDate2(webhook.createdAt, 'DD-MMM-YY hh:mm:ss A')}
            extraClasses="stack"
          />

          <DetailItem2 title="ID" desc={webhook.id} extraClasses="stack" />
        </div>
      </div>

      <WebhookEvents webhook={webhook} />
    </div>
  );
};

const WebhookEvents = ({ webhook }: Props) => {
  const [groupedEvents, setGroupedEvents] = useState<Record<string, string[]>>(
    {}
  );

  useEffect(() => {
    handleEventsGrouping();
    //eslint-disable-next-line
  }, [webhook]);

  const handleEventsGrouping = () => {
    const grouped = webhook.enabledEvents.reduce((acc, event) => {
      const key = event && event.split('.')[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(event);
      return acc;
    }, {} as Record<string, string[]>);

    setGroupedEvents(grouped);
  };

  return (
    <div className="permission-list">
      <div className="p-l-title">Events</div>

      {Object.keys(groupedEvents)
        .filter((group: string) => !unsupportedEvents.includes(group))
        .map((group: string, index: number) => (
          <DetailItem2
            key={index}
            title={convertToTitleCase(group)}
            desc={
              <div className="permissions-list">
                {groupedEvents[group]
                  .map((eventType: string) => eventType)
                  .map((eventType: string) => (
                    <span
                      className={`permission-type ${getPermissionColor(
                        eventType
                      )}`}
                      key={eventType}
                    >
                      {eventType &&
                        convertToTitleCase(
                          eventType.replace(group + '.', '')
                        )}{' '}
                    </span>
                  ))}
              </div>
            }
            extraClasses="align-start column-sm"
          />
        ))}
    </div>
  );
};

interface TestWebhookProps {
  webhook: WebhookType;
}

const TestWebhook = ({ webhook }: TestWebhookProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const { sendSampleEvent } = useWebhooksService();
  const { handleError } = useNotification();
  const [sampleEventResponse, setSampleEventResponse] =
    useState<SendSampleEventResponse | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>('request');

  const handleSendSampleEvent = () => {
    if (!selectedEvent) return;

    setLoading(true);

    sendSampleEvent(webhook.id, {
      eventType: selectedEvent,
    })
      .then((res: SendSampleEventResponse) => {
        setSampleEventResponse(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <LoadingOverlay visible={loading} />

      <Group align="flex-end" spacing={10} className="test-webhook" mb={20}>
        <Select
          label="Test event"
          placeholder="Select event"
          data={webhook.enabledEvents.filter(
            (event) =>
              event && !(event.includes('ach') || event.includes('wire'))
          )}
          searchable
          autoComplete="off"
          value={selectedEvent}
          onChange={setSelectedEvent}
          rightSection={<ChevronDownGray />}
        />

        <Button
          color="dark"
          variant="default"
          onClick={handleSendSampleEvent}
          disabled={!selectedEvent}
        >
          Test
        </Button>
      </Group>

      {sampleEventResponse && (
        <Tabs value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="request">Request</Tabs.Tab>
            <Tabs.Tab value="response">Response</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="request" pt="xs">
            <Prism language="json" withLineNumbers trim>
              {JSON.stringify(sampleEventResponse.request, null, 2)}
            </Prism>
          </Tabs.Panel>

          <Tabs.Panel value="response" pt="xs">
            <>
              <Group pl={10} mb={5} align="center" spacing={4}>
                <Text
                  className="cv2"
                  fz={18}
                  color={
                    `${sampleEventResponse.responseCode}`[0] === '2'
                      ? 'green'
                      : 'red'
                  }
                >
                  •
                </Text>
                <Text fz={12} fw={500} opacity={0.7}>
                  {sampleEventResponse.responseCode}
                </Text>
              </Group>

              <Prism language="json" withLineNumbers trim>
                {JSON.stringify(sampleEventResponse?.response, null, 2)}
              </Prism>
            </>
          </Tabs.Panel>
        </Tabs>
      )}
    </div>
  );
};

export default WebhookDetails;
