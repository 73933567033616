import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { AxiosError } from 'axios';
import {
  CreateRoleData,
  GetRolesResponse,
  RoleType,
} from '../types/roleManagementTypes';
import { Mode } from '../lib/store/reducers/SettingsReducer';

const useRoleManagementService = () => {
  const authApi = useApi(ApiAuthMode.BearerToken, Mode.Live);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, Mode.Live);

  const createRole = (data: CreateRoleData) => {
    return new Promise<RoleType>((resolve, reject) => {
      authApi
        .post(APIS.ROLE_MANAGEMENT.ROLES, data)
        .then((res: { data: RoleType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getRoles = (size: number, page: number) => {
    return new Promise<GetRolesResponse>((resolve, reject) => {
      authApi
        .get(`${APIS.ROLE_MANAGEMENT.ROLES}?size=${size}&page=${page}`)
        .then((res: GetRolesResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getRole = (roleId: string) => {
    return new Promise<RoleType>((resolve, reject) => {
      authApi
        .get(APIS.ROLE_MANAGEMENT.ROLE(roleId))
        .then((res: { data: RoleType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const deleteRole = (roleId: string) => {
    return new Promise((resolve, reject) => {
      authApi
        .delete(APIS.ROLE_MANAGEMENT.ROLES, roleId)
        .then((res: { data: { message: string } }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const updateRole = (body: CreateRoleData, roleId: string) => {
    return new Promise<RoleType>((resolve, reject) => {
      axiosApi
        .put(APIS.ROLE_MANAGEMENT.ROLE(roleId), {
          data: {
            type: 'Role',
            attributes: body,
          },
        })
        .then((res: { data: RoleType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    createRole,
    getRoles,
    getRole,
    deleteRole,
    updateRole,
  };
};

export default useRoleManagementService;
