import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { AxiosError } from 'axios';
import {
  ApiKeyType,
  CreateApiKeyData,
  GetApiKeysResponse,
} from '../types/apiKeysTypes';
import { Mode } from '../lib/store/reducers/SettingsReducer';

const useApiKeysService = () => {
  const authApi = useApi(ApiAuthMode.BearerToken, Mode.Live);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, Mode.Live);

  const createApiKey = (body: CreateApiKeyData) => {
    return new Promise<ApiKeyType>((resolve, reject) => {
      authApi
        .post(APIS.API_MANAGEMENT.API_KEYS, body)
        .then((res: { data: ApiKeyType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getApiKeys = (size: number, page: number) => {
    return new Promise<GetApiKeysResponse>((resolve, reject) => {
      authApi
        .get(`${APIS.API_MANAGEMENT.API_KEYS}?size=${size}&page=${page}`)
        .then((res: GetApiKeysResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getApiKey = (apikeyId: string) => {
    return new Promise<ApiKeyType>((resolve, reject) => {
      authApi
        .get(APIS.API_MANAGEMENT.API_KEY(apikeyId))
        .then((res: { data: ApiKeyType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const activateApiKey = (apikeyId: string) => {
    return new Promise<ApiKeyType>((resolve, reject) => {
      axiosApi
        .post(APIS.API_MANAGEMENT.ACTIVATE_API_KEY(apikeyId))
        .then((res: { data: { data: ApiKeyType } }) => {
          resolve(res.data.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const revokeApiKey = (apikeyId: string) => {
    return new Promise((resolve, reject) => {
      axiosApi
        .post(APIS.API_MANAGEMENT.REVOKE_API_KEY(apikeyId))
        .then((res: { data: { data: ApiKeyType } }) => {
          resolve(res.data.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const deleteApiKey = (apikeyId: string) => {
    return new Promise((resolve, reject) => {
      axiosApi
        .delete(APIS.API_MANAGEMENT.DELETE_API_KEY(apikeyId))
        .then((res: { data: void }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    createApiKey,
    getApiKeys,
    getApiKey,
    activateApiKey,
    revokeApiKey,
    deleteApiKey,
  };
};

export default useApiKeysService;
