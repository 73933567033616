import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetAccountsParams,
  GetAccountsResponse,
  CreateBankAccountData,
  AccountType,
  AccountBalanceType,
} from '../types/accountsTypes';
import { Mode } from '../lib/store/reducers/SettingsReducer';

const useAccountsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);
  const authApiLive = useApi(ApiAuthMode.BearerToken, Mode.Live);

  const getAccounts = (params: GetAccountsParams) => {
    return new Promise<GetAccountsResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.ACCOUNTS.ACCOUNTS}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: '',
              ...(params?.accountType && {
                accountType: params?.accountType,
              }),
              ...(params?.customerId && {
                customerId: params?.customerId,
              }),
              ...(params?.query && {
                query: params?.query,
              }),
            },
          }
        )
        .then((res: GetAccountsResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getAccountDetails = (accountId: string) => {
    return new Promise<AccountType>((resolve, reject) => {
      authApi
        .get(`${APIS.ACCOUNTS.GET_ACCOUNT_DETAILS(accountId)}`, {
          params: {
            include: 'BusinessCustomer,IndividualCustomer,VirtualNuban',
          },
        })
        .then((res: { data: AccountType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getAccountBalance = (accountId: string) => {
    return new Promise<AccountBalanceType>((resolve, reject) => {
      authApi
        .get(`${APIS.ACCOUNTS.GET_ACCOUNT_BALANCE(accountId)}`)
        .then((res: { data: AccountBalanceType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const freezeAccount = (
    accountId: string,
    body: {
      freezeReason: string;
      freezeDescription: string;
    }
  ) => {
    return new Promise((resolve, reject) => {
      authApi
        .request({
          method: 'POST',
          url: APIS.ACCOUNTS.FREEZE_ACCOUNT(accountId),
          type: 'FreezeAccount',
          body,
        })
        .then((res: any) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const unfreezeAccount = (accountId: string) => {
    return new Promise((resolve, reject) => {
      authApi
        .request({
          method: 'POST',
          url: APIS.ACCOUNTS.UNFREEZE_ACCOUNT,
          type: 'UnreezeAccount',
          body: { id: accountId },
        })
        .then((res: any) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const createBankAccount = (data: CreateBankAccountData) => {
    return new Promise<{ headers: any }>((resolve, reject) => {
      const body = {
        productName: data.productName,
        metadata: data.metadata,
        customer: {
          data: {
            id: data.customerId,
            type: data.customerId.includes('anc_ind_cst')
              ? 'IndividualCustomer'
              : 'BusinessCustomer',
          },
        },
      };

      authApi
        .request({
          method: 'POST',
          url: APIS.ACCOUNTS.ACCOUNTS,
          type: 'DepositAccount',
          body,
        })
        .then((res: { headers: unknown }) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getRootAccounts = () => {
    return new Promise<AccountType[]>((resolve, reject) => {
      authApi
        .get(APIS.ACCOUNTS.ROOT_ACCOUNTS)
        .then((res: { data: AccountType[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getAccountProducts = () => {
    return new Promise<string[]>((resolve, reject) => {
      authApiLive
        .get(APIS.ACCOUNTS.GET_ACCOUNT_PRODUCTS)
        .then((res: { data: string[] }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getAccounts,
    getAccountDetails,
    getAccountBalance,
    freezeAccount,
    unfreezeAccount,
    createBankAccount,
    getRootAccounts,
    getAccountProducts,
  };
};

export default useAccountsService;
