import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { Drawer, ScrollArea, Skeleton, Stack, Text } from '@mantine/core';
import useAuditsService from '../../services/audits';
import { AuditType } from '../../types/auditsTypes';
import useNotification from '../../hooks/useNotification';
import { DetailItem2 } from '../../components/DetailItem/DetailItem';
import './audit-logs.scss';
import { Prism } from '@mantine/prism';
import { formatDate2 } from '../../lib/util';
import { ResourceTypes } from '../../types/resourceTypes';

interface AuditDetailsProps {
  auditId: string;
  drawerOpen: boolean;
  closeDrawer: () => void;
}

const AuditDetails = ({
  auditId,
  drawerOpen,
  closeDrawer,
}: AuditDetailsProps) => {
  const { getAudit } = useAuditsService();
  const [audit, setAudit] = useState<AuditType | null>(null);
  const [auditUnlinked, setAuditUnlinked] = useState<JSON | null>(null);
  const { handleError } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (drawerOpen) {
      handleGetAudit();
      handleGetAuditUnlinked();
    } else {
      setAudit(null);
      setAuditUnlinked(null);
    }
    //eslint-disable-next-line
  }, [drawerOpen]);

  const handleGetAudit = () => {
    getAudit(auditId)
      .then((res: AuditType) => {
        setAudit(res);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const handleGetAuditUnlinked = () => {
    getAudit(auditId, true)
      .then((res: AuditType) => {
        setAuditUnlinked(res as unknown as JSON);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const clickableResources = [
    ResourceTypes.NipTransfer,
    ResourceTypes.BookTransfer,
    ResourceTypes.Payment,
    ResourceTypes.DepositAccount,
    ResourceTypes.SubAccount,
    ResourceTypes.BusinessCustomer,
  ];

  const handleResourceClick = () => {
    if (
      audit &&
      clickableResources.includes(audit.resource.data.type as ResourceTypes)
    ) {
      switch (audit.resource.data.type) {
        case ResourceTypes.NipTransfer:
          navigate(`/nip-transfers?id=${audit?.resource?.data?.id}`);
          break;

        case ResourceTypes.BookTransfer:
          navigate(`/book-transfers?id=${audit?.resource?.data?.id}`);
          break;

        case ResourceTypes.Payment:
          navigate(`/payments?id=${audit?.resource?.data?.id}`);
          break;

        case ResourceTypes.DepositAccount:
          navigate(`/account/${audit?.resource?.data?.id}`);
          break;

        case ResourceTypes.SubAccount:
          navigate(`/sub-account/${audit?.resource?.data?.id}`);
          break;

        case ResourceTypes.BusinessCustomer:
          navigate(`/customers/business/${audit?.resource?.data?.id}`);
          break;

        default:
          break;
      }
    }
  };

  return (
    <Drawer
      opened={drawerOpen}
      onClose={closeDrawer}
      title={
        <Text fw={500} color="#2E2E2E">
          Audit log
        </Text>
      }
      padding="md"
      position="bottom"
      size={625}
      overlayOpacity={0.5}
      className="details-drawer-bottom"
    >
      <ScrollArea style={{ height: 535 }} type="scroll">
        <div className="audit-details">
          <div className="a-d-left">
            <DetailItem2 title="Audit ID" desc={audit?.id ?? ''} />

            <DetailItem2 title="Event Name" desc={audit?.eventName ?? ''} />

            <DetailItem2 title="Event Type" desc={audit?.eventType ?? ''} />

            <DetailItem2 title="Actor" desc={audit?.actor ?? ''} />

            <DetailItem2 title="Location" desc={audit?.location ?? ''} />

            <DetailItem2 title="IP Address" desc={audit?.ipAddress ?? ''} />

            <DetailItem2
              title="Timestamp"
              desc={
                audit?.createdAt
                  ? formatDate2(audit.createdAt, 'DD-MMM-YY hh:mm:ss A')
                  : ''
              }
            />

            <DetailItem2 title="Device" desc={audit?.userAgent ?? ''} />

            <DetailItem2
              title="Source"
              desc={<span>{audit?.user ?? ''}</span>}
            />

            <DetailItem2
              title="Resource"
              desc={
                <span
                  className={
                    audit?.resource?.data?.type &&
                    clickableResources.includes(
                      audit?.resource?.data?.type as ResourceTypes
                    )
                      ? 'resource-link click'
                      : ''
                  }
                  onClick={handleResourceClick}
                >
                  {audit?.resource?.data?.type ?? ''}
                </span>
              }
            />
          </div>

          <div className="a-d-right">
            <ScrollArea sx={{ height: 525 }}>
              {auditUnlinked && (
                <Prism language="json" withLineNumbers>
                  {JSON.stringify(auditUnlinked, null, 2)}
                </Prism>
              )}

              {!auditUnlinked && (
                <Stack mt={20} spacing={16} pl={16}>
                  <Skeleton height={8} radius="xl" width="20%" />
                  <Skeleton height={8} radius="xl" width="61%" />
                  <Skeleton height={8} width="40%" radius="xl" />
                  <Skeleton height={8} radius="xl" width="20%" />
                  <Skeleton height={8} radius="xl" width="60%" />
                  <Skeleton height={8} width="40%" radius="xl" />
                  <Skeleton height={8} radius="xl" width="20%" />
                  <Skeleton height={8} radius="xl" width="50%" />
                  <Skeleton height={8} width="40%" radius="xl" />
                  <Skeleton height={8} radius="xl" width="20%" />
                  <Skeleton height={8} radius="xl" width="66%" />
                  <Skeleton height={8} width="40%" radius="xl" />
                </Stack>
              )}
            </ScrollArea>
          </div>
        </div>
      </ScrollArea>
    </Drawer>
  );
};

export default AuditDetails;
