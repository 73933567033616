import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Group, CloseButton, Button, LoadingOverlay } from '@mantine/core';
import useVirtualNubansService from '../../services/virtualNubans';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import { selectMode } from '../../lib/store';
import VirtualNubansTable from './VirtualNubansTable';
import { setVirtualNubans } from '../../lib/store/reducers/DataReducer';
import { GetVirtualNubansResponse } from '../../types/virtualNubansTypes';
import useNotification from '../../hooks/useNotification';
import usePermissions from '../../hooks/usePermissions';

const VirtualNubans = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [query] = useState<string>('');
  const { getVirtualNubans } = useVirtualNubansService();
  const virtualNubans = useAppSelector((state) => state.data.virtualNubans);
  const mode = useAppSelector(selectMode);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const navigate = useNavigate();
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { virtualAaccountWrite } = usePermissions();

  useEffect(() => {
    if (search) {
      handleGetVirtualNubans(search);
    } else {
      handleGetVirtualNubans();
    }
    //eslint-disable-next-line
  }, [page, size, mode, search, query]);

  const handleGetVirtualNubans = (searchQuery?: string) => {
    if (!virtualNubans.dataFetched || searchQuery) setLoading(true);

    getVirtualNubans({ size, page, query: searchQuery ? searchQuery : query })
      .then((res: GetVirtualNubansResponse) => {
        dispatch(
          setVirtualNubans({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearSearch = () => {
    setLoading(true);
    navigate('/virtual-nubans', { replace: true });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Account Numbers | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of account numbers"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <Group position="apart" align="center" mb={25}>
        <div className={`p-title ${search ? 'search-result' : ''}`}>
          {search ? `Showing results for '${search}'` : 'Account Numbers'}
        </div>

        {search ? (
          <CloseButton
            title="Clear search"
            size="xl"
            iconSize={20}
            onClick={clearSearch}
          />
        ) : (
          virtualNubans.meta.total > 0 && (
            <Button
              color="dark"
              onClick={() => {
                navigate('/account-numbers/add');
              }}
              disabled={!virtualAaccountWrite}
            >
              Add Account Number
            </Button>
          )
        )}
      </Group>

      <div className="p-title extra-mb"></div>

      {virtualNubans.meta.total > 0 && (
        <VirtualNubansTable
          virtualNubans={virtualNubans}
          setPage={setPage}
          loading={loading}
          setLoading={setLoading}
          setSize={setSize}
        />
      )}

      {virtualNubans.meta.total === 0 && (
        <EmptyData
          title="There are no account numbers to display"
          desc="At the moment, there is no data to be displayed."
          buttonAction={() => {
            navigate('/account-numbers/add');
          }}
          buttonText="Add Account Number"
        />
      )}
    </div>
  );
};

export default VirtualNubans;
