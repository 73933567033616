import React from 'react';
import { Group, Divider, Text, Button } from '@mantine/core';

import { ReactComponent as Information } from '../../../assets/svg/information.svg';
import { ReactComponent as ArrowDownRight } from '../../../assets/svg/arrow-down-right.svg';
import {
  Address as AddressType,
  OfficerType,
} from '../../../types/businessTypes';
import { formatDate2, formatName, getCountry } from '../../../lib/util';
import { DocumentType, DocumentTypes } from '../../../types/documentTypes';
import {
  ComplianceStatus,
  OrganizationCompliance,
} from '../../../types/organizationTypes';
import {
  Address,
  BusinessOfficer,
  CardItem,
  Document,
} from '../../Customers/BusinessCustomerOverview';
import { scrollToElement } from '../../../lib/util';

interface IKycOverview {
  orgCompliance: OrganizationCompliance;
  isLive: boolean;
  documents: DocumentType[];
  officers: OfficerType[];
  setActiveView: React.Dispatch<React.SetStateAction<'overview' | 'edit' | ''>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleDocumentDownload: (documentId: string) => void;
}

const KycOverview = ({
  orgCompliance,
  documents,
  officers,
  isLive,
  setActiveView,
  setActiveStep,
  handleDocumentDownload,
}: IKycOverview) => {
  const goToEdit = (step: number) => {
    setActiveView('edit');
    setActiveStep(step);
  };

  const sections = [
    {
      title: 'Business Information',
      id: 'business-information',
    },
    {
      title: 'Business Signatory',
      id: 'business-signatory',
    },
    {
      title: 'Business Addresses',
      id: 'business-address',
    },
    {
      title: 'Business Documents',
      id: 'business-documents',
    },
    ...officers.map((officer: OfficerType, index) => ({
      title: `Business Officer ${index + 1}`,
      id: officer.id,
    })),
  ];

  return (
    <div className="business-details">
      <div className="sections-list">
        {sections.map((section) => (
          <div
            key={section.id}
            className="section-item click"
            onClick={() => {
              scrollToElement(section.id);
            }}
          >
            &#8226; {section.title}
          </div>
        ))}
      </div>

      <Text className="page-title">{orgCompliance.orgName}</Text>

      <div className="details-main">
        <BusinessInformation
          orgCompliance={orgCompliance}
          documents={documents}
          isLive={isLive}
          goToEdit={goToEdit}
        />

        {(orgCompliance.complianceStatus.primaryContactSubmitted ||
          orgCompliance.complianceStatus.organizationContactDetails) && (
          <BusinessContact
            compliance={orgCompliance.complianceStatus}
            goToEdit={goToEdit}
            isLive={isLive}
          />
        )}

        {orgCompliance.address && (
          <BusinessAddress
            address={orgCompliance.address}
            documents={documents}
            handleDocumentDownload={handleDocumentDownload}
            goToEdit={goToEdit}
            isLive={isLive}
          />
        )}

        <BusinessDocuments
          documents={documents}
          compliance={orgCompliance.complianceStatus}
          handleDocumentDownload={handleDocumentDownload}
          goToEdit={goToEdit}
          isLive={isLive}
        />

        {officers.map((officer: OfficerType, index) => (
          <BusinessOfficer
            key={index}
            index={index}
            officer={officer}
            type="Business"
            goToEdit={goToEdit}
            isLive={isLive}
          />
        ))}
      </div>
    </div>
  );
};

interface IBusinessInformation {
  orgCompliance: OrganizationCompliance;
  documents: DocumentType[];
  isLive: boolean;
  goToEdit: (step: number) => void;
}

const BusinessInformation = ({
  orgCompliance,
  documents,
  isLive,
  goToEdit,
}: IBusinessInformation) => {
  const {
    orgName,
    complianceStatus: { basicDetail, organizationContactDetails },
  } = orgCompliance;

  return (
    <div className="card reduce-mt" id="business-information">
      <div className="card-title">
        <Information /> Business Information
      </div>

      <div className="card-items">
        <CardItem title="Business Name / DBA" value={orgName} />

        <CardItem
          title="RC Number"
          value={
            documents.find(
              (doc) => doc.documentType === DocumentTypes.RC_NUMBER
            )?.data ?? ''
          }
        />

        <CardItem
          title="TIN"
          value={
            documents.find((doc) => doc.documentType === DocumentTypes.TIN)
              ?.data ?? ''
          }
        />

        <CardItem
          title="Registration Type "
          value={
            orgCompliance.complianceStatus?.basicDetail?.registrationType
              ? orgCompliance.complianceStatus.basicDetail.registrationType.replaceAll(
                  '_',
                  ' '
                )
              : ''
          }
        />

        <CardItem
          title="Website "
          value={orgCompliance.complianceStatus?.basicDetail?.website ?? ''}
        />

        <CardItem
          title="Business Description"
          value={<span className="light">{basicDetail?.description}</span>}
        />

        <CardItem title="Business ID " value={orgCompliance.id} />

        <CardItem
          title="Country"
          value={getCountry(basicDetail?.country ?? '')}
        />

        <CardItem title="Is live?" value={isLive ? 'Yes' : 'No'} />
      </div>

      {organizationContactDetails && (
        <>
          <Divider my={22} />

          <div className="card-items">
            <CardItem
              title="Phone number"
              value={organizationContactDetails.phoneNumber}
            />

            <CardItem
              title="General Email"
              value={organizationContactDetails.email.general}
            />

            <CardItem
              title="Support Email"
              value={organizationContactDetails.email.support}
            />

            <CardItem
              title="Dispute Email"
              value={organizationContactDetails.email.dispute}
            />
          </div>
        </>
      )}

      {!isLive && (
        <Group position="right" mt={32}>
          <Button
            color="dark"
            leftIcon={<ArrowDownRight />}
            size="xs"
            onClick={() => {
              goToEdit(0);
            }}
          >
            Edit
          </Button>
        </Group>
      )}
    </div>
  );
};

interface IBusinessContact {
  compliance: ComplianceStatus;
  goToEdit: (step: number) => void;
  isLive: boolean;
}

const BusinessContact = ({
  compliance,
  goToEdit,
  isLive,
}: IBusinessContact) => {
  const { primaryContact } = compliance;

  return (
    <div className="card" id="business-signatory">
      <div className="card-title">
        <Information /> Business Signatory
      </div>

      {primaryContact && (
        <>
          <div className="card-items">
            <CardItem
              title="Primary Contact Full Name"
              value={formatName(primaryContact.fullName)}
            />

            <CardItem
              title="Primary Contact Phone"
              value={primaryContact.phoneNumber}
            />

            <CardItem
              title="Primary Contact Email"
              value={primaryContact.email}
            />

            <CardItem title="Primary Contact BVN" value={primaryContact.bvn} />
          </div>
        </>
      )}

      {!isLive && (
        <Group position="right" mt={32}>
          <Button
            color="dark"
            leftIcon={<ArrowDownRight />}
            size="xs"
            onClick={() => {
              goToEdit(1);
            }}
          >
            Edit
          </Button>
        </Group>
      )}
    </div>
  );
};

interface IBusinessAddress {
  address: {
    main: AddressType;
    registered: AddressType;
  };
  documents: DocumentType[];
  handleDocumentDownload: (documentId: string) => void;
  goToEdit: (step: number) => void;
  isLive: boolean;
}

const BusinessAddress = ({
  address,
  documents,
  handleDocumentDownload,
  goToEdit,
  isLive,
}: IBusinessAddress) => {
  return (
    <div className="card" id="business-address">
      <div className="card-title">
        <Information /> Business Addresses
      </div>

      <div className="card-items extra-space">
        <CardItem
          title="Registered Address"
          value={<Address address={address.registered} />}
        />

        <CardItem
          title="Current Office Address"
          value={<Address address={address.main} />}
        />
      </div>

      <Divider my={22} color="#E6E6E6" />

      <div className="attachments">
        <div className="a-title">Attachments</div>

        {documents.map((document: DocumentType) => {
          const addressTypes = [DocumentTypes.PROOF_OF_ADDRESS];

          return (
            addressTypes.includes(document.documentType) && (
              <Document
                key={document.id}
                document={document}
                download={handleDocumentDownload}
              />
            )
          );
        })}

        {!documents.find(
          (doc) => doc.documentType === DocumentTypes.PROOF_OF_ADDRESS
        ) && (
          <Text color="gray" mt={20} fz={14}>
            No document uploaded.
          </Text>
        )}
      </div>

      {!isLive && (
        <Group position="right" mt={32}>
          <Button
            color="dark"
            leftIcon={<ArrowDownRight />}
            size="xs"
            onClick={() => {
              goToEdit(0);
            }}
          >
            Edit
          </Button>
        </Group>
      )}
    </div>
  );
};

interface IBusinessDocuments {
  documents: DocumentType[];
  compliance: ComplianceStatus;
  handleDocumentDownload: (documentId: string) => void;
  goToEdit: (step: number) => void;
  isLive: boolean;
}

const BusinessDocuments = ({
  documents,
  compliance,
  handleDocumentDownload,
  goToEdit,
  isLive,
}: IBusinessDocuments) => {
  return (
    <div className="card" id="business-documents">
      <div className="card-title">
        <Information /> Business Documents
      </div>

      <div className="card-items">
        {compliance.basicDetail?.dateOfRegistration && (
          <CardItem
            title="Incorperation Date"
            value={formatDate2(
              compliance.basicDetail.dateOfRegistration,
              'DD-MM-YYYY'
            )}
          />
        )}

        <CardItem
          title="Industry"
          value={compliance.basicDetail?.industry ?? ''}
        />
      </div>

      <Divider my={22} color="#E6E6E6" />

      <div className="attachments">
        <div className="a-title">Attachments</div>

        {documents.map((document: DocumentType) => {
          const businessTypes = [
            DocumentTypes.CERTIFICATE_OF_INCORPORATION,
            DocumentTypes.MEMORANDUM_OF_ASSOCIATION,
            DocumentTypes.CAC_STATUS_REPORT,
          ];

          return (
            businessTypes.includes(document.documentType) && (
              <Document
                key={document.id}
                document={document}
                download={handleDocumentDownload}
              />
            )
          );
        })}

        {documents.length === 0 && (
          <Text color="gray" mt={20} fz={14}>
            No document uploaded.
          </Text>
        )}
      </div>

      {!isLive && (
        <Group position="right" mt={32}>
          <Button
            color="dark"
            leftIcon={<ArrowDownRight />}
            size="xs"
            onClick={() => {
              goToEdit(2);
            }}
          >
            Edit
          </Button>
        </Group>
      )}
    </div>
  );
};

export default KycOverview;
