import { ResponseMetaPagination } from '../lib/types';

export type GetVirtualNubansResponse = {
  data: VirtualNubanType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type VirtualNubanType = {
  id: string;
  type: string;
  settlementAccount: {
    data: Data;
  };
  createdAt: Date;
  bank: {
    id: string;
    name: string;
    nipCode: string;
  };
  accountName: string;
  permanent: boolean;
  currency: string;
  accountNumber: string;
  status: string;
};

interface Data {
  id: string;
  type: string;
}

export enum VirtualNubanStatus {
  active = 'ACTIVE',
}

export type GetVirtualNubansParams = {
  page: number;
  size: number;
  query?: string;
};

export type CreateVirtualNubanData = {
  virtualAccountDetail?: {
    name: string;
    bvn: string;
  };
  settlementAccountId: string;
  metadata?: Record<string, string>;
};
