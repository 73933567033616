import posthog from 'posthog-js';

export const handlePosthogCustomEvent = (event: Event, route: string) => {
  const target = event.target as HTMLElement;
  const parentElement = target.parentElement as HTMLElement;
  const nodeName = target.nodeName;

  switch (nodeName) {
    case 'TD': {
      const parentElementInnerText = parentElement?.innerText;
      const parentElementId = parentElement.id;
      const eventName = `${route} item click`;

      const data = {
        content: parentElementInnerText,
        itemId: parentElementId,
      };

      posthog.capture(eventName, data);
      break;
    }

    case 'SPAN': {
      const innerText = target?.innerText;

      if (target.className.includes('mantine-Button-label')) {
        const eventName = `${route} ${innerText.toLowerCase()} button click`;

        posthog.capture(eventName);
      }
      break;
    }

    case 'DIV': {
      const innerText = target?.innerText;

      if (target.className.includes('mantine-Button-inner')) {
        const eventName = `${route} ${innerText.toLowerCase()} button click`;

        posthog.capture(eventName);
      }
      break;
    }

    case 'A': {
      const innerText = target?.innerText;
      const eventName = `${route} ${innerText.toLowerCase()} link click`;

      posthog.capture(eventName);
      break;
    }
  }
};
