import React from 'react';
import { Burger } from '@mantine/core';
import GlobalSearch from '../GlobalSearch/GlobalSearch';
import EnvSwitch from '../EnvSwitch/EnvSwitch';
interface HeaderProps {
  toggleSidebar: () => void;
  showSidebar: boolean;
}

const Header = ({ toggleSidebar, showSidebar }: HeaderProps) => {
  const title = showSidebar ? 'Close navigation' : 'Open navigation';

  return (
    <div className="logged-m21">
      <div className="loggedin-header">
        <div className="header-items">
          <div className="h-left">
            <Burger
              mr={15}
              opened={showSidebar}
              onClick={toggleSidebar}
              title={title}
              className="menu"
            />

            <div className="search-container">
              <GlobalSearch />
            </div>
          </div>

          <EnvSwitch />
        </div>
      </div>
    </div>
  );
};

export default Header;
