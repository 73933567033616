import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import useNotification from '../../../hooks/useNotification';
import { DetailItem2 } from '../../../components/DetailItem/DetailItem';
import { formatDate2 } from '../../../lib/util';
import { UserType } from '../../../types/userManagementTypes';
import {
  ActionIcon,
  Button,
  Drawer,
  Group,
  LoadingOverlay,
  Menu,
  Tabs,
  Text,
} from '@mantine/core';
import { RoleType, SystemRoles } from '../../../types/roleManagementTypes';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/x-red.svg';
import Confirmation from '../../../components/Confirmation/Confirmation';
import useUserManagementService from '../../../services/userManagement';
import { showNotification } from '@mantine/notifications';
import { ReactComponent as CircleRemove } from '../../../assets/svg/x-mark-circle.svg';
import { ReactComponent as ChevronDownGray } from '../../../assets/svg/chevron-down-gray.svg';
import { useAppSelector } from '../../../hooks/useRedux';
import AuditLogsNested from '../../../components/AuditLogs/AuditLogsNested';

interface UserDetailsProps {
  userId: string;
  drawerOpen: boolean;
  closeDrawer: () => void;
  userActionCallback: () => void;
  roles: RoleType[];
}

const UserDetails = ({
  userId,
  drawerOpen,
  closeDrawer,
  userActionCallback,
  roles,
}: UserDetailsProps) => {
  const [activeTab, setActiveTab] = useState<string | null>('details');
  const [user, setUser] = useState<UserType | null>(null);
  const { getUser } = useUserManagementService();
  const { handleError } = useNotification();

  useEffect(() => {
    if (userId && drawerOpen) {
      handleGetUser();
    } else {
      setUser(null);
      setActiveTab('details');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen, userId]);

  const handleGetUser = () => {
    if (!userId) return;

    getUser(userId)
      .then((res: UserType) => {
        setUser(res);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const updateData = () => {
    handleGetUser();
    userActionCallback();
  };

  return (
    <Drawer
      opened={drawerOpen}
      onClose={closeDrawer}
      title={<span className="modal-title">User</span>}
      padding="xl"
      position="right"
      size={625}
      overlayOpacity={0.5}
    >
      {drawerOpen && (
        <Tabs value={activeTab} onTabChange={setActiveTab} color="dark">
          <Tabs.List>
            <Tabs.Tab value="details">Details</Tabs.Tab>
            <Tabs.Tab value="roles">Roles</Tabs.Tab>
            <Tabs.Tab value="audit-trail">Audit trail</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="details" pt="lg">
            <Details
              user={user}
              userActionCallback={userActionCallback}
              closeDrawer={closeDrawer}
            />
          </Tabs.Panel>

          <Tabs.Panel value="roles" pt="lg">
            {user && (
              <Roles
                userRoles={user.roles.data}
                roles={roles}
                user={user}
                userActionCallback={updateData}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel value="audit-trail" pt="lg">
            {user && (
              <AuditLogsNested
                extraParams={{ username: `${user.firstName} ${user.lastName}` }}
              />
            )}
          </Tabs.Panel>
        </Tabs>
      )}
    </Drawer>
  );
};

interface DetailsProps {
  user: UserType | null;
  userActionCallback: () => void;
  closeDrawer: () => void;
}

const Details = ({ user, userActionCallback, closeDrawer }: DetailsProps) => {
  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { deleteUser } = useUserManagementService();
  const { handleError } = useNotification();
  const userDetails = useAppSelector((state) => state.user.userDetails);

  const handleDeleteUser = () => {
    if (user) {
      setLoading(true);

      deleteUser(user.id)
        .then(() => {
          showNotification({
            title: 'Success',
            message: 'User deleted successfully.',
            color: 'orange',
          });

          closeDrawer();
          userActionCallback();
        })
        .catch((error: AxiosError) => {
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const allowUserManage = () => {
    if (!user) {
      return;
    }

    if (
      user.roles.data.find(
        (role: RoleType) => role?.roleName === SystemRoles.PrimaryOwner
      ) ||
      userDetails.email === user.email
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="relative">
      <LoadingOverlay visible={loading} />

      <Confirmation
        isOpened={deleteUserModal}
        title="Are you sure you want to delete this user?"
        closeModal={() => {
          setDeleteUserModal(false);
        }}
        hasInput={false}
        confirmText="Delete"
        submit={() => {
          setDeleteUserModal(false);
          handleDeleteUser();
        }}
      />

      <div className="group-card-item">
        <DetailItem2 title="First name" desc={user?.firstName ?? ''} />

        <DetailItem2 title="Last name" desc={user?.lastName ?? ''} />

        <DetailItem2
          title="Created At"
          desc={
            user?.createdAt
              ? formatDate2(user.createdAt, 'DD-MMM-YY hh:mm:ss A')
              : ''
          }
        />

        <DetailItem2 title="Email" desc={user?.email ?? ''} />

        <DetailItem2 title="Invited by" desc={user?.createdBy ?? ''} />
      </div>

      {allowUserManage() && (
        <Button
          variant="subtle"
          mt={24}
          leftIcon={<DeleteIcon />}
          compact
          color="#DE7965"
          className="click"
          onClick={() => setDeleteUserModal(true)}
        >
          <Text color="#DE7965" fw={500} fz={13}>
            Delete user
          </Text>
        </Button>
      )}
    </div>
  );
};

interface RolesProps {
  userRoles: RoleType[];
  roles: RoleType[];
  user: UserType;
  userActionCallback: () => void;
}

const Roles = ({ userRoles, roles, user, userActionCallback }: RolesProps) => {
  const [showAddRole, setShowAddRole] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { assignRoleToUser, revokeUserRole } = useUserManagementService();
  const { handleError } = useNotification();

  const handleRoleSelect = (roleId: string) => {
    setLoading(true);
    setShowAddRole(false);

    assignRoleToUser({ userId: user.id, roleId })
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Role assigned successfully.',
          color: 'orange',
        });

        userActionCallback();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const revokeRole = (roleId: string) => {
    setLoading(true);

    revokeUserRole({ userId: user.id, roleId })
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Role revoked successfully.',
          color: 'orange',
        });

        userActionCallback();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="relative">
      <LoadingOverlay visible={loading} />

      {userRoles.length > 0 && (
        <div className="roles-list">
          <div className="r-l-title">Roles assigned</div>

          {userRoles.map((role: RoleType) => (
            <div className="r-l-item" key={role.id}>
              <div className="r-l-item-name">{role.roleName}</div>

              {userRoles.length > 1 && (
                <ActionIcon onClick={() => revokeRole(role.id)}>
                  <CircleRemove />
                </ActionIcon>
              )}
            </div>
          ))}
        </div>
      )}

      {userRoles.length === 0 && (
        <Text pt={70} color="gray" align="center">
          No roles to display
        </Text>
      )}

      <Group mt={18}>
        {!showAddRole && (
          <Text
            className="click"
            fw={500}
            fz={13}
            color="#7F7F7F"
            pb={10}
            onClick={() => {
              setShowAddRole(true);
            }}
          >
            + Add new role
          </Text>
        )}

        {showAddRole && (
          <Menu
            shadow="md"
            width="target"
            opened={showAddRole}
            onChange={setShowAddRole}
          >
            <Menu.Target>
              <Group position="apart" align="center" className="select-role">
                <div>Select new role</div>

                <ChevronDownGray />
              </Group>
            </Menu.Target>

            <Menu.Dropdown>
              {roles
                .filter((role: RoleType) =>
                  userRoles.find((item) => item.id === role.id) ? false : true
                )
                .map((role: RoleType, index: number) => (
                  <div
                    key={index}
                    className="menu-item click"
                    onClick={() => handleRoleSelect(role.id)}
                  >
                    {role?.roleName}
                  </div>
                ))}
            </Menu.Dropdown>
          </Menu>
        )}
      </Group>
    </div>
  );
};

export default UserDetails;
