import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import {
  Group,
  Divider,
  LoadingOverlay,
  Badge,
  Text,
  Button,
} from '@mantine/core';
import { ReactComponent as ChevronBack } from '../../assets/svg/chevron-back.svg';
import { ReactComponent as DocumentIcon } from '../../assets/svg/document.svg';
import { ReactComponent as Information } from '../../assets/svg/information.svg';
// import { ReactComponent as Download } from '../../assets/svg/download.svg';
import useBusinessService from '../../services/businesses';
import useNotification from '../../hooks/useNotification';
import { ReactComponent as ArrowDownRight } from '../..//assets/svg/arrow-down-right.svg';
import {
  Address as AddressType,
  BusinessCustomerType,
  OfficerType,
} from '../../types/businessTypes';
import { formatDate2, getCountry, scrollToElement } from '../../lib/util';
import {
  // DocumentFormatTypes,
  DocumentType,
  DocumentTypes,
} from '../../types/documentTypes';
import { getStatusBadgeColor } from './Customers';
import useDocumentsService from '../../services/documents';
import './customers.scss';

const BusinessCustomerOverview = () => {
  const navigate = useNavigate();
  const { businessId } = useParams();
  const [pageTitle, setPageTitle] = useState<string>('Business Details');
  const { getBusinessCustomer, getBusinessOfficers } = useBusinessService();
  const [business, setBusiness] = useState<BusinessCustomerType | null>(null);
  const { getCustomerDocuments, downloadDocument } = useDocumentsService();
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [officers, setOfficers] = useState<OfficerType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleError } = useNotification();

  useEffect(() => {
    getBusinessDetails();
    getBusinessDocuments();
    getOfficers();
    //eslint-disable-next-line
  }, []);

  const getBusinessDetails = () => {
    if (businessId) {
      setLoading(true);

      getBusinessCustomer(businessId)
        .then((res: BusinessCustomerType) => {
          setBusiness(res);
          setPageTitle(res.businessName);
        })
        .catch((axiosError: AxiosError) => {
          handleError(axiosError);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getBusinessDocuments = () => {
    if (businessId) {
      getCustomerDocuments(businessId)
        .then((res: DocumentType[]) => {
          setDocuments(res);
        })
        .catch((axiosError: AxiosError) => {
          handleError(axiosError);
        });
    }
  };

  const getOfficers = () => {
    if (businessId) {
      getBusinessOfficers(businessId)
        .then((res: OfficerType[]) => {
          setOfficers(res);
        })
        .catch((axiosError: AxiosError) => {
          handleError(axiosError);
        });
    }
  };

  const handleDocumentDownload = (documentId: string) => {
    if (businessId) {
      downloadDocument(businessId, documentId)
        .then((res: any) => {
          console.log(res);
        })
        .catch((axiosError: AxiosError) => {
          handleError(axiosError);
        });
    }
  };

  const sections = [
    {
      title: 'Business Information',
      id: 'business-information',
    },
    {
      title: 'Business Address',
      id: 'business-address',
    },
    {
      title: 'Business Documents',
      id: 'business-documents',
    },
    ...officers.map((officer: OfficerType, index) => ({
      title: `Business Officer ${index + 1}`,
      id: officer.id,
    })),
  ];

  return (
    <div className="business-details">
      <Helmet>
        <title>{pageTitle} | Anchor</title>
      </Helmet>

      <LoadingOverlay visible={loading} />

      <Group spacing={8}>
        <ChevronBack className="click" onClick={() => navigate(-1)} />

        <div className="page-title">{pageTitle}</div>
      </Group>

      {!loading && business && (
        <div className="details-main">
          <div className="sections-list">
            {sections.map((section) => (
              <div
                key={section.id}
                className="section-item click"
                onClick={() => {
                  scrollToElement(section.id);
                }}
              >
                &#8226; {section.title}
              </div>
            ))}
          </div>

          <BusinessInformation business={business} documents={documents} />
          <BusinessAddress
            address={business.address}
            documents={documents}
            handleDocumentDownload={handleDocumentDownload}
          />
          <BusinessDocuments
            documents={documents}
            business={business}
            handleDocumentDownload={handleDocumentDownload}
          />
          {officers.map((officer: OfficerType, index) => (
            <BusinessOfficer key={index} index={index} officer={officer} />
          ))}
        </div>
      )}
    </div>
  );
};

interface IBusinessInformation {
  business: BusinessCustomerType;
  documents: DocumentType[];
}

const BusinessInformation = ({ business, documents }: IBusinessInformation) => {
  return (
    <div className="card reduce-mt" id="business-information">
      <div className="card-title">
        <Information /> Business Information
      </div>

      <div className="card-items">
        <CardItem title="Business Name / DBA" value={business.businessName} />

        <CardItem
          title="RC Number"
          value={
            documents.find(
              (doc) => doc.documentType === DocumentTypes.RC_NUMBER
            )?.data ?? ''
          }
        />

        <CardItem
          title="TIN"
          value={
            documents.find((doc) => doc.documentType === DocumentTypes.TIN)
              ?.data ?? ''
          }
        />

        <CardItem
          title="Business Description"
          value={<span className="light">{business.description}</span>}
        />

        <CardItem
          title="Organization ID "
          value={business.organization.data.id}
        />

        <CardItem title="Country" value={getCountry(business.country)} />

        <CardItem
          title="Verification Status"
          value={
            <Badge color={getStatusBadgeColor(business.verification.status)}>
              {business.verification.status}
            </Badge>
          }
        />
      </div>

      <Divider my={22} color="#E6E6E6" />

      <div className="card-items">
        <CardItem title="Phone number" value={business.contact.phoneNumber} />

        <CardItem title="Business BVN" value={business.contact.bvn} />

        <CardItem title="Email" value={business.email.general} />
      </div>
    </div>
  );
};

interface IBusinessAddress {
  address: {
    main: AddressType;
    registered: AddressType;
  };
  documents: DocumentType[];
  handleDocumentDownload: (documentId: string) => void;
}

const BusinessAddress = ({
  address,
  documents,
  handleDocumentDownload,
}: IBusinessAddress) => {
  return (
    <div className="card" id="business-address">
      <div className="card-title">
        <Information /> Business Addresses
      </div>

      <div className="card-items extra-space">
        <CardItem
          title="Registered Address"
          value={<Address address={address.registered} />}
        />

        <CardItem
          title="Current Office Address"
          value={<Address address={address.main} />}
        />
      </div>

      <Divider my={22} color="#E6E6E6" />

      <div className="attachments">
        <div className="a-title">Attachments</div>

        {documents.map((document: DocumentType) => {
          const addressTypes = [DocumentTypes.PROOF_OF_ADDRESS];

          return (
            addressTypes.includes(document.documentType) && (
              <Document
                key={document.id}
                document={document}
                download={handleDocumentDownload}
              />
            )
          );
        })}

        {documents.length === 0 && (
          <Text color="gray" mt={20} fz={14}>
            No document uploaded.
          </Text>
        )}
      </div>
    </div>
  );
};

interface IBusinessDocuments {
  documents: DocumentType[];
  business: BusinessCustomerType;
  handleDocumentDownload: (documentId: string) => void;
}

const BusinessDocuments = ({
  documents,
  business,
  handleDocumentDownload,
}: IBusinessDocuments) => {
  return (
    <div className="card" id="business-documents">
      <div className="card-title">
        <Information /> Business Documents
      </div>

      <div className="card-items">
        <CardItem
          title="Incorperation Date"
          value={formatDate2(business.dateOfRegistration, 'DD-MM-YYYY')}
        />

        <CardItem title="Industry" value={business.industry} />
      </div>

      <Divider my={22} color="#E6E6E6" />

      <div className="attachments">
        <div className="a-title">Attachments</div>

        {documents.map((document: DocumentType) => {
          const businessTypes = [
            DocumentTypes.CERTIFICATE_OF_INCORPORATION,
            DocumentTypes.MEMORANDUM_OF_ASSOCIATION,
            DocumentTypes.CAC_STATUS_REPORT,
          ];

          return (
            businessTypes.includes(document.documentType) && (
              <Document
                key={document.id}
                document={document}
                download={handleDocumentDownload}
              />
            )
          );
        })}

        {documents.length === 0 && (
          <Text color="gray" mt={20} fz={14}>
            No document uploaded.
          </Text>
        )}
      </div>
    </div>
  );
};

interface IBusinessOfficer {
  officer: OfficerType;
  index: number;
  type?: string;
  goToEdit?: (step: number) => void;
  isLive?: boolean;
}

export const BusinessOfficer = ({
  officer,
  index,
  type,
  goToEdit,
  isLive,
}: IBusinessOfficer) => {
  return (
    <div className="card" id={officer.id}>
      <div className="card-title capitalize">
        <Information /> {type ?? 'Business'} Officer {index + 1}
      </div>

      <div className="card-items">
        <CardItem title="First Name" value={officer.fullName.firstName} />

        <CardItem title="Last Name" value={officer.fullName.lastName} />

        {officer.fullName.middleName && (
          <CardItem title="Middle Name" value={officer.fullName.middleName} />
        )}

        {officer.fullName.maidenName && (
          <CardItem title="Maiden Name" value={officer.fullName.maidenName} />
        )}

        <CardItem title="Email Address" value={officer.email} />

        <CardItem title="Phone Number" value={officer.phoneNumber} />

        <CardItem title="Role" value={officer.role} />

        {officer.title && <CardItem title="Role" value={officer.title} />}

        {officer.percentOwned > 0 && (
          <CardItem title="Ownership" value={`${officer.percentOwned}%`} />
        )}

        <CardItem
          title="Address"
          value={<Address address={officer.address} />}
        />
      </div>

      <Divider my={22} color="#E6E6E6" />

      <div className="card-items">
        <CardItem title="Nationality" value={getCountry(officer.nationality)} />

        <CardItem
          title="Date of Birth"
          value={formatDate2(officer.dateOfBirth, 'DD-MM-YYYY')}
        />

        <CardItem
          title="Identification Type"
          value={
            officer.identificationType
              ? officer.identificationType.replaceAll('_', ' ')
              : ''
          }
        />

        <CardItem
          title="Identification Number"
          value={officer?.idDocumentNumber ?? ''}
        />

        <CardItem title="BVN" value={officer.bvn} />
      </div>

      {goToEdit && !isLive && (
        <Group position="right" mt={32}>
          <Button
            color="dark"
            leftIcon={<ArrowDownRight />}
            size="xs"
            onClick={() => {
              goToEdit(1);
            }}
          >
            Edit
          </Button>
        </Group>
      )}
    </div>
  );
};

interface ICardItem {
  title: string;
  value: string | ReactElement;
}

export const CardItem = ({ title, value }: ICardItem) => {
  return (
    <div className="card-item">
      <div className="card-item-title">{title}</div>
      <div className="card-item-value">{value}</div>
    </div>
  );
};

interface IDocument {
  document: DocumentType;
  download: (documentId: string) => void;
}

export const Document = ({ document, download }: IDocument) => {
  return (
    <div className="attachment">
      <div>
        <DocumentIcon /> {document.documentType}{' '}
        {(document.documentType === 'TIN' ||
          document.documentType === 'RC_NUMBER') && (
          <span className="light"> - {document.data}</span>
        )}
      </div>

      {/* <div>
        {formatDate2(document.createdAt, 'DD-MM-YYYY')}{' '}
        {document.format === DocumentFormatTypes.FILE ? (
          <Download className="click" onClick={() => download(document.id)} />
        ) : (
          <span style={{ width: 16 }}></span>
        )}
      </div> */}
    </div>
  );
};

interface IAddress {
  address: AddressType;
}
export const Address = ({ address }: IAddress) => {
  return (
    <Fragment>
      <div className="light">
        {address.addressLine_1 && (
          <span>
            {address.addressLine_1},
            <br />
          </span>
        )}
        {address.addressLine_2 && (
          <span>
            {address.addressLine_2},
            <br />
          </span>
        )}
        {address.city && (
          <span>
            {address.city},
            <br />
          </span>
        )}
        {address.state && (
          <span>
            {address.state},
            <br />
          </span>
        )}
        {address.country && (
          <span>
            {address.country},
            <br />
          </span>
        )}
        {address.postalCode && <span>{address.postalCode}.</span>}
      </div>
    </Fragment>
  );
};

export default BusinessCustomerOverview;
