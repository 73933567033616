import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  TextInput,
  Button,
  Group,
  LoadingOverlay,
  ScrollArea,
  Divider,
  Checkbox,
  Drawer,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import useNotification from '../../../hooks/useNotification';
import { showNotification } from '@mantine/notifications';
import ActionStep from '../../../components/ActionStep/ActionStep';
import { convertToTitleCase } from '../../../lib/util';
import { PermissionType } from '../../../types/apiKeysTypes';
import useAuthService from '../../../services/auth';
import { RoleType } from '../../../types/roleManagementTypes';
import useRoleManagementService from '../../../services/roleManagement';

interface CreateRoleProps {
  modalOpen: boolean;
  closeModal: () => void;
  callback: () => void;
  role: RoleType | null;
}

const CreateRole = ({
  modalOpen,
  closeModal,
  callback,
  role,
}: CreateRoleProps) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const { getPermissions } = useAuthService();
  const { handleError } = useNotification();

  useEffect(() => {
    handleGetPermissions();
    //eslint-disable-next-line
  }, []);

  const handleGetPermissions = () => {
    getPermissions()
      .then((res: PermissionType[]) => {
        setPermissions(
          res.map((permission: PermissionType) => permission.scope)
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  return (
    <Drawer
      opened={modalOpen}
      onClose={closeModal}
      title={
        <span className="modal-title">{role ? 'Edit' : 'Create'} Role</span>
      }
      padding="xl"
      size="100vh"
      position="bottom"
      overlayOpacity={0.5}
      className="details-drawer-bottom gray-bg "
    >
      <RoleForm
        closeModal={closeModal}
        callback={callback}
        permissions={permissions}
        role={role}
      />
    </Drawer>
  );
};

interface RoleFormProps {
  closeModal: () => void;
  callback: () => void;
  permissions: string[];
  role: RoleType | null;
}

const RoleForm = ({
  closeModal,
  callback,
  permissions,
  role,
}: RoleFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [groupedPermissions, setGroupedPermissions] = useState<
    Record<string, string[]>
  >({});
  const { handleError } = useNotification();
  const { createRole, updateRole } = useRoleManagementService();
  const [selectionType, setSelectionType] = useState<'all' | 'custom'>(
    'custom'
  );

  useEffect(() => {
    form.setFieldValue('permissions', []);

    if (role) {
      form.setFieldValue('name', role.roleName);
      form.setFieldValue('description', role.description);
      form.setFieldValue('permissions', role.permissions);

      if (role.permissions.length === permissions.length) {
        setSelectionType('all');
      }
    }
    //eslint-disable-next-line
  }, []);

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      permissions: [''],
    },

    validate: {
      permissions: (value) =>
        selectionType === 'all'
          ? null
          : value.length === 0
          ? 'Select permission(s)'
          : null,
    },
  });

  useEffect(() => {
    if (permissions.length > 0) {
      handlePermissionsGrouping();
    }

    //eslint-disable-next-line
  }, [permissions]);

  const submit = (values: typeof form.values) => {
    const data = values;

    if (selectionType === 'all') {
      data.permissions = permissions;
    }

    setLoading(true);

    const action = role ? updateRole : createRole;

    action(values, `${role?.id}`)
      .then(() => {
        showNotification({
          title: 'Success',
          message: `Role ${role ? 'updated' : 'created'} successfully.`,
          color: 'orange',
        });
        callback();
        closeModal();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePermissionsGrouping = () => {
    const grouped = permissions.reduce((acc, permission) => {
      const key = permission.split('.')[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(permission);
      return acc;
    }, {} as Record<string, string[]>);

    setGroupedPermissions(grouped);
  };

  return (
    <div className="permission-item-create">
      <form
        className="add-drawer"
        onSubmit={form.onSubmit((values) => submit(values))}
      >
        <LoadingOverlay visible={loading} />

        <div className="form-fields">
          <ActionStep
            number={1}
            title="Role Details"
            desc="Please provide some details about this Role"
          />

          <TextInput
            required
            mt={24}
            label="Role Name"
            placeholder="Role name"
            autoComplete="off"
            {...form.getInputProps('name')}
          />

          <Textarea
            mt="sm"
            placeholder="Description"
            label="Description"
            required
            autoComplete="off"
            {...form.getInputProps('description')}
          />
        </div>

        <div className="events">
          <ActionStep
            number={2}
            title={`${role ? 'Edit' : 'Choose'} Permissions`}
            desc="What kind of permissions do you want this tole to have?"
          />

          <ScrollArea type="auto" className="event-selector">
            <Checkbox
              label="Add all permissions"
              color="cyan"
              checked={selectionType === 'all'}
              onChange={(event) => {
                if (event.currentTarget.checked) {
                  form.setFieldValue(
                    'permissions',
                    permissions.map((event) => event)
                  );
                  setSelectionType('all');
                } else {
                  form.setFieldValue('permissions', []);

                  setSelectionType('custom');
                }
              }}
            />

            <Divider my="sm" />

            {Object.keys(groupedPermissions).map((group: string) => (
              <Group className="event-group" key={group} align="flex-start">
                <div className="e-group-name">{convertToTitleCase(group)}</div>

                <div className="group-items">
                  <Checkbox
                    label={`Access to all ${group} data`}
                    color="cyan"
                    checked={
                      selectionType === 'all' ||
                      groupedPermissions[group].every((permission: string) =>
                        form.values.permissions.includes(permission)
                      )
                    }
                    onChange={(event) => {
                      if (event.currentTarget.checked) {
                        form.setFieldValue('permissions', [
                          ...form.values.permissions,
                          ...groupedPermissions[group].map(
                            (permission: string) => permission
                          ),
                        ]);
                      } else {
                        setSelectionType('custom');

                        form.setFieldValue(
                          'permissions',
                          form.values.permissions.filter(
                            (permission) =>
                              !groupedPermissions[group]
                                .map((permission: string) => permission)
                                .includes(permission)
                          )
                        );
                      }
                    }}
                  />

                  {groupedPermissions[group].map((permission: string) => (
                    <Checkbox
                      key={permission}
                      label={convertToTitleCase(
                        permission.replace(group + '.', '')
                      )}
                      color="cyan"
                      checked={
                        selectionType === 'all' ||
                        form.values.permissions.includes(permission)
                      }
                      onChange={(event) => {
                        if (event.currentTarget.checked) {
                          form.setFieldValue('permissions', [
                            ...form.values.permissions,
                            permission,
                          ]);
                        } else {
                          setSelectionType('custom');

                          form.setFieldValue(
                            'permissions',
                            form.values.permissions.filter(
                              (event) => event !== permission
                            )
                          );
                        }
                      }}
                    />
                  ))}
                </div>
              </Group>
            ))}
          </ScrollArea>
        </div>

        <Group className="add-drawer-footer" position="apart">
          <Button
            color="dark"
            variant="default"
            compact
            className="compact-btn"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="dark"
            compact
            className="compact-btn"
            disabled={
              selectionType === 'custom' && form.values.permissions.length === 0
            }
          >
            {role ? 'Save' : 'Create Role'}
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default CreateRole;
