import React, { Fragment } from 'react';
import moment from 'moment';
import { Text, Group, Switch } from '@mantine/core';
import { DatePicker } from '@mantine/dates';

interface IFilterDateItem {
  label: string;
  checkedState: boolean;
  setCheckedState: (value: boolean) => void;
  dateControl: Date | null;
  setDateControl: (value: Date) => void;
  date: string;
  setDate: (value: string) => void;
  onFilterItemChange: (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => void;
}

const FilterDateItem = ({
  label,
  checkedState,
  setCheckedState,
  dateControl,
  setDateControl,
  date,
  setDate,
  onFilterItemChange,
}: IFilterDateItem) => {
  return (
    <Fragment>
      <Group align="center" position="apart" mt={10}>
        <Text fw={500} fz={14}>
          {label}
        </Text>

        <Switch
          checked={checkedState}
          onChange={(event) => setCheckedState(event.currentTarget.checked)}
        />
      </Group>
      {checkedState && (
        <div className="filter-item-expanded">
          <DatePicker
            placeholder={`Select ${label}`}
            value={dateControl}
            onChange={(value: Date) => {
              setDateControl(value);
              if (value) {
                onFilterItemChange(
                  moment(value).format('YYYY-MM-DD'),
                  date,
                  setDate
                );
              } else {
                onFilterItemChange('', date, setDate);
              }
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default FilterDateItem;
