import React, { useState, useEffect } from 'react';
import { posthog } from 'posthog-js';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Paper, LoadingOverlay } from '@mantine/core';
import Header from './Header';
import Sidebar from './Sidebar';
import { useAppSelector } from '../../hooks/useRedux';
import { scrollToPageTop, pageContainerId } from '../../lib/util';
import { CustomerAccountTypes } from '../../types/customersTypes';
import './index.scss';

import Dashboard from '../../pages/Dashboard/Dashboard';
import Customers from '../../pages/Customers/Customers';
import NipTransfers from '../../pages/Transfers/NipTransfers';
import BookTransfers from '../../pages/Transfers/BookTransfers';
import CreateTransfer from '../../pages/Transfers/CreateTransfer';
import Payments from '../../pages/Payments/Payments';
import BankAccounts from '../../pages/Accounts/BankAccounts';
import AccountOverview from '../../pages/Accounts/AccountOverview';
import SubAccountOverview from '../../pages/Accounts/SubAccountOverview';
import BankTransactions from '../../pages/Transactions/BankTransactions';
import OrganizationTransactions from '../../pages/Transactions/OrganizationTransactions';
import SubAccountTransactions from '../../pages/Transactions/SubAccountTransactions';
import Events from '../../pages/Events/Events';
import Onboarding from '../../pages/Onboarding/Onboarding';
import Counterparty from '../../pages/Counterparty/Counterparty';
import CreateCounterparty from '../../pages/Counterparty/CreateCounterparty';
import VirtualNubans from '../../pages/VirtualNubans/VirtualNubans';
import CreateVirtualNuban from '../../pages/VirtualNubans/CreateVirtualNuban';
import CreateBankAccount from '../../pages/Accounts/CreateBankAccount';
import CreateSubAccount from '../../pages/Accounts/CreateSubAccount';
import Webhooks from '../../pages/Developers/Webhooks/Webhooks';
import ApiKeys from '../../pages/Developers/ApiKeys/ApiKeys';
import Users from '../../pages/Settings/Users/Users';
import General from '../../pages/Settings/General/General';
import Profile from '../../pages/Settings/Profile/Profile';
import RootAccounts from '../../pages/Accounts/RootAccounts';
import SubAccounts from '../../pages/Accounts/SubAccounts';
import Settlements from '../../pages/Settlements/Settlements';
import Roles from '../../pages/Settings/Roles/Roles';
import Kyc from '../../pages/Compliance/Kyc/Kyc';
import Reports from '../../pages/Reports/Reports';
import ScheduleReport from '../../pages/Reports/ScheduleReport';
import SubAccountNipTransfers from '../../pages/Transfers/SubAccountNipTransfers';
import SubAccountBookTransfers from '../../pages/Transfers/SubAccountBookTransfers';
import BusinessCustomerOverview from '../../pages/Customers/BusinessCustomerOverview';
import AuditLogs from '../../pages/AuditLogs/AuditLogs';

const LoggedinContainer = () => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const showLoader = useAppSelector((state) => state.utility.showLoader);
  const location = useLocation();
  const { firstName, lastName, email } = useAppSelector(
    (state) => state.user.userDetails
  );
  const organizations = useAppSelector((state) => state.user.organizations);
  const [orgName, setOrgName] = useState<string>('');

  useEffect(() => {
    if (orgName) {
      posthog.identify(
        `${firstName ?? ''}${firstName ? '-' : ''}${email}(${orgName})`,
        {
          name: firstName + ' ' + lastName,
          email,
        }
      );
    }
  }, [email, firstName, lastName, orgName]);

  useEffect(() => {
    if (organizations.length > 0) {
      setOrgName(organizations[0]?.orgName);
    }
  }, [organizations]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    scrollToPageTop();
  }, [location.pathname]);

  return (
    <Paper className="loggedin-container">
      <LoadingOverlay visible={showLoader} />
      <Sidebar {...{ toggleSidebar, showSidebar }} />

      <div className="logged-m21">
        <Header {...{ toggleSidebar, showSidebar }} />

        <div className="main-section" id={pageContainerId}>
          <div className="main-section-inner">
            <Routes>
              <Route path="/" element={<BankTransactions />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/settings/general" element={<General />} />
              <Route path="/settings/profile" element={<Profile />} />
              <Route path="/settings/users" element={<Users />} />
              <Route path="/settings/roles" element={<Roles />} />
              <Route
                path="/customers/individual"
                element={
                  <Customers customerType={CustomerAccountTypes.individual} />
                }
              />
              <Route
                path="/customers/business"
                element={
                  <Customers customerType={CustomerAccountTypes.business} />
                }
              />
              <Route
                path="/customers/business/:businessId"
                element={<BusinessCustomerOverview />}
              />
              <Route path="/nip-transfers" element={<NipTransfers />} />
              <Route path="/book-transfers" element={<BookTransfers />} />
              <Route
                path="/sub-account-nip-transfers"
                element={<SubAccountNipTransfers />}
              />
              <Route
                path="/sub-account-book-transfers"
                element={<SubAccountBookTransfers />}
              />
              <Route path="/transfers/create" element={<CreateTransfer />} />
              <Route path="/counterparty" element={<Counterparty />} />
              <Route
                path="/counterparty/add"
                element={<CreateCounterparty />}
              />
              <Route path="/payments" element={<Payments />} />
              <Route path="/root-accounts" element={<RootAccounts />} />
              <Route path="/bank-accounts" element={<BankAccounts />} />
              <Route
                path="/bank-accounts/create"
                element={<CreateBankAccount />}
              />
              <Route path="/sub-accounts" element={<SubAccounts />} />
              <Route
                path="/sub-accounts/create"
                element={<CreateSubAccount />}
              />
              <Route path="/account/:accountId" element={<AccountOverview />} />
              <Route
                path="/sub-account/:subAccountId"
                element={<SubAccountOverview />}
              />
              <Route path="/api-keys" element={<ApiKeys />} />
              <Route path="/transactions" element={<BankTransactions />} />
              <Route
                path="/organization-transactions"
                element={<OrganizationTransactions />}
              />
              <Route
                path="/sub-account-transactions"
                element={<SubAccountTransactions />}
              />
              <Route path="/events" element={<Events />} />
              <Route path="/account-numbers" element={<VirtualNubans />} />
              <Route
                path="/account-numbers/add"
                element={<CreateVirtualNuban />}
              />
              <Route path="/webhooks" element={<Webhooks />} />
              <Route path="/settlements" element={<Settlements />} />
              <Route path="/compliance/kyc" element={<Kyc />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/reports/schedule" element={<ScheduleReport />} />
              <Route path="/audit-logs" element={<AuditLogs />} />

              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default LoggedinContainer;
