import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  Badge,
  Table,
  ScrollArea,
  Group,
  CloseButton,
  Button,
  LoadingOverlay,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import useAccountsService from '../../services/accounts';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import {
  AccountType,
  AccountStatusTypes,
  GetAccountsResponse,
} from '../../types/accountsTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import AccountsFilter from './AccountsFilter';
import SimulateTransfer from '../../components/modals/Accounts/SimulateTransfer';
import { Mode } from '../../lib/store/reducers/SettingsReducer';
import { setAccounts } from '../../lib/store/reducers/DataReducer';
import useNotification from '../../hooks/useNotification';
import usePermissions from '../../hooks/usePermissions';

const BankAccounts = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [type, setType] = useState<string>('');
  const [query] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const { getAccounts } = useAccountsService();
  const accounts = useAppSelector((state) => state.data.accounts);
  const mode = useAppSelector(selectMode);
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const dispatch = useAppDispatch();
  const [showSimulateTransferModal, setShowSimulateTransferModal] =
    useState<boolean>(false);
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { accountWrite, accountRead } = usePermissions();

  useEffect(() => {
    if (!accountRead) return;

    if (search) {
      handleGetAccounts(search);
    } else {
      handleGetAccounts();
    }
    //eslint-disable-next-line
  }, [page, size, mode, type, customerId, search, query]);

  const handleGetAccounts = (searchQuery?: string) => {
    if (!accounts.dataFetched || searchQuery) setLoading(true);

    getAccounts({
      size,
      page,
      customerId,
      query: searchQuery ? searchQuery : query,
      accountType: type,
    })
      .then((res: GetAccountsResponse) => {
        dispatch(
          setAccounts({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterItemChange = (
    value: string,
    itemState: string,
    setItemState: (value: string) => void
  ) => {
    if (value !== itemState) setLoading(true);
    setItemState(value);
  };

  const clearSearch = () => {
    setLoading(true);
    navigate('/bank-accounts');
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Bank Accounts | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of bank accounts and sub ledgers"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <SimulateTransfer
        modalOpen={showSimulateTransferModal}
        closeModal={() => {
          setShowSimulateTransferModal(false);
        }}
      />

      <Group position="apart" align="center" mb={20}>
        <div className={`p-title ${search ? 'search-result' : ''}`}>
          {search ? `Showing results for '${search}'` : 'Bank Accounts'}
        </div>

        {search ? (
          <CloseButton
            title="Clear search"
            size="xl"
            iconSize={20}
            onClick={clearSearch}
          />
        ) : (
          <Group style={{ display: width < 600 ? 'none' : '' }}>
            {mode === Mode.Test && (
              <Button
                variant="default"
                onClick={() => {
                  setShowSimulateTransferModal(true);
                }}
              >
                Simulate Transfer
              </Button>
            )}

            <Button
              color="dark"
              onClick={() => {
                navigate('/bank-accounts/create');
              }}
              disabled={!accountWrite}
            >
              Add Bank Account
            </Button>
          </Group>
        )}
      </Group>

      {accounts.dataFetched && (
        <React.Fragment>
          <AccountsFilter
            type={type}
            setType={setType}
            customerId={customerId}
            setCustomerId={setCustomerId}
            onFilterItemChange={onFilterItemChange}
          />

          <ScrollArea
            offsetScrollbars
            type="always"
            mt={10}
            className="table-scroll-container"
          >
            <Table
              mt={10}
              verticalSpacing="md"
              sx={{ minWidth: 800 }}
              highlightOnHover
            >
              <thead>
                <tr>
                  <th>Customer name</th>
                  <th>Account number</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  accounts.data.map((account: AccountType, index: number) => (
                    <tr
                      key={index}
                      className="click"
                      onClick={() => {
                        navigate(`/account/${account.id}`);
                      }}
                      id={account.id}
                    >
                      <td className="capitalize">
                        {account?.accountName &&
                          account?.accountName.toLowerCase()}
                      </td>
                      <td className="gray">{account.accountNumber}</td>
                      <td>
                        <div className="type-badge">{account.type}</div>
                      </td>
                      <td>
                        {' '}
                        <Badge
                          color={
                            account.status === AccountStatusTypes.active
                              ? 'green'
                              : 'red'
                          }
                        >
                          {account.status}
                        </Badge>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            {accounts.meta.total === 0 && (
              <EmptyData
                title="You have not created any account"
                desc="At the moment, there is no data to be displayed."
              />
            )}
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={accounts.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {accounts.meta.total === 0 && !accounts.dataFetched && (
        <EmptyData
          title="You have not created any account"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

export default BankAccounts;
