import { ResponseMetaPagination } from '../lib/types';

export type GetSettlementsResponse = {
  data: SettlementType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type SettlementType = {
  id: string;
  type: string;
  payment: {
    data: Data;
  };
  amount: number;
  settledAmount: number;
  status: string;
};

export type GetSettlementsParams = {
  page: number;
  size: number;
  organizationId?: string;
  paymentId?: string;
  status?: SettlementStatusTypes;
};

export enum SettlementStatusTypes {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  FAILED_RETRYING = 'FAILED_RETRYING',
  IN_PROGRESS = 'IN_PROGRESS',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  COMPLETED = 'COMPLETED',
  REVERSED = 'REVERSED',
  REVERSAL_IN_PROGRESS = 'REVERSAL_IN_PROGRESS',
  RETRYING = 'RETRYING',
  REVERSAL_IMPOSSIBLE = 'REVERSAL_IMPOSSIBLE',
  NOT_START = 'NOT_START',
  NOT_VALID = 'NOT_VALID',
}

interface Data {
  id: string;
  type: string;
}
