import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import ReactCodeInput from 'react-code-input';
import { Button, LoadingOverlay } from '@mantine/core';
import AuthHeader from '../../components/Auth/AuthHeader';
import { ReactComponent as BackArrow } from '../../assets/svg/arrow-back.svg';
import useAuthService from '../../services/auth';
import { SigninResponse } from '../../types/authTypes';
import { OrganizationType } from '../../types/organizationTypes';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { login } from '../../lib/store/reducers/UserReducer';
import { goToTestMode } from '../../lib/store/reducers/SettingsReducer';
import { ISessionDetails } from './Signin';
import useNotification from '../../hooks/useNotification';
import { setSessionRoute } from '../../lib/store/reducers/UtilityReducer';

interface IVerify2fa {
  goBack: () => void;
  sessionDetails: ISessionDetails;
}

const Verify2fa = ({ goBack, sessionDetails }: IVerify2fa) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pin, setPin] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { signin, sendOtp, completeMfaSetup } = useAuthService();
  const { handleError } = useNotification();
  const sessionRoute = useAppSelector((state) => state.utility.sessionRoute);

  const resendOtp = () => {
    const { username } = sessionDetails;
    setLoading(true);

    sendOtp(username)
      .then(() => {
        showNotification({
          title: 'OTP Resent',
          message: 'Please check your email.',
          color: 'orange',
        });
        setPin('');
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submit = () => {
    if (sessionDetails.mfaEnabled) {
      handleSignIn();
    } else {
      handleCompleteMfaSetup();
    }
  };

  const handleSignIn = () => {
    if (sessionDetails) {
      const { username, password } = sessionDetails;
      setLoading(true);

      signin({ username, password, otp: pin })
        .then((res: SigninResponse) => {
          let rootCustomerId = '';

          if (res?.organizations?.data.length > 0) {
            const organization: OrganizationType = res?.organizations?.data[0];
            rootCustomerId = organization?.customer?.data?.id;

            if (!organization?.isLive) {
              dispatch(goToTestMode());
            }
          }

          const payload = {
            loggedIn: true,
            loggedInAt: Date.now(),
            organizations: res?.organizations?.data,
            accessToken: res?.accessToken,
            permissions: res?.permissions,
            rootCustomerId: rootCustomerId,
            userDetails: res?.user,
            authKeys: [],
          };

          dispatch(login(payload));

          showNotification({
            title: 'Success',
            message: 'Login successful.',
            autoClose: 1500,
            color: 'orange',
          });

          if (sessionRoute) {
            navigate(sessionRoute, { replace: true });
            dispatch(setSessionRoute(''));
          } else {
            navigate('/transactions');
          }
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCompleteMfaSetup = () => {
    if (sessionDetails) {
      setLoading(true);

      completeMfaSetup(pin, sessionDetails.token)
        .then(() => {
          handleSignIn();
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="auth-page">
      <Helmet>
        <title>Verify 2FA | Anchor</title>
        <meta
          name="description"
          content="Complete two factor authentication to continue"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <AuthHeader />

      <div className="auth-main">
        <div className="back-btn click" onClick={goBack}>
          <BackArrow /> Back
        </div>

        <div className="a-m-title">Two factor authentication</div>

        <div className="a-m-desc">
          Enter the 6-digit confirmation code sent to your email.
        </div>

        <ReactCodeInput
          name="pin"
          inputMode="numeric"
          type="number"
          fields={6}
          onChange={(value) => {
            setPin(value);
          }}
          value={pin}
        />

        <Button
          color="dark"
          mt={20}
          fullWidth
          onClick={submit}
          size="md"
          disabled={pin.length < 6}
        >
          Submit
        </Button>

        <div className="sec-action">
          Didn’t get the code?{' '}
          <span className="click" onClick={resendOtp}>
            Resend code
          </span>
        </div>
      </div>
    </div>
  );
};

export default Verify2fa;
