import { GridValueFormatterParams } from '@mui/x-data-grid';
import currency from 'currency.js';
import momentjs from 'moment';
import { ItemSchema } from '../components/CustomSelectItem/CustomSelectItem';
import secureRandom from 'secure-random';

export const formatAmount = (
  params: GridValueFormatterParams<string> | number
) => {
  if (params === 0 || !params) {
    return currency(0, {
      fromCents: true,
      separator: ',',
      symbol: '',
    }).format();
  }

  let value;
  if (typeof params === 'number') {
    value = params;
  } else if (params && params.value) {
    value = params.value;
  }
  if (value) {
    return currency(value, {
      fromCents: true,
      separator: ',',
      symbol: '',
    }).format();
  }
};

export const formatName = (nameObject: any) => {
  let ret = '';
  if (nameObject) {
    ret = [nameObject.firstName, nameObject.middleName, nameObject.lastName]
      .map((i) => i)
      .join(' ');
  }
  return ret;
};

export const formatAddress = (addressObject: any) => {
  let ret = '';
  if (addressObject) {
    ret = [
      addressObject.addressLine_1,
      addressObject.addressLine_2,
      addressObject.postalCode,
      addressObject.city,
      addressObject.state,
      addressObject.country,
    ]
      .map((i) => i)
      .join(', ');
  }
  return ret;
};

export const messageFromAxiosError = (err: any, defaultMessage: string) => {
  let message;
  try {
    message =
      err.response.data.errors &&
      err.response.data.errors
        .map((errorItem: any) => errorItem && errorItem.detail)
        .join(['<br>']);
  } finally {
    message = message || defaultMessage;
  }
  return message;
};

export const pageContainerId = 'main-secx2c';

export const scrollToPageTop = (smooth?: boolean) => {
  const myElement = document.getElementById(pageContainerId);
  myElement?.scrollTo({ top: 0, ...(smooth && { behavior: 'smooth' }) });
};

export const handleCustomSelectCreate = (
  query: string,
  data: ItemSchema[],
  existAction: () => any,
  setData: any
) => {
  const itemExists = data.find((itemSchema) => itemSchema.value === query);

  if (itemExists) {
    existAction();
  } else {
    const item: ItemSchema = {
      value: query,
      label: query,
      description: query,
    };
    setData((current: ItemSchema[]) => [...current, item]);
    return item;
  }
};

export const capitalizeTransform = (value: string) => {
  if (!value || typeof value !== 'string') {
    return '';
  } else {
    return value
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }
};

export const truncateString = (value: string, max: number) => {
  return value.length > max ? `${value.substring(0, max)}...` : value;
};

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'NGN':
      return '₦';
    case 'USD':
      return '$';
  }
};

export const formatDate2 = (date: string | Date, format: string) => {
  return momentjs(date).format(format);
};

export const getCountry = (code: string) => {
  switch (code) {
    case 'NG':
      return 'Nigeria';
    default:
      return '';
  }
};

export const statesNG = [
  'KANO',
  'LAGOS',
  'KADUNA',
  'KATSINA',
  'OYO',
  'RIVERS',
  'BAUCHI',
  'JIGAWA',
  'BENUE',
  'ANAMBRA',
  'BORNO',
  'DELTA',
  'NIGER',
  'IMO',
  'AKWA_IBOM',
  'OGUN',
  'SOKOTO',
  'ONDO',
  'OSUN',
  'KOGI',
  'ZAMFARA',
  'ENUGU',
  'KEBBI',
  'EDO',
  'PLATEAU',
  'ADAMAWA',
  'CROSS_RIVER',
  'ABIA',
  'EKITI',
  'KWARA',
  'GOMBE',
  'YOBE',
  'TARABA',
  'EBONYI',
  'NASARAWA',
  'BAYELSA',
  'FCT',
];

export const downloadFile = (data: File, filename: string, type: string) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const industries = [
  'Agriculture-AgriculturalCooperatives',
  'Agriculture-AgriculturalServices',
  'Commerce-Automobiles',
  'Commerce-DigitalGoods',
  'Commerce-PhysicalGoods',
  'Commerce-RealEstate',
  'Commerce-DigitalServices',
  'Commerce-LegalServices',
  'Commerce-PhysicalServices',
  'Commerce-ProfessionalServices',
  'Commerce-OtherProfessionalServices',
  'Education-NurserySchools',
  'Education-PrimarySchools',
  'Education-SecondarySchools',
  'Education-TertiaryInstitutions',
  'Education-VocationalTraining',
  'Education-VirtualLearning',
  'Education-OtherEducationalServices',
  'Gaming-Betting',
  'Gaming-Lotteries',
  'Gaming-PredictionServices',
  'FinancialServices-FinancialCooperatives',
  'FinancialServices-CorporateServices',
  'FinancialServices-PaymentSolutionServiceProviders',
  'FinancialServices-Insurance',
  'FinancialServices-Investments',
  'FinancialServices-AgriculturalInvestments',
  'FinancialServices-Lending',
  'FinancialServices-BillPayments',
  'FinancialServices-Payroll',
  'FinancialServices-Remittances',
  'FinancialServices-Savings',
  'FinancialServices-MobileWallets',
  'Health-Gyms',
  'Health-Hospitals',
  'Health-Pharmacies',
  'Health-HerbalMedicine',
  'Health-Telemedicine',
  'Health-MedicalLaboratories',
  'Hospitality-Hotels',
  'Hospitality-Restaurants',
  'Nonprofits-ProfessionalAssociations',
  'Nonprofits-GovernmentAgencies',
  'Nonprofits-NGOs',
  'Nonprofits-PoliticalParties',
  'Nonprofits-ReligiousOrganizations',
  'Nonprofits-Leisure_And_Entertainment',
  'Nonprofits-Cinemas',
  'Nonprofits-Nightclubs',
  'Nonprofits-Events',
  'Nonprofits-Press_And_Media',
  'Nonprofits-RecreationCentres',
  'Nonprofits-StreamingServices',
  'Logistics-CourierServices',
  'Logistics-FreightServices',
  'Travel-Airlines',
  'Travel-Ridesharing',
  'Travel-TourServices',
  'Travel-Transportation',
  'Travel-TravelAgencies',
  'Utilities-CableTelevision',
  'Utilities-Electricity',
  'Utilities-GarbageDisposal',
  'Utilities-Internet',
  'Utilities-Telecoms',
  'Utilities-Water',
  'Retail',
  'Wholesale',
  'Restaurants',
  'Construction',
  'Unions',
  'RealEstate',
  'FreelanceProfessional',
  'OtherProfessionalServices',
  'OnlineRetailer',
  'OtherEducationServices',
];

export const scrollToElement = (
  id: string,
  block?: 'center' | 'end' | 'start' | 'nearest'
) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: block || 'center' });
  }
};

export const removeDuplicatesFromArray = (arr: string[]) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const generateRandomString = (length: number) => {
  return secureRandom(length, { type: 'Array' }).join('');
};

export const convertToTitleCase = (value: string) => {
  const words = value.split(/(?=[A-Z])|_/);

  const capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  const convertedStr = capitalizedWords.join(' ');

  return convertedStr.replace('.', ' ').replace('_', ' ').replace('-', ' ');
};

export const validateUrl = (url: string) => {
  const regex = /^((http|https):\/\/)/;
  return regex.test(url);
};

const checkIfIncludes = (str: string, arr: string[]) => {
  if (!str || !arr) return false;

  return arr.some((item) => str.includes(item));
};

export const getPermissionColor = (permission: string) => {
  const keysGreen = [
    'create',
    'approve',
    'open',
    'success',
    'complete',
    'received',
    'settle',
    'unfrozen',
    'update',
    'read',
  ];
  const keysGray = ['initiate'];
  const keysYellow = ['schedule', 'review', 'reenter', 'await', 'write'];
  const keysRed = ['fail', 'reject', 'reverse', 'close', 'frozen', 'error'];

  if (checkIfIncludes(permission, keysGreen)) {
    return 'green';
  } else if (checkIfIncludes(permission, keysGray)) {
    return 'gray';
  } else if (checkIfIncludes(permission, keysYellow)) {
    return 'yellow';
  } else if (checkIfIncludes(permission, keysRed)) {
    return 'red';
  } else {
    return 'gray';
  }
};
