import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Button, Popover } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import {
  CustomerAccountTypes,
  GetCustomersResponse,
} from '../../types/customersTypes';
import { ItemSchema } from '../../components/CustomSelectItem/CustomSelectItem';
import useAccountsService from '../../services/accounts';
import useCustomersService from '../../services/customers';
import useCounterpartyService from '../../services/counterparties';
import { capitalizeTransform } from '../../lib/util';
import {
  CounterpartyType,
  GetCounterPartiesResponse,
} from '../../types/counterpartiesTypes';
import useNotification from '../../hooks/useNotification';
import { GetAccountsResponse } from '../../types/accountsTypes';
import useSubAccountsService from '../../services/subAccounts';
import { GetSubAccountsResponse } from '../../types/subAccountsTypes';
import FilterSelectItem from '../../components/FilterItem/FilterSelectItem';
import FilterDateItem from '../../components/FilterItem/FilterDateItem';
import { ReactComponent as ChevronDownGray } from '../../assets/svg/chevron-down-gray.svg';
import usePermissions from '../../hooks/usePermissions';

interface ITransfersFilter {
  accountId: string;
  setAccountId: (value: string) => void;
  subAccountId?: string;
  setSubAccountId?: (value: string) => void;
  customerId: string;
  setCustomerId: (value: string) => void;
  counterPartyId?: string;
  setCounterPartyId?: (value: string) => void;
  destinationAccountId?: string;
  setDestinationAccountId?: (value: string) => void;
  destinationSubAccountId?: string;
  setDestinationSubAccountId?: (value: string) => void;
  dateFrom: string;
  setDateFrom: (value: string) => void;
  dateTo: string;
  setDateTo: (value: string) => void;
  onFilterItemChange: (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => void;
}

const TransfersFilter = ({
  accountId,
  setAccountId,
  subAccountId,
  setSubAccountId,
  customerId,
  setCustomerId,
  counterPartyId,
  setCounterPartyId,
  destinationAccountId,
  setDestinationAccountId,
  destinationSubAccountId,
  setDestinationSubAccountId,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  onFilterItemChange,
}: ITransfersFilter) => {
  const { width } = useViewportSize();
  const [expandFilter, setExpandFilter] = useState<boolean>(false);
  const [filterNumber, setFilterNumber] = useState<number>(0);
  const [accountChecked, setAccountChecked] = useState<boolean>(false);
  const [subAccountChecked, setSubAccountChecked] = useState<boolean>(false);
  const [customerChecked, setCustomerChecked] = useState<boolean>(false);
  const [counterpartyChecked, setCounterpartyChecked] =
    useState<boolean>(false);
  const [destinationAccountChecked, setDestinationAccountChecked] =
    useState<boolean>(false);
  const [destinationSubAccountChecked, setDestinationSubAccountChecked] =
    useState<boolean>(false);
  const [dateFromControl, setDateFromControl] = useState<Date | null>(null);
  const [dateFromChecked, setDateFromChecked] = useState<boolean>(false);
  const [dateToControl, setDateToControl] = useState<Date | null>(null);
  const [dateToChecked, setDateToChecked] = useState<boolean>(false);

  const { getAccounts } = useAccountsService();
  const { getCustomers } = useCustomersService();
  const { getCounterparties } = useCounterpartyService();
  const { getSubAccounts } = useSubAccountsService();
  const [accounts, setAccounts] = useState<ItemSchema[]>([]);
  const [subAccounts, setSubAccounts] = useState<ItemSchema[]>([]);
  const [customers, setCustomers] = useState<ItemSchema[]>([]);
  const [counterparties, setCounterparties] = useState<ItemSchema[]>([]);
  const { handleError } = useNotification();
  const { customerRead, accountRead, counterpartyRead, subaccountRead } =
    usePermissions();

  useEffect(() => {
    if (customerRead) {
      handleGetCustomers();
    }
    if (accountRead) {
      handleGetAccounts();
    }

    if (setCounterPartyId && counterpartyRead) {
      handleGetCounterparties();
    }

    if ((setSubAccountId || setDestinationSubAccountId) && subaccountRead) {
      handleGetSubAccounts();
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (accountChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange('', accountId, setAccountId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [accountChecked]);

  useEffect(() => {
    if (!setSubAccountId) {
      return;
    }

    if (subAccountChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange('', subAccountId, setSubAccountId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [subAccountChecked]);

  useEffect(() => {
    if (customerChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange('', customerId, setCustomerId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [customerChecked]);

  useEffect(() => {
    if (!setCounterPartyId) {
      return;
    }

    if (counterpartyChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange('', counterPartyId, setCounterPartyId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [counterpartyChecked]);

  useEffect(() => {
    if (!setDestinationAccountId) {
      return;
    }

    if (destinationAccountChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange('', destinationAccountId, setDestinationAccountId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [destinationAccountChecked]);

  useEffect(() => {
    if (!setDestinationSubAccountId) {
      return;
    }

    if (destinationSubAccountChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange(
        '',
        destinationSubAccountId,
        setDestinationSubAccountId
      );
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [destinationSubAccountChecked]);

  useEffect(() => {
    if (dateFromChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      setDateFromControl(null);
      onFilterItemChange('', dateFrom, setDateFrom);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [dateFromChecked]);

  useEffect(() => {
    if (dateToChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      setDateToControl(null);
      onFilterItemChange('', dateTo, setDateTo);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [dateToChecked]);

  const handleGetAccounts = () => {
    getAccounts({ page: 0, size: 100 })
      .then((res: GetAccountsResponse) => {
        const data: ItemSchema[] = res.data.map((account) => ({
          label: capitalizeTransform(account?.accountName ?? ''),
          description: `${account.accountNumber} (${account.id})`,
          value: account.id,
        }));

        setAccounts(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleGetCounterparties = () => {
    return new Promise((resolve) => {
      getCounterparties({ page: 0, size: 100 })
        .then((res: GetCounterPartiesResponse) => {
          const data: ItemSchema[] = res.data.map((item: CounterpartyType) => ({
            label: capitalizeTransform(
              `${item?.accountName ?? 'N/A'} - ${item?.bank?.name ?? ''}`
            ),
            description: `${item?.accountNumber ?? ''} (${item.id})`,
            value: item.id,
          }));

          setCounterparties(data);
        })
        .catch((err: AxiosError) => {
          handleError(err);
        });
    });
  };

  const handleGetCustomers = () => {
    getCustomers({ page: 0, size: 100 })
      .then((res: GetCustomersResponse) => {
        const data: ItemSchema[] = res.data.map((customer) => {
          return {
            label: capitalizeTransform(
              customer?.type === CustomerAccountTypes.individual
                ? `${customer?.fullName?.firstName} ${customer?.fullName?.lastName}`
                : customer?.detail?.businessName
            ),
            description: customer?.id,
            value: customer?.id,
          };
        });

        setCustomers(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleGetSubAccounts = () => {
    getSubAccounts(100, 0)
      .then((res: GetSubAccountsResponse) => {
        const data: ItemSchema[] = res.data.map((subAccount) => {
          return {
            label: capitalizeTransform(
              subAccount?.customer?.data?.type === CustomerAccountTypes.business
                ? subAccount?.customer?.data?.detail?.businessName
                : `${subAccount?.customer?.data?.fullName?.firstName} ${subAccount?.customer?.data?.fullName?.lastName}`
            ),
            description: subAccount?.id,
            value: subAccount?.id,
          };
        });

        setSubAccounts(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const filterSelectOptions = [
    {
      label: 'Source Account',
      checkedState: accountChecked,
      setCheckedState: setAccountChecked,
      filterValue: accountId,
      setFilterValue: setAccountId,
      filterData: accounts,
      setFilterData: setAccounts,
      permission: accountRead,
    },
    {
      label: 'Source Sub Account',
      checkedState: subAccountChecked,
      setCheckedState: setSubAccountChecked,
      filterValue: subAccountId,
      setFilterValue: setSubAccountId,
      filterData: subAccounts,
      setFilterData: setSubAccounts,
      permission: subaccountRead,
    },
    {
      label: 'Source Customer',
      checkedState: customerChecked,
      setCheckedState: setCustomerChecked,
      filterValue: customerId,
      setFilterValue: setCustomerId,
      filterData: customers,
      setFilterData: setCustomers,
      permission: customerRead,
    },
    {
      label: 'Counterparty',
      checkedState: counterpartyChecked,
      setCheckedState: setCounterpartyChecked,
      filterValue: counterPartyId,
      setFilterValue: setCounterPartyId,
      filterData: counterparties,
      setFilterData: setCounterparties,
      permission: counterpartyRead,
    },
    {
      label: 'Destination Account',
      checkedState: destinationAccountChecked,
      setCheckedState: setDestinationAccountChecked,
      filterValue: destinationAccountId,
      setFilterValue: setDestinationAccountId,
      filterData: accounts,
      setFilterData: setAccounts,
      permission: accountRead,
    },
    {
      label: 'Destination Sub Account',
      checkedState: destinationSubAccountChecked,
      setCheckedState: setDestinationSubAccountChecked,
      filterValue: destinationSubAccountId,
      setFilterValue: setDestinationSubAccountId,
      filterData: subAccounts,
      setFilterData: setSubAccounts,
      permission: subaccountRead,
    },
  ];

  const filterDateOptions = [
    {
      label: 'Date From',
      checkedState: dateFromChecked,
      setCheckedState: setDateFromChecked,
      dateControl: dateFromControl,
      setDateControl: setDateFromControl,
      date: dateFrom,
      setDate: setDateFrom,
    },
    {
      label: 'Date To',
      checkedState: dateToChecked,
      setCheckedState: setDateToChecked,
      dateControl: dateToControl,
      setDateControl: setDateToControl,
      date: dateTo,
      setDate: setDateTo,
    },
  ];

  return (
    <div className="filter-container">
      <Popover
        opened={expandFilter}
        onChange={setExpandFilter}
        position="bottom-end"
        width={320}
        shadow="lg"
      >
        <Popover.Target>
          <Button
            variant="default"
            size={width > 700 ? 'md' : 'sm'}
            onClick={() => {
              setExpandFilter(!expandFilter);
            }}
            compact
            className="compact-btn unstyled"
            rightIcon={<ChevronDownGray />}
          >
            Filters{' '}
            {filterNumber > 0 && (
              <span className="filter-number">{filterNumber}</span>
            )}
          </Button>
        </Popover.Target>

        <Popover.Dropdown>
          <div className="filter-menu">
            {filterSelectOptions.map(
              (item, index) =>
                item.setFilterValue &&
                item.permission && (
                  <FilterSelectItem
                    key={index}
                    label={item.label}
                    checkedState={item.checkedState}
                    setCheckedState={item.setCheckedState}
                    filterValue={item.filterValue}
                    setFilterValue={item.setFilterValue}
                    filterData={item.filterData}
                    setFilterData={item.setFilterData}
                    onFilterItemChange={onFilterItemChange}
                    closeFilterMenu={() => setExpandFilter(false)}
                  />
                )
            )}

            {filterDateOptions.map((item, index) => (
              <FilterDateItem
                key={index}
                label={item.label}
                checkedState={item.checkedState}
                setCheckedState={item.setCheckedState}
                dateControl={item.dateControl}
                setDateControl={item.setDateControl}
                date={item.date}
                setDate={item.setDate}
                onFilterItemChange={onFilterItemChange}
              />
            ))}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default TransfersFilter;
