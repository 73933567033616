import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { AxiosError } from 'axios';
import {
  ScrollArea,
  Text,
  LoadingOverlay,
  Button,
  Group,
  Stack,
  CloseButton,
} from '@mantine/core';
import useTransactionsService from '../../services/transactions';
import { formatAmount, getCurrencySymbol } from '../../lib/util';
import {
  TransactionType,
  TransactionCategories,
  TransactionDirections,
  TransactionTypes,
} from '../../types/transactionsTypes';
import { TransferType } from '../../types/transfersTypes';
import { PaymentType } from '../../types/paymentsTypes';
import { DetailItem2 } from '../../components/DetailItem/DetailItem';
import { capitalizeTransform } from '../../lib/util';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow-right.svg';
import useTransfersService from '../../services/transfers';
import usePaymentsService from '../../services/payments';
import TransferDetails from '../../components/Transfers/TransferDetails';
import PaymentDetails from '../../components/Payments/PaymentDetails';
import useNotification from '../../hooks/useNotification';

interface ITransactionDetails {
  transactionId: string;
  modalActive: boolean;
}

const TransactionDetails = ({
  transactionId,
  modalActive,
}: ITransactionDetails) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { getTransactionDetails } = useTransactionsService();
  const { getTransferDetails } = useTransfersService();
  const { getPaymentDetails } = usePaymentsService();
  const [transaction, setTransaction] = useState<TransactionType | null>(null);
  const [transfer, setTransfer] = useState<TransferType | null>(null);
  const [payment, setPayment] = useState<PaymentType | null>(null);
  const [activeExtra, setActiveExtra] = useState<
    'sourceAccount' | 'transfer' | 'payment' | ''
  >('');
  const { handleError } = useNotification();

  useEffect(() => {
    if (modalActive) {
      handleGetTransactionsDetails();
    }
    //eslint-disable-next-line
  }, []);

  const handleGetTransactionsDetails = () => {
    setLoading(true);

    getTransactionDetails(transactionId)
      .then((res: TransactionType) => {
        setTransaction(res);

        if (
          (res?.transactionType === TransactionCategories.NIP_TRANSFER ||
            res?.transactionType ===
              TransactionCategories.NIP_TRANSFER_REVERSAL ||
            res?.transactionType === TransactionCategories.BOOK_TRANSFER) &&
          res.transfer
        ) {
          handleGetTransferDetails(res?.transfer?.data?.id);
        }

        if (
          res?.transactionType === TransactionCategories.SETTLEMENT &&
          res.payment
        ) {
          getPayment(res?.payment?.data?.id);
        }
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetTransferDetails = (id: string) => {
    getTransferDetails(id)
      .then((res: TransferType) => {
        setTransfer(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const getPayment = (id: string) => {
    getPaymentDetails(id)
      .then((res: PaymentType) => {
        setPayment(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  return (
    <>
      <LoadingOverlay visible={loading} />

      {transaction && (
        <ScrollArea style={{ height: '88vh' }} type="scroll">
          <div className="details-view">
            <div className="d-header">
              <div className="h-date">
                {moment(transaction.createdAt).format('MMM Do, YYYY h:mm a')}
              </div>

              <div className="h-amount">
                {`${getCurrencySymbol(transaction.currency)}${formatAmount(
                  transaction.amount
                )}`}
              </div>

              <div className="type-badge">
                {transaction.type === TransactionTypes.NIPTransaction && 'NIP'}
                {transaction.type === TransactionTypes.BookTransaction &&
                  'Book'}
                {transaction.type === TransactionTypes.NIPReversal &&
                  'Reversal'}
                {transaction.type === TransactionTypes.FeeTransaction && 'Fee'}
                {transaction.type === TransactionTypes.CUSTOM && 'Custom'}
                {transaction.type === TransactionTypes.BillsTransaction &&
                  'Bill'}
                {transaction.type === TransactionTypes.CommissionTransaction &&
                  'Commission'}
              </div>
            </div>

            <div className="d-main">
              <DetailItem2
                title="Direction"
                desc={
                  <div
                    className={`direction-badge ${
                      transaction.direction === TransactionDirections.credit
                        ? 'inflow'
                        : 'outflow'
                    }`}
                  >
                    {transaction.direction}
                  </div>
                }
              />

              <DetailItem2
                title="Balance Before"
                desc={
                  transaction.balanceBefore &&
                  `${getCurrencySymbol(transaction.currency)}${formatAmount(
                    transaction.balanceBefore
                  )}`
                }
              />

              <DetailItem2
                title="Balance After"
                desc={
                  transaction.balanceAfter &&
                  `${getCurrencySymbol(transaction.currency)}${formatAmount(
                    transaction.balanceAfter
                  )}`
                }
              />

              <DetailItem2 title="Transaction ID" desc={transaction.id} />

              <DetailItem2 title="Summary" desc={transaction.summary} />

              <DetailItem2
                title="Category"
                desc={
                  <div className="type-badge">
                    {transaction.transactionType ===
                      TransactionCategories.NIP_TRANSFER && 'NIP'}
                    {transaction.transactionType ===
                      TransactionCategories.NIP_TRANSFER_REVERSAL && 'Reversal'}
                    {transaction.transactionType ===
                      TransactionCategories.CUSTOM && 'Custom'}
                    {transaction.transactionType ===
                      TransactionCategories.SETTLEMENT && 'Settlement'}
                    {transaction.transactionType ===
                      TransactionCategories.BOOK_TRANSFER && 'Book'}
                    {transaction.transactionType ===
                      TransactionCategories.ORGANIZATION_FEE && 'Fee'}
                    {transaction.transactionType ===
                      TransactionCategories.BILL_PAYMENT && 'Bill'}
                  </div>
                }
              />
            </div>

            {transaction.account && (
              <div className="d-extra-info">
                {activeExtra === 'sourceAccount' ? (
                  <div>
                    <Group position="apart" mb={20}>
                      <div className="e-i-title">Source Account</div>

                      <CloseButton
                        aria-label="Close"
                        onClick={() => setActiveExtra('')}
                      />
                    </Group>

                    <DetailItem2
                      title="Account ID"
                      desc={transaction.account.data.id}
                    />

                    <DetailItem2
                      title="Account Name"
                      desc={capitalizeTransform(
                        transaction.account.data?.accountName ?? ''
                      )}
                    />

                    <DetailItem2
                      title="Account Number"
                      desc={transaction.account.data.accountNumber}
                    />

                    <DetailItem2
                      title="Bank Name"
                      desc={capitalizeTransform(
                        transaction.account.data.bank.name
                      )}
                    />
                  </div>
                ) : (
                  <Group position="apart">
                    <Stack spacing={8}>
                      <Text fw={500}>Source Account</Text>
                      <div className="resource-card-desc">
                        {capitalizeTransform(
                          transaction.account.data?.accountName ?? ''
                        )}
                      </div>
                    </Stack>

                    <Stack spacing={8} align="flex-end">
                      <Text fz={16} fw={600}>
                        {transaction.account.data.accountNumber}
                      </Text>

                      <Button
                        variant="light"
                        rightIcon={<ArrowRight className="arr-right" />}
                        compact
                        onClick={() => {
                          setActiveExtra('sourceAccount');
                        }}
                      >
                        See details
                      </Button>
                    </Stack>
                  </Group>
                )}
              </div>
            )}

            {transaction.transfer && transfer && (
              <>
                {activeExtra === 'transfer' ? (
                  <Group mt={30} grow>
                    <TransferDetails
                      transfer={transfer}
                      otherResource
                      close={() => {
                        setActiveExtra('');
                      }}
                    />
                  </Group>
                ) : (
                  <div className="d-extra-info">
                    <Group position="apart">
                      <Stack spacing={8}>
                        <Text fw={500}>Transfer</Text>
                        <div className="resource-card-desc">
                          {moment(transaction.transfer.data.createdAt).format(
                            'MMM Do, YYYY'
                          )}
                        </div>
                      </Stack>

                      <Stack spacing={8} align="flex-end">
                        <Text fz={16} fw={600}>{`${getCurrencySymbol(
                          transaction.currency
                        )}${formatAmount(
                          transaction.transfer.data.amount
                        )}`}</Text>

                        <Button
                          variant="light"
                          compact
                          rightIcon={<ArrowRight className="arr-right" />}
                          onClick={() => {
                            setActiveExtra('transfer');
                          }}
                        >
                          See details
                        </Button>
                      </Stack>
                    </Group>
                  </div>
                )}
              </>
            )}

            {transaction.payment && payment && (
              <>
                {activeExtra === 'payment' ? (
                  <Group mt={30} grow>
                    <PaymentDetails
                      payment={payment}
                      otherResource
                      close={() => {
                        setActiveExtra('');
                      }}
                    />
                  </Group>
                ) : (
                  <div className="d-extra-info">
                    <Group position="apart">
                      <Stack spacing={8}>
                        <Text fw={500}>Payment</Text>
                        <div className="resource-card-desc">
                          {moment(transaction.payment.data.paidAt).format(
                            'MMM Do, YYYY'
                          )}
                        </div>
                      </Stack>

                      <Stack spacing={8} align="flex-end">
                        <Text fz={16} fw={600}>
                          {`${getCurrencySymbol(
                            transaction.payment?.data?.currency
                          )}${formatAmount(transaction.payment?.data.amount)}`}
                        </Text>

                        <Button
                          variant="light"
                          rightIcon={<ArrowRight className="arr-right" />}
                          compact
                          onClick={() => {
                            setActiveExtra('payment');
                          }}
                        >
                          See details
                        </Button>
                      </Stack>
                    </Group>
                  </div>
                )}
              </>
            )}
          </div>
        </ScrollArea>
      )}
    </>
  );
};

export default TransactionDetails;
