import React, { Fragment, useState } from 'react';
import { AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Button,
  LoadingOverlay,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import AuthHeader, { HeaderTypes } from '../../components/Auth/AuthHeader';
import useAuthService from '../../services/auth';
import {
  SigninResponse,
  InitiateMfaSetupResponse,
} from '../../types/authTypes';
import './auth.scss';
import useNotification from '../../hooks/useNotification';
import Verify2fa from './Verify2fa';

export interface ISessionDetails {
  username: string;
  password: string;
  mfaEnabled: boolean;
  token: string;
}

const SigninPage = () => {
  const [activeView, setActiveView] = useState<'signin' | '2fa'>('signin');
  const [sessionDetails, setSessionDetails] = useState<ISessionDetails>({
    username: '',
    password: '',
    mfaEnabled: false,
    token: '',
  });

  const changeTo2fa = (values: ISessionDetails) => {
    setSessionDetails(values);
    setActiveView('2fa');
  };

  return (
    <Fragment>
      {activeView === 'signin' && <Signin changeTo2fa={changeTo2fa} />}
      {activeView === '2fa' && (
        <Verify2fa
          goBack={() => {
            setActiveView('signin');
          }}
          sessionDetails={sessionDetails}
        />
      )}
    </Fragment>
  );
};

interface ISignin {
  changeTo2fa: (values: ISessionDetails) => void;
}

export const Signin = ({ changeTo2fa }: ISignin) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { checkMfaEnabled, signin, sendOtp, initiateMfaSetup } =
    useAuthService();
  const { handleError } = useNotification();

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },

    validate: {
      username: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) =>
        value.length < 8 ? 'Password must be at least 8 characters' : null,
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    setLoading(true);

    checkMfaEnabled({ username: values.username, password: values.password })
      .then((res: { mfaEnabled: boolean }) => {
        handleMfa(res.mfaEnabled);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMfa = (mfaEnabled: boolean) => {
    if (mfaEnabled) {
      handleSendOtp();
    } else {
      getUserTokenForMfaSetup();
    }
  };

  const handleSendOtp = () => {
    const { username } = form.values;
    setLoading(true);

    sendOtp(username)
      .then((res: Record<string, never>) => {
        showNotification({
          title: 'OTP Sent',
          message: 'Please check your email.',
          color: 'orange',
        });

        changeTo2fa({ ...form.values, mfaEnabled: true, token: '' });
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserTokenForMfaSetup = () => {
    const { username, password } = form.values;

    setLoading(true);

    signin({ username, password })
      .then((res: SigninResponse) => {
        handleInitiateMfaSetup(res.accessToken);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInitiateMfaSetup = (token: string) => {
    setLoading(true);

    initiateMfaSetup(token)
      .then((res: InitiateMfaSetupResponse) => {
        showNotification({
          title: 'OTP Sent',
          message: 'Please check your email.',
          color: 'orange',
        });

        changeTo2fa({ ...form.values, mfaEnabled: false, token });
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="auth-page">
      <Helmet>
        <title>Signin | Anchor</title>
        <meta
          name="description"
          content="Signin to manage manage your organization"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <AuthHeader type={HeaderTypes.noAccount} />

      <div className="auth-main">
        <div className="a-m-title">Sign in to Anchor</div>
        <div className="a-m-desc">
          View and manage your organization data with your account
        </div>

        <form
          className="a-form"
          onSubmit={form.onSubmit((values) => handleSubmit(values))}
        >
          <TextInput
            withAsterisk
            label="Email"
            placeholder="your@email.com"
            {...form.getInputProps('username')}
          />

          <PasswordInput
            required
            mt="sm"
            label="Password"
            placeholder="Password (min 8 characters)"
            {...form.getInputProps('password')}
          />

          <Button color="dark" mt={20} fullWidth type="submit">
            Sign in
          </Button>

          <div className="sec-action">
            Forgot your password?{' '}
            <span className="click">
              <Link to="/auth/forgot-password">Reset password</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SigninPage;
