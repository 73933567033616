import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationAuthKeyType, UserState } from '../../../types/authTypes';

const getUserStateFromLocalStorage = () => {
  const userString = localStorage.getItem('user');
  if (userString) {
    const user = JSON.parse(userString) as UserState;
    if (Date.now() - user.loggedInAt > 86400000) {
      localStorage.removeItem('user');
    } else {
      return user;
    }
  }
};

export const initialState: UserState = {
  accessToken: '',
  authKeys: [],
  loggedIn: false,
  loggedInAt: 0,
  permissions: [],
  organizations: [],
  rootCustomerId: '',
  userDetails: {
    username: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    createdAt: '',
    isActive: true,
    email: '',
    isPinEnabled: false,
    isMfaEnabled: false,
    noOfLoginRetries: 0,
    isLocked: false,
    isEmailValidated: true,
    dob: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: getUserStateFromLocalStorage() || initialState,
  reducers: {
    login: (state, action: PayloadAction<UserState>) => {
      state.accessToken = action.payload.accessToken;
      state.authKeys = action.payload.authKeys;
      state.loggedIn = action.payload.loggedIn;
      state.loggedInAt = action.payload.loggedInAt;
      state.permissions = action.payload.permissions;
      state.rootCustomerId = action.payload.rootCustomerId;
      state.userDetails = action.payload.userDetails;
      state.organizations = action.payload.organizations;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.accessToken = initialState.accessToken;
      state.authKeys = initialState.authKeys;
      state.loggedIn = initialState.loggedIn;
      state.loggedInAt = initialState.loggedInAt;
      state.organizations = initialState.organizations;
      state.rootCustomerId = initialState.rootCustomerId;
      state.userDetails = initialState.userDetails;
      state.permissions = initialState.permissions;
      localStorage.removeItem('user');
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setAuthKey: (state, action: PayloadAction<OrganizationAuthKeyType[]>) => {
      state.authKeys = action.payload;
    },
  },
});

export const { login, logout, setIsLoggedIn, setAuthKey } = userSlice.actions;

export default userSlice.reducer;
