import { VirtualNubanType } from './virtualNubansTypes';
import { SettlementType } from './settlementsTypes';
import { ResponseMetaPagination } from '../lib/types';

export type GetPaymentsResponse = {
  data: PaymentType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type PaymentType = {
  id: string;
  type: string;
  virtualNuban: {
    data: VirtualNubanType;
  };
  settlementAccount: {
    data: SettlementAccount;
  };
  settlements: {
    data: SettlementType[];
  };
  createdAt: Date;
  amount: number;
  paymentReference: string;
  fee: number;
  narration: string;
  paidAt: Date;
  counterParty: CounterParty;
  currency: string;
};

export enum SettlementStatusTypes {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export interface CounterParty {
  accountNumber: string;
  accountName: string;
  bank: {
    name: string;
  };
}

export interface SettlementAccount {
  id: string;
  type: string;
  subAccounts: {
    data: any[];
  };
  virtualNubans: {
    data: Data[];
  };
  customer: {
    data: Data;
  };
  createdAt: Date;
  bank: {
    id: string;
    name: string;
    cbnCode: string;
    nipCode: string;
  };
  accountName: string;
  frozen: boolean;
  currency: string;
  accountNumber: string;
  status: string;
}

interface Data {
  id: string;
  type: string;
}

export type GetPaymentsParams = {
  page: number;
  size: number;
  accountId?: string;
  virtualNubanId?: string;
  query?: string;
  from?: string;
  to?: string;
};
