import React, { ReactElement, useState, Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { showNotification } from '@mantine/notifications';
import {
  Tabs,
  Group,
  Button,
  Badge,
  Text,
  LoadingOverlay,
  Divider,
  Menu,
  ScrollArea,
  Table,
  Tooltip,
  Drawer,
  ActionIcon,
} from '@mantine/core';
import useAccountsService from '../../services/accounts';
import useTransactionsService from '../../services/transactions';
import useVirtualNubansService from '../../services/virtualNubans';
import {
  AccountType,
  AccountStatusTypes,
  AccountBalanceType,
} from '../../types/accountsTypes';
import {
  TransactionType,
  GetTransactionsResponse,
  TransactionDirections,
  TransactionTypes,
} from '../../types/transactionsTypes';
import {
  GetVirtualNubansResponse,
  VirtualNubanType,
} from '../../types/virtualNubansTypes';
import { ResponseMetaPagination } from '../../lib/types';
import {
  capitalizeTransform,
  downloadFile,
  formatAmount,
  formatDate2,
  truncateString,
} from '../../lib/util';
import FreezeAccount from '../../components/modals/Accounts/FreezeAccount';
import { initialMetaData } from '../../lib/store/reducers/DataReducer';
import VirtualNubansTable from '../VirtualNubans/VirtualNubansTable';
import Confirmation from '../../components/Confirmation/Confirmation';
import useNotification from '../../hooks/useNotification';
import { ReactComponent as ChevronBack } from '../../assets/svg/chevron-back.svg';
import { ReactComponent as ArrowDownRight } from '../../assets/svg/arrow-down-right.svg';
import { ReactComponent as ChevronDown } from '../../assets/svg/chevron-down.svg';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { ReactComponent as Download } from '../../assets/svg/download-black.svg';
import './accounts.scss';
import TransactionDetails from '../Transactions/TransactionDetails';
import useStatementsService from '../../services/statements';
import {
  GetStatementsResponse,
  StatementStatusTypes,
  StatementType,
} from '../../types/statementsTypes';
import { scrollToElement } from '../../lib/util';
import TransactionsFilter from '../Transactions/TransactionsFilter';
import ExportData, {
  ExportResources,
} from '../../components/ExportData/ExportData';
import { useViewportSize } from '@mantine/hooks';
import GenerateCustomeStatement from '../../components/modals/Statements/GenerateCustomeStatement';
import usePermissions from '../../hooks/usePermissions';

const AccountOverview = () => {
  const [activeTab, setActiveTab] = useState<string | null>('nubans');
  const [accountName, setAccountName] = useState<string>('');

  return (
    <div className="account-overview">
      <Helmet>
        <title> {accountName || 'Account Overview'} | Anchor</title>
        <meta
          name="description"
          content="View the details of a bank account and associated Nubans"
        />
      </Helmet>

      <AccountDetails setAccountName={setAccountName} />

      <Group pt={6} id={containerId} grow>
        <Tabs mt={30} value={activeTab} onTabChange={setActiveTab} color="dark">
          <Tabs.List>
            <Tabs.Tab value="nubans">Nubans</Tabs.Tab>
            <Tabs.Tab value="transactions">Transactions</Tabs.Tab>
            <Tabs.Tab value="statement">Statement</Tabs.Tab>
            {/* <Tabs.Tab value="metadata">Meta data</Tabs.Tab> */}
          </Tabs.List>

          <Tabs.Panel value="nubans" pt="lg">
            <Nubans />
          </Tabs.Panel>

          <Tabs.Panel value="transactions" pt="lg">
            <Transactions />
          </Tabs.Panel>

          <Tabs.Panel value="statement" pt="lg">
            <Statements accountName={accountName} />
          </Tabs.Panel>

          {/* <Tabs.Panel value="metadata" pt="lg">
            metadata
          </Tabs.Panel> */}
        </Tabs>
      </Group>
    </div>
  );
};

interface IAccountDetails {
  setAccountName: (name: string) => void;
}

const AccountDetails = ({ setAccountName }: IAccountDetails) => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const [account, setAccount] = useState<AccountType | null>(null);
  const [freezeAccountModal, setFreezeAccountModal] = useState<boolean>(false);
  const [confirmUnfreeze, setConfirmUnfreeze] = useState<boolean>(false);
  const [balance, setBalance] = useState<AccountBalanceType>({
    availableBalance: 0,
    ledgerBalance: 0,
    hold: 0,
    pending: 0,
  });
  const {
    getAccountDetails,
    getAccountBalance,
    unfreezeAccount,
    freezeAccount,
  } = useAccountsService();
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { accountRead } = usePermissions();

  useEffect(() => {
    if (accountRead) {
      getBalances();
      handleGetAccountDetails();
    }

    //eslint-disable-next-line
  }, []);

  const handleGetAccountDetails = () => {
    if (accountId) {
      setLoading(true);

      getAccountDetails(accountId)
        .then((res: AccountType) => {
          setAccountName(res?.accountName ?? 'N/A');
          setAccount(res);
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getBalances = () => {
    if (accountId) {
      getAccountBalance(accountId)
        .then((res: AccountBalanceType) => {
          setBalance(res);
        })
        .catch((err: AxiosError) => {
          handleError(err);
        });
    }
  };

  const freeze = (values: {
    freezeReason: string;
    freezeDescription: string;
  }) => {
    if (accountId) {
      setLoading(true);

      freezeAccount(accountId, values)
        .then(() => {
          showNotification({
            title: 'Success',
            message: 'Account frozen.',
            color: 'orange',
          });
          handleGetAccountDetails();
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const unfreeze = () => {
    if (accountId) {
      setLoading(true);

      unfreezeAccount(accountId)
        .then(() => {
          showNotification({
            title: 'Success',
            message: 'Account unfrozen.',
            color: 'orange',
          });
          handleGetAccountDetails();
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <FreezeAccount
        modalOpen={freezeAccountModal}
        closeModal={() => {
          setFreezeAccountModal(false);
        }}
        submit={freeze}
      />

      <Confirmation
        isOpened={confirmUnfreeze}
        title="Are you sure you want to unfreeze this account?"
        closeModal={() => {
          setConfirmUnfreeze(false);
        }}
        hasInput={false}
        confirmText="account unfreeze?"
        submit={() => {
          setConfirmUnfreeze(false);
          unfreeze();
        }}
      />

      <Group spacing={8} mb={10}>
        <ChevronBack className="click" onClick={() => navigate(-1)} />

        <Text fz={20} fw={500} color="#2E2E2E">
          {capitalizeTransform(account?.accountName || 'Account Overview')}
        </Text>
      </Group>

      <Menu shadow="md" width={150} disabled={!account}>
        <Menu.Target>
          <Button
            leftIcon={<ArrowDownRight />}
            rightIcon={<ChevronDown />}
            color="dark"
            mb={15}
          >
            Actions
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            onClick={() => {
              if (account?.frozen) {
                setConfirmUnfreeze(true);
              } else {
                setFreezeAccountModal(true);
              }
            }}
          >
            <Text fz={14} color="#7b7b7b" fw={500}>
              {account?.frozen ? 'Unfreeze Account' : 'Freeze Account'}
            </Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      {/* <div className="accunt-metrics-row">
        <div className="account-card balance-history">
          <div className="card-title">
            <Information /> Balance History
          </div>

          <Group className="balances" position="apart" mt={30}>
            <BalanceComponent
              desc="Account balance"
              value={`₦${formatAmount(balance.availableBalance)}`}
            />

            <BalanceComponent
              desc="Ledger balance"
              value={`₦${formatAmount(balance.ledgerBalance)}`}
            />

            <BalanceComponent
              desc="Pending"
              value={`₦${formatAmount(balance.pending)}`}
              extraClassname="add-opacity"
            />

            <BalanceComponent
              desc="Hold"
              value={`₦${formatAmount(balance.hold)}`}
              extraClassname="add-opacity"
            />
          </Group>
        </div>

        <div className="account-card">
          <Group position="apart" align="flex-start">
            <div className="card-title">
              <Information /> Inflow & Outflow
            </div>

            <Group spacing={16}>
              <BalanceComponent
                desc={<span>&#8226; Inflow</span>}
                value="₦5,494,382"
              />

              <BalanceComponent
                desc={<span>&#8226; Outflow</span>}
                value="₦894,256"
              />
            </Group>
          </Group>
        </div>
      </div> */}

      <div className="account-card mt relative">
        <LoadingOverlay visible={loading} />

        <Group className="info-items">
          <BalanceComponent
            desc="Account balance"
            value={`₦${formatAmount(balance.availableBalance)}`}
          />

          <BalanceComponent
            desc="Ledger balance"
            value={`₦${formatAmount(balance.ledgerBalance)}`}
          />

          <BalanceComponent
            desc="Pending"
            value={`₦${formatAmount(balance.pending)}`}
            extraClassname="add-opacity"
          />

          <BalanceComponent
            desc="Hold"
            value={`₦${formatAmount(balance.hold)}`}
            extraClassname="add-opacity"
          />
          {/* 
          <Divider orientation="vertical" />

          <BalanceComponent
            desc="NIP Transfer Limit"
            value="₦0.00/ ₦1,000,000,000"
          />

          <BalanceComponent
            desc="Book Transfer Limit"
            value="₦0.00/ ₦1,000,000,000"
          /> */}
        </Group>

        <Divider my={20} />

        <Group className="info-items">
          <AccountInfoItem
            desc="Date Created"
            value={moment(account?.createdAt).format('MMM Do, YYYY h:mm a')}
          />

          <AccountInfoItem desc="Account ID" value={account?.id ?? ''} />

          <AccountInfoItem desc="Account Type" value={account?.type ?? ''} />

          <AccountInfoItem
            desc="Account Number"
            value={account?.accountNumber ?? ''}
          />

          <AccountInfoItem
            desc="Account Status"
            value={
              <Badge
                color={
                  account?.status === AccountStatusTypes.active
                    ? 'green'
                    : 'red'
                }
              >
                {account?.status}
              </Badge>
            }
          />

          <AccountInfoItem
            desc="Freeze Status"
            value={
              <Badge
                variant="dot"
                color={account?.frozen ? 'red' : 'gray'}
                className="capitalize"
              >
                {account?.frozen ? 'Account frozen' : 'Not frozen'}
              </Badge>
            }
          />
        </Group>
      </div>
    </>
  );
};

const Nubans = () => {
  const { accountId } = useParams();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getVirtualNubansByAccount, createVirtualNuban } =
    useVirtualNubansService();
  const [virtualNubans, setVirtualNubans] = useState<{
    data: VirtualNubanType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  }>({ data: [], meta: initialMetaData, dataFetched: false });
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { virtualAaccountRead, virtualAaccountWrite } = usePermissions();
  const { width } = useViewportSize();
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  useEffect(() => {
    if (accountId && virtualAaccountRead) getVirtualNubans();
    //eslint-disable-next-line
  }, [page, size]);

  const getVirtualNubans = () => {
    if (accountId) {
      setLoading(true);

      getVirtualNubansByAccount(accountId, size, page)
        .then((res: GetVirtualNubansResponse) => {
          setVirtualNubans({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          });

          if (virtualNubans.dataFetched) {
            setTimeout(() => {
              scrollToElement(containerId, 'start');
            }, 100);
          }
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCreateVirtualNuban = () => {
    if (!accountId) return;

    setCreateLoading(true);

    createVirtualNuban({
      settlementAccountId: accountId,
    })
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Account number created.',
          color: 'orange',
        });
        getVirtualNubans();
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  return (
    <div className="relative table-container with-action-btns">
      <LoadingOverlay
        visible={loading}
        loader={<Text color="gray">Fetching nubans...</Text>}
      />

      {virtualAaccountWrite &&
        virtualNubans.dataFetched &&
        virtualNubans.data.length === 0 && (
          <Group spacing={10} position="right" className="action-btns">
            <Button
              compact
              className="compact-btn"
              color="dark"
              size={width > 700 ? 'md' : 'sm'}
              onClick={() => {
                handleCreateVirtualNuban();
              }}
              disabled={!accountId}
              loading={createLoading}
            >
              Add account number
            </Button>
          </Group>
        )}

      {!loading && virtualNubans.meta.total > 0 && (
        <VirtualNubansTable
          virtualNubans={virtualNubans}
          setPage={setPage}
          setLoading={setLoading}
          loading={loading}
          setSize={setSize}
        />
      )}

      {virtualNubans.dataFetched && virtualNubans.meta.total === 0 && (
        <Text pt={84} color="gray" align="center">
          No virtual nuban found
        </Text>
      )}
    </div>
  );
};

const Transactions = () => {
  const { accountId } = useParams();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [type, setType] = useState<string>('');
  const [direction, setDirection] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const { getTransactions } = useTransactionsService();
  const [transactions, setTransactions] = useState<{
    data: TransactionType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  }>({ data: [], meta: initialMetaData, dataFetched: false });
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>('');
  const [exportModal, setExportModal] = useState<boolean>(false);
  const { width } = useViewportSize();
  const { transactionRead } = usePermissions();

  useEffect(() => {
    if (transactionRead) getAccountTransactions();
    //eslint-disable-next-line
  }, [page, size, type, direction, dateFrom, dateTo]);

  const getAccountTransactions = () => {
    if (accountId) {
      setLoading(true);

      getTransactions({
        size,
        page,
        accountId,
        type,
        direction,
        from: dateFrom,
        to: dateTo,
      })
        .then((res: GetTransactionsResponse) => {
          setTransactions({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          });

          if (transactions.dataFetched) {
            setTimeout(() => {
              scrollToElement(containerId, 'start');
            }, 100);
          }
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFilterItemChange = (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => {
    if (value !== itemState) setLoading(true);
    setItemState && setItemState(value);
  };

  return (
    <div className="relative table-container with-action-btns">
      <LoadingOverlay
        visible={loading}
        loader={<Text color="gray">Fetching transactions...</Text>}
      />

      <Drawer
        opened={detailsDrawer}
        onClose={() => setDetailsDrawer(false)}
        padding="xl"
        size={650}
        title={
          <Text fw={600} fz={23}>
            Transaction Details
          </Text>
        }
        position="right"
      >
        <TransactionDetails
          transactionId={transactionId}
          modalActive={detailsDrawer}
        />
      </Drawer>

      <ExportData
        modalOpen={exportModal}
        closeModal={() => {
          setExportModal(false);
        }}
        resource={ExportResources.transactions}
        extraData={{ accountId: accountId ?? '' }}
      />

      <Group spacing={10} position="right" className="action-btns">
        <TransactionsFilter
          type={type}
          setType={setType}
          direction={direction}
          setDirection={setDirection}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          onFilterItemChange={onFilterItemChange}
        />

        <Button
          compact
          className="compact-btn"
          color="dark"
          size={width > 700 ? 'md' : 'sm'}
          onClick={() => {
            setExportModal(true);
          }}
          disabled={!accountId}
        >
          Export CSV
        </Button>
      </Group>

      {!loading && transactions.meta.total > 0 && (
        <>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table
              mt={10}
              verticalSpacing="md"
              sx={{ minWidth: transactions.data.length === 0 ? 500 : 900 }}
              highlightOnHover
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Summary</th>
                  <th>Direction</th>
                  <th>Amount</th>
                  <th>Balance After</th>
                </tr>
              </thead>
              <tbody>
                {transactions.data.map(
                  (transaction: TransactionType, index: number) => (
                    <tr
                      className="click"
                      key={index}
                      onClick={() => {
                        setTransactionId(transaction.id);
                        setDetailsDrawer(true);
                      }}
                      id={transaction.id}
                    >
                      <td className="gray">
                        {moment(transaction.createdAt).format('MMM D, YYYY')}
                      </td>

                      <td>
                        <div className="type-badge">
                          {transaction.type ===
                            TransactionTypes.NIPTransaction && 'NIP'}
                          {transaction.type ===
                            TransactionTypes.BookTransaction && 'Book'}
                          {transaction.type === TransactionTypes.NIPReversal &&
                            'Reversal'}
                          {transaction.type ===
                            TransactionTypes.FeeTransaction && 'Fee'}
                          {transaction.type === TransactionTypes.CUSTOM &&
                            'Custom'}
                          {transaction.type ===
                            TransactionTypes.BillsTransaction && 'Bill'}
                          {transaction.type ===
                            TransactionTypes.CommissionTransaction &&
                            'Commission'}
                        </div>
                      </td>

                      <td className="capitalize" style={{ maxWidth: 250 }}>
                        <Tooltip
                          multiline
                          width={350}
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label={transaction.summary}
                          position="bottom"
                          disabled={transaction.summary.length < 51}
                        >
                          <span>{truncateString(transaction.summary, 50)}</span>
                        </Tooltip>
                      </td>

                      <td>
                        <div
                          className={`direction-badge ${
                            transaction.direction ===
                            TransactionDirections.credit
                              ? 'inflow'
                              : 'outflow'
                          }`}
                        >
                          {transaction.direction}
                        </div>
                      </td>

                      <td>{`₦${formatAmount(transaction.amount)}`}</td>

                      <td className="gray">
                        {transaction.balanceAfter &&
                          `₦${formatAmount(transaction.balanceAfter)}`}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>

            {transactions.meta.total === 0 && (
              <Text pt={70} color="gray" align="center">
                No transactions to display
              </Text>
            )}
          </ScrollArea>

          <PaginationControls
            meta={transactions.meta}
            setPage={setPage}
            setLoader={setLoading}
            setSize={setSize}
          />
        </>
      )}

      {transactions.dataFetched && transactions.meta.total === 0 && (
        <Text pt={84} color="gray" align="center">
          No transactions to display
        </Text>
      )}
    </div>
  );
};

interface IStatements {
  accountName: string;
}

const Statements = ({ accountName }: IStatements) => {
  const { accountId } = useParams();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getStatements, downloadStatement } = useStatementsService();
  const [statements, setStatements] = useState<{
    data: StatementType[];
    meta: ResponseMetaPagination;
    dataFetched: boolean;
  }>({ data: [], meta: initialMetaData, dataFetched: false });
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [statementId, setStatementId] = useState<string>('');
  const [statementModal, setStatementModal] = useState<boolean>(false);
  const { width } = useViewportSize();
  const { statementRead } = usePermissions();

  useEffect(() => {
    if (accountId && statementRead) handleGetStatements();
    //eslint-disable-next-line
  }, [page, size]);

  const handleGetStatements = () => {
    if (accountId) {
      setLoading(true);

      getStatements({ size, page, accountId, accountType: 'DepositAccount' })
        .then((res: GetStatementsResponse) => {
          setStatements({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          });

          if (statements.dataFetched) {
            setTimeout(() => {
              scrollToElement(containerId, 'start');
            }, 100);
          }
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDownloadStatement = (statement: StatementType) => {
    downloadStatement(statement.id)
      .then((res: File) => {
        const fileName = `${accountName}-${statement.account.data.id}-${statement.fromDate}-${statement.toDate}`;

        const extension = 'xlsx';

        downloadFile(
          res,
          `${fileName}.${extension}`,
          'application/octet-stream'
        );

        setStatementId('');
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  return (
    <div className="relative table-container with-action-btns">
      <LoadingOverlay
        visible={loading}
        loader={<Text color="gray">Fetching statements...</Text>}
      />

      <GenerateCustomeStatement
        modalOpen={statementModal}
        closeModal={() => {
          setStatementModal(false);
        }}
        accountId={accountId ?? ''}
        accountType="DepositAccount"
        callback={handleGetStatements}
      />

      <Group spacing={10} position="right" className="action-btns">
        <Button
          compact
          className="compact-btn"
          color="dark"
          size={width > 700 ? 'md' : 'sm'}
          onClick={() => {
            setStatementModal(true);
          }}
          disabled={!accountId}
        >
          Custom Statement
        </Button>
      </Group>

      {!loading && statements.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table verticalSpacing="md" sx={{ minWidth: 600 }} highlightOnHover>
              <thead>
                <tr>
                  <th>Period</th>
                  <th>Status</th>
                  <th>Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {statements.data.map((statement: StatementType) => (
                  <tr key={statement.id} id={statement.id}>
                    <td className="gray">
                      {statement.fromDate}{' '}
                      <span className="arrow-right-text">→</span>{' '}
                      {statement.toDate}
                    </td>

                    <td>
                      <Badge
                        color={
                          statement.status === StatementStatusTypes.completed
                            ? 'green'
                            : 'gray'
                        }
                      >
                        {statement.status}
                      </Badge>
                    </td>

                    <td>
                      {formatDate2(statement.createdAt, 'MMM D, YYYY h:mm a')}
                    </td>

                    <td className="table-last">
                      {statement.status === StatementStatusTypes.completed && (
                        <ActionIcon
                          onClick={() => {
                            setStatementId(statement.id);
                            handleDownloadStatement(statement);
                          }}
                          loading={statement.id === statementId}
                          size="sm"
                        >
                          <Download />
                        </ActionIcon>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>

          <PaginationControls
            meta={statements.meta}
            setPage={setPage}
            setLoader={setLoading}
            setSize={setSize}
          />
        </React.Fragment>
      )}

      {statements.dataFetched && statements.meta.total === 0 && (
        <Text pt={84} color="gray" align="center">
          No statement found
        </Text>
      )}
    </div>
  );
};

interface IBalanceComponent {
  desc: string | ReactElement;
  value: string;
  extraClassname?: string;
}

export const BalanceComponent = ({
  desc,
  value,
  extraClassname,
}: IBalanceComponent) => {
  return (
    <div>
      <div className={`card-balance-item ${extraClassname ?? ''}`}>
        <div className="b-desc">{desc}</div>

        <div className="b-value">{value}</div>
      </div>
    </div>
  );
};

interface IAccountInfoItem {
  desc: string | ReactElement;
  value: string | ReactElement;
  extraClasses?: '';
}

export const AccountInfoItem = ({
  desc,
  value,
  extraClasses,
}: IAccountInfoItem) => {
  return (
    <div className={`account-info-item ${extraClasses || ''} `}>
      <div className="i-desc">{desc}</div>

      <div className="i-value">{value}</div>
    </div>
  );
};

const containerId = 'data-container';

export default AccountOverview;
