import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { uniqBy } from 'lodash';
import moment from 'moment';
import {
  Button,
  Group,
  TextInput,
  Select,
  ScrollArea,
  ActionIcon,
  Drawer,
  Text,
  NumberInput,
  LoadingOverlay,
  Modal,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { DatePicker } from '@mantine/dates';
import { useAppDispatch } from '../../../hooks/useRedux';
import useOrganizationService from '../../../services/organization';
import {
  OfficerType,
  AddOfficerData,
  OfficerRoles,
  CreateBusinessCustomerData,
} from '../../../types/businessTypes';
import {
  OfficersRequirement,
  OrganizationCompliance,
} from '../../../types/organizationTypes';
import { setShowLoader } from '../../../lib/store/reducers/UtilityReducer';
import { formatName, statesNG, capitalizeTransform } from '../../../lib/util';
import Confirmation from '../../../components/Confirmation/Confirmation';
import { ReactComponent as Trash } from '../../../assets/svg/trash.svg';
import useNotification from '../../../hooks/useNotification';

interface IBusinessOfficers {
  nextStep: () => void;
  prevStep: () => void;
  isLive: boolean;
  officers: OfficerType[];
  getOfficers: () => void;
  getComplianceData: () => void;
  orgCompliance: OrganizationCompliance | null;
}

const BusinessOfficers = ({
  nextStep,
  prevStep,
  isLive,
  officers,
  getOfficers,
  getComplianceData,
  orgCompliance,
}: IBusinessOfficers) => {
  const dispatch = useAppDispatch();
  const {
    addOrgBusinessOfficer,
    updateOrgBusinessOfficer,
    deleteOrgBusinessOfficer,
    getOfficersRequirement,
    activateOrganization,
  } = useOrganizationService();
  const [officersRequirement, setOfficersRequirement] =
    useState<OfficersRequirement>({
      minimumDirectors: 0,
      minimumOwners: 0,
      minimumPercentageOwned: 0,
    });
  const [editMode, setEditMode] = useState<OfficerRoles | ''>('');
  const [activeOfficer, setActiveOfficer] = useState<OfficerType | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [addPercentOwnedModal, setAddPercentOwnedModal] =
    useState<boolean>(false);
  const { handleError } = useNotification();
  const [signatoryEmail, setSignatoryEmail] = useState<string>('');

  useEffect(() => {
    handleGetOfficersRequirement();

    if (orgCompliance && orgCompliance.complianceStatus.primaryContact) {
      setSignatoryEmail(orgCompliance.complianceStatus?.primaryContact?.email);
    }
    //eslint-disable-next-line
  }, []);

  const handleGetOfficersRequirement = () => {
    getOfficersRequirement()
      .then((res: OfficersRequirement) => {
        setOfficersRequirement(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const submit = (values: AddOfficerData) => {
    const requestData = values;
    if (editMode === OfficerRoles.OWNER) {
      delete requestData.title;
    }
    if (editMode) {
      setLoading(true);
    } else {
      dispatch(setShowLoader(true));
    }

    (activeOfficer && editMode
      ? updateOrgBusinessOfficer(activeOfficer.id, requestData)
      : addOrgBusinessOfficer(requestData)
    )
      .then(() => {
        setEditMode('');
        setActiveOfficer(null);
        getOfficers();
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        if (editMode) {
          setLoading(false);
        } else {
          dispatch(setShowLoader(false));
        }
      });
  };

  const deleteOfficer = () => {
    if (activeOfficer) {
      dispatch(setShowLoader(true));

      deleteOrgBusinessOfficer(activeOfficer.id)
        .then(() => {
          showNotification({
            title: 'Success',
            message: 'Officer deleted successfully',
            color: 'orange',
          });
          getOfficers();
          setEditMode('');
          setActiveOfficer(null);
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          dispatch(setShowLoader(false));
        });
    }
  };

  const addDirectorAsOwner = (percentageOwned: number) => {
    setAddPercentOwnedModal(false);

    if (activeOfficer) {
      const requestData: AddOfficerData = {
        fullName: activeOfficer?.fullName,
        role: OfficerRoles.OWNER,
        dateOfBirth: activeOfficer?.dateOfBirth,
        email: activeOfficer?.email,
        phoneNumber: activeOfficer?.phoneNumber,
        nationality: activeOfficer?.nationality,
        identificationType: activeOfficer?.identificationType ?? '',
        idDocumentNumber: activeOfficer?.idDocumentNumber ?? '',
        address: activeOfficer?.address,
        bvn: activeOfficer?.bvn,
        percentageOwned,
      };

      submit(requestData);
    }
  };

  const saveBusinessContact = (officerEmail: string) => {
    const officer: OfficerType | undefined = officers.find(
      (officer: OfficerType) => officer.email === officerEmail
    );

    if (officer && orgCompliance) {
      const { fullName, email, phoneNumber, bvn } = officer;
      const {
        address,
        complianceStatus: { basicDetail, organizationContactDetails },
      } = orgCompliance;

      if (address && basicDetail && organizationContactDetails) {
        const requestData: CreateBusinessCustomerData = {
          ...basicDetail,
          address,
          phoneNumber: organizationContactDetails.phoneNumber,
          email: organizationContactDetails.email,
          contact: {
            fullName,
            email,
            phoneNumber,
            bvn,
          },
        };

        dispatch(setShowLoader(true));

        activateOrganization(requestData)
          .then(() => {
            getComplianceData();
          })
          .catch((err: AxiosError) => {
            handleError(err);
          })
          .finally(() => {
            dispatch(setShowLoader(false));
          });
      }
    }
  };

  return (
    <div>
      <LoadingOverlay visible={loading} />

      <Confirmation
        isOpened={confirmDelete}
        title="Are you sure you want to delete this officer?"
        closeModal={() => {
          setConfirmDelete(false);
        }}
        hasInput={false}
        confirmText="Delete"
        submit={() => {
          setConfirmDelete(false);
          deleteOfficer();
        }}
      />

      <Drawer
        opened={
          editMode === OfficerRoles.OWNER || editMode === OfficerRoles.DIRECTOR
        }
        onClose={() => {
          setActiveOfficer(null);
          setEditMode('');
        }}
        title={
          <Text fw={500} fz={18} className="capitalize">
            {`Business ${editMode.toLowerCase()}`}
          </Text>
        }
        padding="xl"
        size={450}
        position="right"
      >
        <AddOfficer
          submit={submit}
          officer={activeOfficer}
          role={editMode}
          loading={loading}
          isLive={isLive}
        />
      </Drawer>

      <Modal
        opened={addPercentOwnedModal}
        onClose={() => {
          setAddPercentOwnedModal(false);
          setActiveOfficer(null);
        }}
        title={
          <Text fw={600} fz={16}>
            Add Percentage Owned
          </Text>
        }
        size={500}
      >
        <AddPercentageOwned
          officerName={formatName(activeOfficer?.fullName)}
          submit={addDirectorAsOwner}
        />
      </Modal>

      <div className="officers-group">
        <div className="o-g-title">Business Directors</div>

        <div className="o-g-desc">
          Please identify at least {officersRequirement.minimumDirectors}{' '}
          {officersRequirement.minimumDirectors > 1 ? 'directors' : 'director'}{' '}
          of your business
        </div>

        {officers
          .filter(
            (officer: OfficerType) => officer.role === OfficerRoles.DIRECTOR
          )
          .map((officer: OfficerType) => (
            <div className="officer-row" key={officer.id}>
              <div className={`o-main ${isLive ? 'full' : ''}`}>
                <div>{formatName(officer.fullName)}</div>

                <Button
                  variant="subtle"
                  compact
                  onClick={() => {
                    setActiveOfficer(officer);
                    setEditMode(OfficerRoles.DIRECTOR);
                  }}
                >
                  {isLive ? 'View' : 'Edit'}
                </Button>
              </div>

              {!isLive && (
                <ActionIcon
                  size="xl"
                  variant="light"
                  onClick={() => {
                    setActiveOfficer(officer);
                    setConfirmDelete(true);
                  }}
                  disabled={isLive}
                >
                  <Trash />
                </ActionIcon>
              )}
            </div>
          ))}

        {!isLive && (
          <Group mt={20}>
            <Button
              variant="light"
              color="gray"
              onClick={() => {
                setEditMode(OfficerRoles.DIRECTOR);
              }}
              disabled={isLive}
            >
              Add Director
            </Button>
          </Group>
        )}
      </div>

      <div className="officers-group">
        <div className="o-g-title">Business Owners</div>

        <div className="o-g-desc">
          Identify or add{' '}
          {officersRequirement.minimumOwners > 0 &&
            `at least ${officersRequirement.minimumOwners}`}{' '}
          people who own{' '}
          {officersRequirement.minimumPercentageOwned > 0 &&
            `a minimum total of ${officersRequirement.minimumPercentageOwned}% of`}{' '}
          the business .
        </div>

        {officers
          .filter((officer: OfficerType) => officer.role === OfficerRoles.OWNER)
          .map((officer: OfficerType) => (
            <div className="officer-row" key={officer.id}>
              <div className={`o-main ${isLive ? 'full' : ''}`}>
                <div>
                  {formatName(officer.fullName)} - {officer.percentOwned}%
                </div>

                <Button
                  variant="subtle"
                  compact
                  onClick={() => {
                    setActiveOfficer(officer);
                    setEditMode(OfficerRoles.OWNER);
                  }}
                >
                  {isLive ? 'View' : 'Edit'}
                </Button>
              </div>

              {!isLive && (
                <ActionIcon
                  size="xl"
                  variant="light"
                  onClick={() => {
                    setActiveOfficer(officer);
                    setConfirmDelete(true);
                  }}
                >
                  <Trash />
                </ActionIcon>
              )}
            </div>
          ))}

        {!isLive && (
          <Group mt={20} spacing={8} className="add-owner-row">
            <Button
              variant="light"
              color="gray"
              onClick={() => {
                setEditMode(OfficerRoles.OWNER);
              }}
            >
              Add Owner
            </Button>

            <Select
              data={officers
                .filter(
                  (officer: OfficerType) =>
                    officer.role === OfficerRoles.DIRECTOR
                )
                .map((officer: OfficerType) => ({
                  key: officer.id,
                  label: formatName(officer.fullName),
                  value: officer.id,
                }))}
              placeholder="Select from directors"
              variant="filled"
              onChange={(value: string) => {
                const officer = officers.find(
                  (officer: OfficerType) => officer.id === value
                );

                if (officer) {
                  setActiveOfficer(officer);
                  setAddPercentOwnedModal(true);
                }
              }}
              value={null}
            />
          </Group>
        )}
      </div>

      <div className="officers-group">
        <div className="o-g-title">Business Signatory</div>

        <div className="o-g-desc">
          Select the officer with the business signatory
        </div>

        <Select
          data={uniqBy(officers, 'email').map((officer: OfficerType) => ({
            key: officer.id,
            label: formatName(officer.fullName),
            value: officer.email,
          }))}
          placeholder="Select signatory"
          variant="filled"
          value={signatoryEmail}
          onChange={(value: string) => {
            setSignatoryEmail(value);
            saveBusinessContact(value);
          }}
          mt={20}
          disabled={isLive}
        />
      </div>

      <Group grow mt={20}>
        <Button variant="default" onClick={prevStep}>
          Previous
        </Button>

        <Button
          color="dark"
          onClick={nextStep}
          disabled={
            isLive
              ? false
              : !orgCompliance?.complianceStatus.primaryContactSubmitted ||
                officers.filter(
                  (officer: OfficerType) =>
                    officer.role === OfficerRoles.DIRECTOR
                ).length < officersRequirement.minimumDirectors ||
                officers.filter(
                  (officer: OfficerType) => officer.role === OfficerRoles.OWNER
                ).length < officersRequirement.minimumOwners
          }
        >
          Next
        </Button>
      </Group>
    </div>
  );
};

interface IAddOfficer {
  submit: (values: AddOfficerData) => void;
  officer: OfficerType | null;
  role: OfficerRoles | '';
  loading: boolean;
  isLive: boolean;
}

const AddOfficer = ({
  submit,
  officer,
  role,
  loading,
  isLive,
}: IAddOfficer) => {
  const form = useForm({
    initialValues: {
      fullName: {
        firstName: '',
        lastName: '',
        middleName: '',
      },
      role: '',
      dateOfBirth: new Date(),
      email: '',
      phoneNumber: '',
      title: '',
      nationality: '',
      identificationType: '',
      idDocumentNumber: '',
      address: {
        addressLine_1: '',
        addressLine_2: '',
        postalCode: '',
        city: '',
        state: '',
        country: '',
      },
      bvn: '',
      percentageOwned: 0,
    },
    validate: {
      nationality: (value) => (value === '' ? 'Select nationality' : null),
      dateOfBirth: (value) => (!value ? 'Select date of birth' : null),
      address: {
        country: (value) => (value === '' ? 'Select country' : null),
      },
    },
  });

  useEffect(() => {
    if (officer) {
      form.setValues({
        ...officer,
        dateOfBirth: new Date(officer.dateOfBirth),
        percentageOwned: officer.percentOwned,
      });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <ScrollArea style={{ height: '88vh' }} type="scroll">
      <LoadingOverlay visible={loading} />

      <form
        onSubmit={form.onSubmit((values) =>
          submit({
            ...values,
            dateOfBirth: moment(values.dateOfBirth).format('YYYY-MM-DD'),
            role,
          })
        )}
      >
        <TextInput
          label="First Name"
          placeholder="First name"
          required
          mt="sm"
          {...form.getInputProps(`fullName.firstName`)}
          autoComplete="off"
        />

        <TextInput
          label="Middle Name"
          placeholder="Middle name"
          required
          mt="md"
          {...form.getInputProps(`fullName.middleName`)}
          autoComplete="off"
        />

        <TextInput
          label="Last Name"
          placeholder="Last name"
          required
          mt="md"
          {...form.getInputProps(`fullName.lastName`)}
          autoComplete="off"
        />

        <TextInput
          label="Email"
          placeholder="Email"
          required
          mt={40}
          {...form.getInputProps(`email`)}
          autoComplete="off"
        />

        <TextInput
          label="Phone Number"
          placeholder="Phone number"
          required
          mt="md"
          {...form.getInputProps(`phoneNumber`)}
          autoComplete="off"
        />

        <DatePicker
          required
          label="Date Of Birth"
          placeholder="Select date of birth"
          withAsterisk
          inputFormat="DD-MM-YYYY"
          allowFreeInput
          initialLevel="year"
          mt="md"
          {...form.getInputProps(`dateOfBirth`)}
        />

        {role === OfficerRoles.DIRECTOR && (
          <Select
            required={role === OfficerRoles.DIRECTOR}
            label="Title"
            placeholder="Title"
            data={[
              'CEO',
              'COO',
              'CFO',
              'President',
              'CIO',
              'VP',
              'Treasurer',
              'Controller',
              'Manager',
              'Partner',
              'Member',
            ]}
            mt="md"
            {...form.getInputProps(`title`)}
          />
        )}

        {role === OfficerRoles.OWNER && (
          <NumberInput
            required={role === OfficerRoles.OWNER}
            label="Percentage Owned (5% - 100%)"
            placeholder="Percentage owned"
            {...form.getInputProps(`percentageOwned`)}
            mt="md"
            max={100}
            min={5.0}
            step={0.01}
            precision={2}
          />
        )}

        <TextInput
          required
          label="Address Line 1"
          placeholder="Address line 1"
          mt={40}
          {...form.getInputProps(`address.addressLine_1`)}
        />

        <TextInput
          required
          label="Address Line 2"
          placeholder="Address line 2"
          mt="md"
          {...form.getInputProps(`address.addressLine_2`)}
        />

        <TextInput
          required
          label="Postal Code"
          placeholder="Postal code"
          mt="md"
          {...form.getInputProps(`address.postalCode`)}
        />

        <TextInput
          required
          label="City"
          placeholder="City"
          mt="md"
          {...form.getInputProps(`address.city`)}
        />

        <Select
          required
          label="State"
          placeholder="Select state"
          data={statesNG.sort().map((state: string) => ({
            label: capitalizeTransform(state),
            value: state,
          }))}
          searchable
          mt="md"
          {...form.getInputProps(`address.state`)}
        />

        <Select
          required
          label="Country"
          placeholder="Select Country"
          data={[{ value: 'NG', label: 'Nigeria' }]}
          mt="md"
          {...form.getInputProps(`address.country`)}
        />

        <Select
          required
          label="Nationality"
          placeholder="Select nationality"
          data={[{ value: 'NG', label: 'Nigerian' }]}
          mt={40}
          {...form.getInputProps(`nationality`)}
        />

        <Select
          required
          label="Identification Type"
          placeholder="Identification type"
          data={[
            { value: 'DRIVERS_LICENSE', label: 'Drivers License' },
            { value: 'VOTERS_CARD', label: 'Voters Card' },
            { value: 'PASSPORT', label: 'International Passport' },
            { value: 'NATIONAL_ID', label: 'National ID' },
            { value: 'NIN_SLIP', label: 'NIN Slip' },
          ]}
          mt="md"
          {...form.getInputProps(`identificationType`)}
        />

        <TextInput
          label="ID Document Number"
          placeholder="ID document number"
          required
          mt="md"
          {...form.getInputProps(`idDocumentNumber`)}
          autoComplete="off"
        />

        <TextInput
          label="BVN"
          placeholder="BVN"
          required
          mt="md"
          {...form.getInputProps(`bvn`)}
          autoComplete="off"
        />

        <Button mt={20} color="dark" type="submit" fullWidth disabled={isLive}>
          <span className="capitalize">
            {officer ? 'Update' : 'Add'} {role.toLowerCase()}
          </span>
        </Button>
      </form>
    </ScrollArea>
  );
};

interface IAddPercentageOwned {
  officerName: string;
  submit: (value: number) => void;
}

const AddPercentageOwned = ({ officerName, submit }: IAddPercentageOwned) => {
  const [value, setValue] = useState<number | undefined>(undefined);

  return (
    <div>
      <Text fz={14} color="#919191">
        You have selected {officerName} to be added as an Owner. Enter the
        percentage of the company they own to continue.
      </Text>

      <NumberInput
        value={value}
        onChange={setValue}
        label="Percentage Owned (5% - 100%)"
        placeholder="Percentage owned"
        mt="md"
        max={100}
        min={5.0}
        step={0.01}
        precision={2}
        required
        autoComplete="off"
      />

      <Button
        mt={20}
        color="dark"
        fullWidth
        disabled={!value || value < 5}
        onClick={() => {
          if (value) {
            submit(value);
          }
        }}
      >
        Add Owner
      </Button>
    </div>
  );
};

export default BusinessOfficers;
