import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  Button,
  Group,
  LoadingOverlay,
  ScrollArea,
  Tabs,
  Menu,
  Drawer,
} from '@mantine/core';
import useNotification from '../../../hooks/useNotification';
import {
  convertToTitleCase,
  formatDate2,
  getPermissionColor,
} from '../../../lib/util';
import { ReactComponent as ArrowDownRight } from '../../../assets/svg/arrow-down-right.svg';
import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron-down.svg';
import { DetailItem2 } from '../../../components/DetailItem/DetailItem';
import AuditLogsNested from '../../../components/AuditLogs/AuditLogsNested';
import Confirmation from '../../../components/Confirmation/Confirmation';
import { showNotification } from '@mantine/notifications';
import CreateRole from './CreateRole';
import { RoleType } from '../../../types/roleManagementTypes';
import useRoleManagementService from '../../../services/roleManagement';

interface RoleDetailsProps {
  modalOpen: boolean;
  closeModal: () => void;
  callback: () => void;
  activeRole: RoleType | null;
}

const RoleDetails = ({
  modalOpen,
  closeModal,
  callback,
  activeRole,
}: RoleDetailsProps) => {
  const [activeTab, setActiveTab] = useState<string | null>('audit');
  const [deleteRoleModal, setDeleteRoleModal] = useState<boolean>(false);
  const { deleteRole, getRole } = useRoleManagementService();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleError } = useNotification();
  const [createRoleModal, setCreateRoleModal] = useState<boolean>(false);
  const [role, setRole] = useState<RoleType | null>(null);

  useEffect(() => {
    if (activeRole) {
      setRole(activeRole);
    }
  }, [activeRole]);

  const handleGetRole = () => {
    if (!activeRole) return;

    getRole(activeRole.id)
      .then((res: RoleType) => {
        setRole(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleDeleteRole = () => {
    if (!role) return;

    setLoading(true);

    deleteRole(role.id)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Role deleted successfully.',
          color: 'orange',
        });
        callback();
        closeModal();
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onEditRole = () => {
    setTimeout(() => {
      handleGetRole();
      callback();
    }, 200);
  };

  return (
    <Drawer
      opened={modalOpen}
      onClose={closeModal}
      title={
        <Group align="center" spacing={40}>
          <div className="modal-title">{role?.roleName ?? 'Role Details'}</div>

          {!role?.systemRole && (
            <Menu shadow="md" width="target">
              <Menu.Target>
                <Button
                  leftIcon={<ArrowDownRight />}
                  rightIcon={<ChevronDown />}
                  color="dark"
                >
                  Actions
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  className="menu-item active text-center"
                  onClick={() => setCreateRoleModal(true)}
                >
                  Edit Role
                </Menu.Item>

                <Menu.Item
                  color="#DE7965"
                  fw={500}
                  fz={13}
                  className="text-center"
                  onClick={() => setDeleteRoleModal(true)}
                >
                  Delete Role
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Group>
      }
      padding="xl"
      size="100vh"
      position="bottom"
      overlayOpacity={0.5}
      className="details-drawer-bottom gray-bg"
    >
      <LoadingOverlay visible={loading} />

      <Confirmation
        isOpened={deleteRoleModal}
        title="Are you sure you want to delete this role?"
        closeModal={() => {
          setDeleteRoleModal(false);
        }}
        hasInput={false}
        confirmText="Delete"
        submit={() => {
          setDeleteRoleModal(false);
          handleDeleteRole();
        }}
      />

      <CreateRole
        modalOpen={createRoleModal}
        closeModal={() => {
          setCreateRoleModal(false);
        }}
        callback={onEditRole}
        role={role}
      />

      {role && (
        <ScrollArea type="auto" className="fullscreen-modal-section-scroll">
          <div className="permission-item-details">
            <RoleInfo role={role} />

            <div className="p-tabs-container fullscreen-modal-section-scroll allow-grow child no-pr relative">
              <Tabs value={activeTab} onTabChange={setActiveTab} color="dark">
                <Tabs.List>
                  <Tabs.Tab value="audit">Audit Logs</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="audit" pt="xs">
                  <AuditLogsNested
                    extraParams={{ resourceId: role.id }}
                    extraClasses="no-border"
                  />
                </Tabs.Panel>
              </Tabs>
            </div>
          </div>
        </ScrollArea>
      )}
    </Drawer>
  );
};

interface Props {
  role: RoleType;
}

const RoleInfo = ({ role }: Props) => {
  return (
    <div className="p-i-info">
      <div className="p-i-main">
        <div>
          <DetailItem2
            title="Role Name"
            desc={role.roleName}
            extraClasses="stack"
          />

          <DetailItem2
            title="Description"
            desc={role.description}
            extraClasses="stack"
          />
        </div>

        <div>
          <DetailItem2
            title="Date Created"
            desc={formatDate2(role.createdAt, 'MMM Do, YYYY h:mm a')}
            extraClasses="stack"
          />

          <DetailItem2
            title="Type"
            desc={role.systemRole ? 'System' : 'Custom'}
            extraClasses="stack"
          />
        </div>
      </div>

      <RolePermissions role={role} />
    </div>
  );
};

const RolePermissions = ({ role }: Props) => {
  const [groupedPermissions, setGroupedPermissions] = useState<
    Record<string, string[]>
  >({});

  useEffect(() => {
    handlePermissionsGrouping();
    //eslint-disable-next-line
  }, [role]);

  const handlePermissionsGrouping = () => {
    const grouped = role.permissions.reduce((acc, permission) => {
      const key = permission.split('.')[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(permission);
      return acc;
    }, {} as Record<string, string[]>);

    setGroupedPermissions(grouped);
  };

  return (
    <div className="permission-list">
      <div className="p-l-title">Events</div>

      {Object.keys(groupedPermissions).map((group: string, index: number) => (
        <DetailItem2
          key={index}
          title={convertToTitleCase(group)}
          desc={
            <div className="permissions-list">
              {groupedPermissions[group]
                .map((eventType: string) => eventType)
                .map((eventType: string) => (
                  <span
                    className={`permission-type ${getPermissionColor(
                      eventType
                    )}`}
                    key={eventType}
                  >
                    {convertToTitleCase(eventType.replace(group + '.', ''))}{' '}
                  </span>
                ))}
            </div>
          }
          extraClasses="align-start column-sm"
        />
      ))}
    </div>
  );
};

export default RoleDetails;
