import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi, useAxiosApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetStatementsResponse,
  StatementType,
  GetStatementsParams,
  CreateStatementData,
} from '../types/statementsTypes';

const useStatementsService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);
  const axiosApi = useAxiosApi(ApiAuthMode.BearerToken, mode);

  const getStatements = (params: GetStatementsParams) => {
    return new Promise<GetStatementsResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.STATEMENTS.STATEMENTS}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: 'DepositAccount,SubAccount',
              ...(params?.accountId && {
                accountId: params?.accountId,
              }),
              ...(params?.accountType && {
                accountType: params?.accountType,
              }),
            },
          }
        )
        .then((res: GetStatementsResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getStatement = (statementId: string) => {
    return new Promise<StatementType>((resolve, reject) => {
      authApi
        .get(APIS.STATEMENTS.STATEMENT(statementId), {
          params: {
            include: 'DepositAccount',
          },
        })
        .then((res: { data: StatementType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const downloadStatement = (statementId: string) => {
    return new Promise<File>((resolve, reject) => {
      axiosApi
        .get(APIS.STATEMENTS.DOWNLOAD_STATEMENT(statementId), {
          responseType: 'blob',
        })
        .then((res: { data: File }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const createStatement = (data: CreateStatementData) => {
    return new Promise<StatementType>((resolve, reject) => {
      const { fromDate, toDate, accountId } = data;

      authApi
        .post(APIS.STATEMENTS.STATEMENTS, {
          accountId,
          fromDate,
          toDate,
          account: {
            id: accountId,
            type: data.accountType,
          },
        })
        .then((res: { data: StatementType }) => {
          resolve(res.data);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getStatements,
    getStatement,
    downloadStatement,
    createStatement,
  };
};

export default useStatementsService;
