import { ResponseMetaPagination } from '../lib/types';
import { RoleType } from './roleManagementTypes';

export type GetUsersResponse = {
  data: UserType[];
  meta: {
    pagination: ResponseMetaPagination;
  };
};

export type GetUsersParams = {
  page: number;
  size: number;
  invitationStatus?: InvitationStatusTypes;
  roleId?: string;
};

export type JoinTeamType = {
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
};

export type InviteUserType = {
  email: string;
  role: {
    id: string;
  };
};

export type UserType = {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  createdBy: string;
  invitationStatus: string;
  email: string;
  roles: {
    data: RoleType[];
  };
};

export enum RoleTypes {
  administrator = 'Administrator',
  primaryOwner = 'PrimaryOwner',
  developer = 'Developer',
}

export enum InvitationStatusTypes {
  accepted = 'accepted',
  pending = 'pending',
}

export type ManageUserRoleData = {
  userId: string;
  roleId: string;
};
