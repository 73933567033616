import {
  showNotification,
  cleanNotificationsQueue,
  cleanNotifications,
} from '@mantine/notifications';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './useRedux';
import { selectAccessToken } from '../lib/store';
import { LOGOUT } from '../lib/store/actionTypes/actionTypes';
import { setSessionRoute } from '../lib/store/reducers/UtilityReducer';

const useNotification = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken);

  const checkTokenExpiry = () => {
    const decoded: JwtPayload = jwt_decode(accessToken);

    if (decoded.exp && decoded.exp * 1000 < Date.now()) {
      return true;
    }
  };

  const logoutUser = (isUnauthorized?: boolean) => {
    if (isUnauthorized) {
      dispatch(setSessionRoute(location.pathname));
    }

    localStorage.removeItem('user');
    dispatch({ type: LOGOUT });
    cleanNotificationsQueue();
    cleanNotifications();

    showNotification({
      title: isUnauthorized ? 'Session expired' : 'User logged out',
      message: 'Sign in to continue',
      color: 'red',
      autoClose: 2500,
    });
  };

  const handleError = (error: any) => {
    if (!error.response) {
      return showNotification({
        title: 'Network Error',
        message: 'Please check your connection',
        color: 'red',
      });
    }

    if (accessToken && checkTokenExpiry()) {
      return logoutUser(true);
    }

    if (error?.response?.status === 500) {
      return showNotification({
        title: 'Error',
        message:
          error?.response?.data?.errors[0]?.detail ??
          'An error occured, please try again',
        color: 'red',
      });
    }

    if (typeof error?.response?.data?.errors === 'object' && error !== null) {
      // eslint-disable-next-line
      for (const [key] of Object?.entries(error?.response?.data?.errors)) {
        showNotification({
          title: error?.response?.data?.errors.length === 1 ? 'Error' : '',
          message: `${error?.response?.data?.errors[key]?.detail}`,
          color: 'red',
        });
      }
    } else {
      showNotification({
        title: 'Error',
        message: 'Something went wrong, please try again',
        color: 'red',
      });
    }
  };
  return {
    handleError,
    logoutUser,
    checkTokenExpiry,
  };
};

export default useNotification;
