import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { Text, Button, Popover, Group, Switch, Select } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import {
  CustomerAccountTypes,
  GetCustomersResponse,
} from '../../types/customersTypes';
import CustomSelectItem, {
  ItemSchema,
} from '../../components/CustomSelectItem/CustomSelectItem';
import useCustomersService from '../../services/customers';
import { handleCustomSelectCreate, capitalizeTransform } from '../../lib/util';
import useNotification from '../../hooks/useNotification';
import { ReactComponent as ChevronDownGray } from '../../assets/svg/chevron-down-gray.svg';
import usePermissions from '../../hooks/usePermissions';

interface IAccountsFilter {
  type: string;
  setType: (value: string) => void;
  customerId: string;
  setCustomerId: (value: string) => void;
  onFilterItemChange: (
    value: string,
    itemState: string,
    setItemState: (value: string) => void
  ) => void;
}

const AccountsFilter = ({
  type,
  setType,
  customerId,
  setCustomerId,
  onFilterItemChange,
}: IAccountsFilter) => {
  const { width } = useViewportSize();
  const [expandFilter, setExpandFilter] = useState<boolean>(false);
  const [filterNumber, setFilterNumber] = useState<number>(0);
  const [typeChecked, setTypeChecked] = useState<boolean>(false);
  const [customerChecked, setCustomerChecked] = useState<boolean>(false);
  const { getCustomers } = useCustomersService();
  const [customers, setCustomers] = useState<ItemSchema[]>([]);
  const { handleError } = useNotification();
  const { customerRead } = usePermissions();

  useEffect(() => {
    if (customerRead) handleGetCustomers();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange('', type, setType);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [typeChecked]);

  useEffect(() => {
    if (customerChecked) {
      setFilterNumber(filterNumber + 1);
    } else {
      onFilterItemChange('', customerId, setCustomerId);
      if (filterNumber > 0) {
        setFilterNumber(filterNumber - 1);
      }
    }
    //eslint-disable-next-line
  }, [customerChecked]);

  const handleGetCustomers = () => {
    getCustomers({ page: 0, size: 50 })
      .then((res: GetCustomersResponse) => {
        const data: ItemSchema[] = res.data.map((customer) => {
          return {
            label: capitalizeTransform(
              customer?.type === CustomerAccountTypes.individual
                ? `${customer?.fullName?.firstName} ${customer?.fullName?.lastName}`
                : customer?.detail?.businessName
            ),
            description: customer?.id,
            value: customer?.id,
          };
        });

        setCustomers(data);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  return (
    <div className="filter-container">
      <Popover
        opened={expandFilter}
        onChange={setExpandFilter}
        position="bottom-end"
        width={320}
        shadow="lg"
      >
        <Popover.Target>
          <Button
            variant="default"
            size={width > 700 ? 'md' : 'sm'}
            onClick={() => {
              setExpandFilter(!expandFilter);
            }}
            compact
            className="compact-btn unstyled"
            rightIcon={<ChevronDownGray />}
          >
            Filters{' '}
            {filterNumber > 0 && (
              <span className="filter-number">{filterNumber}</span>
            )}
          </Button>
        </Popover.Target>

        <Popover.Dropdown>
          <div className="filter-menu">
            <Group align="center" position="apart">
              <Text fw={500} fz={14}>
                Type
              </Text>

              <Switch
                checked={typeChecked}
                onChange={(event) =>
                  setTypeChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {typeChecked && (
              <div className="filter-item-expanded">
                <Select
                  placeholder="Account type"
                  value={type}
                  onChange={(value: string) => {
                    onFilterItemChange(value, type, setType);
                    setExpandFilter(false);
                  }}
                  data={[
                    { value: 'SAVINGS', label: 'Savings' },
                    { value: 'CURRENT', label: 'Current' },
                    { value: 'MASTER', label: 'Master' },
                    { value: 'REVENUE', label: 'Revenue' },
                    { value: 'SETTLEMENT', label: 'Settlement' },
                    { value: 'FBO', label: 'FBO' },
                  ]}
                />
              </div>
            )}

            <Group align="center" position="apart" mt={10}>
              <Text fw={500} fz={14}>
                Customer ID
              </Text>

              <Switch
                checked={customerChecked}
                onChange={(event) =>
                  setCustomerChecked(event.currentTarget.checked)
                }
              />
            </Group>
            {customerChecked && (
              <div className="filter-item-expanded">
                <Select
                  placeholder="Select Customer/ Input ID"
                  value={customerId}
                  onChange={(value: string) => {
                    onFilterItemChange(value, customerId, setCustomerId);
                    setExpandFilter(false);
                  }}
                  searchable
                  itemComponent={CustomSelectItem}
                  data={customers}
                  maxDropdownHeight={400}
                  creatable
                  getCreateLabel={(query) => `Select ${query}`}
                  onCreate={(query) => {
                    return handleCustomSelectCreate(
                      query,
                      customers,
                      () =>
                        onFilterItemChange(query, customerId, setCustomerId),
                      setCustomers
                    );
                  }}
                />
              </div>
            )}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default AccountsFilter;
