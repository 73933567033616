import React from 'react';
import { Group, Pagination, Menu } from '@mantine/core';
import { ResponseMetaPagination } from '../../lib/types';
import { ReactComponent as ChevronDownGray } from '../../assets/svg/chevron-down-gray.svg';
import './pagination.scss';

interface IPaginationControls {
  meta: ResponseMetaPagination;
  setPage: (value: number) => void;
  setLoader: (value: boolean) => void;
  setSize: (value: number) => void;
}

const PaginationControls = ({
  meta,
  setPage,
  setLoader,
  setSize,
}: IPaginationControls) => {
  return (
    <div className="pagination">
      <Group position="apart" mt={20} align="center">
        <div>
          <Pagination
            siblings={1}
            color="dark"
            withControls={false}
            page={meta.number + 1}
            onChange={(value: number) => {
              if (value !== meta.number + 1) {
                setLoader(true);
                setPage(value - 1);
              }
            }}
            total={meta.totalPages}
          />
        </div>

        <Menu shadow="md" position="bottom-end">
          <Menu.Target>
            <span>
              {meta.total > 0 && (
                <Group
                  className="size-select click"
                  spacing={10}
                  align="center"
                >
                  <div>Show {meta.size} items</div>

                  <ChevronDownGray />
                </Group>
              )}
            </span>
          </Menu.Target>

          <Menu.Dropdown>
            {[20, 50, 100].map((size) => (
              <Menu.Item
                key={size}
                onClick={() => {
                  setLoader(true);
                  setSize(size);
                  setPage(0);
                }}
                disabled={size === meta.size}
              >
                Show {size} items
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Group>
    </div>
  );
};

export default PaginationControls;
