import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  Group,
  Table,
  ScrollArea,
  Tooltip,
  LoadingOverlay,
} from '@mantine/core';
import useRoleManagementService from '../../../services/roleManagement';
import {
  GetRolesResponse,
  RoleType,
  RolesState,
} from '../../../types/roleManagementTypes';
import EmptyData from '../../../components/EmptyData/EmptyData';
import PaginationControls from '../../../components/PaginationControls/PaginationControls';
import { initialMetaData } from '../../../lib/store/reducers/DataReducer';
import { truncateString } from '../../../lib/util';
import { AxiosError } from 'axios';
import useNotification from '../../../hooks/useNotification';
import usePermissions from '../../../hooks/usePermissions';
import CreateRole from './CreateRole';
import RoleDetails from './RoleDetails';

const Roles = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const { getRoles } = useRoleManagementService();
  const [activeRole, setActiveRole] = useState<RoleType | null>(null);
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [roles, setRoles] = useState<RolesState>({
    data: [],
    meta: initialMetaData,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { handleError } = useNotification();
  const { roleWrite } = usePermissions();
  const [createRoleModal, setCreateRoleModal] = useState<boolean>(false);

  useEffect(() => {
    handleGetRoles();
    //eslint-disable-next-line
  }, [page, size]);

  const handleGetRoles = () => {
    setLoading(true);

    getRoles(size, page)
      .then((res: GetRolesResponse) => {
        setRoles({
          data: res?.data,
          meta: res?.meta?.pagination,
        });
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Roles | Anchor</title>
        <meta
          name="description"
          content="View and manage the roles of your organization"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <CreateRole
        modalOpen={createRoleModal}
        closeModal={() => {
          setCreateRoleModal(false);
        }}
        callback={() => handleGetRoles()}
        role={activeRole}
      />

      <RoleDetails
        modalOpen={detailsDrawer}
        closeModal={() => {
          setDetailsDrawer(false);
          setActiveRole(null);
        }}
        activeRole={activeRole}
        callback={() => handleGetRoles()}
      />

      <Group position="apart" align="center" mb={25}>
        <div className="p-title">Roles</div>

        {roles.meta.total > 0 && (
          <Button
            color="dark"
            onClick={() => {
              setCreateRoleModal(true);
            }}
            disabled={!roleWrite}
            compact
            className="compact-btn"
          >
            Create Role
          </Button>
        )}
      </Group>

      {roles.data.length > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table mt={20} verticalSpacing="md" sx={{ minWidth: 600 }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  roles.data.map((role: RoleType, index: number) => (
                    <tr
                      key={index}
                      className="click"
                      id={role.id}
                      onClick={() => {
                        setActiveRole(role);
                        setDetailsDrawer(true);
                      }}
                    >
                      <td>{role.roleName}</td>

                      <td>
                        <div className="type-badge">
                          {role.systemRole ? 'Default' : 'Custom'}
                        </div>
                      </td>

                      <td>
                        <Tooltip
                          multiline
                          width={350}
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label={role.description}
                          position="bottom"
                          disabled={role.description.length < 61}
                        >
                          <span>{truncateString(role.description, 60)}</span>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={roles.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {roles.data.length === 0 && (
        <EmptyData
          title="You have not created any role"
          desc="At the moment, there is no data to be displayed."
          buttonAction={() => {
            setCreateRoleModal(true);
          }}
          buttonText="Create Role"
        />
      )}
    </div>
  );
};

export default Roles;
