import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../../hooks/useRedux';
import { OrganizationType } from '../../../types/organizationTypes';

const General = () => {
  const organizations = useAppSelector((state) => state.user.organizations);
  const [organization, setOrganization] = useState<OrganizationType | null>(
    null
  );

  useEffect(() => {
    if (organizations.length > 0) {
      setOrganization(organizations[0]);
    }
  }, [organizations]);

  return (
    <div className="profile">
      <Helmet>
        <title>General | Anchor</title>
        <meta
          name="description"
          content="View and edit your profile information"
        />
      </Helmet>

      <div className="p-title extra-mb">General</div>

      <div className="p-group">
        <div className="p-g-item">
          <div>Organisation name</div>
          <div>{organization?.orgName}</div>
        </div>

        <div className="p-g-item">
          <div>Organisation ID</div>
          <div>{organization?.id}</div>
        </div>
      </div>
    </div>
  );
};

export default General;
