import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import AuthHeader, { HeaderTypes } from '../../components/Auth/AuthHeader';
import CheckEmail from '../../assets/png/email-verification.png';

const EmailSent = () => {
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('reset')) {
      setIsPasswordReset(true);
    }
  }, [searchParams]);

  return (
    <div className="auth-page">
      <Helmet>
        <title>Check your email | Anchor</title>
        <meta name="description" content="Check your email to continue" />
      </Helmet>

      <AuthHeader type={HeaderTypes.hasAccount} />

      <div className="auth-main verify-email">
        <img height={270} width={261} src={CheckEmail} alt="check email" />

        <div className="a-m-title">Check your email</div>

        <div className="a-m-desc">
          {isPasswordReset
            ? 'A password reset link has been sent to your email address. Kindly follow this link to reset your password'
            : 'A verification link has been sent to your email address. Kindly click on the link to verify your email address'}
        </div>
      </div>
    </div>
  );
};

export default EmailSent;
