import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { AxiosError } from 'axios';
import { CSVLink } from 'react-csv';
import {
  Button,
  Modal,
  LoadingOverlay,
  Stack,
  Alert,
  Group,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import useTransfersService from '../../services/transfers';
import useTransactionsService from '../../services/transactions';
import useSubAccountsService from '../../services/subAccounts';
import { ResponseMetaPagination } from '../../lib/types';
import { TransferType, TransferTypes } from '../../types/transfersTypes';
import { TransactionType } from '../../types/transactionsTypes';
import { showNotification } from '@mantine/notifications';
import { formatAmount } from '../../lib/util';
import useNotification from '../../hooks/useNotification';
import { useViewportSize } from '@mantine/hooks';

export enum ExportResources {
  nipTransfers = 'NIP_TRANSFER',
  bookTransfers = 'BOOK_TRANSFER',
  transactions = 'transactions',
  subAccountTransactions = 'subAccountTransactions',
}

interface IExportData {
  modalOpen: boolean;
  closeModal: () => void;
  resource: string;
  extraData?: Record<string, string | boolean>;
}

export const ExportData = ({
  modalOpen,
  closeModal,
  resource,
  extraData,
}: IExportData) => {
  const { width } = useViewportSize();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadReady, setDownloadReady] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState<
    (Date | string | undefined)[][]
  >([]);
  const { getTransfers } = useTransfersService();
  const { getTransactions } = useTransactionsService();
  const { getSubAccountTransactions } = useSubAccountsService();
  const { handleError } = useNotification();
  const fromRef = useRef<HTMLInputElement>(null);
  const toRef = useRef<HTMLInputElement>(null);
  const [disableFromRef, setDisableFromRef] = React.useState<boolean>(false);
  const [disableToRef, setDisableToRef] = React.useState<boolean>(false);

  const form = useForm({
    initialValues: {
      startDate: null,
      endDate: null,
    },

    validate: {
      startDate: (value) => (!value ? 'Select start date' : null),
      endDate: (value) => (!value ? 'Select end date' : null),
    },
  });

  useEffect(() => {
    if (!modalOpen) {
      setDownloadData([]);
      setDownloadReady(false);
    }
  }, [modalOpen]);

  type GetResourceData = (exportSize?: number) => Promise<unknown> | undefined;

  const getResourceData: GetResourceData = (exportSize?: number) => {
    switch (resource) {
      case ExportResources.nipTransfers:
        return getTransfers({
          page: 0,
          size: exportSize ? exportSize : 20,
          type: TransferTypes.NIP_TRANSFER,
          from: moment(form.values.startDate).format('YYYY-MM-DD'),
          to: moment(form.values.endDate).format('YYYY-MM-DD'),
          onlySubAccount: extraData?.isSubAccount ? true : false,
        });

      case ExportResources.bookTransfers:
        return getTransfers({
          page: 0,
          size: exportSize ? exportSize : 20,
          type: TransferTypes.BOOK_TRANSFER,
          from: moment(form.values.startDate).format('YYYY-MM-DD'),
          to: moment(form.values.endDate).format('YYYY-MM-DD'),
          onlySubAccount: extraData?.isSubAccount ? true : false,
        });

      case ExportResources.transactions:
        return getTransactions({
          page: 0,
          size: exportSize ? exportSize : 20,
          from: moment(form.values.startDate).format('YYYY-MM-DD'),
          to: moment(form.values.endDate).format('YYYY-MM-DD'),
          ...(extraData &&
            extraData.accountId && {
              accountId: `${extraData.accountId}`,
            }),
        });

      case ExportResources.subAccountTransactions:
        return getSubAccountTransactions({
          page: 0,
          size: exportSize ? exportSize : 20,
          startDate: moment(form.values.startDate).format('YYYY-MM-DD'),
          toDate: moment(form.values.endDate).format('YYYY-MM-DD'),
          ...(extraData &&
            extraData.subAccountId && {
              subAccountId: `${extraData.subAccountId}`,
            }),
        });
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    getResourceData()
      ?.then((res: any) => {
        getResourceData(calcExportSize(res?.meta?.pagination))
          ?.then((res: any) => {
            form.reset();
            prepareCSV(res.data);
          })
          ?.catch((err: AxiosError) => {
            handleError(err);
          })
          ?.finally(() => {
            setLoading(false);
          });
      })
      ?.catch((err: AxiosError) => {
        handleError(err);
        setLoading(false);
      });
  };

  const prepareCSV = (data: any[]) => {
    switch (resource) {
      case ExportResources.nipTransfers: {
        const transfersData: TransferType[] = data;

        const csvHead = [
          'ID',
          'Amount',
          'Currency',
          'Date Created',
          'Source Account Name',
          'Source Account Number',
          'Source Bank',
          'Counterparty Name',
          'Counterparty Account Number',
          'Counterparty Bank',
          'Type',
          'Status',
          'Failure Reason',
          'Session ID',
          'Reference',
          'Reason',
        ];

        const csvBody = transfersData.map((transfer) => {
          const {
            id,
            amount,
            currency,
            createdAt,
            account,
            counterParty,
            type,
            status,
            failureReason,
            sessionId,
            reference,
            reason,
          } = transfer;

          return [
            id,
            formatAmount(amount),
            currency,
            `${createdAt}`,
            account.data.accountName,
            `${account.data.accountNumber}`,
            account.data.bank.name,
            counterParty?.data?.accountName,
            counterParty?.data?.accountNumber,
            counterParty?.data?.bank?.name,
            type,
            status,
            failureReason,
            sessionId,
            reference,
            reason,
          ];
        });

        setDownloadData([csvHead, ...csvBody]);
        setDownloadReady(true);
        break;
      }

      case ExportResources.bookTransfers: {
        const transfersData: TransferType[] = data;

        const csvHead = [
          'ID',
          'Amount',
          'Currency',
          'Date Created',
          'Source Account Name',
          'Source Account Number',
          'Source Bank',
          'Destination Account Name',
          'Destination Account Number',
          'Destination Bank',
          'Type',
          'Status',
          'Failure Reason',
          'Session ID',
          'Reference',
          'Reason',
        ];

        const csvBody = transfersData.map((transfer) => {
          const {
            id,
            amount,
            currency,
            createdAt,
            account,
            destinationAccount,
            type,
            status,
            failureReason,
            sessionId,
            reference,
            reason,
          } = transfer;

          return [
            id,
            formatAmount(amount),
            currency,
            `${createdAt}`,
            account.data.accountName,
            `${account.data.accountNumber}`,
            account.data.bank.name,
            destinationAccount?.data?.accountName,
            destinationAccount?.data?.accountNumber,
            destinationAccount?.data?.bank?.name,
            type,
            status,
            failureReason,
            sessionId,
            reference,
            reason,
          ];
        });

        setDownloadData([csvHead, ...csvBody]);
        setDownloadReady(true);
        break;
      }

      case ExportResources.transactions: {
        const transactionsData: TransactionType[] = data;

        const csvHead = [
          'ID',
          'Currency',
          'Amount',
          'Balance Before',
          'Balance After',
          'Transaction Date',
          'Direction',
          'Transaction Type',
          'Summary',
        ];

        const csvBody = transactionsData.map((transaction) => {
          const {
            id,
            currency,
            amount,
            balanceBefore,
            balanceAfter,
            transactionDate,
            direction,
            transactionType,
            summary,
          } = transaction;

          return [
            id,
            currency,
            formatAmount(amount),
            formatAmount(balanceBefore),
            formatAmount(balanceAfter),
            transactionDate,
            direction,
            transactionType,
            summary,
          ];
        });

        setDownloadData([csvHead, ...csvBody]);
        setDownloadReady(true);
        break;
      }

      case ExportResources.subAccountTransactions: {
        const transactionsData: TransactionType[] = data;

        const csvHead = [
          'ID',
          'Currency',
          'Amount',
          'Balance Before',
          'Balance After',
          'Transaction Date',
          'Direction',
          'Transaction Type',
          'Summary',
        ];

        const csvBody = transactionsData.map((transaction) => {
          const {
            id,
            currency,
            amount,
            balanceBefore,
            balanceAfter,
            transactionDate,
            direction,
            transactionType,
            summary,
          } = transaction;

          return [
            id,
            currency,
            formatAmount(amount),
            formatAmount(balanceBefore),
            formatAmount(balanceAfter),
            transactionDate,
            direction,
            transactionType,
            summary,
          ];
        });

        setDownloadData([csvHead, ...csvBody]);
        setDownloadReady(true);
        break;
      }
    }
  };

  const calcExportSize = (meta: ResponseMetaPagination) => {
    return meta.totalPages * meta.size;
  };

  const onDownloadClick = () => {
    showNotification({
      title: 'Download Successful',
      message: 'Your file has been downloaded.',
      color: 'orange',
    });
    closeModal();
    form.reset();
  };

  return (
    <Modal
      opened={modalOpen}
      onClose={closeModal}
      size={500}
      title={<div className="modal-title">Export Data</div>}
      overflow="inside"
      fullScreen={width < 568}
      padding={width < 568 ? 'lg' : 50}
      withCloseButton={false}
      radius={7}
    >
      <LoadingOverlay visible={loading} />

      <div className="modal-desc" style={{ maxWidth: 321 }}>
        Select dates to export data.
      </div>

      {form.values.startDate &&
        form.values.endDate &&
        form.values.startDate > form.values.endDate && (
          <Alert mt={10} color="red" fw={500}>
            Start date cannot be after end date!
          </Alert>
        )}

      {downloadReady && (
        <Alert my={10} color="green">
          Your file is ready. Click the button below to download!
        </Alert>
      )}

      <form onSubmit={form.onSubmit(() => handleSubmit())}>
        <Stack>
          {!downloadReady && (
            <>
              <div className="date-range-input">
                <div
                  className="input-container"
                  onClick={() => {
                    if (disableFromRef) {
                      return;
                    }

                    if (fromRef.current) {
                      fromRef.current.click();
                    }
                  }}
                >
                  <DatePicker
                    variant="unstyled"
                    placeholder="Select date"
                    label="From"
                    clearable={false}
                    color="dark"
                    ref={fromRef}
                    onDropdownOpen={() => setDisableFromRef(true)}
                    onDropdownClose={() => {
                      setTimeout(() => {
                        setDisableFromRef(false);
                      }, 200);
                    }}
                    {...form.getInputProps('startDate')}
                  />
                </div>

                <div
                  className="input-container"
                  onClick={() => {
                    if (disableToRef) {
                      return;
                    }

                    if (toRef.current) {
                      toRef.current.click();
                    }
                  }}
                >
                  <DatePicker
                    variant="unstyled"
                    placeholder="Select date"
                    label="To"
                    clearable={false}
                    color="dark"
                    ref={toRef}
                    onDropdownOpen={() => setDisableToRef(true)}
                    onDropdownClose={() => {
                      setTimeout(() => {
                        setDisableToRef(false);
                      }, 200);
                    }}
                    {...form.getInputProps('endDate')}
                  />
                </div>
              </div>
            </>
          )}

          {downloadReady ? (
            <Group position="right" mt={60}>
              <Button
                variant="default"
                compact
                className="compact-btn"
                onClick={closeModal}
              >
                Close
              </Button>

              <CSVLink
                data={downloadData}
                filename={`${resource.toLowerCase()}s_export.csv`}
                onClick={onDownloadClick}
              >
                <Button
                  color="dark"
                  compact
                  className="compact-btn"
                  onClick={handleSubmit}
                >
                  Download File
                </Button>
              </CSVLink>
            </Group>
          ) : (
            <Group position="right" mt={60}>
              <Button
                variant="default"
                compact
                className="compact-btn"
                onClick={closeModal}
              >
                Cancel
              </Button>

              <Button
                color="dark"
                type="submit"
                compact
                className="compact-btn"
                onClick={handleSubmit}
                disabled={
                  form.values.startDate &&
                  form.values.endDate &&
                  form.values.startDate > form.values.endDate
                    ? true
                    : false
                }
              >
                Generate Data
              </Button>
            </Group>
          )}
        </Stack>
      </form>
    </Modal>
  );
};

export default ExportData;
