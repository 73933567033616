import React from 'react';
import { Switch } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import { ReactComponent as Book } from '../../assets/svg/book.svg';
import {
  Mode,
  goToLiveMode,
  goToTestMode,
} from '../../lib/store/reducers/SettingsReducer';
import { selectMode } from '../../lib/store';
import { CLEAR_DATA } from '../../lib/store/actionTypes/actionTypes';
import { useViewportSize } from '@mantine/hooks';
import './env-switch.scss';

const EnvSwitch = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectMode);
  const isLive = useAppSelector((state) => state.user.organizations[0]?.isLive);
  const { width } = useViewportSize();

  const toggleMode = () => {
    dispatch({ type: CLEAR_DATA });
    if (mode === Mode.Live) {
      dispatch(goToTestMode());
      showNotification({
        title: 'Env changed',
        message: 'You are now in sandbox mode',
        autoClose: 1000,
        color: 'orange',
      });
    } else {
      dispatch(goToLiveMode());
      showNotification({
        title: 'Env changed',
        message: 'You are now in live mode',
        autoClose: 1000,
        color: 'orange',
      });
    }
  };

  return (
    <div className="env-switch-docs">
      <a
        href="https://docs.getanchor.co/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="go-to-docs click">
          <Book />
          API Docs
        </div>
      </a>

      <div className="env-switch">
        <Switch
          labelPosition="left"
          onChange={toggleMode}
          checked={mode === Mode.Live}
          label={
            mode === Mode.Live
              ? 'Live is on'
              : `Sandbox${width > 768 || width < 500 ? ' is on' : ''}`
          }
          disabled={!isLive}
        />
      </div>
    </div>
  );
};

export default EnvSwitch;
