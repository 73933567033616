import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import {
  Text,
  Button,
  Group,
  ScrollArea,
  Table,
  Badge,
  LoadingOverlay,
} from '@mantine/core';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import useApiKeysService from '../../../services/apiKeys';
import EmptyData from '../../../components/EmptyData/EmptyData';
import PaginationControls from '../../../components/PaginationControls/PaginationControls';
import {
  ApiKeyType,
  ApiKeyStateTypes,
  GetApiKeysResponse,
} from '../../../types/apiKeysTypes';
import { selectMode } from '../../../lib/store';
import { formatDate2 } from '../../../lib/util';
import { setApiKeys } from '../../../lib/store/reducers/DataReducer';
import useNotification from '../../../hooks/useNotification';
import usePermissions from '../../../hooks/usePermissions';
import CreateApiKey from './CreateApiKey';
import ApiKeyDetails from './ApiKeyDetails';
import './api-keys.scss';

const ApiKeys = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const apiKeys = useAppSelector((state) => state.data.apiKeys);
  const { getApiKeys } = useApiKeysService();
  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectMode);
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { apikeyWrite } = usePermissions();
  const [createApiKeyModal, setCreateApiKeyModal] = useState<boolean>(false);
  const [apiKeyDetailsModal, setApiKeyDetailsModal] = useState<boolean>(false);
  const [activeApiKey, setActiveApiKey] = useState<ApiKeyType | null>(null);

  useEffect(() => {
    handleGetApiKeys();
    //eslint-disable-next-line
  }, [page, size, mode]);

  const handleGetApiKeys = () => {
    if (!apiKeys.dataFetched) setLoading(true);

    getApiKeys(size, page)
      .then((res: GetApiKeysResponse) => {
        dispatch(
          setApiKeys({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>API Keys | Anchor</title>
        <meta
          name="description"
          content="View and manage your organization API Keys"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <CreateApiKey
        modalOpen={createApiKeyModal}
        closeModal={() => {
          setCreateApiKeyModal(false);
        }}
        callback={() => handleGetApiKeys()}
      />

      <ApiKeyDetails
        modalOpen={apiKeyDetailsModal}
        closeModal={() => {
          setApiKeyDetailsModal(false);
          setActiveApiKey(null);
        }}
        callback={() => handleGetApiKeys()}
        activeApiKey={activeApiKey}
      />

      <Group position="apart" align="center" mb={25}>
        <div className="p-title">API Keys</div>

        {apiKeys.meta.total > 0 && (
          <Button
            color="dark"
            onClick={() => {
              setCreateApiKeyModal(true);
            }}
            compact
            className="compact-btn"
            disabled={!apikeyWrite}
          >
            <Text fz={14}>Create API Key</Text>
          </Button>
        )}
      </Group>

      {apiKeys.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table mt={15} verticalSpacing="md" sx={{ minWidth: 800 }}>
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Prefix</th>
                  <th>Environment</th>
                  <th>State</th>
                  <th>Date Created</th>
                  <th>Expiry Date</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  apiKeys.data.map((apiKey: ApiKeyType, index: number) => (
                    <tr
                      key={index}
                      className="click"
                      onClick={() => {
                        setActiveApiKey(apiKey);
                        setApiKeyDetailsModal(true);
                      }}
                    >
                      <td id={apiKey.id}>{apiKey.label}</td>

                      <td>{apiKey.prefix}</td>

                      <td>
                        <div className="type-badge">{apiKey.environment}</div>
                      </td>

                      <td>
                        <Badge
                          color={
                            apiKey.state === ApiKeyStateTypes.active
                              ? 'green'
                              : 'red'
                          }
                        >
                          {apiKey.state}
                        </Badge>
                      </td>

                      <td className="gray">
                        {formatDate2(apiKey.createdAt, 'MMM Do, YYYY h:mm a')}
                      </td>

                      <td>
                        {apiKey?.expiryTime ? (
                          formatDate2(apiKey?.expiryTime, 'MMM Do, YYYY h:mm a')
                        ) : (
                          <Text opacity={0.7}>Never expires</Text>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={apiKeys.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {apiKeys.meta.total === 0 && (
        <EmptyData
          title="No API Keys yet"
          desc="At the moment, there is no data to be displayed."
          buttonText="Create Api Key"
          buttonAction={() => {
            setCreateApiKeyModal(true);
          }}
        />
      )}
    </div>
  );
};

export default ApiKeys;
