import React, { useState, useEffect, Fragment } from 'react';
import {
  Button,
  TextInput,
  Select,
  Group,
  Text,
  Drawer,
  ScrollArea,
  LoadingOverlay,
} from '@mantine/core';
import { AxiosError } from 'axios';
import { useDebouncedValue } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useForm, UseFormReturnType } from '@mantine/form';
import useUserManagementService from '../../../services/userManagement';
import useRoleManagementService from '../../../services/roleManagement';
import { InviteUserType } from '../../../types/userManagementTypes';
import {
  RoleType,
  GetRolesResponse,
  SystemRoles,
} from '../../../types/roleManagementTypes';
import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron-down-gray.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/x-red.svg';
import useNotification from '../../../hooks/useNotification';

type UserSchema = {
  email: string;
  role: string;
};

interface AddUsersProps {
  drawerOpen: boolean;
  closeDrawer: () => void;
  callback: () => void;
}

const AddUsers = ({ drawerOpen, closeDrawer, callback }: AddUsersProps) => {
  const [hasError, setHasError] = useState<boolean>(true);
  const userSchema: UserSchema = {
    email: '',
    role: '',
  };
  const { inviteUsers, checkIfUserExists } = useUserManagementService();
  const { getRoles } = useRoleManagementService();
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleError } = useNotification();

  useEffect(() => {
    handleGetRoles();
    //eslint-disable-next-line
  }, []);

  const handleGetRoles = () => {
    getRoles(40, 0)
      .then((res: GetRolesResponse) => {
        setRoles(res.data);
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  const form = useForm({
    initialValues: {
      users: [userSchema],
    },

    validate: {
      users: {
        role: (value) => (value === '' ? 'Select role' : null),
      },
    },
  });

  const submit = (values: { users: UserSchema[] }) => {
    setLoading(true);

    const requestData: InviteUserType[] = values.users.map(
      (user: UserSchema) => ({
        email: user.email,
        role: {
          id: user.role,
        },
      })
    );

    inviteUsers(requestData)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Invites sent successfully.',
          color: 'orange',
        });

        form.reset();
        closeDrawer();
        callback();
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckIfUserExists = (email: string, index: number) => {
    checkIfUserExists(email)
      .then((res: { userExists: boolean }) => {
        if (res.userExists) {
          form.setFieldError(`users.${index}.email`, 'User already exists');
          setHasError(true);
        } else {
          form.clearFieldError(`users.${index}.email`);
          setHasError(false);
        }
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };

  return (
    <Drawer
      opened={drawerOpen}
      onClose={closeDrawer}
      title={<span className="modal-title">Add Team Member</span>}
      padding="lg"
      position="right"
      size={500}
    >
      <LoadingOverlay visible={loading} />

      <form
        className="add-drawer"
        onSubmit={form.onSubmit((values) => submit(values))}
      >
        <ScrollArea type="scroll">
          <div className="add-scrollable-section">
            {form.values.users.map((item: UserSchema, index: number) => (
              <Fragment key={index}>
                <UserComponent
                  index={index}
                  form={form}
                  handleCheckIfUserExists={handleCheckIfUserExists}
                  roles={roles}
                />
              </Fragment>
            ))}

            <Group>
              <Text
                className="click"
                fw={500}
                fz={13}
                color="#7F7F7F"
                pb={10}
                onClick={() => form.insertListItem('users', userSchema)}
              >
                + Add User
              </Text>
            </Group>
          </div>
        </ScrollArea>

        <Group className="add-drawer-footer" position="apart">
          <Button
            color="dark"
            variant="default"
            compact
            className="compact-btn"
            onClick={closeDrawer}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="dark"
            compact
            className="compact-btn"
            disabled={hasError}
          >
            Invite
          </Button>
        </Group>
      </form>
    </Drawer>
  );
};

interface IUserComponent {
  index: number;
  form: UseFormReturnType<{ users: UserSchema[] }>;
  handleCheckIfUserExists: (email: string, index: number) => void;
  roles: RoleType[];
}

const UserComponent = ({
  index,
  form,
  handleCheckIfUserExists,
  roles,
}: IUserComponent) => {
  const [email] = useDebouncedValue(form.values.users[index].email, 300);

  useEffect(() => {
    const email = form.values.users[index].email;
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return;
    }
    handleCheckIfUserExists(email, index);
    //eslint-disable-next-line
  }, [email]);

  return (
    <div className="member-c">
      <TextInput
        label="Email"
        required
        placeholder="User email address"
        {...form.getInputProps(`users.${index}.email`)}
      />

      <Select
        required
        label="Select role"
        placeholder="Select role"
        mt="sm"
        data={roles
          .filter(
            (role: RoleType) => role.roleName !== SystemRoles.PrimaryOwner
          )
          .map((role: RoleType) => ({
            label: role.roleName,
            value: role.id,
          }))}
        rightSection={<ChevronDown />}
        {...form.getInputProps(`users.${index}.role`)}
      />

      {form.values.users.length > 1 && (
        <Group align="center" mt={16} spacing={4}>
          <DeleteIcon />

          <Text
            color="#DE7965"
            fw={500}
            fz={13}
            onClick={() => form.removeListItem('users', index)}
            className="click"
          >
            Delete user
          </Text>
        </Group>
      )}
    </div>
  );
};

export default AddUsers;
