import React, { useEffect, useState, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Badge,
  Group,
  Table,
  ScrollArea,
  Drawer,
  Text,
  CloseButton,
  LoadingOverlay,
} from '@mantine/core';
import { useAppSelector } from '../../hooks/useRedux';
import { ReactComponent as User } from '../../assets/svg/user-checked.svg';
import MetricCard from '../../components/MetricCard/MetricCard';
import useCustomersService from '../../services/customers';
import EmptyData from '../../components/EmptyData/EmptyData';
import {
  CustomerType,
  CustomerAccountTypes,
  VerificationStatusTypes,
  Officer,
  GetCustomersResponse,
} from '../../types/customersTypes';
import { formatAddress, formatName } from '../../lib/util';
import DetailItem from '../../components/DetailItem/DetailItem';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import { ResponseMetaPagination } from '../../lib/types';
import { initialMetaData } from '../../lib/store/reducers/DataReducer';
import useNotification from '../../hooks/useNotification';

interface ICustomers {
  customerType: CustomerAccountTypes;
}

interface CustomersState {
  data: CustomerType[];
  meta: ResponseMetaPagination;
}

const Customers = ({ customerType }: ICustomers) => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [query] = useState<string>('');
  const { getCustomers } = useCustomersService();
  const [customers, setCustomers] = useState<CustomersState>({
    data: [],
    meta: initialMetaData,
  });
  const mode = useAppSelector(selectMode);
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [activeCustomer, setActiveCustomer] = useState<CustomerType | null>(
    null
  );
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const navigate = useNavigate();
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (search) {
      setLoading(true);
      handleGetCustomers(search);
    } else {
      handleGetCustomers();
    }

    //eslint-disable-next-line
  }, [page, size, mode, search, query, customerType]);

  const handleGetCustomers = (searchQuery?: string) => {
    setLoading(true);

    getCustomers({
      size,
      page,
      query: searchQuery ? searchQuery : query,
      customerType,
    })
      .then((res: GetCustomersResponse) => {
        setCustomers({
          data: res?.data,
          meta: res?.meta?.pagination,
        });
      })
      .catch((axiosError: AxiosError) => {
        handleError(axiosError);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearSearch = () => {
    setLoading(true);
    navigate(
      `/customers/${
        customerType === CustomerAccountTypes.business
          ? 'business'
          : 'individual'
      }`
    );
  };

  return (
    <div className="customers-page table-container">
      <Helmet>
        <title>
          {customerType === CustomerAccountTypes.business
            ? 'Business'
            : 'Individual'}{' '}
          Customers | Anchor
        </title>
        <meta name="description" content="View and manage your customers" />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <Drawer
        opened={detailsDrawer}
        onClose={() => setDetailsDrawer(false)}
        title={
          <Text fw={600} fz={23}>
            Customer Details
          </Text>
        }
        padding="xl"
        size={650}
        position="right"
      >
        <CustomerDetails customer={activeCustomer} />
      </Drawer>

      <Group position="apart" align="center">
        <div className={`p-title extra-mb ${search ? 'search-result' : ''}`}>
          {search && `Showing results for '${search}'`}

          {!search &&
            `${
              customerType === CustomerAccountTypes.business
                ? 'Business'
                : 'Individual'
            } Customers`}
        </div>

        {search && (
          <CloseButton
            title="Clear search"
            size="xl"
            iconSize={20}
            onClick={clearSearch}
          />
        )}
      </Group>

      <Group position="apart" align="center" mb={4}>
        {customers.meta.total > 0 && (
          <MetricCard
            Icon={User}
            title="Total customers created"
            value={`${customers.meta.total}`}
          />
        )}
      </Group>

      {customers.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
            mt={15}
          >
            <Table verticalSpacing="md" sx={{ minWidth: 800 }} highlightOnHover>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Date Created</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  customers.data.map(
                    (customer: CustomerType, index: number) => (
                      <tr
                        key={index}
                        onClick={() => {
                          if (customer.type === CustomerAccountTypes.business) {
                            navigate(`/customers/business/${customer.id}`);
                          } else {
                            setActiveCustomer(customer);
                            setDetailsDrawer(true);
                          }
                        }}
                        className="click"
                        id={customer.id}
                      >
                        <td className="capitalize">
                          {customer.type === CustomerAccountTypes.individual &&
                            `${customer?.fullName?.firstName.toLowerCase()} ${customer?.fullName?.lastName.toLowerCase()}`}

                          {customer.type === CustomerAccountTypes.business &&
                            customer.detail?.businessName.toLowerCase()}
                        </td>
                        <td>
                          <div className="type-badge">
                            {customer.type === CustomerAccountTypes.business &&
                              'Business'}
                            {customer.type ===
                              CustomerAccountTypes.individual && 'Individual'}
                          </div>
                        </td>
                        <td>
                          <Badge
                            color={getStatusBadgeColor(
                              customer?.verification?.status
                            )}
                          >
                            {customer?.verification?.status ?? 'unverified'}
                          </Badge>
                        </td>
                        <td className="gray">
                          {moment(customer.createdAt).format('MMM D, YYYY')}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={customers.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {customers.meta.total === 0 && (
        <EmptyData
          title="No customers yet"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

const CustomerDetails = ({ customer }: { customer: CustomerType | null }) => {
  return (
    customer && (
      <ScrollArea style={{ height: '88vh' }} type="always">
        <div className="details-drawer">
          <DetailItem
            title={
              customer?.type === CustomerAccountTypes.business
                ? 'Business name'
                : 'Customer name'
            }
            extraClasses="w-full main"
          >
            {customer?.type === CustomerAccountTypes.individual &&
              formatName(customer.fullName)}
            {customer?.type === CustomerAccountTypes.business &&
              customer.detail?.businessName}
          </DetailItem>

          <DetailItem title="Customer ID">{customer?.id}</DetailItem>

          <DetailItem title="Customer Type">
            <div className="type-badge">
              {customer?.type === CustomerAccountTypes.business && 'Business'}
              {customer?.type === CustomerAccountTypes.individual &&
                'Individual'}
            </div>
          </DetailItem>

          <DetailItem title="Status">
            <Badge color={getStatusBadgeColor(customer?.verification?.status)}>
              {customer?.verification?.status ?? 'unverified'}
            </Badge>
          </DetailItem>

          {customer?.verification?.level && (
            <DetailItem title="Tier">
              <Badge variant="dot" color="grape">
                {customer?.verification?.level}
              </Badge>
            </DetailItem>
          )}

          <DetailItem title="Date Added">
            {moment(customer?.createdAt).format('MMM Do, YYYY h:mm a')}
          </DetailItem>

          {customer?.type === CustomerAccountTypes.individual && (
            <>
              <DetailItem title="Email">{customer?.email}</DetailItem>

              <DetailItem title="Phone Number">
                {customer?.phoneNumber}
              </DetailItem>

              <DetailItem title="Address" extraClasses="sm-full">
                {formatAddress(customer?.address)}
              </DetailItem>
            </>
          )}

          {customer?.type === CustomerAccountTypes.business && (
            <>
              <DetailItem title="Business Info" extraClasses="w-full s-t" />

              <DetailItem title="BVN">
                {customer?.detail?.businessBvn}
              </DetailItem>

              <DetailItem title="Country">
                {customer?.detail?.country}
              </DetailItem>

              <DetailItem title="Registration Date">
                {moment(customer?.detail?.dateOfRegistration).format(
                  'MMM Do, YYYY'
                )}
              </DetailItem>

              <DetailItem title="Industry">
                {customer?.detail?.industry}
              </DetailItem>

              <DetailItem title="Registration Type">
                {customer?.detail?.registrationType}
              </DetailItem>

              {customer?.officers &&
                customer?.officers.length > 0 &&
                customer?.officers.map((officer: Officer, index: number) => (
                  <Fragment key={index}>
                    <DetailItem
                      title={`Officer ${
                        customer?.officers && customer?.officers.length > 1
                          ? index + 1
                          : ''
                      }`}
                      extraClasses="w-full s-t"
                    />

                    <DetailItem title="Full Name">
                      {formatName(officer?.fullName)}
                    </DetailItem>

                    <DetailItem title="Email">{officer?.email}</DetailItem>

                    <DetailItem title="BVN">{officer?.bvn}</DetailItem>

                    <DetailItem title="Nationality">
                      {officer?.nationality}
                    </DetailItem>

                    <DetailItem title="Phone Number">
                      {officer?.phoneNumber}
                    </DetailItem>

                    <DetailItem title="Role">{officer?.role}</DetailItem>

                    <DetailItem title="Address" extraClasses="sm-full">
                      {formatAddress(officer?.address)}
                    </DetailItem>
                  </Fragment>
                ))}
            </>
          )}
        </div>
      </ScrollArea>
    )
  );
};

export const getStatusBadgeColor = (status: VerificationStatusTypes) => {
  if (status === VerificationStatusTypes.approved) {
    return 'green';
  } else if (status === VerificationStatusTypes.pending) {
    return 'pink';
  } else if (status === VerificationStatusTypes.pendingManualReview) {
    return 'red';
  } else {
    return 'gray';
  }
};

export default Customers;
