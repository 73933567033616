import Kitsu from 'kitsu';
import { apiBaseUrl, apiNameSpace, sandBoxBaseUrl } from '../constants';
import { useAppSelector } from '../../hooks/useRedux';
import {
  selectAccessToken,
  selectAuthKeyProd,
  selectAuthKeyTest,
} from '../store';
import { Mode } from '../store/reducers/SettingsReducer';
import axios from 'axios';

export const createApi = () => {
  return new Kitsu({
    baseURL: apiBaseUrl + apiNameSpace,
    pluralize: false,
  });
};

export const createApiWithAuthKey = (apiKey: string) => {
  return new Kitsu({
    baseURL: apiBaseUrl + apiNameSpace,
    pluralize: false,
    headers: {
      'X-anchor-key': apiKey,
    },
  });
};

export enum ApiAuthMode {
  NoAuth = 1,
  BearerToken,
  ApiKey,
}

export const useApi = (apiAuthMode: ApiAuthMode, mode?: Mode) => {
  const accessToken = useAppSelector(selectAccessToken);
  const authKeyProd = useAppSelector(selectAuthKeyProd);
  const authKeyTest = useAppSelector(selectAuthKeyTest);

  const headers: any = {};
  if (apiAuthMode === ApiAuthMode.BearerToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  } else if (apiAuthMode === ApiAuthMode.ApiKey) {
    headers['X-anchor-key'] = mode === Mode.Test ? authKeyTest : authKeyProd;
  }

  const baseURL =
    mode === Mode.Test
      ? sandBoxBaseUrl + apiNameSpace
      : apiBaseUrl + apiNameSpace;

  return new Kitsu({
    baseURL,
    pluralize: false,
    headers,
  });
};

export const useWrappedApi = (apiAuthMode: ApiAuthMode, mode?: Mode) => {
  const accessToken = useAppSelector(selectAccessToken);
  const authKeyProd = useAppSelector(selectAuthKeyProd);
  const authKeyTest = useAppSelector(selectAuthKeyTest);

  const headers: any = {};
  if (apiAuthMode === ApiAuthMode.BearerToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  } else if (mode && apiAuthMode === ApiAuthMode.ApiKey) {
    headers['X-anchor-key'] = mode === Mode.Test ? authKeyTest : authKeyProd;
  }

  const baseURL =
    mode === Mode.Test
      ? sandBoxBaseUrl + apiNameSpace
      : apiBaseUrl + apiNameSpace;

  const kitsu = new Kitsu({
    baseURL,
    pluralize: false,
    headers,
  });

  return {
    baseURL,
    kitsu,
  };
};

export const useAxiosApi = (
  apiAuthMode: ApiAuthMode,
  mode?: Mode,
  customApiKey?: string
) => {
  const accessToken = useAppSelector(selectAccessToken);
  const authKeyProd = useAppSelector(selectAuthKeyProd);
  const authKeyTest = useAppSelector(selectAuthKeyTest);

  const AxiosApi: any = axios.create({
    baseURL:
      mode === Mode.Test
        ? sandBoxBaseUrl + apiNameSpace
        : apiBaseUrl + apiNameSpace,
  });

  AxiosApi.defaults.headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
  };

  const getApiKey = () => {
    let apiKey: string | undefined;

    if (customApiKey) {
      apiKey = customApiKey;
    } else if (mode === Mode.Test) {
      apiKey = authKeyTest;
    } else if (mode === Mode.Live) {
      apiKey = authKeyProd;
    }

    return apiKey;
  };

  AxiosApi.interceptors.request.use(function (config: any) {
    if (apiAuthMode === ApiAuthMode.BearerToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    } else if (mode && apiAuthMode === ApiAuthMode.ApiKey) {
      config.headers['X-anchor-key'] = getApiKey();
    }

    return config;
  });

  return AxiosApi;
};
