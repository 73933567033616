import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  TextInput,
  Select,
  Button,
  Group,
  LoadingOverlay,
  ScrollArea,
  Divider,
  Checkbox,
  Drawer,
  CopyButton,
  Modal,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import useNotification from '../../../hooks/useNotification';
import { showNotification } from '@mantine/notifications';
import ActionStep from '../../../components/ActionStep/ActionStep';
import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron-down-gray.svg';
import { convertToTitleCase } from '../../../lib/util';
import {
  ApiKeyType,
  CreateApiKeyData,
  PermissionType,
} from '../../../types/apiKeysTypes';
import useAuthService from '../../../services/auth';
import { useAppSelector } from '../../../hooks/useRedux';
import useApiKeysService from '../../../services/apiKeys';
import { Prism } from '@mantine/prism';
import { useViewportSize } from '@mantine/hooks';
import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';

interface CreateApiKeyProps {
  modalOpen: boolean;
  closeModal: () => void;
  callback: () => void;
}

const CreateApiKey = ({
  modalOpen,
  closeModal,
  callback,
}: CreateApiKeyProps) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const { getPermissions } = useAuthService();
  const { handleError } = useNotification();

  useEffect(() => {
    handleGetPermissions();
    //eslint-disable-next-line
  }, []);

  const handleGetPermissions = () => {
    getPermissions()
      .then((res: PermissionType[]) => {
        setPermissions(
          res
            .filter(
              (permission: PermissionType) => permission.environmentSpecific
            )
            .map((permission: PermissionType) => permission.scope)
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  return (
    <Drawer
      opened={modalOpen}
      onClose={closeModal}
      title={<span className="modal-title">Create API Key</span>}
      padding="xl"
      size="100vh"
      position="bottom"
      overlayOpacity={0.5}
      className="details-drawer-bottom gray-bg "
    >
      <ApiKeyForm
        closeModal={closeModal}
        callback={callback}
        permissions={permissions}
      />
    </Drawer>
  );
};

interface ApiKeyFormProps {
  closeModal: () => void;
  callback: () => void;
  permissions: string[];
}

const ApiKeyForm = ({ closeModal, callback, permissions }: ApiKeyFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [groupedPermissions, setGroupedPermissions] = useState<
    Record<string, string[]>
  >({});
  const organizations = useAppSelector((state) => state.user.organizations);
  const [isLive, setIsLive] = useState<boolean>(false);
  const { handleError } = useNotification();
  const [noExpiry, setNoExpiry] = useState<boolean>(true);
  const { createApiKey } = useApiKeysService();
  const [tokenModal, setTokenModal] = useState<boolean>(false);
  const [responseToken, setResponseToken] = useState<string>('');
  const [selectionType, setSelectionType] = useState<'all' | 'custom'>(
    'custom'
  );

  useEffect(() => {
    if (organizations.length > 0) {
      setIsLive(organizations[0]?.isLive);
    }
  }, [organizations]);

  useEffect(() => {
    form.setFieldValue('authorities', []);
    //eslint-disable-next-line
  }, []);

  const form = useForm({
    initialValues: {
      environment: isLive ? '' : 'TEST',
      label: '',
      authorities: [''],
      expiryDate: new Date(),
    },

    validate: {
      environment: (value) => (value === '' ? 'Select environment' : null),
      expiryDate: (value) =>
        noExpiry ? null : !value ? 'Select expiry date' : null,
      authorities: (value) =>
        selectionType === 'all'
          ? null
          : value.length === 0
          ? 'Select permission(s)'
          : null,
    },
  });

  useEffect(() => {
    if (permissions.length > 0) {
      handlePermissionsGrouping();
    }

    //eslint-disable-next-line
  }, [permissions]);

  const handleSubmit = (values: typeof form.values) => {
    const data: CreateApiKeyData = values;

    if (noExpiry) {
      delete data.expiryDate;
    }

    if (selectionType === 'all') {
      data.authorities = permissions;
    }

    setLoading(true);

    createApiKey(data)
      .then((res: ApiKeyType) => {
        showNotification({
          title: 'Success',
          message: 'Api key created.',
          color: 'orange',
        });
        callback();
        setResponseToken(res.token);
        setTokenModal(true);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePermissionsGrouping = () => {
    const grouped = permissions.reduce((acc, permission) => {
      const key = permission.split('.')[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(permission);
      return acc;
    }, {} as Record<string, string[]>);

    setGroupedPermissions(grouped);
  };

  return (
    <div className="permission-item-create">
      <form
        className="add-drawer"
        onSubmit={form.onSubmit((values) => handleSubmit(values))}
      >
        <LoadingOverlay visible={loading} />

        <ResponseModal
          modalOpen={tokenModal}
          closeModal={() => {
            setTokenModal(false);
            closeModal();
          }}
          token={responseToken}
        />

        <div className="form-fields">
          <ActionStep
            number={1}
            title="API Key Information"
            desc="Please provide some details about this API Key."
          />

          <TextInput
            required
            mt={24}
            label="Label"
            placeholder="Label"
            autoComplete="off"
            {...form.getInputProps('label')}
          />

          <Select
            label="Select Environment"
            required
            mt="sm"
            placeholder="Select environment"
            data={['PROD', 'TEST']}
            disabled={!isLive}
            rightSection={<ChevronDown />}
            {...form.getInputProps('environment')}
          />

          <Text color="#7f7f7f" mt={24} mb={13} fw={500} fz={14}>
            Expiry date
          </Text>

          <div className="group-select">
            <GroupSelectItem
              label="Never expires"
              desc="Last until deleted by admin"
              checked={noExpiry}
              onClick={() => setNoExpiry(true)}
            />

            <GroupSelectItem
              label="Expires"
              desc="Expires on date set"
              checked={!noExpiry}
              onClick={() => setNoExpiry(false)}
            />
          </div>

          {!noExpiry && (
            <>
              <Divider mb={20} mt={24} />

              <DatePicker
                label="Select date"
                disabled={noExpiry}
                placeholder="Pick date"
                required={noExpiry ? false : true}
                minDate={dayjs(new Date()).add(1, 'days').toDate()}
                {...form.getInputProps('expiryDate')}
              />
            </>
          )}
        </div>

        <div className="events">
          <ActionStep
            number={2}
            title="Add Permissions"
            desc="What kind of permissions do you want included?"
          />

          <ScrollArea type="auto" className="event-selector">
            <Checkbox
              label="Add all permissions"
              color="cyan"
              checked={selectionType === 'all'}
              onChange={(event) => {
                if (event.currentTarget.checked) {
                  form.setFieldValue(
                    'authorities',
                    permissions.map((event) => event)
                  );
                  setSelectionType('all');
                } else {
                  form.setFieldValue('authorities', []);

                  setSelectionType('custom');
                }
              }}
            />

            <Divider my="sm" />

            {Object.keys(groupedPermissions).map((group: string) => (
              <Group className="event-group" key={group} align="flex-start">
                <div className="e-group-name">{convertToTitleCase(group)}</div>

                <div className="group-items">
                  <Checkbox
                    label={`Access to all ${group} data`}
                    color="cyan"
                    checked={
                      selectionType === 'all' ||
                      groupedPermissions[group].every((permission: string) =>
                        form.values.authorities.includes(permission)
                      )
                    }
                    onChange={(event) => {
                      if (event.currentTarget.checked) {
                        form.setFieldValue('authorities', [
                          ...form.values.authorities,
                          ...groupedPermissions[group].map(
                            (permission: string) => permission
                          ),
                        ]);
                      } else {
                        setSelectionType('custom');

                        form.setFieldValue(
                          'authorities',
                          form.values.authorities.filter(
                            (permission) =>
                              !groupedPermissions[group]
                                .map((permission: string) => permission)
                                .includes(permission)
                          )
                        );
                      }
                    }}
                  />

                  {groupedPermissions[group].map((permission: string) => (
                    <Checkbox
                      key={permission}
                      label={convertToTitleCase(
                        permission.replace(group + '.', '')
                      )}
                      color="cyan"
                      checked={
                        selectionType === 'all' ||
                        form.values.authorities.includes(permission)
                      }
                      onChange={(event) => {
                        if (event.currentTarget.checked) {
                          form.setFieldValue('authorities', [
                            ...form.values.authorities,
                            permission,
                          ]);
                        } else {
                          setSelectionType('custom');

                          form.setFieldValue(
                            'authorities',
                            form.values.authorities.filter(
                              (event) => event !== permission
                            )
                          );
                        }
                      }}
                    />
                  ))}
                </div>
              </Group>
            ))}
          </ScrollArea>
        </div>

        <Group className="add-drawer-footer" position="apart">
          <Button
            color="dark"
            variant="default"
            compact
            className="compact-btn"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="dark"
            compact
            className="compact-btn"
            disabled={
              selectionType === 'custom' && form.values.authorities.length === 0
            }
          >
            Create Api Key
          </Button>
        </Group>
      </form>
    </div>
  );
};

interface IResponseModal {
  modalOpen: boolean;
  closeModal: () => void;
  token: string;
}

const ResponseModal = ({ modalOpen, closeModal, token }: IResponseModal) => {
  const { width } = useViewportSize();

  return (
    <Modal
      opened={modalOpen}
      onClose={closeModal}
      title={<span className="modal-title">API Key Token</span>}
      size={600}
      fullScreen={width < 700}
    >
      <Text color="#7F7F7F" fz={13} mb={14} mt={30} fw={500}>
        For security reasons, this is the only time we will display this token
        to you. You will need to generate a new token if you lose this one.
      </Text>

      <Prism mt={10} language="json" noCopy className="apikey-token">
        {token}
      </Prism>
      <Group mt={5}>
        <CopyButton value={token}>
          {({ copied, copy }) => (
            <Button
              compact
              variant={copied ? 'light' : 'default'}
              color="cyan"
              onClick={copy}
              className="compact-btn"
            >
              {copied ? 'Copied' : 'Copy'}
            </Button>
          )}
        </CopyButton>
      </Group>

      <Group mt={20} position="right">
        <Button color="dark" compact onClick={closeModal}>
          Done
        </Button>
      </Group>
    </Modal>
  );
};

interface GroupSelectItemProps {
  label: string;
  desc: string;
  checked: boolean;
  onClick: () => void;
}

const GroupSelectItem = ({
  label,
  desc,
  checked,
  onClick,
}: GroupSelectItemProps) => {
  return (
    <Group
      position="apart"
      align="flex-start"
      className={`group-select-item click ${checked ? 'selected' : ''}`}
      onClick={onClick}
      spacing={4}
    >
      <div>
        <Text color="#11181C" fw={500} fz={14}>
          {label}
        </Text>

        <Text color="#2E2E2E" fw={500} fz={13} opacity={0.5}>
          {desc}
        </Text>
      </div>

      <Group align="center">
        <Checkbox color="cyan" checked={checked} readOnly />
      </Group>
    </Group>
  );
};

export default CreateApiKey;
