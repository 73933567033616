import { Verification } from './customersTypes';

export type CreateBusinessCustomerData = {
  businessName: string;
  industry: string;
  registrationType: string;
  dateOfRegistration: string;
  country: string;
  description: string;
  website?: string;
  phoneNumber: string;
  email: Email;
  address: {
    main: Address;
    registered: Address;
  };
  metadata?: Record<string, string>;
  contact?: {
    email: string;
    phoneNumber: string;
    fullName: FullName;
    bvn: string;
  };
};

export type BusinessCustomerType = {
  id: string;
  type: string;
  organization: Organization;
  country: string;
  website: string;
  address: {
    main: Address;
    registered: Address;
  };
  isRoot: boolean;
  businessName: string;
  dateOfRegistration: string;
  description: string;
  industry: string;
  createdAt: Date;
  phoneNumber: string;
  registrationType: string;
  contact: Contact;
  email: Email;
  verification: Verification;
  status: string;
  officers: {
    data: OfficerType[];
  };
};

interface Organization {
  data: Data;
}

export interface Data {
  id: string;
  type: string;
}

export interface AddOfficerData {
  fullName: FullName;
  role: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  title?: string;
  nationality: string;
  identificationType: string;
  idDocumentNumber: string;
  address: Address;
  bvn: string;
  percentageOwned: number;
}

export interface OfficerType {
  id: string;
  type: string;
  customer: {
    data: Data;
  };
  verification: Verification;
  status: string;
  fullName: FullName;
  role: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  title?: string;
  nationality: string;
  identificationType?: string;
  idDocumentNumber?: string;
  address: Address;
  bvn: string;
  percentOwned: number;
}

export enum OfficerRoles {
  DIRECTOR = 'DIRECTOR',
  OWNER = 'OWNER',
}

export interface Contact {
  fullName: FullName;
  email: string;
  phoneNumber: string;
  bvn: string;
}

export interface FullName {
  firstName: string;
  lastName: string;
  middleName: string;
  maidenName?: string | null;
}

export interface Address {
  addressLine_1: string;
  addressLine_2: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
}

export interface Email {
  general: string;
  support: string;
  dispute: string;
}
