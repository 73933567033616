import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  Badge,
  ScrollArea,
  Group,
  Button,
  LoadingOverlay,
} from '@mantine/core';
import useReportsService from '../../services/reports';
import useNotification from '../../hooks/useNotification';
import {
  ReportStatusTypes,
  ReportType,
  ReportTypes,
  ReportDocumentTypes,
} from '../../types/reportsTypes';
import { ReactComponent as Download } from '../../assets/svg/download.svg';
import { DetailItem2 } from '../../components/DetailItem/DetailItem';
import { downloadFile, formatDate2 } from '../../lib/util';

interface IReportDetails {
  modalOpen: boolean;
  reportId: string;
}

const ReportDetails = ({ modalOpen, reportId }: IReportDetails) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState<ReportType | null>(null);
  const { handleError } = useNotification();
  const { getReport, downloadReport } = useReportsService();

  useEffect(() => {
    if (modalOpen) {
      handleGetReport();
    }
    //eslint-disable-next-line
  }, [reportId]);

  const handleGetReport = () => {
    setLoading(true);

    getReport(reportId)
      .then((res: ReportType) => {
        setReport(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownloadReport = () => {
    setLoading(true);

    downloadReport(reportId)
      .then((res: File) => {
        const fileName = `${report?.account.data.accountName}-${report?.reportType}-${report?.fromDate}-${report?.toDate}`;
        const extension =
          report?.documentType === ReportDocumentTypes.EXCEL
            ? 'xlsx'
            : report?.documentType;

        downloadFile(
          res,
          `${fileName}.${extension}`,
          'application/octet-stream'
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ScrollArea style={{ height: '88vh' }} type="scroll">
      <LoadingOverlay visible={loading} />

      {report && (
        <div className="report-details details-view">
          <Group position="apart" align="center" mb={30}>
            <Badge
              color={
                report.status === ReportStatusTypes.completed ? 'green' : 'gray'
              }
            >
              {report.status}
            </Badge>
          </Group>

          <DetailItem2
            title="Type"
            desc={
              <div className="type-badge">
                {report.reportType === ReportTypes.transaction_history &&
                  'Transaction history'}
                {report.reportType === ReportTypes.balance_summary &&
                  'Balance summary'}
              </div>
            }
          />

          <DetailItem2 title="Report ID" desc={report.id} />

          <DetailItem2
            title="Start Date → End Date"
            desc={
              <div>
                {report.fromDate} <span className="arrow-right-text">→</span>{' '}
                {report.toDate}
              </div>
            }
          />

          <DetailItem2
            title="Initiated"
            desc={formatDate2(report.createdAt, 'MMM D, YYYY h:mm a')}
          />

          {report.completedAt && (
            <DetailItem2
              title="Completed"
              desc={formatDate2(report.completedAt, 'MMM D, YYYY h:mm a')}
            />
          )}

          {report.account.data.accountName && (
            <DetailItem2
              title="Account Name"
              desc={report.account.data.accountName}
            />
          )}

          {report.account.data.accountNumber && (
            <DetailItem2
              title="Account Number"
              desc={report.account.data.accountNumber}
            />
          )}

          {report.account.data.id && (
            <DetailItem2 title="Account ID" desc={report.account.data.id} />
          )}

          {report?.documentType && (
            <DetailItem2
              title="Download"
              desc={
                <Button
                  color="dark"
                  leftIcon={<Download className="download-icon" />}
                  size="xs"
                  disabled={report.status !== ReportStatusTypes.completed}
                  onClick={handleDownloadReport}
                >
                  {report.documentType.toUpperCase()}
                </Button>
              }
            />
          )}
        </div>
      )}
    </ScrollArea>
  );
};

export default ReportDetails;
