import React, { useRef } from 'react';
import moment from 'moment';
import { AxiosError } from 'axios';
import { Modal, Group, LoadingOverlay, Button, Alert } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { DatePicker } from '@mantine/dates';
import useStatementsService from '../../../services/statements';
import { CreateStatementData } from '../../../types/statementsTypes';
import useNotification from '../../../hooks/useNotification';
import { showNotification } from '@mantine/notifications';

interface IGenerateCustomeStatement {
  modalOpen: boolean;
  closeModal: () => void;
  accountId: string;
  accountType: 'DepositAccount' | 'SubAccount';
  callback: () => void;
}

const GenerateCustomeStatement = ({
  modalOpen,
  closeModal,
  accountId,
  accountType,
  callback,
}: IGenerateCustomeStatement) => {
  const { width } = useViewportSize();
  const [fromDate, setFromDate] = React.useState<Date | null>(null);
  const [toDate, setToDate] = React.useState<Date | null>(null);
  const fromRef = useRef<HTMLInputElement>(null);
  const toRef = useRef<HTMLInputElement>(null);
  const [disableFromRef, setDisableFromRef] = React.useState<boolean>(false);
  const [disableToRef, setDisableToRef] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { createStatement } = useStatementsService();
  const { handleError } = useNotification();

  const handleSubmit = () => {
    if (!fromDate || !toDate) {
      return;
    }

    setLoading(true);

    const payload: CreateStatementData = {
      accountId,
      accountType,
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
      toDate: moment(toDate).format('YYYY-MM-DD'),
    };

    createStatement(payload)
      .then(() => {
        showNotification({
          title: 'Statement scheduled',
          message: 'Statement has been scheduled successfully',
          color: 'orange',
        });

        setFromDate(null);
        setToDate(null);

        callback();
        closeModal();
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      opened={modalOpen}
      onClose={closeModal}
      size={500}
      title={<div className="modal-title">Generate a custom statement</div>}
      overflow="inside"
      fullScreen={width < 568}
      padding={width < 568 ? 'lg' : 50}
      withCloseButton={false}
      radius={7}
    >
      <LoadingOverlay visible={loading} />

      <div className="modal-desc" style={{ maxWidth: 321 }}>
        Select dates to generate a statement.
      </div>

      {fromDate && toDate && fromDate > toDate && (
        <Alert mt={10} color="red" fw={500}>
          Start date cannot be after end date!
        </Alert>
      )}

      <div className="date-range-input">
        <div
          className="input-container"
          onClick={() => {
            if (disableFromRef) {
              return;
            }

            if (fromRef.current) {
              fromRef.current.click();
            }
          }}
        >
          <DatePicker
            value={fromDate}
            onChange={setFromDate}
            variant="unstyled"
            placeholder="Select date"
            label="From"
            clearable={false}
            color="dark"
            ref={fromRef}
            onDropdownOpen={() => setDisableFromRef(true)}
            onDropdownClose={() => {
              setTimeout(() => {
                setDisableFromRef(false);
              }, 200);
            }}
          />
        </div>

        <div
          className="input-container"
          onClick={() => {
            if (disableToRef) {
              return;
            }

            if (toRef.current) {
              toRef.current.click();
            }
          }}
        >
          <DatePicker
            value={toDate}
            onChange={setToDate}
            variant="unstyled"
            placeholder="Select date"
            label="To"
            clearable={false}
            color="dark"
            ref={toRef}
            onDropdownOpen={() => setDisableToRef(true)}
            onDropdownClose={() => {
              setTimeout(() => {
                setDisableToRef(false);
              }, 200);
            }}
          />
        </div>
      </div>

      <Group position="right" mt={60}>
        <Button
          variant="default"
          compact
          className="compact-btn"
          onClick={closeModal}
        >
          Cancel
        </Button>

        <Button
          color="dark"
          compact
          className="compact-btn"
          onClick={handleSubmit}
          disabled={fromDate && toDate && fromDate > toDate ? true : false}
        >
          Generate Statement
        </Button>
      </Group>
    </Modal>
  );
};

export default GenerateCustomeStatement;
