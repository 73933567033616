import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Divider } from '@mantine/core';
import { useAppSelector } from '../../../hooks/useRedux';
import ChangePassword from '../../../components/modals/ChangePassword/ChangePassword';
import './profile.scss';

const Profile = () => {
  const userDetails = useAppSelector((state) => state.user.userDetails);
  const [changePasswordModal, setChangePasswordModal] =
    useState<boolean>(false);

  return (
    <div className="profile">
      <Helmet>
        <title>Profile | Anchor</title>
        <meta
          name="description"
          content="View and edit your profile information"
        />
      </Helmet>

      <ChangePassword
        modalOpen={changePasswordModal}
        closeModal={() => setChangePasswordModal(false)}
      />

      <div className="p-title extra-mb">Profile</div>

      <div className="p-group">
        <div className="p-g-item">
          <div>First name</div>
          <div>{userDetails.firstName}</div>
        </div>

        <div className="p-g-item">
          <div>Last name</div>
          <div>{userDetails.lastName}</div>
        </div>

        <div className="p-g-item">
          <div>Email Address</div>
          <div>{userDetails.email}</div>
        </div>

        <div className="p-g-item">
          <div>Phone Number</div>
          <div>{userDetails.phoneNumber}</div>
        </div>
      </div>

      <Divider mb={40} />

      <div className="p-sub-title">Change password</div>
      <div className="p-sub-desc">
        Set a unique password to protect your account.
      </div>
      <Button
        color="dark"
        compact
        className="compact-btn"
        mt={16}
        onClick={() => setChangePasswordModal(true)}
      >
        Change Password
      </Button>
    </div>
  );
};

export default Profile;
