import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  LoadingOverlay,
  TextInput,
  Select,
  Button,
  Modal,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { ReactComponent as ArrowBack } from '../../assets/svg/arrow-back.svg';
import useCounterpartyService from '../../services/counterparties';
import {
  BankType,
  CounterpartyType,
  CreateCounterpartyData,
  VerifyAccountResponse,
} from '../../types/counterpartiesTypes';
import useNotification from '../../hooks/useNotification';
import usePermissions from '../../hooks/usePermissions';

interface ICreateCounterparty {
  withinModal?: boolean;
  callback?: (data: CounterpartyType) => void;
}

const CreateCounterparty = ({ withinModal, callback }: ICreateCounterparty) => {
  const navigate = useNavigate();
  const { handleError } = useNotification();
  const { getBankList, verifyAccount, createCounterparty } =
    useCounterpartyService();
  const [bankList, setBankList] = useState<BankType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { counterpartyWrite } = usePermissions();

  const form = useForm({
    initialValues: {
      accountName: '',
      accountNumber: '',
      nipCode: '',
    },

    validate: {
      nipCode: (value) => (value === '' ? 'Select bank' : null),
      accountName: (value) => (value === '' ? 'Account name missing' : null),
    },
  });

  useEffect(() => {
    handleGetBankList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleVerifyAccount();
    //eslint-disable-next-line
  }, [form.values.accountNumber, form.values.nipCode]);

  const handleGetBankList = () => {
    getBankList()
      .then((res: BankType[]) => {
        setBankList(res);
      })
      .catch((err: AxiosError) => {
        handleError(err);
      });
  };

  const handleVerifyAccount = () => {
    form.setFieldValue('accountName', '');
    const { nipCode, accountNumber } = form.values;

    if (nipCode && accountNumber.length === 10) {
      setLoading(true);

      verifyAccount(nipCode, accountNumber)
        .then((res: VerifyAccountResponse) => {
          form.setFieldValue('accountName', res.accountName);
        })
        .catch((err: AxiosError) => {
          handleError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = (values: typeof form.values) => {
    const { accountName, nipCode, accountNumber } = values;

    const selectedBank = bankList.find(
      (bank: BankType) => nipCode === bank.nipCode
    );

    if (!selectedBank) {
      return;
    }

    const request: CreateCounterpartyData = {
      accountName,
      accountNumber,
      bankId: selectedBank.id,
    };

    setLoading(true);

    createCounterparty(request)
      .then((res: CounterpartyType) => {
        showNotification({
          title: 'Success',
          message: 'Counterparty created.',
          color: 'orange',
        });

        if (withinModal && callback) {
          callback(res);
        } else {
          navigate(-1);
        }
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="add-new">
      <LoadingOverlay visible={loading} />

      {!withinModal && (
        <div
          className="back-btn click"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBack /> <span>Back</span>
        </div>
      )}

      <div className="add-new-main">
        {!withinModal && <div className="i-m-title">Add Counterperty</div>}

        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Select
            label="Select Bank"
            required
            mt={withinModal ? -10 : 24}
            placeholder="Select Bank"
            data={bankList.map((bank: BankType) => ({
              value: bank.nipCode,
              label: bank.name,
            }))}
            searchable
            {...form.getInputProps('nipCode')}
          />

          <TextInput
            required
            mt="sm"
            label="Account Number"
            placeholder="Account Number"
            type="number"
            value={form.values.accountNumber}
            onKeyDown={(e) =>
              ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
            }
            onChange={(e: any) => {
              if (e.target.value.length > 10) {
                return;
              }
              if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
                form.setFieldValue('accountNumber', e.target.value);
              }
            }}
          />

          <TextInput
            required
            mt="sm"
            label="Account Name"
            placeholder="Account Name"
            disabled
            {...form.getInputProps('accountName')}
          />

          <Button
            type="submit"
            color="dark"
            fullWidth
            mt={20}
            disabled={!counterpartyWrite}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

interface ICreateCounterpartyModal {
  modalOpen: boolean;
  closeModal: () => void;
  callback?: (data: CounterpartyType) => void;
}

export const CreateCounterpartyModal = ({
  modalOpen,
  closeModal,
  callback,
}: ICreateCounterpartyModal) => {
  return (
    <Modal
      opened={modalOpen}
      onClose={closeModal}
      title={<Text fw={600}>Add New Counterparty</Text>}
    >
      <CreateCounterparty withinModal callback={callback} />
    </Modal>
  );
};

export default CreateCounterparty;
