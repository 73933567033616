import { APIS } from '../lib/api/apilog';
import { ApiAuthMode, useApi } from '../lib/api';
import { useAppSelector } from '../hooks/useRedux';
import { AxiosError } from 'axios';
import { selectMode } from '../lib/store';
import {
  GetVirtualNubansParams,
  CreateVirtualNubanData,
  GetVirtualNubansResponse,
  VirtualNubanType,
} from '../types/virtualNubansTypes';

const useVirtualNubansService = () => {
  const mode = useAppSelector(selectMode);
  const authApi = useApi(ApiAuthMode.BearerToken, mode);

  const getVirtualNubans = (params: GetVirtualNubansParams) => {
    return new Promise<GetVirtualNubansResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.VIRTUAL_NUBANS.VIRTUAL_NUBANS}?size=${params.size}&page=${params.page}`,
          {
            params: {
              include: '',
              ...(params?.query && {
                query: params?.query,
              }),
            },
          }
        )
        .then((res: GetVirtualNubansResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getVirtualNuban = (virtualAccountId: string) => {
    return new Promise<{ data: VirtualNubanType }>((resolve, reject) => {
      authApi
        .get(APIS.VIRTUAL_NUBANS.VIRTUAL_NUBAN(virtualAccountId))
        .then((res: { data: VirtualNubanType }) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const getVirtualNubansByAccount = (
    accountId: string,
    size: number,
    page: number
  ) => {
    return new Promise<GetVirtualNubansResponse>((resolve, reject) => {
      authApi
        .get(
          `${APIS.VIRTUAL_NUBANS.FETCH_BY_SETTLEMENT_ACCOUNT(
            accountId
          )}?size=${size}&page=${page}`
        )
        .then((res: GetVirtualNubansResponse) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  const createVirtualNuban = (data: CreateVirtualNubanData) => {
    return new Promise<VirtualNubanType>((resolve, reject) => {
      const body = {
        virtualAccountDetail: data.virtualAccountDetail,
        metadata: data.metadata,
        settlementAccount: {
          data: {
            id: data.settlementAccountId,
            type: data.settlementAccountId.includes('anc_acc')
              ? 'DepositAccount'
              : 'SubAccount',
          },
        },
      };

      authApi
        .request({
          method: 'POST',
          url: APIS.VIRTUAL_NUBANS.VIRTUAL_NUBANS,
          type: 'VirtualNuban',
          body,
        })
        .then((res: VirtualNubanType) => {
          resolve(res);
        })
        .catch((axiosError: AxiosError) => {
          reject(axiosError);
        });
    });
  };

  return {
    getVirtualNubans,
    getVirtualNuban,
    getVirtualNubansByAccount,
    createVirtualNuban,
  };
};

export default useVirtualNubansService;
