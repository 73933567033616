import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Table,
  ScrollArea,
  Text,
  Badge,
  Drawer,
  LoadingOverlay,
} from '@mantine/core';
import useSettlementsService from '../../services/settlements';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import { formatAmount } from '../../lib/util';
import { AxiosError } from 'axios';
import {
  SettlementType,
  GetSettlementsResponse,
  SettlementStatusTypes,
} from '../../types/settlementsTypes';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import { selectMode } from '../../lib/store';
import { setSettlements } from '../../lib/store/reducers/DataReducer';
import { DetailsWrapper as PaymentDetails } from '../Payments/Payments';
import useNotification from '../../hooks/useNotification';

const Settlements = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const settlements = useAppSelector((state) => state.data.settlements);
  const mode = useAppSelector(selectMode);
  const dispatch = useAppDispatch();
  const { getSettlements } = useSettlementsService();
  const [paymentId, setPaymentId] = useState<string>('');
  const [detailsDrawer, setDetailsDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleError } = useNotification();

  useEffect(() => {
    handleGetSettlements();
    //eslint-disable-next-line
  }, [page, size, mode]);

  const handleGetSettlements = () => {
    if (!settlements.dataFetched) {
      setLoading(true);
    }

    getSettlements({
      size,
      page,
    })
      .then((res: GetSettlementsResponse) => {
        dispatch(
          setSettlements({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((error: AxiosError) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Settlements | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of settlements"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <Drawer
        opened={detailsDrawer}
        onClose={() => setDetailsDrawer(false)}
        title={
          <Text fw={600} fz={23}>
            Payment Details
          </Text>
        }
        padding="xl"
        size={650}
        position="right"
      >
        <PaymentDetails paymentId={paymentId} modalActive={detailsDrawer} />
      </Drawer>

      <div className="p-title extra-mb">Settlements</div>

      {settlements.meta.total > 0 && (
        <React.Fragment>
          <ScrollArea
            offsetScrollbars
            type="always"
            className="table-scroll-container"
          >
            <Table
              mt={10}
              verticalSpacing="md"
              sx={{ minWidth: 500 }}
              highlightOnHover
            >
              <thead>
                <tr style={{ maxWidth: 800 }}>
                  <th>Amount</th>
                  <th>Settled Amount</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {settlements.data.map(
                  (settlement: SettlementType, index: number) => (
                    <tr
                      className="click"
                      key={index}
                      onClick={() => {
                        setPaymentId(settlement?.payment?.data?.id);
                        setDetailsDrawer(true);
                      }}
                      id={settlement.id}
                    >
                      <td>{`₦${formatAmount(settlement?.amount)}`}</td>

                      <td>{`₦${formatAmount(settlement?.settledAmount)}`}</td>

                      <td>
                        <Badge
                          mb={5}
                          color={
                            settlement.status ===
                            SettlementStatusTypes.COMPLETED
                              ? 'green'
                              : 'red'
                          }
                        >
                          {settlement?.status}
                        </Badge>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </ScrollArea>

          {!loading && (
            <PaginationControls
              meta={settlements.meta}
              setPage={setPage}
              setLoader={setLoading}
              setSize={setSize}
            />
          )}
        </React.Fragment>
      )}

      {settlements.meta.total === 0 && (
        <EmptyData
          title="There are no settlements to display"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

export default Settlements;
