import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';
import useTransactionsService from '../../services/transactions';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import EmptyData from '../../components/EmptyData/EmptyData';
import { selectMode } from '../../lib/store';
import TransactionsTable from './TransactionsTable';
import { setTransactions } from '../../lib/store/reducers/DataReducer';
import { GetTransactionsResponse } from '../../types/transactionsTypes';
import useNotification from '../../hooks/useNotification';
import './transactions.scss';
import { LoadingOverlay } from '@mantine/core';

const BankTransactions = () => {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [query, setQuery] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [direction, setDirection] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const { getTransactions } = useTransactionsService();
  const transactions = useAppSelector((state) => state.data.transactions);
  const mode = useAppSelector(selectMode);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const { handleError } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    handleGetTransactions();
    //eslint-disable-next-line
  }, [
    page,
    size,
    query,
    mode,
    type,
    accountId,
    direction,
    customerId,
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    if (search) {
      setQuery(search);
    }
  }, [search]);

  const handleGetTransactions = () => {
    if (!transactions.dataFetched || query) {
      setLoading(true);
    }

    getTransactions({
      size,
      query,
      page,
      accountId,
      type,
      direction,
      customerId,
      from: dateFrom,
      to: dateTo,
    })
      .then((res: GetTransactionsResponse) => {
        dispatch(
          setTransactions({
            data: res?.data,
            meta: res?.meta?.pagination,
            dataFetched: true,
          })
        );
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterItemChange = (
    value: string,
    itemState?: string,
    setItemState?: (value: string) => void
  ) => {
    if (value !== itemState) setLoading(true);
    setItemState && setItemState(value);
  };

  return (
    <div className="table-container">
      <Helmet>
        <title>Transactions | Anchor</title>
        <meta
          name="description"
          content="View the summary and details of transactions"
        />
      </Helmet>

      <LoadingOverlay visible={loading} />

      <div className="p-title">Transactions</div>

      {transactions.dataFetched && (
        <TransactionsTable
          transactions={transactions}
          setPage={setPage}
          type={type}
          setType={setType}
          accountId={accountId}
          setAccountId={setAccountId}
          direction={direction}
          setDirection={setDirection}
          customerId={customerId}
          setCustomerId={setCustomerId}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          onFilterItemChange={onFilterItemChange}
          loading={loading}
          setLoading={setLoading}
          setSize={setSize}
        />
      )}

      {transactions.meta.total === 0 && !transactions.dataFetched && (
        <EmptyData
          title="There are no transactions to display"
          desc="At the moment, there is no data to be displayed."
        />
      )}
    </div>
  );
};

export default BankTransactions;
