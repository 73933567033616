import React, { useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { showNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  NumberInput,
  Button,
  Checkbox,
  Anchor,
} from '@mantine/core';
import AuthHeader, { HeaderTypes } from '../../components/Auth/AuthHeader';
import { passwordSchema } from '../../lib/utility/validator';
import useAuthService from '../../services/auth';
import { SignupType } from '../../types/authTypes';
import { useAppDispatch } from '../../hooks/useRedux';
import { setShowLoader } from '../../lib/store/reducers/UtilityReducer';
import useNotification from '../../hooks/useNotification';
import './auth.scss';

const Signup = () => {
  const { signup } = useAuthService();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleError } = useNotification();

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      invitationCode: '',
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) =>
        passwordSchema.validate(value)
          ? null
          : 'Must contain 8 characters, 1 upper case, 1 symbol and 1 number.',
      confirmPassword: (value, values) =>
        value !== values.password ? 'Passwords do not match' : null,
      termsOfService: (value) => (!value ? 'Accept terms to continue' : null),
    },
  });

  useEffect(() => {
    const code = searchParams.get('code');

    if (code) {
      form.setValues((prev) => ({ ...prev, invitationCode: code }));
    }
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (values: typeof form.values) => {
    const {
      firstName,
      lastName,
      companyName,
      email,
      phoneNumber,
      password,
      invitationCode,
    } = values;

    const reqData: SignupType = {
      companyName,
      country: 'NG',
      user: {
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        invitationCode,
      },
    };

    dispatch(setShowLoader(true));

    signup(reqData)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Please login to continue.',
          autoClose: 1500,
          color: 'orange',
        });

        navigate('/signin');
      })
      .catch((err: AxiosError) => {
        handleError(err);
      })
      .finally(() => {
        dispatch(setShowLoader(false));
      });
  };

  return (
    <div className="auth-page">
      <Helmet>
        <title>Create account | Anchor</title>
        <meta name="description" content="Create an account to get started" />
      </Helmet>

      <AuthHeader type={HeaderTypes.hasAccount} />

      <div className="auth-main lrgr">
        <div className="a-m-title">
          Welcome to Anchor, <br /> Create an account to get started
        </div>
        <div className="a-m-desc"></div>

        <form
          className="a-form la6g"
          onSubmit={form.onSubmit((values) => handleSubmit(values))}
        >
          <div className="form-row">
            <TextInput
              label="First name"
              className="form-item"
              required
              placeholder="First name"
              {...form.getInputProps('firstName')}
            />

            <TextInput
              label="Last name"
              className="form-item"
              required
              placeholder="Last name"
              {...form.getInputProps('lastName')}
            />
          </div>

          <div className="form-row">
            <TextInput
              label="Email"
              className="form-item"
              required
              placeholder="your@email.com"
              {...form.getInputProps('email')}
            />

            <NumberInput
              label="Phone number"
              className="form-item"
              required
              placeholder="Phone number"
              min={234}
              defaultValue={234}
              hideControls
              {...form.getInputProps('phoneNumber')}
            />
          </div>

          <div className="form-row">
            <TextInput
              label="Company name"
              className="form-item"
              required
              placeholder="Company name"
              {...form.getInputProps('companyName')}
            />

            <TextInput
              label="Invite Code"
              className="form-item"
              required
              disabled={!!searchParams.get('code')}
              placeholder="Invite Code"
              {...form.getInputProps('invitationCode')}
            />
          </div>

          <div className="form-row">
            <PasswordInput
              required
              className="form-item"
              label="Password"
              placeholder="Create a password"
              {...form.getInputProps('password')}
            />

            <PasswordInput
              required
              className="form-item"
              label="Confirm password"
              placeholder="Confirm password"
              {...form.getInputProps('confirmPassword')}
            />
          </div>

          <Checkbox
            mt="md"
            label={
              <div
                className={`no-select ${
                  form?.errors?.termsOfService ? 'error-text' : ''
                }`}
              >
                I have read and agreed to the{' '}
                <Anchor
                  size="sm"
                  href="https://getanchor.co/privacy"
                  target="_blank"
                >
                  Privacy Policy
                </Anchor>{' '}
                and{' '}
                <Anchor
                  size="sm"
                  href="https://getanchor.co/terms"
                  target="_blank"
                >
                  Terms & Conditions
                </Anchor>
                .
              </div>
            }
            {...form.getInputProps('termsOfService', { type: 'checkbox' })}
          />

          <Button color="dark" mt={20} fullWidth type="submit">
            Create your account
          </Button>

          <div className="sec-action">
            Forgot your password?{' '}
            <span className="click">
              <Link to="/auth/forgot-password">Reset password</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
